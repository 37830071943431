import styled, { keyframes } from 'styled-components';

const floatingKeyframe = keyframes`
  0% {
    transform: rotate(-2deg) translateX(2px);
  }
  50% {
    transform: rotate(-4deg) translateX(-2px);
  }
  100% {
    transform: rotate(-16deg) translateX(2px);
  }
`;

const spinningKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const PlanetContainer = styled.div`
  position: relative;
  width: 158px;
  height: 86px;
`;

export const PlanetSVG = styled.div`
  position: absolute;
  top: 8px;
  left: 36px;
  animation: ${floatingKeyframe} 4s ease-in-out 0s infinite alternate;

  svg {
    height: 66px;
    width: 84px;
  }
`;

export const SatelitesSVG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${spinningKeyframe} 12s linear 0s infinite forwards;

  svg {
    height: 86px;
    width: 158px;
  }
`;
