import { PrimaryButton } from 'glints-aries/lib/@next';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const Container = styled.div`
  @media (max-width: ${BREAKPOINTS.small}) {
    width: 100%;
  }
`;

export const Button = styled(PrimaryButton)`
  @media (max-width: ${BREAKPOINTS.small}) {
    width: 100%;
  }
`;
