import { ReactComponent as TeamExpansionSVG } from '@/assets/images/team-expansion-budget-tool.svg';
import { ToolButton } from '@/components/molecules/ToolButton/ToolButton';

const TEAM_EXPANSION_URL =
  'https://talenthub.glints.com/employee-cost-calculator';

export interface BudgetToolButtonProps {
  onClick?: () => void;
}

export const BudgetToolButton = ({
  onClick = () => window.open(TEAM_EXPANSION_URL, '_blank'),
}: BudgetToolButtonProps) => (
  <ToolButton
    icon={<TeamExpansionSVG />}
    title="Team Expansion Budget Tool"
    description="Receive tailored salary suggestions based on budget, function, role, and job seniority."
    onClick={onClick}
  />
);
