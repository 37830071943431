import { Banner } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: ${space12} 20px;
`;

export const SingleFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  padding: ${space8} 0 ${space16};
  border-bottom: 1px solid ${Neutral.B85};

  &[data-noborder='true'] {
    border-bottom: none;
  }

  p {
    margin: 0;
  }
`;

export const FlexRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
`;

export const StyledBanner = styled(Banner)`
  min-height: 0;
`;
