import { eachMonthOfInterval, format, subMonths } from 'date-fns';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { PeriodLabel, StyledBadge } from './PeriodFilterStyle';
import { MONTH_YEAR_DATE_FORMAT } from '@/constants/date';

export const periodFilterOptions = () => {
  const today = new Date();
  const pastMonths = 11;
  const subPastMonths = subMonths(today, pastMonths);

  const dateInterval = eachMonthOfInterval({
    start: subPastMonths,
    end: today,
  });

  return dateInterval
    .map((date, index) => ({
      label: (
        <PeriodLabel>
          <Typography variant="body1" as="span" color={Neutral.B18}>
            {format(date, MONTH_YEAR_DATE_FORMAT)}
          </Typography>
          {index === pastMonths && (
            <StyledBadge status="information">Current</StyledBadge>
          )}
        </PeriodLabel>
      ),
      value: format(date, MONTH_YEAR_DATE_FORMAT),
    }))
    .reverse();
};
