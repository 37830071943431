import jwtDecode from 'jwt-decode';

import { checkTokenValid } from '../utils/checkTokenValid';

export const AUTH_TOKEN_KEY = 'mts_auth_token';

interface AuthTokens {
  getAuthToken: () => string | null;
  setAuthToken: (token: string) => void;
  clearAuthToken: () => void;
  isAuthenticated: () => boolean;
  getCurrentUserId: () => string | null;
}

/**
 * custom hook for storing and retrieving auth tokens
 */
export const useAuthToken = (): AuthTokens => {
  const getAuthToken = () => {
    try {
      return localStorage.getItem(AUTH_TOKEN_KEY);
    } catch {
      // user possibly disabled the storage or the quota limit has reached.
      // swallow the error, till we decide it's necessary to report the error to Bugsnag.
      return null;
    }
  };

  const setAuthToken = (token: string): void => {
    try {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
    } catch {
      // user possibly disabled the storage or the quota limit has reached.
      // swallow the error, till we decide it's necessary to report the error to Bugsnag.
    }
  };

  const clearAuthToken = (): void => {
    try {
      localStorage.removeItem(AUTH_TOKEN_KEY);
    } catch {
      // user possibly disabled the storage or the quota limit has reached.
      // swallow the error, till we decide it's necessary to report the error to Bugsnag.
    }
  };

  const isAuthenticated = () => {
    const token = getAuthToken();
    return token !== null ? checkTokenValid(token) : false;
  };

  const getCurrentUserId = () => {
    const token = getAuthToken();
    const decodedToken: { sub?: string } | null = token
      ? jwtDecode(token)
      : null;
    return decodedToken?.sub ?? null;
  };

  return {
    getAuthToken,
    setAuthToken,
    clearAuthToken,
    isAuthenticated,
    getCurrentUserId,
  };
};
