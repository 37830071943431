import { Fragment, useEffect, useState } from 'react';
import { addDays, format, parseISO } from 'date-fns';
import { Box } from 'glints-aries';
import {
  Banner,
  Card,
  Divider,
  Icon,
  Link,
  OutlineButton,
  PrimaryButton,
  Typography,
  useAlert,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { type Invoice, type PlatformPayment } from '../interfaces';
import {
  BANK_TRANSFER_STEP,
  type BankTransferStepType,
  PAYMENT_DUE_DATE_FORMAT,
  URL_INVOICE_PARAMETER_NAME,
} from './constants';
import {
  ReviewSummaryLoadingState,
  ViewInstructionsLoadingState,
} from './LoadingState';
import * as Styled from './styled.sc';
import { getGraphqlClient } from '@/clients/graphql';
import { CopyText } from '@/components/atoms/CopyText/CopyText';
import { NotReadyInvoiceText } from '@/components/atoms/NotReadyInvoiceText/NotReadyInvoiceText';
import { CardSectionData } from '@/components/CardSectionData/CardSectionData';
import { ALERT_STATUS, ALERT_TYPE, alertMessages } from '@/constants/alert';
import {
  type CreatePlatformPaymentMutation,
  type GetInvoiceByIdsQuery,
  type GetInvoicePdfMutation,
  type GetPlatformPaymentQuery,
  PlatformPaymentMethod,
  useCreatePlatformPaymentMutation,
  useGetInvoiceByIdsQuery,
  useGetInvoicePdfMutation,
  useGetPlatformPaymentQuery,
} from '@/generated/graphql';
import {
  ERROR_MESSAGES,
  ERROR_TYPES,
} from '@/modules/InvoicePayments/constants';
import { formatMoney } from '@/utils/formatString';

export const BankTransfer = () => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { open: openAlert } = useAlert();
  const [currentStep, setCurrentStep] = useState<BankTransferStepType>(
    BANK_TRANSFER_STEP.reviewSummary,
  );
  const [invoicesIds, setInvoicesByIds] = useState(
    searchParams.getAll(URL_INVOICE_PARAMETER_NAME),
  );
  const [newPlatformPaymentData, setPlatformPaymentData] = useState<
    PlatformPayment | undefined
  >();

  const {
    isLoading: isLoadingInvoices,
    refetch: refetchInvoices,
    data: invoicesData,
    isFetching: isFetchingInvoices,
  } = useGetInvoiceByIdsQuery<GetInvoiceByIdsQuery, Error>(graphqlClient, {
    ids: invoicesIds,
  });

  const existingPlatformPayment = invoicesData?.invoiceByIds
    .map((invoice) => invoice.platformPayment)
    .filter((referenceId) => Boolean(referenceId))[0];

  const {
    isLoading: isLoadingPlatformPayment,
    data: existingPlatformPaymentData,
  } = useGetPlatformPaymentQuery<GetPlatformPaymentQuery, Error>(
    graphqlClient,
    { referenceId: existingPlatformPayment?.referenceId ?? '' },
    {
      // only fetch if referenceId is present
      // otherwise we need to make a new platform payment
      enabled: Boolean(existingPlatformPayment?.referenceId),
    },
  );

  useEffect(() => {
    if (
      existingPlatformPaymentData &&
      currentStep !== BANK_TRANSFER_STEP.viewInstructions
    ) {
      setCurrentStep(BANK_TRANSFER_STEP.viewInstructions);
    }
  }, [currentStep, existingPlatformPaymentData]);

  const platformPaymentData =
    newPlatformPaymentData || existingPlatformPaymentData?.platformPayment;

  const { mutate: pdfMutate } = useGetInvoicePdfMutation<
    Error,
    GetInvoicePdfMutation
  >(graphqlClient);

  const { mutate: createPlatformPayment } = useCreatePlatformPaymentMutation<
    Error,
    CreatePlatformPaymentMutation
  >(graphqlClient);

  const onCreatePlatformPayment = () => {
    createPlatformPayment(
      {
        paymentMethod: PlatformPaymentMethod.BankTransfer,
        invoiceIds: invoicesData?.invoiceByIds.map((invoice) => invoice.id),
      },
      {
        onSuccess: (data: CreatePlatformPaymentMutation) => {
          setPlatformPaymentData(data.createPlatformPayment);
          refetchInvoices();
        },
      },
    );
  };

  const handleClickInvoicePdf = (id: string) => {
    const pdfVariables = {
      id,
    };
    pdfMutate(pdfVariables, {
      onSuccess: (data) => {
        window.open(data?.invoicePdf?.url, '_blank');
      },
      onError: (error) => {
        if (error.message.includes(ERROR_TYPES.invoicePdfNotReady)) {
          openAlert({
            content: ERROR_MESSAGES[ERROR_TYPES.invoicePdfNotReady],
            status: ALERT_STATUS.warning,
          });
        } else {
          openAlert({
            content: alertMessages[ALERT_TYPE.apiError],
            status: ALERT_STATUS.error,
          });
        }
      },
    });
  };

  const handleRemoveInvoice = (id: string) => {
    const invoiceIds = searchParams.getAll(URL_INVOICE_PARAMETER_NAME);
    const remainingInvoiceIds = invoiceIds.filter(
      (invoiceId) => invoiceId !== id,
    );

    setInvoicesByIds(remainingInvoiceIds);

    searchParams.delete(URL_INVOICE_PARAMETER_NAME);
    remainingInvoiceIds.forEach((invoiceId) => {
      searchParams.append(URL_INVOICE_PARAMETER_NAME, invoiceId);
    });
    setSearchParams(searchParams, { replace: true });
  };

  const navigateToAwaitingPaymentPage = () => {
    navigate('/invoice-payments/awaiting-payment');
  };

  const handleNextClick = () => {
    if (currentStep === BANK_TRANSFER_STEP.reviewSummary) {
      if (!existingPlatformPaymentData) onCreatePlatformPayment();
      setCurrentStep(BANK_TRANSFER_STEP.viewInstructions);
    }

    if (currentStep === BANK_TRANSFER_STEP.viewInstructions) {
      navigateToAwaitingPaymentPage();
    }
  };

  const isGetInvoicesLoading = isLoadingInvoices || isFetchingInvoices;

  const renderStepContent = () => {
    if (currentStep === BANK_TRANSFER_STEP.reviewSummary) {
      if (isGetInvoicesLoading) return <ReviewSummaryLoadingState />;

      const currency = invoicesData?.invoiceByIds[0]?.unpaidAmount?.currency;
      const total =
        invoicesData?.invoiceByIds
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.unpaidAmount.amount),
            0,
          )
          .toString() || '0';

      return (
        <Styled.ReviewSummaryContent>
          <Styled.InvoicePaymentSummary>
            <Styled.InvoicePaymentTotal>
              <Box pt={8}>
                <Typography as="span" variant="body2">
                  Invoice Payment Total
                </Typography>
              </Box>
              <Styled.TotalAmount>
                <Typography variant="overline" as="span" color={Neutral.B40}>
                  {currency}
                </Typography>
                <Typography as="span" variant="body2">
                  {formatMoney({
                    amount: total,
                  })}
                </Typography>
              </Styled.TotalAmount>
            </Styled.InvoicePaymentTotal>
            <Divider />
            {invoicesData?.invoiceByIds.map((invoice: Invoice) => (
              <Styled.InvoiceItem
                enabledHover={invoicesData?.invoiceByIds.length > 1}
                key={`invoice-item-${invoice.id}`}
              >
                {invoice.isPdfReady ? (
                  <Link
                    target="_blank"
                    onClick={() => handleClickInvoicePdf(invoice.id)}
                  >
                    {invoice.referenceNumber}
                  </Link>
                ) : (
                  <NotReadyInvoiceText
                    referenceNumber={invoice.referenceNumber}
                  />
                )}

                {invoice.creditNoteLines.length > 0 && (
                  <>
                    {invoice.creditNoteLines.map((creditNote, index) => (
                      <Typography
                        variant="overline"
                        as="span"
                        color={Neutral.B40}
                        key={`credit-note-${index}`}
                      >
                        {`${creditNote.amount.currency} ${formatMoney({
                          amount: creditNote.amount.amount,
                        })}`}
                      </Typography>
                    ))}
                  </>
                )}
                <Typography variant="body1" as="span">
                  {`${formatMoney({
                    amount: invoice.unpaidAmount.amount,
                  })}`}
                </Typography>
                {invoicesData?.invoiceByIds.length > 1 && (
                  <Styled.InvoiceItemCloseButton
                    onClick={() => handleRemoveInvoice(invoice.id)}
                  >
                    <Icon
                      name="ri-close-circle-fill"
                      height="20px"
                      width="20px"
                      fill={Neutral.B40}
                    />
                  </Styled.InvoiceItemCloseButton>
                )}
              </Styled.InvoiceItem>
            ))}
          </Styled.InvoicePaymentSummary>
          <Styled.InvoicePaymentTotalAmount>
            <Typography as="span" variant="body2">
              Total
            </Typography>
            <Styled.TotalAmount>
              <Typography variant="overline" as="span" color={Neutral.B40}>
                {currency}
              </Typography>
              <Typography as="span" variant="body2">
                {formatMoney({
                  amount: total,
                })}
              </Typography>
            </Styled.TotalAmount>
          </Styled.InvoicePaymentTotalAmount>
        </Styled.ReviewSummaryContent>
      );
    }

    if (currentStep === BANK_TRANSFER_STEP.viewInstructions) {
      if (isLoadingPlatformPayment) return <ViewInstructionsLoadingState />;

      const paymentDueDate = format(
        addDays(parseISO(platformPaymentData?.createdAt), 2),
        PAYMENT_DUE_DATE_FORMAT,
      );

      return (
        <Styled.ViewInstructionsContent>
          <Banner dismissable={false} status="warning">
            <Box mb={2}>
              Please follow the steps below and complete your payment at least 2
              days before the due date {paymentDueDate}, as there is typically a
              2-day processing time:
            </Box>
            <Box ml={2}>
              <ol>
                <li>Log in to your bank account.</li>
                <li>Enter the current amount.</li>
                <li>Copy and paste the Virtual Account Number.</li>
                <li>Complete your payment.</li>
              </ol>
            </Box>
          </Banner>
          <Card heading="Payment Details">
            <Card.Section>
              <Styled.RowFlexContainer>
                <Styled.LeftSectionContainer>
                  <CardSectionData label="Amount">
                    <CopyText
                      label={
                        formatMoney({
                          amount: platformPaymentData?.total.amount,
                        }) ?? ''
                      }
                      copyValue={platformPaymentData?.total.amount || ''}
                    />
                  </CardSectionData>
                </Styled.LeftSectionContainer>
                <Styled.RightSectionContainer>
                  <CardSectionData label="Billing Currency">
                    {platformPaymentData?.total.currency}
                  </CardSectionData>
                </Styled.RightSectionContainer>
              </Styled.RowFlexContainer>
              <Styled.RowFlexContainer>
                <CardSectionData label="Payables">
                  {invoicesData?.invoiceByIds.map((invoice: Invoice, index) => (
                    <Fragment key={`invoice-ref-${invoice.id}`}>
                      {invoice.isPdfReady ? (
                        <Link
                          target="_blank"
                          onClick={() => handleClickInvoicePdf(invoice.id)}
                        >
                          {invoice.referenceNumber}
                        </Link>
                      ) : (
                        <NotReadyInvoiceText
                          referenceNumber={invoice.referenceNumber}
                        />
                      )}
                      {index !== invoicesData?.invoiceByIds.length - 1 && (
                        <span>{', '}</span>
                      )}
                    </Fragment>
                  ))}
                </CardSectionData>
              </Styled.RowFlexContainer>
            </Card.Section>
          </Card>
          <Styled.BankTransferDetailsCard>
            <Card heading="Bank Transfer Details">
              <Card.Section>
                <Styled.RowFlexContainer>
                  <Styled.LeftSectionContainer>
                    <CardSectionData label="Beneficiary Name">
                      <CopyText
                        label={platformPaymentData?.beneficiaryName || ''}
                        copyValue={platformPaymentData?.beneficiaryName || ''}
                      />
                    </CardSectionData>
                  </Styled.LeftSectionContainer>
                  <Styled.RightSectionContainer>
                    <CardSectionData label="Bank">
                      <CopyText
                        label={platformPaymentData?.bankName || ''}
                        copyValue={platformPaymentData?.bankName || ''}
                      />
                    </CardSectionData>
                  </Styled.RightSectionContainer>
                </Styled.RowFlexContainer>
                <Styled.RowFlexContainer>
                  <Styled.LeftSectionContainer>
                    <CardSectionData label="Bank Code">
                      <CopyText
                        label={platformPaymentData?.bankCode || ''}
                        copyValue={platformPaymentData?.bankCode || ''}
                      />
                    </CardSectionData>
                  </Styled.LeftSectionContainer>
                  <Styled.RightSectionContainer>
                    <CardSectionData label="Virtual Account Number">
                      <CopyText
                        label={platformPaymentData?.virtualAccount || ''}
                        copyValue={platformPaymentData?.virtualAccount || ''}
                      />
                    </CardSectionData>
                  </Styled.RightSectionContainer>
                </Styled.RowFlexContainer>
              </Card.Section>
            </Card>
          </Styled.BankTransferDetailsCard>
        </Styled.ViewInstructionsContent>
      );
    }
  };
  return (
    <Styled.BankTransferContainer>
      <Box>
        <Typography as="span" variant="headline6" color={Neutral.B18}>
          Pay via Bank Transfer
        </Typography>
      </Box>
      <Styled.BankTransferContent>
        <Styled.StepsContainer>
          <Styled.Steps currentStep={currentStep}>
            <Styled.Steps.Step label="Review Summary" />
            <Styled.Steps.Step label="View Instructions" />
          </Styled.Steps>
        </Styled.StepsContainer>
        <Styled.StepContent>{renderStepContent()}</Styled.StepContent>
      </Styled.BankTransferContent>

      <Styled.BankTransferFooter>
        <Styled.BankTransferFooterContent>
          {currentStep === BANK_TRANSFER_STEP.reviewSummary ? (
            <OutlineButton onClick={navigateToAwaitingPaymentPage}>
              Cancel
            </OutlineButton>
          ) : (
            <>&nbsp;</>
          )}
          <PrimaryButton
            icon={
              currentStep !== BANK_TRANSFER_STEP.viewInstructions ? (
                <Icon name="ri-arrow-m-right-line" />
              ) : (
                <></>
              )
            }
            iconPosition="right"
            onClick={handleNextClick}
          >
            {currentStep !== BANK_TRANSFER_STEP.viewInstructions
              ? 'Next'
              : 'Done'}
          </PrimaryButton>
        </Styled.BankTransferFooterContent>
      </Styled.BankTransferFooter>
    </Styled.BankTransferContainer>
  );
};
