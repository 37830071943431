import { useEffect, useState } from 'react';
import { format, getMonth, getYear, parse } from 'date-fns';
import {
  Alert,
  Badge,
  Icon,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { periodFilterOptions } from '../components/PeriodFilter';
import {
  alertContent,
  AlertType,
  JS_MONTH_OFFSET,
  type ShowAlertType,
} from '../constants';
import { DailyLogTable } from './components/DailyLogTable';
import {
  employmentStatusText,
  selectAttendanceStatusOptions,
  talentStatusBadgeMapping,
} from './constants';
import {
  AttendanceStatusSelect,
  AttendanceStatusSelectContainer,
  BackButtonContainer,
  Container,
  ExportContainer,
  FilterContainer,
  HeadingContainer,
  HeadingNav,
  HeadingTitle,
  LeftContainer,
  PeriodSelect,
  PeriodSelectContainer,
} from './DailyLogStyle';
import { getGraphqlClient } from '@/clients/graphql';
import { MONTH_YEAR_DATE_FORMAT } from '@/constants/date';
import {
  AttendanceSortField,
  type AttendanceStatus,
  type ExportTalentAttendanceMutation,
  type GetTalentAttendanceLogsQuery,
  SortOrder,
  useExportTalentAttendanceMutation,
  useGetTalentAttendanceLogsQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';

const DailyLog = () => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState<ShowAlertType>({
    status: undefined,
    content: '',
    shouldShow: false,
  });
  const { hubberId } = useParams();
  const today = new Date();
  const [searchParams, setSearchParams] = useSearchParams({
    status: selectAttendanceStatusOptions[0].value,
  });

  const yearParam = searchParams.get('year');
  const monthParam = searchParams.get('month');
  const dateParamFormat = 'yyyy-M';

  const [prevSearch] = useState(searchParams.get('prevSearch') ?? '');
  const [prevPage] = useState(searchParams.get('prevPage') ?? '1');
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    (searchParams.get('sortOrder') as SortOrder) ?? SortOrder.Desc,
  );
  const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState(
    searchParams.get('status') ?? selectAttendanceStatusOptions[0].value,
  );
  const [selectedPeriod, setSelectedPeriod] = useState(() => {
    if (yearParam && monthParam) {
      const dateString = `${yearParam}-${monthParam}`;
      const parsedDate = parse(dateString, dateParamFormat, new Date());
      return format(parsedDate, MONTH_YEAR_DATE_FORMAT);
    } else {
      return format(today, MONTH_YEAR_DATE_FORMAT);
    }
  });

  const parsedSelectedPeriodValue = parse(
    selectedPeriod,
    MONTH_YEAR_DATE_FORMAT,
    new Date(),
  );

  const getAttendanceVariables = {
    id: hubberId,
    period: {
      year: getYear(parsedSelectedPeriodValue),
      month: getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET,
    },
    sorts: {
      field: AttendanceSortField.Date,
      order: sortOrder,
    },
    ...(selectedAttendanceStatus !== selectAttendanceStatusOptions[0].value && {
      status: selectedAttendanceStatus as AttendanceStatus,
    }),
  };

  const {
    isLoading: isLoadingExport,
    mutate: exportMutate,
    error: exportTalentAttendanceError,
  } = useExportTalentAttendanceMutation<Error, ExportTalentAttendanceMutation>(
    graphqlClient,
  );

  const {
    isLoading: isLoadingAttendance,
    data: attendanceData,
    error: getAttendanceError,
    isFetching: isFetchingAttendance,
  } = useGetTalentAttendanceLogsQuery<GetTalentAttendanceLogsQuery, Error>(
    graphqlClient,
    getAttendanceVariables,
    {},
  );

  const handleBack = () => {
    navigate({
      pathname: '/attendance',
      search: createSearchParams({
        year: String(getYear(parsedSelectedPeriodValue)),
        month: String(getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET),
        page: prevPage,
        name: prevSearch,
      }).toString(),
    });
  };

  useEffect(() => {
    const handlePopState = () => handleBack();
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleResetFiltersClick = () => {
    setSelectedAttendanceStatus(selectAttendanceStatusOptions[0].value);
    setSelectedPeriod(format(today, MONTH_YEAR_DATE_FORMAT));
  };

  const handleSortClick = () => {
    setSortOrder(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
  };

  const handleSelectPeriod = ({ value }: { value: string }) => {
    setSelectedPeriod(value);

    const parsedDateValue = parse(value, MONTH_YEAR_DATE_FORMAT, new Date());
    setSearchParams({
      year: String(getYear(parsedDateValue)),
      month: String(getMonth(parsedDateValue) + JS_MONTH_OFFSET),
    });
  };

  const handleSelectStatus = ({ value }: { value: string }) => {
    setSelectedAttendanceStatus(value);
    setSearchParams({ status: value });
  };

  const onExport = async () => {
    const exportVariables = {
      hubberId,
      period: {
        year: getYear(parsedSelectedPeriodValue),
        month: getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET,
      },
      ...(selectedAttendanceStatus !==
        selectAttendanceStatusOptions[0].value && {
        status: selectedAttendanceStatus as AttendanceStatus,
      }),
    };

    exportMutate(exportVariables, {
      onSuccess: (exportData) => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });

        const linkElement = document.createElement('a');
        linkElement.href = exportData?.exportHubberAttendance?.fileUrl;
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
      },
      onError() {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
      },
    });
  };

  useEffect(() => {
    setSearchParams({
      year: String(getYear(parsedSelectedPeriodValue)),
      month: String(getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET),
      status: selectedAttendanceStatus,
      sortField: AttendanceSortField.Date,
      sortOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttendanceStatus, setSearchParams, sortOrder]);

  useGraphqlError([getAttendanceError, exportTalentAttendanceError]);

  return (
    <Container>
      <HeadingContainer>
        <HeadingNav>
          <BackButtonContainer onClick={handleBack}>
            <Icon
              name="ri-arrow-m-left-line"
              height="24px"
              fill={Neutral.B40}
            />
          </BackButtonContainer>
          <Typography as="div" variant="caption" color={Neutral.B40}>
            Back to overview
          </Typography>
        </HeadingNav>
        <HeadingTitle>
          <Typography variant="headline6" as="span" color={Neutral.B18}>
            {attendanceData?.hubber.fullName}
          </Typography>
          {attendanceData?.hubber.status && (
            <Badge
              status={talentStatusBadgeMapping[attendanceData?.hubber.status]}
            >
              {employmentStatusText[attendanceData?.hubber.status]}
            </Badge>
          )}
        </HeadingTitle>
      </HeadingContainer>
      <FilterContainer>
        <LeftContainer>
          <PeriodSelectContainer>
            <PeriodSelect
              prefix={
                <Icon
                  name="ri-calendar-event-line"
                  height="20px"
                  fill={Neutral.B40}
                />
              }
              options={periodFilterOptions()}
              onSelect={handleSelectPeriod}
              selectedValues={[selectedPeriod]}
              width="100%"
              listHeight={248}
            />
          </PeriodSelectContainer>
          <AttendanceStatusSelectContainer>
            <AttendanceStatusSelect
              prefix={
                <Icon
                  name="ri-equalizer-line"
                  height="20px"
                  fill={Neutral.B40}
                />
              }
              options={selectAttendanceStatusOptions}
              onSelect={handleSelectStatus}
              selectedValues={[selectedAttendanceStatus]}
              width="100%"
              listHeight={248}
            />
          </AttendanceStatusSelectContainer>
        </LeftContainer>
        <ExportContainer>
          <PrimaryButton
            icon={<Icon name="ri-download-line" />}
            type="button"
            onClick={onExport}
            loading={isLoadingExport}
          >
            Export
          </PrimaryButton>
        </ExportContainer>
      </FilterContainer>
      <DailyLogTable
        hub={attendanceData?.hubber.hub}
        attendanceLogs={attendanceData?.hubber.attendance.data}
        sortOrder={sortOrder}
        onSort={handleSortClick}
        loading={isLoadingAttendance || isFetchingAttendance}
        handleResetFiltersClick={handleResetFiltersClick}
      />
      <Alert
        show={showAlert.shouldShow}
        onDismissed={() =>
          setShowAlert({ status: undefined, content: '', shouldShow: false })
        }
        content={showAlert.content}
        status={showAlert.status}
      />
    </Container>
  );
};

export default DailyLog;
