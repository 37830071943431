import { Flex } from 'glints-aries';
import {
  ActionList,
  type ActionListItem,
  Icon,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { PLACEMENT } from '@/components/atoms/Popover/constants';
import { Popover } from '@/components/atoms/Popover/Popover';

const ActivatorButton = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export interface ActionPopoverButtonProps {
  onRemove: () => void;
}

export const ActionPopoverButton = ({ onRemove }: ActionPopoverButtonProps) => {
  const handleSubmit = (actionFunction?: () => void) => {
    actionFunction?.();
  };

  const actions: ActionListItem[] = [
    {
      content: (
        <Flex
          gap={space8}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            handleSubmit(onRemove);
          }}
        >
          <Icon
            name="ri-indeterminate-circle-line"
            height={24}
            fill={Neutral.B40}
          />
          <Typography as="span" variant="body1" color={Neutral.B18}>
            Remove
          </Typography>
        </Flex>
      ),
    },
  ];

  return (
    <Popover
      content={<ActionList items={actions} />}
      placement={PLACEMENT.bottomLeft}
      padding="2px"
    >
      <ActivatorButton>
        <Icon name="ri-more" height={20} fill={Neutral.B40} />
      </ActivatorButton>
    </Popover>
  );
};
