import { Role } from '../CompanyProfile/RolesPermissionsTab/interfaces';

export const PAYMENT_COMPLETED_TABLE_PAGE_SIZE = 10;

export const paymentMethodMap: { [key: string]: string } = {
  BANK_TRANSFER: 'Bank Transfer',
};

export const ERROR_TYPES = {
  invoicePdfNotReady: 'not ready',
} as const;

export const ERROR_MESSAGES = {
  [ERROR_TYPES.invoicePdfNotReady]:
    'The file is still generating. Please retry later.',
} as const;

export const ALLOWED_PAYMENT_COUNTRIES = ['SG'];
export const ALLOWED_PAYMENT_CURRENCIES = ['SGD'];

export const PERMITTED_EGIRO_ROLES = [Role.CompanyAdmin, Role.Payer];
