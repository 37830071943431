import { Badge, Collapse, Icon, Typography } from 'glints-aries/lib/@next';
import { Green, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  permissionDetails,
  permissionFeaturesInOrder,
  permissionFeatureTitle,
  rolePermissionsDetailsMapping,
} from '../../constants';
import { type Permission, type Role } from '../../interfaces';
import * as Styled from './styled.sc';

export interface PermissionSideSheetProps {
  role?: Role;
  showSideSheet: boolean;
  updateShowSideSheet(newValue: boolean): void;
}

export const PermissionSideSheet = ({
  role,
  showSideSheet,
  updateShowSideSheet,
}: PermissionSideSheetProps) => {
  const getStatusBadge = (
    obj: Partial<Record<Partial<Permission>, boolean>>,
  ) => {
    const values = Object.values(obj);
    if (values.every((value) => value)) {
      return <Badge status="success">Full Access</Badge>;
    } else if (values.some((value) => value)) {
      return <Badge status="warning">Limited Access</Badge>;
    } else {
      return <Badge status="neutral">No Access</Badge>;
    }
  };

  if (!role) return null;
  return (
    <Styled.SideSheet
      isOpen={showSideSheet}
      onClose={() => updateShowSideSheet(false)}
      title={`${rolePermissionsDetailsMapping[role].role}'s Permissions`}
      showBackButton={false}
    >
      <Styled.SideSheetContentContainer>
        <Collapse>
          {permissionFeaturesInOrder.map((permissionFeature) => {
            const title = permissionFeatureTitle[permissionFeature];

            return (
              <Styled.CollapseItemContainer
                key={`${role}-${permissionFeature}`}
              >
                <Collapse.Item
                  title={title}
                  defaultCollapsed={false}
                  headerRight={getStatusBadge(
                    rolePermissionsDetailsMapping[role].permissions[
                      permissionFeature
                    ],
                  )}
                >
                  <Styled.PermissionDetailsContainer>
                    {Object.entries(permissionDetails[permissionFeature]).map(
                      ([permission, permissionDetails], index) => {
                        const isPermitted =
                          rolePermissionsDetailsMapping[role].permissions[
                            permissionFeature
                          ][permission];
                        return (
                          <li
                            key={`${permissionFeature}-permission-li-${index}`}
                          >
                            <div>
                              {isPermitted ? (
                                <Icon
                                  name="ri-checkbox-circle-fill"
                                  fill={Green.B61}
                                  height="24px"
                                />
                              ) : (
                                <Icon
                                  name="ri-close-circle-fill"
                                  fill={Neutral.B68}
                                  height="24px"
                                />
                              )}
                            </div>
                            <Typography
                              as="span"
                              variant="body1"
                              color={Neutral.B18}
                            >
                              {permissionDetails}
                            </Typography>
                          </li>
                        );
                      },
                    )}
                  </Styled.PermissionDetailsContainer>
                </Collapse.Item>
              </Styled.CollapseItemContainer>
            );
          })}
        </Collapse>
      </Styled.SideSheetContentContainer>
    </Styled.SideSheet>
  );
};
