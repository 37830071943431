import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex } from 'glints-aries';
import {
  Button,
  Checkbox,
  EmptyState,
  Icon,
  type PaginationProps,
  PlainButton,
  PrimaryButton,
  TextInput,
  Typography,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';

import { EMPLOYEES_TABLE_KEY, EMPLOYEES_TABLE_TITLE } from './constants';
import {
  calculateSelectableHubberId,
  hubberNameSorter,
  isAssignedToSelectedManager,
} from './helpers';
import * as Styled from './styled.sc';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { GlintsAntdTable } from '@/components/atoms/GlintsAntdTable/GlintsAntdTable';
import {
  type TableColumns,
  type TableFilters,
  type TableOnChange,
  type TableRowSelection,
} from '@/components/atoms/GlintsAntdTable/types';
import { GlintsAntdTooltip } from '@/components/atoms/GlintsAntdTooltip/GlintsAntdTooltip';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { SimplePagination } from '@/components/atoms/SimplePagination/SimplePagination';
import {
  type HubberWithDirectManagerFragment,
  type ReportingLinesTabContactFragment,
} from '@/generated/graphql';
import { generateHubFiltersOptions } from '@/utils/talentHub';

export interface AssignEmployeesModalProps {
  isOpen: boolean;
  selectedContact: ReportingLinesTabContactFragment;
  hubbersWithDirectManager: HubberWithDirectManagerFragment[];
  companyHubs: string[];
  loading?: boolean;
  submitting?: boolean;
  isFiltering: boolean;
  filteredProps: {
    filteredInfo: TableFilters;
    setFilteredInfo: React.Dispatch<React.SetStateAction<TableFilters>>;
    onReset: () => void;
  };
  paginationProps: PaginationProps;
  onTableChange: TableOnChange;
  onSubmit: (selectedHubberIds: string[]) => void;
  onClose: () => void;
}

export const AssignEmployeesModal = ({
  isOpen,
  selectedContact,
  hubbersWithDirectManager,
  companyHubs,
  loading,
  submitting,
  isFiltering,
  filteredProps,
  paginationProps,
  onTableChange,
  onSubmit,
  onClose,
}: AssignEmployeesModalProps) => {
  const [selectedHubberIds, setSelectedHubberIds] = useState(new Set<string>());
  const [
    alreadyAssignedToSelectedMangerHubberIds,
    setAlreadyAssignedToSelectedMangerHubberIds,
  ] = useState(new Set<string>());
  const [searchNameText, setSearchNameText] = useState<string>('');
  const searchNameInput = useRef<HTMLInputElement>(null);
  const [searchJobTitleText, setSearchJobTitleText] = useState<string>('');
  const searchJobTitleInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isOpen) return;

    if (selectedContact || hubbersWithDirectManager) {
      const hubberIds = new Set<string>();

      hubbersWithDirectManager.forEach((hubber) => {
        if (
          isAssignedToSelectedManager(
            selectedContact.id,
            hubber.directManager[0]?.id,
          )
        ) {
          hubberIds.add(hubber.id);
        }
      });

      setAlreadyAssignedToSelectedMangerHubberIds(hubberIds);
    }
  }, [isOpen, selectedContact, hubbersWithDirectManager]);

  const selectableHubberIds = useMemo(
    () =>
      calculateSelectableHubberId(selectedContact.id, hubbersWithDirectManager),
    [selectedContact, hubbersWithDirectManager],
  );

  const onAllCheckedCheckboxChange = (checked: boolean) => {
    if (checked || isIndeterminate) {
      setSelectedHubberIds(
        (prev) => new Set([...prev, ...selectableHubberIds]),
      );
    } else {
      setSelectedHubberIds(new Set());
    }
  };

  const onCheckboxChange = useCallback(
    (hebberId: string) => () => {
      setSelectedHubberIds((currentIds) => {
        const newIds = new Set(currentIds);
        if (newIds.has(hebberId)) {
          newIds.delete(hebberId);
        } else {
          newIds.add(hebberId);
        }
        return newIds;
      });
    },
    [],
  );

  const areAllDirectManagersAssigned = hubbersWithDirectManager.every(
    (hubber) => hubber.directManager[0]?.id,
  );
  const isAllChecked =
    [...selectableHubberIds].every((id) => selectedHubberIds.has(id)) &&
    !areAllDirectManagersAssigned;
  const isIndeterminate =
    !isAllChecked &&
    [...selectableHubberIds].some((id) => selectedHubberIds.has(id));

  const clearStatesAndResetFilters = () => {
    setSelectedHubberIds(new Set());
    setSearchNameText('');
    setSearchJobTitleText('');
    filteredProps.onReset();
  };

  const handleClose = () => {
    onClose();
    clearStatesAndResetFilters();
  };

  const handleAssign = () => {
    onSubmit(Array.from(selectedHubberIds));
    clearStatesAndResetFilters();
  };

  const rowSelection: TableRowSelection<HubberWithDirectManagerFragment> = {
    selectedRowKeys:
      selectedHubberIds.size > 0 ? Array.from(selectedHubberIds) : [],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    renderCell(_, record, __, ___) {
      if (loading)
        return (
          <Box mx={8}>
            <Checkbox disabled={true} isPadded={false} />
          </Box>
        );
      return (
        <Box mx={8}>
          {record.directManager[0]?.id ? (
            <GlintsAntdTooltip
              title={
                record.directManager[0]?.id === selectedContact?.id
                  ? 'Assigned to this manager already'
                  : 'Multiple assignments not allowed'
              }
              placement="topLeft"
            >
              <Checkbox
                checked={
                  alreadyAssignedToSelectedMangerHubberIds.has(record.id) ||
                  selectedHubberIds.has(record.id)
                }
                disabled={true}
                isPadded={false}
              />
            </GlintsAntdTooltip>
          ) : (
            <Checkbox
              checked={selectedHubberIds.has(record.id)}
              onChange={onCheckboxChange(record.id)}
              isPadded={false}
              disabled={submitting}
            />
          )}
        </Box>
      );
    },
    columnTitle: () => (
      <Box mx={8}>
        <Checkbox
          onChange={onAllCheckedCheckboxChange}
          indeterminate={isIndeterminate}
          checked={isAllChecked}
          disabled={
            hubbersWithDirectManager.length === 0 ||
            areAllDirectManagersAssigned ||
            loading ||
            submitting
          }
        />
      </Box>
    ),
  };

  const columns: TableColumns<HubberWithDirectManagerFragment> = [
    {
      key: EMPLOYEES_TABLE_KEY.employee,
      title: EMPLOYEES_TABLE_TITLE[EMPLOYEES_TABLE_KEY.employee],
      dataIndex: 'fullName',
      width: 200,
      sorter: hubberNameSorter,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'ascend',
      filteredValue:
        filteredProps.filteredInfo[EMPLOYEES_TABLE_KEY.employee] || null,
      filterIcon: () =>
        filteredProps.filteredInfo[EMPLOYEES_TABLE_KEY.employee] ? (
          <Icon name="ri-search" fill={Blue.S99} height={16} width={16} />
        ) : (
          <Icon name="ri-search" fill={Neutral.B40} height={16} width={16} />
        ),
      filterDropdown: ({ confirm }) => (
        <Box p={16}>
          <Box mb={16}>
            <TextInput
              inputRef={searchNameInput}
              placeholder="Search Name"
              value={searchNameText}
              onChange={(value: string) => setSearchNameText(value)}
            />
          </Box>
          <Flex gap={space8}>
            <PlainButton
              onClick={() => {
                confirm({ closeDropdown: true });
                setSearchNameText('');
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [EMPLOYEES_TABLE_KEY.employee]: null,
                });
              }}
            >
              Clear
            </PlainButton>
            <PrimaryButton
              onClick={() => {
                setSearchNameText(searchNameText);
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [EMPLOYEES_TABLE_KEY.employee]: [searchNameText],
                });
              }}
            >
              Search
            </PrimaryButton>
          </Flex>
        </Box>
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchNameInput.current?.select(), 100);
        }
        if (!visible) {
          if (searchNameText) {
            setSearchNameText(searchNameText);
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [EMPLOYEES_TABLE_KEY.employee]: [searchNameText],
            });
          } else {
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [EMPLOYEES_TABLE_KEY.employee]: null,
            });
          }
        }
      },
    },
    {
      key: EMPLOYEES_TABLE_KEY.jobTitle,
      title: EMPLOYEES_TABLE_TITLE[EMPLOYEES_TABLE_KEY.jobTitle],
      dataIndex: 'jobTitle',
      width: 250,
      filteredValue:
        filteredProps.filteredInfo[EMPLOYEES_TABLE_KEY.jobTitle] || null,
      filterIcon: () =>
        filteredProps.filteredInfo[EMPLOYEES_TABLE_KEY.jobTitle] ? (
          <Icon name="ri-search" fill={Blue.S99} height={16} width={16} />
        ) : (
          <Icon name="ri-search" fill={Neutral.B40} height={16} width={16} />
        ),
      filterDropdown: ({ confirm }) => (
        <Box p={16}>
          <Box mb={16}>
            <TextInput
              inputRef={searchJobTitleInput}
              placeholder="Search Job Title"
              value={searchJobTitleText}
              onChange={(value: string) => setSearchJobTitleText(value)}
            />
          </Box>
          <Flex gap={space8}>
            <PlainButton
              onClick={() => {
                confirm({ closeDropdown: true });
                setSearchJobTitleText('');
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [EMPLOYEES_TABLE_KEY.jobTitle]: null,
                });
              }}
            >
              Clear
            </PlainButton>
            <PrimaryButton
              onClick={() => {
                setSearchJobTitleText(searchJobTitleText);
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [EMPLOYEES_TABLE_KEY.jobTitle]: [searchJobTitleText],
                });
              }}
            >
              Search
            </PrimaryButton>
          </Flex>
        </Box>
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchJobTitleInput.current?.select(), 100);
        }
        if (!visible) {
          if (searchJobTitleText) {
            setSearchJobTitleText(searchJobTitleText);
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [EMPLOYEES_TABLE_KEY.jobTitle]: [searchJobTitleText],
            });
          } else {
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [EMPLOYEES_TABLE_KEY.jobTitle]: null,
            });
          }
        }
      },
    },
    {
      key: EMPLOYEES_TABLE_KEY.talentHub,
      title: EMPLOYEES_TABLE_TITLE[EMPLOYEES_TABLE_KEY.talentHub],
      dataIndex: 'hub',
      width: 130,
      render: (record: HubberWithDirectManagerFragment['hub']) => (
        <HubLocationText hubCode={record as HubCode} />
      ),
      filteredValue:
        filteredProps.filteredInfo[EMPLOYEES_TABLE_KEY.talentHub] || null,
      filters: generateHubFiltersOptions(companyHubs),
    },
    {
      key: EMPLOYEES_TABLE_KEY.reportingManager,
      title: EMPLOYEES_TABLE_TITLE[EMPLOYEES_TABLE_KEY.reportingManager],
      render: (record: HubberWithDirectManagerFragment) => (
        <>
          {record.directManager[0] ? (
            record.directManager[0].name
          ) : (
            <Typography as="span" variant="subtitle2" color={Neutral.B68}>
              Unassigned
            </Typography>
          )}
        </>
      ),
    },
  ];

  const customActions = (
    <Styled.Actions>
      <Flex flexDirection="row" alignItems="center" gap={space16}>
        <SimplePagination
          currentPage={paginationProps.currentPage}
          pageSize={paginationProps.pageSize}
          totalItems={paginationProps.totalItems}
          onPageChanged={paginationProps.onPageChanged}
          hidePageButtons={true}
        />
        <Typography as="span" variant="body1" color={Neutral.B18}>
          Selected: {selectedHubberIds.size}
        </Typography>
      </Flex>
      <Flex gap={space8}>
        <Button onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
        <PrimaryButton
          onClick={handleAssign}
          disabled={selectedHubberIds.size === 0}
          loading={submitting}
        >
          Assign
        </PrimaryButton>
      </Flex>
    </Styled.Actions>
  );

  const emptyState = (
    <EmptyState
      title="No active employees"
      description="There are no employees working for your company right now."
      image={<NebulaSVG />}
    />
  );

  const noMatchingResultsState = (
    <Box py={64}>
      <EmptyState
        title="No Matching Results"
        description="No results were found based on your search keywords or filtering conditions."
        basicButtonAction={{
          label: 'Reset All',
          onClick: () => {
            setSearchNameText('');
            setSearchJobTitleText('');
            filteredProps.onReset();
          },
        }}
      />
    </Box>
  );

  return (
    <Styled.Modal
      isOpen={isOpen}
      header={`Assign Employees to ${selectedContact.name}`}
      showCloseButton={false}
      onClose={handleClose}
      customActions={customActions}
    >
      <Styled.ModalContent>
        <GlintsAntdTable
          columns={columns}
          dataSource={hubbersWithDirectManager}
          rowSelection={rowSelection}
          onChange={onTableChange}
          loading={loading}
          hideTableBorderBottom={true}
          pagination={false}
          emptyState={
            hubbersWithDirectManager.length === 0 && isFiltering
              ? noMatchingResultsState
              : emptyState
          }
          sticky={{
            offsetHeader: 0,
          }}
          scroll={{ x: 'max-content' }}
          height="100%"
        />
      </Styled.ModalContent>
    </Styled.Modal>
  );
};
