import { Breakpoints, Button } from 'glints-aries/lib/@next';
import { space16 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ContentContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Breakpoints.large}) {
    width: 100%;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &[data-margin-top-auto='true'] {
    margin-top: auto;
  }
`;

export const StyledButton = styled(Button)`
  @media (max-width: ${Breakpoints.large}) {
    &[data-footer='true'] {
      margin-bottom: 32px;
    }
  }
`;

export const ContentDescription = styled.div`
  text-align: center;
  white-space: pre-line;
  margin-bottom: 32px;

  @media (max-width: ${Breakpoints.large}) {
    white-space: normal;
  }
`;

export const ContactUsContainer = styled.div`
  margin-top: auto;

  &[data-margin-bottom='true'] {
    margin-bottom: ${space16};
  }

  @media (max-width: ${Breakpoints.large}) {
    margin-top: auto;
    white-space: pre-line;
  }
`;

export const ContactUsContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: column;
  }
`;

export const EmailSupportContainer = styled.div`
  display: flex;
`;
