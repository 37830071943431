import { type IconNames } from 'glints-aries/lib/@next/Icon/icons/icons';

export const menuType = {
  ITEM: 'ITEM',
  GROUP: 'GROUP',
} as const;
export type MenuType = ValueOf<typeof menuType>;

export interface MenuItem extends Item {
  type: MenuType;
  children?: Item[];
}

export interface Item {
  key: string;
  label: React.ReactNode;
  selectedKey?: string;
  iconName?: IconNames;
  selectedIconName?: IconNames;
  onClick?: (key: string) => void;
}
