import {
  type FocusEventHandler,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from 'glints-aries';
import {
  Icon,
  type SelectProps as AriesSelectProps,
  Spinner,
} from 'glints-aries/lib/@next';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import useDeepCompareEffect, {
  useDeepCompareMemoize,
} from 'use-deep-compare-effect';

import { DropdownListItem } from './DropdownListItem';
import {
  buildDisplayValue,
  filterOptions,
  getSelectedItemsFromOptions,
  isOptGroup,
  isOption,
} from './helpers';
import type { DropdownProps } from './styled.sc';
import * as Styled from './styled.sc';
import type { SelectorOptGroup, SelectorOption } from './types';
import { useOutsideClick } from '@/hooks/useOutsideClick';

export interface SelectorProps extends Omit<AriesSelectProps, 'options'> {
  options: (SelectorOption | SelectorOptGroup)[];
  defaultSelectedValues?: string[];
  dropdownPlaceholder?: string;
  removable?: boolean;
  clearOnSelect?: boolean;
  showTags?: boolean;
  showInputBadge?: boolean;
  onClear?: () => void;
  onSelectedValuesChange?: (selectedValue: string[]) => void;
}

export const Selector = ({
  options,
  defaultSelectedValues,
  selectedValues: controlledSelectedValues,
  placeholder,
  dropdownPlaceholder,
  name,
  allowMultiple,
  removable,
  clearOnSelect,
  prefix,
  loadingOptions,
  disabled,
  hasError,
  onSelect,
  onBlur,
  onClear,
  onSelectedValuesChange,
  searchableProps,
  searchable,
  showInputBadge = true,
}: SelectorProps) => {
  const [_selectedValues, setSelectedValues] = useState(
    defaultSelectedValues || [],
  );
  const selectedValues = controlledSelectedValues || _selectedValues;
  const selectedItems = useMemo(
    () => getSelectedItemsFromOptions(options, selectedValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([selectedValues, options]),
  );

  const [_displayValue, _setDisplayValue] = useState<string>(
    buildDisplayValue(options, selectedValues),
  );
  const displayValue = searchableProps?.inputValue || _displayValue;
  const setDisplayValue = useCallback(
    (v: string) => {
      _setDisplayValue(v);
      searchableProps?.onInputChange?.(v);
    },
    [searchableProps],
  );

  const [searchValue, setSearchValue] = useState<string>(displayValue);

  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function syncSelectedValueWithDisplayValueOnMount() {
    if (defaultSelectedValues?.join(', ') !== displayValue) {
      setDisplayValue(displayValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    setDisplayValue(buildDisplayValue(options, selectedValues));
  }, [selectedValues]);

  useDeepCompareEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function initDisplayValue() {
      if (displayValue === '') {
        setDisplayValue(buildDisplayValue(options, selectedValues));
      }
    },
    [options],
  );

  useEffect(() => {
    const filteredOptions: (SelectorOption | SelectorOptGroup)[] =
      filterOptions(searchValue, options);
    setDropdownOptions(filteredOptions);
  }, [options, searchValue]);

  const selectorRef = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputFocus: FocusEventHandler<HTMLInputElement> = () => {
    setDropdownVisible(true);
    setSearchValue('');
  };

  const handleClickDropdown = () => {
    setDropdownVisible(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    setDisplayValue(value);
    setSearchValue(value);

    const filteredOptions: (SelectorOption | SelectorOptGroup)[] =
      filterOptions(value, options);
    setDropdownOptions(filteredOptions);
  };

  const [dropdownOptions, setDropdownOptions] =
    useState<(SelectorOption | SelectorOptGroup)[]>(options);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const dropdownListRef = useRef<HTMLUListElement>(null);

  const handleDropdownClose = useCallback(
    (_selectedItems: SelectorOption[]) => () => {
      setDropdownVisible(false);

      if (dropdownListRef.current) {
        setDisplayValue(_selectedItems.map((o) => o.label).join(', '));
        dropdownListRef.current.scrollTop = 0;
        setDropdownOptions(options);
      }
    },
    [options, setDisplayValue],
  );
  useOutsideClick(selectorRef, handleDropdownClose(selectedItems));

  const handleDropdownListItemChange = (selectedItem: SelectorOption) => {
    let updatedSelectedItems: SelectorOption[] = [];

    if (allowMultiple) {
      const selectedValues = selectedItems.map((item) => item.value);

      const isIncludeValue = selectedValues.some(
        (value) => value === selectedItem.value,
      );

      if (isIncludeValue) {
        updatedSelectedItems = selectedItems.filter(
          (item) => item.value !== selectedItem.value,
        );
      } else {
        updatedSelectedItems = [...selectedItems, selectedItem];
      }
    } else {
      updatedSelectedItems = [selectedItem];
    }

    const updatedSelectedValues = updatedSelectedItems.map(
      (item) => item.value,
    );
    setSelectedValues(updatedSelectedValues);

    onSelect?.({ value: selectedItem.value });
    onSelectedValuesChange?.(updatedSelectedValues);

    if (clearOnSelect) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (disabled) {
      handleDropdownClose(selectedItems);
    }
  }, [disabled, handleDropdownClose, selectedItems]);

  const [openDirection, setOpenDirection] =
    useState<DropdownProps['openDirection']>('down');

  useEffect(() => {
    if (selectorRef.current && selectorRef.current) {
      const offsetTop = selectorRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const offsetBottom = windowHeight - offsetTop;

      const shouldOpenAbove = offsetBottom < 300;
      if (shouldOpenAbove) {
        setOpenDirection('up');
      } else {
        setOpenDirection('down');
      }
    }
  }, [dropdownVisible]);

  const handleRemoveInputValue = () => {
    setSelectedValues([]);
    setDisplayValue('');
    setSearchValue('');
    onClear?.();
  };

  const handleOnBlur = () => {
    // Only trigger onBlur logic if the dropdown was not visible,
    // leveraging the timing difference to infer if the dropdown was previously open.
    if (!dropdownVisible) {
      setDisplayValue(buildDisplayValue(options, selectedValues));
      onBlur?.();
    }
  };

  return (
    <Styled.Selector ref={selectorRef}>
      <Styled.InputContainer aria-disabled={disabled} data-error={hasError}>
        {prefix && <Styled.InputPrefix>{prefix}</Styled.InputPrefix>}
        {!allowMultiple && selectedItems.length > 0 && (
          <Styled.InputIcon data-prefix={Boolean(prefix)}>
            {selectedItems[0].icon}
          </Styled.InputIcon>
        )}
        <Styled.Input
          name={name}
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleOnBlur}
          onChange={handleInputChange}
          value={displayValue}
          placeholder={
            placeholder ? placeholder : 'please input the defaultValues'
          }
          data-prefix={Boolean(prefix)}
          data-icon={
            !allowMultiple &&
            selectedItems.length > 0 &&
            Boolean(selectedItems[0].icon)
          }
          data-readonly={!searchable}
          readOnly={!searchable}
          type="text"
        />
        {!allowMultiple &&
          showInputBadge &&
          selectedItems.length > 0 &&
          selectedItems[0].badge && (
            <Styled.InputBadge onClick={() => setDropdownVisible(true)}>
              {selectedItems[0].badge}
            </Styled.InputBadge>
          )}
        {!searchable && (
          <Styled.InputDropdownSuffix>
            <Icon name="ri-arrow-m-down-line" onClick={handleClickDropdown} />
          </Styled.InputDropdownSuffix>
        )}
        {searchable && removable && displayValue && (
          <Styled.InputSuffix>
            <Icon
              name={'ri-close-circle-fill'}
              onClick={handleRemoveInputValue}
            />
          </Styled.InputSuffix>
        )}
      </Styled.InputContainer>

      <Styled.Dropdown visible={dropdownVisible} openDirection={openDirection}>
        {!loadingOptions ? (
          dropdownOptions.length ? (
            <Styled.DropdownList ref={dropdownListRef}>
              {isOptGroup(dropdownOptions) &&
                dropdownOptions.map((opt) => (
                  <Fragment key={opt.label}>
                    <Styled.DropdownListGroupLabel>
                      {opt.label}
                    </Styled.DropdownListGroupLabel>
                    {opt.options.map((optItem) => (
                      <DropdownListItem
                        key={optItem.id}
                        multiple={allowMultiple}
                        selectedValues={selectedValues}
                        optItem={optItem}
                        onChange={handleDropdownListItemChange}
                      />
                    ))}
                  </Fragment>
                ))}

              {isOption(dropdownOptions) &&
                dropdownOptions.map((optItem) => (
                  <DropdownListItem
                    key={optItem.id}
                    multiple={allowMultiple}
                    selectedValues={selectedValues}
                    optItem={optItem}
                    onChange={handleDropdownListItemChange}
                  />
                ))}
            </Styled.DropdownList>
          ) : (
            <Styled.DropdownListPlaceholder>
              {dropdownPlaceholder}
            </Styled.DropdownListPlaceholder>
          )
        ) : (
          <Box py={8}>
            <Spinner fill={Blue.S99} size="small" />
          </Box>
        )}
      </Styled.Dropdown>
    </Styled.Selector>
  );
};
