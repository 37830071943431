import { useState } from 'react';
import { Box } from 'glints-aries';
import {
  Badge,
  Card,
  Icon,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral, Orange } from 'glints-aries/lib/@next/utilities/colors';
import { gql } from 'graphql-request';
import { useForm } from 'react-hook-form';

import {
  REQUEST_STATUS_TEXT,
  requestStatusBadgeMapping,
} from '../../constants';
import { TimeOffTypeText } from '../TimeOffTypeText/TimeOffTypeText';
import { AutoApprovedBanner } from './AutoApprovedBanner';
import {
  APPROVAL_STATE,
  type ApprovalState,
  AVAILABLE_REQUEST_HUBS,
  getApprovalOptions,
  NOT_AVAILABLE_REQUEST_HUBS,
  REJECTION_FORM_NAMES,
  REJECTION_FORM_PLACEHOLDERS,
  REJECTION_FORM_RULES,
  type RejectionFormFields,
} from './constants';
import { HalfDayLeaveTooltip } from './HalfDayLeaveTooltip';
import { timeOffDurationText } from './helpers';
import { ModifiedBanner } from './ModifiedBanner';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { InlineError } from '@/components/atoms/InlineError/InlineError';
import { RadioButtonGroup } from '@/components/atoms/RadioButtonGroup/RadioButtonGroup';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { FormTextArea } from '@/components/molecules/Forms/FormTextArea/FormTextArea';
import { CardData } from '@/components/molecules/SideSheet/CardData';
import { LoadingState } from '@/components/molecules/SideSheet/LoadingState';
import * as SideSheetStyled from '@/components/molecules/SideSheet/styled.sc';
import {
  type TimeOffRequestSideSheetTimeOffRequestFragment,
  TimeOffRequestStatus,
} from '@/generated/graphql';
import { computeTimeOffDuration } from '@/utils/date';
import { type TimeZone } from '@/utils/timeZone';

export interface TimeOffRequestSideSheetProps {
  isOpen: boolean;
  timeOffRequestData?: TimeOffRequestSideSheetTimeOffRequestFragment;
  isRequestModified?: boolean;
  loading?: boolean;
  submitting?: boolean;
  onApprove: (timeOffId: string) => void;
  onReject: (timeOffId: string, remark?: string) => void;
  onClose: () => void;
}

export const TimeOffRequestSideSheet = ({
  isOpen,
  timeOffRequestData,
  isRequestModified,
  loading,
  submitting,
  onApprove,
  onReject,
  onClose,
}: TimeOffRequestSideSheetProps) => {
  const [selectedApprovalState, setSelectedApprovalState] =
    useState<ApprovalState | null>(null);

  const {
    control,
    handleSubmit,
    watch,
    reset: resetForm,
  } = useForm<RejectionFormFields>({
    mode: 'onBlur',
    defaultValues: {
      [REJECTION_FORM_NAMES.rejectReason]: '',
    },
  });

  const watchedRejectReason = watch(REJECTION_FORM_NAMES.rejectReason);

  const handleApprovalChange = (value: ApprovalState) => {
    setSelectedApprovalState(value);
  };

  const handleConfirm = (data: RejectionFormFields) => {
    if (!timeOffRequestData || !selectedApprovalState) return;

    if (selectedApprovalState === APPROVAL_STATE.approve) {
      onApprove(timeOffRequestData.id);
    } else if (selectedApprovalState === APPROVAL_STATE.reject) {
      onReject(timeOffRequestData.id, data[REJECTION_FORM_NAMES.rejectReason]);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedApprovalState(null);
    resetForm();
  };

  const approvalOptions = getApprovalOptions(
    timeOffRequestData?.hubber.hub as HubCode,
  );

  const timeOffDurationByDate =
    timeOffRequestData?.timeoffStartDate &&
    timeOffRequestData?.timeoffEndDate &&
    computeTimeOffDuration({
      startDate: timeOffRequestData?.timeoffStartDate,
      endDate: timeOffRequestData?.timeoffEndDate,
    });

  const isHalfDayLeave =
    timeOffDurationByDate &&
    timeOffDurationByDate > Number(timeOffRequestData?.timeoffDuration);

  const isAutoApproved =
    timeOffRequestData?.requestStatus === TimeOffRequestStatus.Approved &&
    (timeOffRequestData?.approval === null ||
      timeOffRequestData?.approval?.contact === null);
  const isRequestUpdatedFromUnavailableHub =
    NOT_AVAILABLE_REQUEST_HUBS.includes(
      timeOffRequestData?.hubber.hub as HubCode,
    ) && timeOffRequestData?.requestStatus !== TimeOffRequestStatus.Pending;
  const approver =
    isAutoApproved || isRequestUpdatedFromUnavailableHub
      ? 'Glints'
      : timeOffRequestData?.approval?.contact?.name;

  const renderPendingStateCardContent = () => (
    <Card>
      <SideSheetStyled.CardRow>
        <CardData
          label="Employee"
          value={timeOffRequestData?.hubber.fullName}
        />
        <CardData
          label="TalentHub"
          value={
            timeOffRequestData?.hubber.hub && (
              <HubLocationText
                hubCode={timeOffRequestData?.hubber.hub as HubCode}
              />
            )
          }
        />
      </SideSheetStyled.CardRow>

      <SideSheetStyled.Divider />

      <SideSheetStyled.CardRow>
        <CardData
          label="Requested Time"
          value={
            <FormattedDate
              date={timeOffRequestData?.requestedAt}
              timeZone={timeOffRequestData?.hubber.hub as TimeZone}
              pattern={{
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              textVariant="body1"
            />
          }
        />
        <CardData
          label="Time Off Type"
          value={
            timeOffRequestData?.balance.name &&
            timeOffRequestData?.balance.category ? (
              <TimeOffTypeText
                name={timeOffRequestData.balance.name}
                category={timeOffRequestData.balance.category}
              />
            ) : null
          }
          emptyText="Undefined"
        />
      </SideSheetStyled.CardRow>

      <SideSheetStyled.CardRow>
        <CardData
          label="Used Amount"
          value={
            timeOffRequestData?.balance.used
              ? timeOffDurationText({
                  timeoffDuration: timeOffRequestData?.balance.used,
                })
              : null
          }
          emptyText="Undefined"
        />
        <CardData
          label="Remaining Amount"
          value={
            timeOffRequestData?.balance.balance
              ? timeOffDurationText({
                  timeoffDuration: timeOffRequestData?.balance.balance,
                })
              : null
          }
        />
      </SideSheetStyled.CardRow>

      <SideSheetStyled.Divider />

      <SideSheetStyled.CardRow>
        <CardData
          label="Start Date"
          value={
            <FormattedDate
              date={timeOffRequestData?.timeoffStartDate}
              timeZone={timeOffRequestData?.hubber.hub as TimeZone}
              textVariant="body1"
            />
          }
        />
        <CardData
          label="End Date"
          value={
            <FormattedDate
              date={timeOffRequestData?.timeoffEndDate}
              timeZone={timeOffRequestData?.hubber.hub as TimeZone}
              textVariant="body1"
            />
          }
        />
      </SideSheetStyled.CardRow>
      <SideSheetStyled.CardRow>
        <CardData
          label="Duration"
          value={
            timeOffRequestData?.timeoffDuration &&
            timeOffDurationText({
              timeoffDuration: timeOffRequestData?.timeoffDuration,
            })
          }
          suffix={isHalfDayLeave && <HalfDayLeaveTooltip />}
        />
        <CardData
          label="Status"
          value={
            timeOffRequestData?.requestStatus && (
              <Badge
                status={
                  requestStatusBadgeMapping[timeOffRequestData?.requestStatus]
                }
              >
                {REQUEST_STATUS_TEXT[timeOffRequestData?.requestStatus]}
              </Badge>
            )
          }
        />
      </SideSheetStyled.CardRow>

      <SideSheetStyled.CardRow>
        <CardData
          label="Notes to Manager"
          value={timeOffRequestData?.noteToManager || null}
          emptyText="None"
        />
      </SideSheetStyled.CardRow>
    </Card>
  );

  const renderApprovedOrRejectedStateCardContent = () => (
    <SideSheetStyled.MultipleCardsContainer>
      <Card>
        <SideSheetStyled.CardRow>
          <CardData
            label="Employee"
            value={timeOffRequestData?.hubber.fullName}
          />
          <CardData
            label="TalentHub"
            value={
              timeOffRequestData?.hubber.hub && (
                <HubLocationText
                  hubCode={timeOffRequestData?.hubber.hub as HubCode}
                />
              )
            }
          />
        </SideSheetStyled.CardRow>

        <SideSheetStyled.Divider />

        <SideSheetStyled.CardRow>
          <CardData
            label="Requested Time"
            value={
              <FormattedDate
                date={timeOffRequestData?.requestedAt}
                timeZone={timeOffRequestData?.hubber.hub as TimeZone}
                pattern={{
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }}
                textVariant="body1"
              />
            }
          />
          <CardData
            label="Time Off Type"
            value={
              timeOffRequestData?.balance.name &&
              timeOffRequestData?.balance.category ? (
                <TimeOffTypeText
                  name={timeOffRequestData.balance.name}
                  category={timeOffRequestData.balance.category}
                />
              ) : null
            }
            emptyText="Undefined"
          />
        </SideSheetStyled.CardRow>

        <SideSheetStyled.CardRow>
          <CardData
            label="Start Date"
            value={
              <FormattedDate
                date={timeOffRequestData?.timeoffStartDate}
                timeZone={timeOffRequestData?.hubber.hub as TimeZone}
                textVariant="body1"
              />
            }
          />
          <CardData
            label="End Date"
            value={
              <FormattedDate
                date={timeOffRequestData?.timeoffEndDate}
                timeZone={timeOffRequestData?.hubber.hub as TimeZone}
                textVariant="body1"
              />
            }
          />
        </SideSheetStyled.CardRow>

        <SideSheetStyled.CardRow>
          <CardData
            label="Duration"
            value={
              timeOffRequestData?.timeoffDuration &&
              timeOffDurationText({
                timeoffDuration: timeOffRequestData?.timeoffDuration,
              })
            }
            suffix={isHalfDayLeave && <HalfDayLeaveTooltip />}
          />
          <CardData
            label="Status"
            value={
              timeOffRequestData?.requestStatus && (
                <Badge
                  status={
                    requestStatusBadgeMapping[timeOffRequestData?.requestStatus]
                  }
                >
                  {REQUEST_STATUS_TEXT[timeOffRequestData?.requestStatus]}
                </Badge>
              )
            }
          />
        </SideSheetStyled.CardRow>

        <SideSheetStyled.CardRow>
          <CardData
            label="Notes to Manager"
            value={timeOffRequestData?.noteToManager || null}
            emptyText="None"
          />
        </SideSheetStyled.CardRow>
      </Card>

      <Card>
        <SideSheetStyled.CardRow>
          <CardData label="Manager" value={approver} />
          <CardData
            label="Status Updated"
            value={
              <FormattedDate
                date={timeOffRequestData?.approval?.updatedAt}
                timeZone={timeOffRequestData?.hubber.hub as TimeZone}
                pattern={{
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }}
                textVariant="body1"
              />
            }
          />
        </SideSheetStyled.CardRow>

        {timeOffRequestData?.requestStatus ===
          TimeOffRequestStatus.Rejected && (
          <SideSheetStyled.CardRow>
            <CardData
              label="Reason for Rejection"
              value={timeOffRequestData?.approval?.remarks || null}
              emptyText="None"
            />
          </SideSheetStyled.CardRow>
        )}
      </Card>
    </SideSheetStyled.MultipleCardsContainer>
  );

  if (loading) {
    return (
      <SideSheetStyled.SideSheet
        title="Time Off Request"
        isOpen={isOpen}
        onClose={handleClose}
        showBackButton={false}
      >
        <LoadingState />
      </SideSheetStyled.SideSheet>
    );
  }

  return (
    <SideSheetStyled.SideSheet
      title="Time Off Request"
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
    >
      {isRequestModified && <ModifiedBanner />}
      {isAutoApproved &&
        AVAILABLE_REQUEST_HUBS.includes(
          timeOffRequestData?.hubber.hub as HubCode,
        ) && (
          <AutoApprovedBanner
            startDate={timeOffRequestData?.timeoffStartDate}
          />
        )}

      {timeOffRequestData?.requestStatus === TimeOffRequestStatus.Pending && (
        <>
          {renderPendingStateCardContent()}
          <Box mt={16}>
            <SideSheetStyled.ApprovalActionsLabel>
              <Icon name="ri-flag-fill" fill={Orange.S86} height="18px" />
              <Typography as="div" variant="caption" color={Neutral.B40}>
                Action needed:
              </Typography>
            </SideSheetStyled.ApprovalActionsLabel>
            <SideSheetStyled.ApprovalActions>
              <RadioButtonGroup
                options={approvalOptions}
                onChange={(value: string) =>
                  handleApprovalChange(value as ApprovalState)
                }
                disabled={submitting}
              />
            </SideSheetStyled.ApprovalActions>
            {NOT_AVAILABLE_REQUEST_HUBS.includes(
              timeOffRequestData?.hubber.hub as HubCode,
            ) && (
              <InlineError errorMessage="This feature is not yet available in the employee’s country. To approve or reject, please contact your Account Manager." />
            )}
            {selectedApprovalState === APPROVAL_STATE.reject && (
              <FormTextArea
                name={REJECTION_FORM_NAMES.rejectReason}
                control={control}
                rules={REJECTION_FORM_RULES[REJECTION_FORM_NAMES.rejectReason]}
                disabled={submitting}
                placeholder={
                  REJECTION_FORM_PLACEHOLDERS[REJECTION_FORM_NAMES.rejectReason]
                }
                maxLength={150}
                canExceedMaxLength={false}
                rows={3}
                width="unset"
              />
            )}
          </Box>
          {AVAILABLE_REQUEST_HUBS.includes(
            timeOffRequestData?.hubber.hub as HubCode,
          ) && (
            <SideSheetStyled.SideSheetFooter>
              <PrimaryButton
                onClick={handleSubmit(handleConfirm)}
                disabled={
                  !selectedApprovalState ||
                  (selectedApprovalState === APPROVAL_STATE.reject &&
                    watchedRejectReason === '')
                }
                loading={submitting}
              >
                Confirm
              </PrimaryButton>
            </SideSheetStyled.SideSheetFooter>
          )}
        </>
      )}

      {(timeOffRequestData?.requestStatus === TimeOffRequestStatus.Approved ||
        timeOffRequestData?.requestStatus === TimeOffRequestStatus.Rejected) &&
        renderApprovedOrRejectedStateCardContent()}
    </SideSheetStyled.SideSheet>
  );
};

TimeOffRequestSideSheet.fragments = {
  timeOffRequest: gql`
    fragment TimeOffRequestSideSheetTimeOffRequest on TimeOffRequest {
      id
      noteToManager
      requestStatus
      requestedAt
      timeoffStartDate
      timeoffEndDate
      timeoffDuration
      hubber {
        id
        fullName
        hub
        status
        directManager {
          id
          name
        }
      }
      approval {
        id
        remarks
        contact {
          id
          name
        }
        createdAt
        updatedAt
      }
      balance {
        hubberId
        name
        category
        balance(year: $year, month: $month)
        used(year: $year, month: $month)
        available(year: $year, month: $month)
      }
    }
  `,
};
