import { useEffect, useRef, useState } from 'react';
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  DestructiveButton,
  EmptyState,
  Icon,
  SkeletonText,
  Typography,
  useAlert,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useNavigate } from 'react-router-dom';

import {
  CardContainer,
  CardContentContainer,
  CardHeaderLoadingContainer,
  CustomModal,
  LeftSectionContainer,
  ModalHeadingContainer,
  RightSectionContainer,
} from '../../../components/CardStyle';
import SingleData from '../../../components/SingleData';
import {
  alertContent,
  ROLE_UNASSIGNED,
  roleDetailsMapping,
} from '../../constants';
import {
  FormMode,
  type GetUsersPerRoleQuery,
  type ManageUsersProps,
  type Role,
  type User,
} from '../../interfaces';
import {
  BackOverviewButton,
  RoleBannerContainer,
  RoleBannerContent,
  ViewPermissionsButton,
} from '../../RolesPermissionsTabStyle';
import { AddEditUserModal } from '../AddEditUserModal/AddEditUserModal';
import { PermissionSideSheet } from '../PermissionSideSheet/PermissionSideSheet';
import { UserCard } from './UserCard';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { ALERT_STATUS } from '@/constants/alert';
import {
  type DeleteUserMutation,
  useDeleteUserMutation,
  useGetUsersPerRoleQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';

export const ManageUsers = ({
  role,
  updateIsManageUsers,
}: ManageUsersProps) => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const { open: openAlert } = useAlert();

  const userRef = useRef<HTMLDivElement | null>(null);

  const isUnassignedRole = role === ROLE_UNASSIGNED;
  const [userFormModal, setUserFormModal] = useState<{
    mode: FormMode;
    userId?: string;
    isOpen: boolean;
  }>({
    mode: FormMode.VIEW,
    isOpen: false,
    userId: undefined,
  });
  const [showSideSheet, setShowSideSheet] = useState(false);
  const [currUsers, setCurrUsers] = useState<User[] | undefined>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();

  const {
    isLoading: isLoadingUsers,
    error: getUsersPerRoleError,
    data,
  } = useGetUsersPerRoleQuery<GetUsersPerRoleQuery, Error>(
    graphqlClient,
    { roleIds: isUnassignedRole ? [null] : [role] },
    { staleTime: 0 },
  );

  const {
    error: deleteUserError,
    isLoading,
    mutate,
  } = useDeleteUserMutation<Error, DeleteUserMutation>(graphqlClient);

  const onEditUser = ({ id }: { id: string }) => {
    setSelectedUser(currUsers?.find((user) => user.id === id));
    setUserFormModal({ isOpen: true, mode: FormMode.EDIT, userId: id });
  };

  const updateFormMode = (newMode: {
    mode: FormMode;
    userId?: string;
    isOpen: boolean;
  }) => {
    setUserFormModal(newMode);
  };

  const onDeleteContact = ({ id }: { id: string }) => {
    setUserFormModal({ mode: FormMode.DELETE, userId: id, isOpen: false });
  };

  const updateCurrentUsers = (newUsers: User[]) => {
    setCurrUsers(newUsers);
  };

  const updateShowSideSheet = (newValue: boolean) => {
    setShowSideSheet(newValue);
  };

  const handleCloseUserModal = () => {
    setUserFormModal({ isOpen: false, mode: FormMode.VIEW });
    setSelectedUser(undefined);
  };

  useEffect(() => {
    setCurrUsers(data?.company?.contacts);
  }, [data?.company?.contacts]);

  useGraphqlError([getUsersPerRoleError, deleteUserError]);

  if (isLoadingUsers) {
    const heading = (
      <CardHeaderLoadingContainer>
        <SkeletonText rows={[{ width: '300px' }]} />
      </CardHeaderLoadingContainer>
    );
    const SKELETON_ROWS_LENGTH = 3;
    const CARD_ARRAY_LENGTH = 2;
    const skeletonRows = Array.from(
      { length: SKELETON_ROWS_LENGTH },
      (_, i) => i + 1,
    ).map((i) => <SingleData key={i} isLoading={isLoadingUsers} />);

    return (
      <>
        {Array.from({ length: CARD_ARRAY_LENGTH }, (_, i) => i + 1).map((i) => (
          <CardContainer key={i}>
            <Card heading={heading}>
              <CardContentContainer>
                <LeftSectionContainer>{skeletonRows}</LeftSectionContainer>
                <RightSectionContainer>{skeletonRows}</RightSectionContainer>
              </CardContentContainer>
            </Card>
          </CardContainer>
        ))}
      </>
    );
  }

  const handleDelete = () => {
    mutate(
      { id: userFormModal.userId },
      {
        onSuccess: () => {
          const deletedUser = currUsers?.find(
            (user) => user.id === userFormModal.userId,
          );
          setCurrUsers(
            currUsers?.filter((user) => user.id !== userFormModal.userId),
          );
          setUserFormModal({
            mode: FormMode.VIEW,
            userId: undefined,
            isOpen: false,
          });
          openAlert({
            content: `${deletedUser?.name} ${alertContent[FormMode.DELETE]}`,
            status: ALERT_STATUS.success,
          });
        },
      },
    );
  };

  const deleteActions = (
    <ButtonGroup>
      <Button
        onClick={() =>
          setUserFormModal({
            mode: FormMode.VIEW,
            userId: undefined,
            isOpen: false,
          })
        }
      >
        Cancel
      </Button>
      <DestructiveButton onClick={handleDelete} isLoading={isLoading}>
        Delete
      </DestructiveButton>
    </ButtonGroup>
  );

  const currDeleteContactName = currUsers?.find(
    (user) => user?.id === userFormModal?.userId,
  )?.name;

  return (
    <>
      <CustomModal
        isOpen={
          Boolean(userFormModal.userId) &&
          userFormModal.mode === FormMode.DELETE
        }
        header={
          <ModalHeadingContainer>{`Delete ${currDeleteContactName}`}</ModalHeadingContainer>
        }
        showCloseButton={false}
        customActions={deleteActions}
      >
        <ModalHeadingContainer>
          Are you sure you want to delete <b>{currDeleteContactName}</b> as a
          user?
        </ModalHeadingContainer>
      </CustomModal>
      {userFormModal.isOpen && (
        <AddEditUserModal
          user={selectedUser}
          formMode={userFormModal}
          isOpen={userFormModal.isOpen}
          onClose={handleCloseUserModal}
          currentRole={isUnassignedRole ? undefined : role}
          updateCurrentUsers={updateCurrentUsers}
          updateFormMode={updateFormMode}
          users={currUsers}
        />
      )}
      {!isUnassignedRole && (
        <PermissionSideSheet
          showSideSheet={showSideSheet}
          updateShowSideSheet={updateShowSideSheet}
          role={role}
        />
      )}
      <BackOverviewButton
        onClick={() => {
          updateIsManageUsers(false);
          navigate('/company-settings/roles-and-permissions');
        }}
      >
        <Icon name="ri-arrow-m-left-line" height="24px" />
        <Typography as="span" variant="body2">
          Back to overview
        </Typography>
      </BackOverviewButton>
      <RoleBannerContainer>
        {!isUnassignedRole && (
          <Banner
            status="info"
            dismissable={false}
            action={
              <Button
                icon={<Icon name="ri-add" />}
                onClick={() =>
                  setUserFormModal({ isOpen: true, mode: FormMode.CREATE })
                }
              >
                {role && `Add ${roleDetailsMapping[role].role}`}
              </Button>
            }
            secondaryAction={
              <ViewPermissionsButton onClick={() => updateShowSideSheet(true)}>
                <Typography as="span" variant="button">
                  View Permissions
                </Typography>
              </ViewPermissionsButton>
            }
          >
            <RoleBannerContent>
              <Typography as="span" variant="body2" color={Neutral.B18}>
                {role && roleDetailsMapping[role].role}
              </Typography>
              <Typography as="span" variant="body1" color={Neutral.B18}>
                {role && roleDetailsMapping[role].roleDetails}
              </Typography>
            </RoleBannerContent>
          </Banner>
        )}
      </RoleBannerContainer>
      {currUsers?.length === 0 && !isUnassignedRole && (
        <EmptyState
          image={<NebulaSVG />}
          title={`No ${roleDetailsMapping[role as Role].role}s`}
          helpText={`You haven’t added any users as ${
            roleDetailsMapping[role as Role].role
          }s yet`}
        />
      )}
      {currUsers?.map((user) => (
        <UserCard
          user={user}
          key={user.id}
          onEdit={onEditUser}
          onDelete={onDeleteContact}
          ref={user.id === userFormModal.userId ? userRef : null}
        />
      ))}
    </>
  );
};
