import { borderRadius4 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { RADIO_BUTTON_VARIANT_COLORS } from './constants';
import { type RadioButtonSize, type RadioButtonVariant } from './types';

export const RadioButtonGroupContainer = styled.div<{
  width?: string;
  bordered?: boolean;
}>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.bordered ? Neutral.B68 : Neutral.B100)};
  border-radius: ${borderRadius4};
  background-color: ${Neutral.B100};
  width: ${(props) => props.width ?? '100%'};
  padding: ${space8};
`;

const defaultButtonSizeStyle = `
  padding: ${space8} ${space16};
`;
const smallButtonSizeStyle = `
  padding: ${space4} ${space12};
`;
const largeButtonSizeStyle = `
  padding: ${space12} ${space24};
`;

const buttonSizeStyleMap: Record<RadioButtonSize, string> = {
  default: defaultButtonSizeStyle,
  small: smallButtonSizeStyle,
  large: largeButtonSizeStyle,
};

export const RadioButtonContainer = styled.div<{
  vairant: RadioButtonVariant;
  size: RadioButtonSize;
  active: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.active ? RADIO_BUTTON_VARIANT_COLORS[props.vairant] : Neutral.B100};
  border-radius: ${borderRadius4};

  ${({ size = 'default' }: { size: RadioButtonSize }) => {
    const sizeStyle = buttonSizeStyleMap[size];
    if (size && !sizeStyle) {
      console.warn(`${size} is not a valid ButtonSize, default will be used`);
    }
    return buttonSizeStyleMap[size] || defaultButtonSizeStyle;
  }}

  &[aria-disabled='true'] {
    pointer-events: none;
    background-color: ${Neutral.B95};
  }
`;

export const RadioButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${space12};
`;

export const RadioButtonIcon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
    fill: ${Neutral.B40};
  }

  &[aria-disabled='true'] {
    svg {
      fill: ${Neutral.B85};
    }
  }
`;

export const RadioButtonLabel = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  margin: 0 ${space8};
  background-color: ${Neutral.B85};
`;
