export const UNASSIGNED_EMPLOYEES_TABLE_KEY = {
  employee: 'employee',
  jobTitle: 'jobTitle',
  talentHub: 'talentHub',
  reportingManager: 'reportingManager',
} as const;

export const UNASSIGNED_EMPLOYEES_TABLE_TITLE = {
  [UNASSIGNED_EMPLOYEES_TABLE_KEY.employee]: 'Employee',
  [UNASSIGNED_EMPLOYEES_TABLE_KEY.jobTitle]: 'Job Title',
  [UNASSIGNED_EMPLOYEES_TABLE_KEY.talentHub]: 'TalentHub',
  [UNASSIGNED_EMPLOYEES_TABLE_KEY.reportingManager]: 'Reporting Manager',
} as const;
