import { useState } from 'react';

import { RadioButton } from './RadioButton';
import { RadioButtonGroupContainer, VerticalDivider } from './styled.sc';
import { type RadioButtonGroupProps } from './types';

export const RadioButtonGroup = ({
  options,
  disabled,
  width,
  size,
  bordered = true,
  onChange,
}: RadioButtonGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <RadioButtonGroupContainer width={width} bordered={bordered}>
      {options.map((option, index) => (
        <>
          <RadioButton
            key={option.value}
            label={option.label}
            value={option.value}
            variant={option.variant}
            size={size}
            icon={option.icon}
            disabled={option.disabled || disabled}
            active={selectedValue === option.value}
            onChange={handleChange}
          />
          {index !== options.length - 1 && <VerticalDivider />}
        </>
      ))}
    </RadioButtonGroupContainer>
  );
};
