import { useEffect, useRef, useState } from 'react';
import { Box, Flex } from 'glints-aries';
import {
  Badge,
  EmptyState,
  Icon,
  type PaginationProps,
  PlainButton,
  PrimaryButton,
  TextInput,
  Typography,
  useAlert,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import { gql } from 'graphql-request';
import { isBrowser } from 'react-device-detect';

import {
  CLAIM_APPROVAL_STATUS_TEXT,
  claimApprovalStatusBadgeMapping,
  DEFAULT_BILLING_CURRENCY,
  pendingStatus,
  PERMITTED_CLAIM_WRITE_ROLES,
} from '../../../constants';
import { BaseAmountTooltip } from '../BaseAmountTooltip';
import {
  expenseClaimAlertContent,
  EXPENSES_TABLE_KEY,
  EXPENSES_TABLE_TITLE,
  statusFiltersOptions,
} from './constants';
import {
  buildReportingManagerFiltersOptions,
  isReportingLineHubber,
} from './helpers';
import * as Styled from './styled.sc';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { FormattedMoney } from '@/components/atoms/FormattedMoney/FormattedMoney';
import { GlintsAntdTable } from '@/components/atoms/GlintsAntdTable/GlintsAntdTable';
import {
  type TableColumns,
  type TableFilters,
  type TableOnChange,
} from '@/components/atoms/GlintsAntdTable/types';
import { GlintsAntdTooltip } from '@/components/atoms/GlintsAntdTooltip/GlintsAntdTooltip';
import { HubberName } from '@/components/atoms/HubberName/HubberName';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { NonDirectManagerTooltip } from '@/components/molecules/NonDirectManagerTooltip/NonDirectManagerTooltip';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { ALERT_STATUS, ALERT_TYPE, alertMessages } from '@/constants/alert';
import {
  type ApproveExpenseClaimsMutation,
  type ExpenseClaimAttachmentUrlMutation,
  type ExpenseSideSheetExpenseClaimFragment,
  type ExpensesTableExpenseClaimFragment,
  type GetCompanyDirectManagersQuery,
  type GetExpenseSideSheetExpenseClaimQuery,
  type RejectExpenseClaimsMutation,
  useApproveExpenseClaimsMutation,
  useExpenseClaimAttachmentUrlMutation,
  useGetCompanyDirectManagersQuery,
  useGetExpenseSideSheetExpenseClaimQuery,
  useRejectExpenseClaimsMutation,
} from '@/generated/graphql';
import { useAntdTableScrollbar } from '@/hooks/useAntdTableScrollbar';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { ExpenseSideSheet } from '@/modules/Expenses/ExpensesPage/components/ExpenseSideSheet/ExpenseSideSheet';
import { capitalizeFirstLetter } from '@/utils/formatString';
import { getCompanyBillingCurrency } from '@/utils/locale';
import { generateHubFiltersOptions } from '@/utils/talentHub';
import { type TimeZone } from '@/utils/timeZone';

interface ExpensesTableProps {
  expenseClaimsData: ExpensesTableExpenseClaimFragment[];
  companyHubs: string[];
  loading: boolean;
  isFiltering: boolean;
  filteredProps: {
    filteredInfo: TableFilters;
    setFilteredInfo: React.Dispatch<React.SetStateAction<TableFilters>>;
    onReset: () => void;
  };
  paginationProps: PaginationProps;
  onTableChange: TableOnChange;
}

export const ExpensesTable = ({
  expenseClaimsData,
  companyHubs,
  loading,
  isFiltering,
  filteredProps,
  paginationProps,
  onTableChange,
}: ExpensesTableProps) => {
  const graphqlClient = getGraphqlClient();
  const { userInfo } = useAuthContext();
  const { open: openAlert } = useAlert();
  const { isTableDefaultScrollbarXHidden } = useAntdTableScrollbar();

  const [tableExpenseClaimsData, setTableExpenseClaimsData] = useState<
    ExpensesTableExpenseClaimFragment[]
  >(expenseClaimsData || []);
  const [searchNameText, setSearchNameText] = useState<string>('');
  const searchNameInput = useRef<HTMLInputElement>(null);

  const [isExpenseSideSheetOpen, setIsExpenseSideSheetOpen] = useState(false);
  // `triggeredExpenseClaimRequestId` is the ID used to fetch expense cliam request data when the sidesheet is opened.
  // Setting this ID triggers the GraphQL query to load the relevant expense cliam request details.
  const [triggeredExpenseClaimRequestId, setTriggeredExpenseClaimRequestId] =
    useState<string>();
  const [sideSheetExpenseCliamData, setSideSheetExpenseClaimData] =
    useState<ExpenseSideSheetExpenseClaimFragment>();
  const [expenseSideSheetLoading, setExpenseSideSheetLoading] = useState(false);

  const companyBillingCurreny =
    getCompanyBillingCurrency(userInfo?.company?.billingAddress) ||
    DEFAULT_BILLING_CURRENCY;

  useEffect(() => {
    if (expenseClaimsData) {
      setTableExpenseClaimsData(expenseClaimsData);
    }
  }, [expenseClaimsData]);

  const {
    data: getExpenseSideSheetExpenseClaimData,
    isLoading: getExpenseSideSheetExpenseClaimLoading,
    error: getExpenseSideSheetExpenseClaimError,
  } = useGetExpenseSideSheetExpenseClaimQuery<
    GetExpenseSideSheetExpenseClaimQuery,
    Error
  >(
    graphqlClient,
    {
      expenseClaimId: triggeredExpenseClaimRequestId,
      baseCurrency: companyBillingCurreny,
    },
    {
      enabled: Boolean(triggeredExpenseClaimRequestId),
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    data: getCompanyDirectMangersData,
    isLoading: getCompanyDirectManagersLoading,
    error: getCompanyDirectManagersError,
  } = useGetCompanyDirectManagersQuery<GetCompanyDirectManagersQuery, Error>(
    graphqlClient,
  );

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function setSideSheetExpenseCliamDataWhenSideSheetOpen() {
      if (getExpenseSideSheetExpenseClaimData?.expenseClaim) {
        setSideSheetExpenseClaimData(
          getExpenseSideSheetExpenseClaimData.expenseClaim,
        );
      }
    },
    [getExpenseSideSheetExpenseClaimData],
  );

  const {
    mutate: expenseClaimAttachmentUrlMutate,
    error: expenseClaimAttachmentUrlError,
  } = useExpenseClaimAttachmentUrlMutation<
    Error,
    ExpenseClaimAttachmentUrlMutation
  >(graphqlClient);

  const {
    mutate: approveExpenseClaimsMutate,
    error: approveExpenseClaimsError,
  } = useApproveExpenseClaimsMutation<Error, ApproveExpenseClaimsMutation>(
    graphqlClient,
    {
      onSuccess: (data) => {
        const response = data.approveExpenseClaims;
        if (response) {
          updateTableExpenseClaimData(response);
          setExpenseSideSheetLoading(false);
          openAlert({
            content: expenseClaimAlertContent[ALERT_STATUS.success],
            status: ALERT_STATUS.success,
            zIndex: 1200,
            position: {
              top: '24px',
            },
          });
        }
      },
      onError: () => handleExpenseCliamMutationError(),
    },
  );

  const { mutate: rejectExpenseClaimsMutate, error: rejectExpenseClaimsError } =
    useRejectExpenseClaimsMutation<Error, RejectExpenseClaimsMutation>(
      graphqlClient,
      {
        onSuccess: (data) => {
          const response = data.rejectExpenseClaims;
          if (response) {
            updateTableExpenseClaimData(response);
            setExpenseSideSheetLoading(false);
            openAlert({
              content: expenseClaimAlertContent[ALERT_STATUS.success],
              status: ALERT_STATUS.success,
              zIndex: 1200,
              position: {
                top: '24px',
              },
            });
          }
        },
        onError: () => handleExpenseCliamMutationError(),
      },
    );

  const columns: TableColumns<ExpensesTableExpenseClaimFragment> = [
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.createdAt],
      key: EXPENSES_TABLE_KEY.createdAt,
      width: 230,
      render: (record: ExpensesTableExpenseClaimFragment) => (
        <FormattedDate
          date={record.createdAt}
          pattern={{
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
          timeZone={record.hubber.hub as TimeZone}
        />
      ),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.employee],
      key: EXPENSES_TABLE_KEY.employee,
      dataIndex: 'hubber',
      width: 200,
      render: (record: ExpensesTableExpenseClaimFragment['hubber']) => (
        <HubberName hubber={record} />
      ),
      filteredValue:
        filteredProps.filteredInfo[EXPENSES_TABLE_KEY.employee] || null,
      filterIcon: () =>
        filteredProps.filteredInfo[EXPENSES_TABLE_KEY.employee] ? (
          <Icon name="ri-search" fill={Blue.S99} height={16} width={16} />
        ) : (
          <Icon name="ri-search" fill={Neutral.B40} height={16} width={16} />
        ),
      filterDropdown: ({ confirm }) => (
        <Box p={16}>
          <Box mb={16}>
            <TextInput
              inputRef={searchNameInput}
              placeholder="Search Name"
              value={searchNameText}
              onChange={(value: string) => setSearchNameText(value)}
            />
          </Box>
          <Flex gap={space8}>
            <PlainButton
              onClick={() => {
                confirm({ closeDropdown: true });
                setSearchNameText('');
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [EXPENSES_TABLE_KEY.employee]: null,
                });
              }}
            >
              Clear
            </PlainButton>
            <PrimaryButton
              onClick={() => {
                setSearchNameText(searchNameText);
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [EXPENSES_TABLE_KEY.employee]: [searchNameText],
                });
              }}
            >
              Search
            </PrimaryButton>
          </Flex>
        </Box>
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchNameInput.current?.select(), 100);
        }
        if (!visible) {
          if (searchNameText) {
            setSearchNameText(searchNameText);
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [EXPENSES_TABLE_KEY.employee]: [searchNameText],
            });
          } else {
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [EXPENSES_TABLE_KEY.employee]: null,
            });
          }
          paginationProps.onPageChanged?.(1);
        }
      },
    },
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.talentHub],
      key: EXPENSES_TABLE_KEY.talentHub,
      dataIndex: 'hubber',
      width: 150,
      render: (record: ExpensesTableExpenseClaimFragment['hubber']) => (
        <HubLocationText hubCode={record.hub as HubCode} />
      ),
      filteredValue:
        filteredProps.filteredInfo[EXPENSES_TABLE_KEY.talentHub] || null,
      filters: generateHubFiltersOptions(companyHubs),
    },
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.expenseCategory],
      key: EXPENSES_TABLE_KEY.expenseCategory,
      dataIndex: 'category',
      width: 200,
      render: (record: ExpensesTableExpenseClaimFragment['category']) => (
        <Typography as="span" variant="caption">
          {capitalizeFirstLetter(record)}
        </Typography>
      ),
    },
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.amount],
      key: EXPENSES_TABLE_KEY.amount,
      dataIndex: 'amount',
      width: 200,
      align: 'right',
      render: (record: ExpensesTableExpenseClaimFragment['amount']) => (
        <FormattedMoney
          amount={record.amount}
          currency={record.currency}
          amountVariant="subtitle2"
        />
      ),
    },
    {
      title: (
        <Flex alignItems="center" justifyContent="flex-end" gap={space8}>
          <span>{EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.baseAmount]}</span>
          <BaseAmountTooltip />
        </Flex>
      ),
      key: EXPENSES_TABLE_KEY.baseAmount,
      dataIndex: 'baseAmount',
      width: 250,
      align: 'right',
      render: (record: ExpensesTableExpenseClaimFragment['baseAmount']) => (
        <FormattedMoney
          amount={record.amount}
          currency={record.currency}
          amountVariant="subtitle2"
        />
      ),
    },
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.reportingManager],
      key: EXPENSES_TABLE_KEY.reportingManager,
      width: 230,
      render: (record: ExpensesTableExpenseClaimFragment) => (
        <>
          {record.hubber.directManager[0] ? (
            record.hubber.directManager[0].name
          ) : (
            <Typography variant="subtitle2" color={Neutral.B68}>
              Unassigned
            </Typography>
          )}
        </>
      ),
      filteredValue:
        filteredProps.filteredInfo[EXPENSES_TABLE_KEY.reportingManager] || null,
      filters: buildReportingManagerFiltersOptions(
        getCompanyDirectMangersData?.company.directManagers || [],
      ),
    },
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.status],
      key: EXPENSES_TABLE_KEY.status,
      dataIndex: 'approval',
      fixed: isBrowser ? 'right' : undefined,
      width: 120,
      render: (record: ExpensesTableExpenseClaimFragment['approval']) => (
        <Badge
          status={
            claimApprovalStatusBadgeMapping[record?.status ?? pendingStatus]
          }
        >
          {CLAIM_APPROVAL_STATUS_TEXT[record?.status ?? pendingStatus]}
        </Badge>
      ),
      filteredValue:
        filteredProps.filteredInfo[EXPENSES_TABLE_KEY.status] || null,
      filters: statusFiltersOptions,
    },
    {
      title: EXPENSES_TABLE_TITLE[EXPENSES_TABLE_KEY.actions],
      key: EXPENSES_TABLE_KEY.actions,
      fixed: isBrowser ? 'right' : undefined,
      width: 80,
      render: (record: ExpensesTableExpenseClaimFragment) => {
        const isPermitted = PERMITTED_CLAIM_WRITE_ROLES.includes(
          userInfo?.contact?.roles[0].id,
        );
        return (
          <>
            {isPermitted ? (
              isReportingLineHubber(userInfo, record.hubber) ? (
                <Styled.OutlineButton
                  onClick={() => handleOpenExpenseSideSheet(record)}
                >
                  <Icon name="ri-arrow-m-right-line" />
                </Styled.OutlineButton>
              ) : (
                <NonDirectManagerTooltip placement="topRight">
                  <Styled.OutlineButton disabled={true}>
                    <Icon name="ri-arrow-m-right-line" />
                  </Styled.OutlineButton>
                </NonDirectManagerTooltip>
              )
            ) : (
              <GlintsAntdTooltip
                placement="topRight"
                title="No permission to manage expenses"
              >
                <Styled.OutlineButton disabled={true}>
                  <Icon name="ri-arrow-m-right-line" />
                </Styled.OutlineButton>
              </GlintsAntdTooltip>
            )}
          </>
        );
      },
    },
  ];

  const emptyState = (
    <EmptyState
      title="No Expense Claims"
      description="You haven’t received any expense claims from your active employees yet."
      image={<NebulaSVG />}
    />
  );

  const noMatchingResultsState = (
    <Box py={64}>
      <EmptyState
        title="No Matching Results"
        description="No results were found based on your search keywords or filtering conditions."
        basicButtonAction={{
          label: 'Reset All',
          onClick: () => {
            setSearchNameText('');
            filteredProps.onReset();
          },
        }}
      />
    </Box>
  );

  const handleClickExpenseClaimAttachment = (id: string) => {
    expenseClaimAttachmentUrlMutate(
      { id },
      {
        onSuccess: (data) => {
          window.open(data?.expenseClaimAttachmentUrl?.url, '_blank');
        },
        onError: () => {
          openAlert({
            content: alertMessages[ALERT_TYPE.apiError],
            status: ALERT_STATUS.error,
            zIndex: 1200,
            position: {
              top: '24px',
            },
          });
        },
      },
    );
  };

  const updateTableExpenseClaimData = (
    updatedData: ExpenseSideSheetExpenseClaimFragment,
  ) => {
    if (!updatedData) return;

    const updatedExpenseData = tableExpenseClaimsData.map((data) =>
      data.id === updatedData.id ? { ...data, ...updatedData } : data,
    );
    setTableExpenseClaimsData(updatedExpenseData);

    if (sideSheetExpenseCliamData) {
      setSideSheetExpenseClaimData({
        ...sideSheetExpenseCliamData,
        ...updatedData,
      });
    }
  };

  const handleOpenExpenseSideSheet = (
    data: ExpensesTableExpenseClaimFragment,
  ) => {
    setTriggeredExpenseClaimRequestId(data.id);
    setIsExpenseSideSheetOpen(true);
  };

  const handleExpenseSideSheetClose = () => {
    setIsExpenseSideSheetOpen(false);
    setExpenseSideSheetLoading(false);
  };

  const handleExpenseClaimApprove = (claimId: string) => {
    setExpenseSideSheetLoading(true);
    approveExpenseClaimsMutate({
      expenseClaimId: claimId,
      baseCurrency: companyBillingCurreny,
    });
  };

  const handleExpenseClaimReject = (claimId: string, remark?: string) => {
    setExpenseSideSheetLoading(true);
    rejectExpenseClaimsMutate({
      expenseClaimId: claimId,
      remarks: remark,
      baseCurrency: companyBillingCurreny,
    });
  };

  const handleExpenseCliamMutationError = () => {
    openAlert({
      content: alertMessages[ALERT_TYPE.apiError],
      status: ALERT_STATUS.error,
      zIndex: 1200,
      position: {
        top: '24px',
      },
    });
  };

  const isTableLoading = loading || getCompanyDirectManagersLoading;

  useGraphqlError([
    getCompanyDirectManagersError,
    getExpenseSideSheetExpenseClaimError,
    approveExpenseClaimsError,
    rejectExpenseClaimsError,
    expenseClaimAttachmentUrlError,
  ]);

  return (
    <>
      <Styled.TableContainer>
        <GlintsAntdTable
          columns={columns}
          dataSource={tableExpenseClaimsData}
          onChange={onTableChange}
          loading={isTableLoading}
          pagination={false}
          emptyState={
            tableExpenseClaimsData?.length === 0 && isFiltering
              ? noMatchingResultsState
              : emptyState
          }
          scroll={{ x: 'max-content' }}
          hideDefaultScrollbarX={isTableDefaultScrollbarXHidden}
          sticky={{
            offsetHeader: 0,
            offsetScroll: 64,
          }}
          height="100%"
        />
        {!loading && tableExpenseClaimsData?.length > 0 && (
          <Styled.TablePaginationContainer>
            <TablePagination
              currentPage={paginationProps.currentPage}
              pageSize={paginationProps.pageSize}
              totalItems={paginationProps.totalItems}
              onPageChanged={paginationProps.onPageChanged}
            />
          </Styled.TablePaginationContainer>
        )}
      </Styled.TableContainer>

      <ExpenseSideSheet
        isOpen={isExpenseSideSheetOpen}
        expenseClaimData={sideSheetExpenseCliamData}
        loading={getExpenseSideSheetExpenseClaimLoading}
        submitting={expenseSideSheetLoading}
        onClickAttachment={handleClickExpenseClaimAttachment}
        onApprove={handleExpenseClaimApprove}
        onReject={handleExpenseClaimReject}
        onClose={handleExpenseSideSheetClose}
      />
    </>
  );
};

ExpensesTable.fragments = {
  expenseClaim: gql`
    fragment ExpensesTableExpenseClaim on ExpenseClaim {
      id
      hubber {
        id
        fullName
        hub
        status
        directManager {
          id
          name
        }
      }
      amount {
        currency
        amount
      }
      baseAmount: amount(currency: $baseCurrency) {
        currency
        amount
      }
      description
      category
      approval {
        id
        status
      }
      createdAt
      updatedAt
    }
  `,
};
