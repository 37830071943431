import { useEffect, useState } from 'react';
import { EmptyState, Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { EGIROBanner } from '../components/EGIROBanner';
import { ALLOWED_PAYMENT_COUNTRIES, PERMITTED_EGIRO_ROLES } from '../constants';
import { type Invoice } from '../interfaces';
import {
  AwaitingPaymentContainer,
  EGIROContainer,
  EGIROContent,
  EGIROContentSection,
  EmptyStateContainer,
  SetupeGIROButton,
  StyledBadge,
  TopSection,
} from './AwaitingPaymentTabStyle';
import { AwaitingPaymentTable } from './components/AwaitingPaymentTable/AwaitingPaymentTable';
import { InvoiceBanner } from './components/InvoiceBanner';
import { PAGE_SIZE } from './constants';
import { ReactComponent as AstronautSafeSVG } from '@/assets/images/astronaut-safe.svg';
import { ReactComponent as EgiroSVG } from '@/assets/images/eGIRO.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { useFeatureFlagContext } from '@/components/particles/FeatureFlagProvider/FeatureFlagProvider';
import { UNLEASH_FLAG_NAME } from '@/constants/constants';
import {
  DirectDebitStatus,
  type GetDirectDebitAuthorisationQuery,
  type GetInvoiceInvoiceSummaryQuery,
  type GetInvoicesQuery,
  InvoiceSortField,
  PaymentLineSortField,
  SortOrder,
  useGetInvoiceInvoiceSummaryQuery,
  useGetInvoicesQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';

export const AwaitingPaymentTab = ({
  isLoadingDirectDebitAuth,
  directDebitAuthData,
}: {
  isLoadingDirectDebitAuth: boolean;
  directDebitAuthData?: GetDirectDebitAuthorisationQuery;
}) => {
  const featureFlagContext = useFeatureFlagContext();
  const { useFlagEnabled } = featureFlagContext;
  const { userInfo } = useAuthContext();
  const iseGIROShown =
    useFlagEnabled(UNLEASH_FLAG_NAME.mtseGIRO) &&
    userInfo?.company?.billingAddress?.country &&
    ALLOWED_PAYMENT_COUNTRIES.includes(userInfo.company.billingAddress.country);

  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [loadingDelay, setLoadingDelay] = useState(true);
  const [timerSet, setTimerSet] = useState(false);

  const invoicesVariables = {
    page,
    pageSize: PAGE_SIZE,
    isClosed: false,
    paymentLineSortInput: {
      field: PaymentLineSortField.Date,
      order: SortOrder.Desc,
    },
    sorts: { field: InvoiceSortField.DueDate, order: SortOrder.Asc },
  };

  const {
    isLoading: isLoadingInvoices,
    refetch: refetchInvoices,
    data: invoicesData,
    error: getInvoicesError,
  } = useGetInvoicesQuery<GetInvoicesQuery, Error>(
    graphqlClient,
    invoicesVariables,
    {
      cacheTime: 0,
    },
  );

  const {
    data: invoiceSummaryData,
    isLoading: isLoadingInvoiceSummary,
    error: getInvoiceSummaryError,
  } = useGetInvoiceInvoiceSummaryQuery<GetInvoiceInvoiceSummaryQuery, Error>(
    graphqlClient,
  );

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRefetch = () => {
    refetchInvoices();
  };

  const handlePayClick = (invoiceIds: string[]) => {
    if (invoiceIds.length === 0) return;

    navigate({
      pathname: 'bank-transfer',
      search: createSearchParams({
        invoices: invoiceIds,
      }).toString(),
    });
  };

  const isLoading =
    isLoadingInvoices || isLoadingInvoiceSummary || isLoadingDirectDebitAuth;

  useEffect(() => {
    if (isLoading) {
      setLoadingDelay(true);
      setTimerSet(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && !timerSet) {
      setTimerSet(true);

      const delay = 400;
      const timeoutId = setTimeout(() => {
        setLoadingDelay(false);
        setTimerSet(false);
      }, delay);

      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const awaitingPaymentTable =
    invoicesData?.invoices.data.length === 0 ? (
      <EmptyStateContainer>
        <EmptyState
          image={<AstronautSafeSVG />}
          title="No Invoices Awaiting Payment"
          description={
            iseGIROShown &&
            PERMITTED_EGIRO_ROLES.includes(userInfo?.contact?.roles[0].id) &&
            'Save time on future payments by setting up eGIRO for automated processing.'
          }
          basicButtonAction={
            iseGIROShown &&
            PERMITTED_EGIRO_ROLES.includes(userInfo?.contact?.roles[0].id)
              ? {
                  label: 'Set Up eGIRO',
                  icon: <Icon name="ri-settings-line" />,
                  iconPosition: 'left',
                  onClick: () => {
                    navigate('/company-settings/auto-payment');
                  },
                }
              : undefined
          }
        />
      </EmptyStateContainer>
    ) : (
      <AwaitingPaymentTable
        currentPage={invoicesData?.invoices?.pageNo || 1}
        pageSize={invoicesData?.invoices?.pageSize || 0}
        totalItems={invoicesData?.invoices.total || 0}
        onPageChanged={handlePageChange}
        invoicesData={
          getInvoicesError ? [] : (invoicesData?.invoices.data as Invoice[])
        }
        loading={loadingDelay}
        handleRefetch={handleRefetch}
        handlePayClick={handlePayClick}
        iseGIROActive={Boolean(
          iseGIROShown &&
            directDebitAuthData?.company.directDebit?.status ===
              DirectDebitStatus.Active &&
            PERMITTED_EGIRO_ROLES.includes(userInfo?.contact?.roles[0].id),
        )}
      />
    );

  useGraphqlError([getInvoicesError, getInvoiceSummaryError]);

  return (
    <AwaitingPaymentContainer>
      <TopSection
        data-direct-debit-active={
          iseGIROShown &&
          directDebitAuthData?.company.directDebit?.status ===
            DirectDebitStatus.Active
        }
      >
        {iseGIROShown &&
          directDebitAuthData?.company.directDebit?.status ===
            DirectDebitStatus.Active &&
          PERMITTED_EGIRO_ROLES.includes(userInfo?.contact?.roles[0].id) && (
            <EGIROBanner />
          )}
        {invoicesData?.invoices?.data?.length !== 0 && (
          <InvoiceBanner
            loading={loadingDelay}
            openCount={invoiceSummaryData?.invoiceSummary.open || 0}
            outstandingCount={
              invoiceSummaryData?.invoiceSummary.outstanding || 0
            }
            overdueCount={invoiceSummaryData?.invoiceSummary.overdue || 0}
            totalUnpaidAmount={invoiceSummaryData?.invoiceSummary?.totalUnpaid}
          />
        )}
        {iseGIROShown &&
          invoicesData?.invoices?.data?.length !== 0 &&
          directDebitAuthData?.company.directDebit?.status !==
            DirectDebitStatus.Active &&
          PERMITTED_EGIRO_ROLES.includes(userInfo?.contact?.roles[0].id) && (
            <EGIROContainer>
              <StyledBadge status="promotion">Recommended</StyledBadge>
              <EGIROContent>
                <div>
                  <EgiroSVG />
                </div>
                <EGIROContentSection>
                  <Typography
                    className="egiro-content"
                    variant="body2"
                    color={Neutral.B18}
                  >
                    Automate payments for time savings
                  </Typography>
                  <SetupeGIROButton
                    icon={<Icon name="ri-arrow-m-right-line" />}
                    iconPosition="right"
                    onClick={() => {
                      navigate('/company-settings/auto-payment');
                    }}
                  >
                    Set Up eGIRO
                  </SetupeGIROButton>
                </EGIROContentSection>
              </EGIROContent>
            </EGIROContainer>
          )}
      </TopSection>
      {awaitingPaymentTable}
    </AwaitingPaymentContainer>
  );
};
