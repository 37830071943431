export const PLACEMENT = {
  bottomLeft: 'bottom-left',
  bottom: 'bottom',
  bottomRight: 'bottom-right',
} as const;

export type PlacementType = ValueOf<typeof PLACEMENT>;

export const PLACEMENT_LEFT_VALUE = {
  [PLACEMENT.bottomLeft]: '100%',
  [PLACEMENT.bottom]: '50%',
  [PLACEMENT.bottomRight]: '0',
} as const;

export const PLACEMENT_TRANSFORM_X = {
  [PLACEMENT.bottomLeft]: 'translateX(-100%)',
  [PLACEMENT.bottom]: 'translateX(-50%)',
  [PLACEMENT.bottomRight]: 'translateX(0)',
} as const;
