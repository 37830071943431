import { SideSheet as AriesSideSheet } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space12,
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const CardData = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  flex: 1;

  &[data-left-margin='true'] {
    margin-left: ${space32};
  }
`;

export const SideSheet = styled(AriesSideSheet)`
  .sidesheet-content {
    background: ${Neutral.B99};
    padding: ${space12};
    height: 100%;

    &::-webkit-scrollbar {
      display: none !important;
    }

    *::-webkit-scrollbar {
      width: 0 !important;
    }
  }
`;

export const SideSheetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${space16};
`;

export const CardRow = styled.div`
  padding: ${space16} ${space24};
  display: flex;
  flex-direction: row;
`;
