import { useEffect, useState } from 'react';
import {
  OutlineButton,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral, Red } from 'glints-aries/lib/@next/utilities/colors';

import {
  ActionButtons,
  BankContainer,
  BankInstructions,
  RedirectContainer,
  SetupWithBankContainer,
} from '../AutoPaymentTabStyle';
import {
  computeLastMonthInvoiceSGD,
  DEFAULT_LAST_MONTH_INVOICE_AMOUNT,
  DEFAULT_LAST_MONTH_INVOICE_CURRENCY,
} from '../constants';
import { type BankDetails } from '../interfaces';
import { getGraphqlClient } from '@/clients/graphql';
import {
  type InitializeDirectDebitMutation,
  type Money,
  useInitializeDirectDebitMutation,
} from '@/generated/graphql';
import { formatMoney } from '@/utils/formatString';

interface SetupWithBankProps extends Omit<BankDetails, 'url'> {
  lastMonthTotalInvoice?: Omit<Money, 'inCurrencies'>[];
}
export const SetupWithBank = ({
  bankName,
  icon,
  lastMonthTotalInvoice,
  bankSwiftBicCode,
}: SetupWithBankProps) => {
  const graphqlClient = getGraphqlClient();
  const [lastMonthTotalInvoiceSGD, setLastMonthTotalInvoiceSGD] = useState<
    Omit<Money, 'inCurrencies'> | undefined
  >({
    amount: DEFAULT_LAST_MONTH_INVOICE_AMOUNT,
    currency: DEFAULT_LAST_MONTH_INVOICE_CURRENCY,
  });
  const [redirectToBank, setRedirectToBank] = useState(false);

  const { mutate: initializeDirectDebitMutate } =
    useInitializeDirectDebitMutation<Error, InitializeDirectDebitMutation>(
      graphqlClient,
    );

  const initializeDirectDebit = () => {
    const initializeDirectDebitParams = {
      param: {
        redirectUrl: `${window.location.origin}/egiro/connect?bank=${bankSwiftBicCode}`,
        bankSwiftBicCode,
      },
    };

    initializeDirectDebitMutate(initializeDirectDebitParams, {
      onSuccess: (data) => {
        window.open(data.initializeDirectDebit.authorizationUrl, '_blank');
      },
      onError: () => {
        // handle error
      },
    });
  };

  const handleClickRedirectToBank = () => {
    const delay = 2000;
    setRedirectToBank(true);

    setTimeout(initializeDirectDebit, delay);
  };

  useEffect(() => {
    if (lastMonthTotalInvoice) {
      const lastMonthInvoiceSGD = computeLastMonthInvoiceSGD(
        lastMonthTotalInvoice,
      );

      setLastMonthTotalInvoiceSGD({
        amount:
          lastMonthInvoiceSGD?.amount ?? DEFAULT_LAST_MONTH_INVOICE_AMOUNT,
        currency:
          lastMonthInvoiceSGD?.currency ?? DEFAULT_LAST_MONTH_INVOICE_CURRENCY,
      });
    }
  }, [lastMonthTotalInvoice]);

  return (
    <SetupWithBankContainer>
      <BankContainer>
        {icon}
        <Typography variant="headline3" color={Neutral.B18}>
          {bankName}
        </Typography>
      </BankContainer>
      <BankInstructions>
        <Typography as="span" variant="body1">
          Before redirecting you to DBS, please note that you will be required
          to define a{' '}
          <Typography as="span" variant="body1" color={Red.B93}>
            payment limit,
          </Typography>{' '}
          ensuring it is set{' '}
          <Typography as="span" variant="body1" color={Red.B93}>
            above
            {lastMonthTotalInvoiceSGD
              ? ` ${lastMonthTotalInvoiceSGD?.currency} ${formatMoney({
                  amount: lastMonthTotalInvoiceSGD?.amount,
                })} `
              : 'your expected payment amount '}
          </Typography>
          to prevent any disruptions in your future payments.
        </Typography>
      </BankInstructions>
      <ActionButtons>
        {redirectToBank ? (
          <RedirectContainer>
            <Typography as="span" variant="body1">
              We’re redirecting you to {bankName}...
            </Typography>
          </RedirectContainer>
        ) : (
          <PrimaryButton onClick={handleClickRedirectToBank}>
            OK, Ready to Proceed
          </PrimaryButton>
        )}
        <OutlineButton onClick={() => window.location.reload()}>
          Back to Overview
        </OutlineButton>
      </ActionButtons>
    </SetupWithBankContainer>
  );
};
