import { Fragment, useEffect, useState } from 'react';
import { Box, Flex } from 'glints-aries';
import {
  Badge,
  Card,
  Icon,
  Link,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral, Orange } from 'glints-aries/lib/@next/utilities/colors';
import { space4 } from 'glints-aries/lib/@next/utilities/spacing';
import { gql } from 'graphql-request';
import { useForm } from 'react-hook-form';

import {
  CLAIM_APPROVAL_STATUS_TEXT,
  claimApprovalStatusBadgeMapping,
  pendingStatus,
} from '../../../constants';
import { BaseAmountTooltip } from '../BaseAmountTooltip';
import {
  APPROVAL_STATE,
  approvalOptions,
  type ApprovalState,
  REJECTION_FORM_NAMES,
  REJECTION_FORM_PLACEHOLDERS,
  REJECTION_FORM_RULES,
  type RejectionFormFields,
} from './constants';
import * as Styled from './styled.sc';
import { FormattedMoney } from '@/components/atoms/FormattedMoney/FormattedMoney';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { RadioButtonGroup } from '@/components/atoms/RadioButtonGroup/RadioButtonGroup';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { FormTextArea } from '@/components/molecules/Forms/FormTextArea/FormTextArea';
import { CardData } from '@/components/molecules/SideSheet/CardData';
import { LoadingState } from '@/components/molecules/SideSheet/LoadingState';
import * as SideSheetStyled from '@/components/molecules/SideSheet/styled.sc';
import {
  ExpenseClaimsStatus,
  type ExpenseSideSheetExpenseClaimFragment,
} from '@/generated/graphql';
import { capitalizeFirstLetter } from '@/utils/formatString';
import { type TimeZone } from '@/utils/timeZone';

export interface ExpenseSideSheetProps {
  isOpen: boolean;
  expenseClaimData?: ExpenseSideSheetExpenseClaimFragment;
  loading?: boolean;
  submitting?: boolean;
  onClickAttachment: (id: string) => void;
  onApprove: (claimId: string) => void;
  onReject: (claimId: string, remark?: string) => void;
  onClose: () => void;
}

export const ExpenseSideSheet = ({
  isOpen,
  expenseClaimData,
  loading,
  submitting,
  onClickAttachment,
  onApprove,
  onReject,
  onClose,
}: ExpenseSideSheetProps) => {
  const [selectedApprovalState, setSelectedApprovalState] =
    useState<ApprovalState | null>(null);

  const {
    control,
    handleSubmit,
    watch,
    reset: resetForm,
  } = useForm<RejectionFormFields>({
    mode: 'onBlur',
    defaultValues: {
      [REJECTION_FORM_NAMES.rejectReason]: '',
    },
  });

  const watchedRejectReason = watch(REJECTION_FORM_NAMES.rejectReason);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    if (isOpen) {
      setIsExpanded(false);
    }
  }, [isOpen]);

  const handleApprovalChange = (value: ApprovalState) => {
    setSelectedApprovalState(value);
  };

  const handleConfirm = (data: RejectionFormFields) => {
    if (!expenseClaimData || !selectedApprovalState) return;

    if (selectedApprovalState === APPROVAL_STATE.approve) {
      onApprove(expenseClaimData.id);
    } else if (selectedApprovalState === APPROVAL_STATE.reject) {
      onReject(expenseClaimData.id, data[REJECTION_FORM_NAMES.rejectReason]);
    }
  };

  const handleClickAttachment = (id: string) => {
    onClickAttachment(id);
  };

  const handleClose = () => {
    onClose();
    setSelectedApprovalState(null);
    resetForm();
  };

  const renderCardContent = () => {
    const isPendingState = expenseClaimData?.approval === null;
    return (
      <Styled.CardWrapper>
        <Card>
          <SideSheetStyled.CardRow>
            <CardData
              label="Employee"
              value={expenseClaimData?.hubber.fullName}
            />
            <CardData
              label="TalentHub"
              value={
                expenseClaimData?.hubber.hub && (
                  <HubLocationText
                    hubCode={expenseClaimData?.hubber.hub as HubCode}
                  />
                )
              }
            />
          </SideSheetStyled.CardRow>

          <SideSheetStyled.Divider />

          <SideSheetStyled.CardRow>
            <CardData
              label="Requested Time"
              value={
                <FormattedDate
                  date={expenseClaimData?.createdAt}
                  timeZone={expenseClaimData?.hubber.hub as TimeZone}
                  pattern={{
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  }}
                  textVariant="body1"
                />
              }
            />
            <CardData
              label="Expense Category"
              value={
                expenseClaimData?.category &&
                capitalizeFirstLetter(expenseClaimData.category)
              }
            />
          </SideSheetStyled.CardRow>

          <SideSheetStyled.CardRow>
            <CardData
              label={
                <>
                  <div>Total Amount</div>
                  <Typography as="span" variant="overline" color={Neutral.B40}>
                    (Local)
                  </Typography>
                </>
              }
              value={(() => {
                if (!expenseClaimData) return null;
                return (
                  <FormattedMoney
                    amount={expenseClaimData.amount.amount}
                    currency={expenseClaimData.amount.currency}
                  />
                );
              })()}
              emptyText="Undefined"
            />
            <CardData
              label={
                <>
                  <Flex alignItems="center" gap={space4}>
                    Est. Total Amount
                    <BaseAmountTooltip placement="bottomRight" />
                  </Flex>
                  <Typography as="span" variant="overline" color={Neutral.B40}>
                    (Base)
                  </Typography>
                </>
              }
              value={(() => {
                if (!expenseClaimData) return null;
                return (
                  <FormattedMoney
                    amount={expenseClaimData.baseAmount.amount}
                    currency={expenseClaimData.baseAmount.currency}
                  />
                );
              })()}
              emptyText="Undefined"
            />
          </SideSheetStyled.CardRow>

          <SideSheetStyled.CardRow>
            <CardData
              label="Status"
              value={
                <Badge
                  status={
                    claimApprovalStatusBadgeMapping[
                      expenseClaimData?.approval?.status ?? pendingStatus
                    ]
                  }
                >
                  {
                    CLAIM_APPROVAL_STATUS_TEXT[
                      expenseClaimData?.approval?.status ?? pendingStatus
                    ]
                  }
                </Badge>
              }
            />
          </SideSheetStyled.CardRow>

          {(isExpanded || isPendingState) && (
            <>
              <SideSheetStyled.Divider />

              <SideSheetStyled.CardRow flexDirection="column">
                <CardData
                  label="Item Description"
                  value={expenseClaimData?.description || null}
                  emptyText="None"
                />
                <CardData
                  label="Attachment"
                  value={expenseClaimData?.attachments.map(
                    (attachment, index) => (
                      <Fragment key={`attachment-ref-${attachment.id}`}>
                        <Link
                          target="_blank"
                          onClick={() => handleClickAttachment(attachment.id)}
                        >
                          <Styled.FileName>
                            {attachment.fileName}
                          </Styled.FileName>
                        </Link>
                        {index !== expenseClaimData?.attachments.length - 1 && (
                          <span>{', '}</span>
                        )}
                      </Fragment>
                    ),
                  )}
                  flexWrap="wrap"
                />
              </SideSheetStyled.CardRow>

              <SideSheetStyled.Divider />

              <SideSheetStyled.CardRow>
                <CardData
                  label="Notes to Manager"
                  value={expenseClaimData?.notesToManager || null}
                  emptyText="None"
                />
              </SideSheetStyled.CardRow>
            </>
          )}

          {!isPendingState && (
            <Styled.ExpandButton onClick={toggleExpand}>
              {isExpanded ? (
                <>
                  <Typography as="span" variant="subtitle2" color={Neutral.B18}>
                    Show Less
                  </Typography>
                  <Icon
                    name="ri-arrow-m-up-line"
                    fill={Neutral.B40}
                    height="24px"
                  />
                </>
              ) : (
                <>
                  <Typography as="span" variant="subtitle2" color={Neutral.B18}>
                    View More
                  </Typography>
                  <Icon
                    name="ri-arrow-m-down-line"
                    fill={Neutral.B40}
                    height="24px"
                  />
                </>
              )}
            </Styled.ExpandButton>
          )}
        </Card>
      </Styled.CardWrapper>
    );
  };

  if (loading) {
    return (
      <SideSheetStyled.SideSheet
        title="Expense Claim"
        isOpen={isOpen}
        onClose={handleClose}
        showBackButton={false}
      >
        <LoadingState />
      </SideSheetStyled.SideSheet>
    );
  }

  return (
    <SideSheetStyled.SideSheet
      title="Expense Claim"
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
    >
      {renderCardContent()}
      {expenseClaimData?.approval === null ? (
        <>
          <Box mt={16}>
            <SideSheetStyled.ApprovalActionsLabel>
              <Icon name="ri-flag-fill" fill={Orange.S86} height="18px" />
              <Typography as="div" variant="caption" color={Neutral.B40}>
                Action needed:
              </Typography>
            </SideSheetStyled.ApprovalActionsLabel>
            <SideSheetStyled.ApprovalActions>
              <RadioButtonGroup
                options={approvalOptions}
                onChange={(value: string) =>
                  handleApprovalChange(value as ApprovalState)
                }
                disabled={submitting}
              />
            </SideSheetStyled.ApprovalActions>
            {selectedApprovalState === APPROVAL_STATE.reject && (
              <FormTextArea
                name={REJECTION_FORM_NAMES.rejectReason}
                control={control}
                rules={REJECTION_FORM_RULES[REJECTION_FORM_NAMES.rejectReason]}
                disabled={submitting}
                placeholder={
                  REJECTION_FORM_PLACEHOLDERS[REJECTION_FORM_NAMES.rejectReason]
                }
                maxLength={150}
                canExceedMaxLength={false}
                rows={3}
                width="unset"
              />
            )}
          </Box>
          <SideSheetStyled.SideSheetFooter>
            <PrimaryButton
              onClick={handleSubmit(handleConfirm)}
              disabled={
                !selectedApprovalState ||
                (selectedApprovalState === APPROVAL_STATE.reject &&
                  watchedRejectReason === '')
              }
              loading={submitting}
            >
              Confirm
            </PrimaryButton>
          </SideSheetStyled.SideSheetFooter>
        </>
      ) : (
        <>
          <Box mt={24}>
            <Card>
              <SideSheetStyled.CardRow>
                <CardData
                  label="Manager"
                  value={expenseClaimData?.approval?.approver?.name}
                />
                <CardData
                  label="Status Updated"
                  value={
                    <FormattedDate
                      date={expenseClaimData?.approval?.updatedAt}
                      timeZone={expenseClaimData?.hubber.hub as TimeZone}
                      pattern={{
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      }}
                      textVariant="body1"
                    />
                  }
                />
              </SideSheetStyled.CardRow>
              {expenseClaimData?.approval?.status ===
                ExpenseClaimsStatus.Rejected && (
                <>
                  <SideSheetStyled.Divider />
                  <SideSheetStyled.CardRow>
                    <CardData
                      label="Reason for Rejection"
                      value={expenseClaimData?.approval?.remarks || null}
                      emptyText="None"
                    />
                  </SideSheetStyled.CardRow>
                </>
              )}
            </Card>
          </Box>
        </>
      )}
    </SideSheetStyled.SideSheet>
  );
};

ExpenseSideSheet.fragments = {
  expenseClaim: gql`
    fragment ExpenseSideSheetExpenseClaim on ExpenseClaim {
      id
      hubber {
        id
        fullName
        hub
        status
        directManager {
          id
          name
        }
      }
      amount {
        currency
        amount
      }
      baseAmount: amount(currency: $baseCurrency) {
        currency
        amount
      }
      description
      notesToManager
      attachments {
        id
        fileName
      }
      category
      approval {
        id
        approver {
          id
          name
        }
        status
        remarks
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  `,
};
