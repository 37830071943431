import { ReactComponent as FlagID } from '@/assets/images/flag-id.svg';
import { ReactComponent as FlagMY } from '@/assets/images/flag-my.svg';
import { ReactComponent as FlagPH } from '@/assets/images/flag-ph.svg';
import { ReactComponent as FlagSG } from '@/assets/images/flag-sg.svg';
import { ReactComponent as FlagTW } from '@/assets/images/flag-tw.svg';
import { ReactComponent as FlagVN } from '@/assets/images/flag-vn.svg';

export const HUB_CODE = {
  BT: 'BT',
  ID: 'ID',
  MY: 'MY',
  PH: 'PH',
  SG: 'SG',
  TW: 'TW',
  VN: 'VN',
} as const;

export type HubCode = ValueOf<typeof HUB_CODE>;

export const HUB_CODE_LABEL: { [code in HubCode]: HubCode } = {
  [HUB_CODE.BT]: HUB_CODE.ID,
  [HUB_CODE.ID]: HUB_CODE.ID,
  [HUB_CODE.MY]: HUB_CODE.MY,
  [HUB_CODE.PH]: HUB_CODE.PH,
  [HUB_CODE.SG]: HUB_CODE.SG,
  [HUB_CODE.TW]: HUB_CODE.TW,
  [HUB_CODE.VN]: HUB_CODE.VN,
};

export const HUB_CODE_FLAG: { [code in HubCode]: React.ReactElement } = {
  [HUB_CODE.BT]: <FlagID />,
  [HUB_CODE.ID]: <FlagID />,
  [HUB_CODE.MY]: <FlagMY />,
  [HUB_CODE.PH]: <FlagPH />,
  [HUB_CODE.SG]: <FlagSG />,
  [HUB_CODE.TW]: <FlagTW />,
  [HUB_CODE.VN]: <FlagVN />,
};
