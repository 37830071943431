import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import { insetButton as insetButtonShadow } from 'glints-aries/lib/@next/utilities/dropShadow';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const ContactListItem = styled.div<{ active: boolean }>`
  display: flex;
  gap: ${space16};
  padding: ${space8} 20px;
  cursor: pointer;
  height: 56px;
  box-shadow: ${insetButtonShadow};

  ${({ active }) => active && `background-color: ${Blue.S08};`}

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    box-shadow: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`;

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`;
