export const getInitials = (str: string) => {
  if (!str) {
    return;
  }

  const splitStr = str.split(' ');
  const initials = splitStr.map((s) => s.charAt(0).toUpperCase());

  return initials.join('');
};

export const formatMoney = ({
  amount,
  locale,
  options = { minimumFractionDigits: 2 },
}: {
  amount: string;
  locale?: string | string[];
  options?: Intl.NumberFormatOptions;
}) => new Intl.NumberFormat(locale, options).format(parseFloat(amount));

export const capitalizeFirstLetter = (str: string): string => {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str;
  }
  return `${str.slice(0, maxLength - 3)}...`;
};
