import { TextInput, Typography } from 'glints-aries/lib/@next';
import { space4 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

interface FormTextInputProps {
  height: string;
}

export const FormTextInputContainer = styled.div<FormTextInputProps>`
  height: ${(props) => props.height};
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: ${space4};
  margin-top: 0;
`;

export const StyledLabel = styled(Typography)`
  margin: 0px;
`;

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${space4};
  margin-top: 0;
`;
