import { type CompanyProfileTabUrl, companyProfileTabUrls } from './constants';

export const getCompanyProfileTabFromPathname = (
  pathname: string,
): {
  isValidTab: boolean;
  tabPathUrl: CompanyProfileTabUrl | null;
} => {
  const matchingTabUrl = (
    Object.values(companyProfileTabUrls) as string[]
  ).find((url) => pathname.startsWith(url)) as CompanyProfileTabUrl | undefined;

  return {
    isValidTab: Boolean(matchingTabUrl),
    tabPathUrl: matchingTabUrl || null,
  };
};
