import { ALLOWED_PAYMENT_CURRENCIES } from '../constants';

export const PAGE_SIZE = 20;
export const EGIRO_NEXT_COLLECTION_DAY = '25th';

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ShowAlertType = {
  status?: AlertType.SUCCESS | AlertType.ERROR;
  content: string;
  shouldShow?: boolean;
};

export const alertContent = {
  [AlertType.SUCCESS]: 'Your dispute has been successfully sent',
  [AlertType.ERROR]: 'Failed to send your dispute. Please try again later',
};

export enum InvoiceStatus {
  OUTSTANDING = 'Outstanding',
  DUE = 'Due',
  OVERDUE = 'Overdue',
  DUESOON = 'Due in',
}

export const DISABLED_INVOICE_NAMES = {
  unavailablePaymentCurrency: 'unavailablePaymentCurrency',
  eGIROEnabled: 'eGIROEnabled',
} as const;

export const DISABLED_INVOICE_REASON = {
  [DISABLED_INVOICE_NAMES.unavailablePaymentCurrency]: `Only available for ${ALLOWED_PAYMENT_CURRENCIES.join(
    ', ',
  )} invoices`,
  [DISABLED_INVOICE_NAMES.eGIROEnabled]:
    'eGIRO auto-payment is enabled on your account',
} as const;
