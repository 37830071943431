import { useState } from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { StyledBanner } from './AttendanceLogPageStyle';
import { AttendanceLogTable } from './AttendanceLogTable/AttendanceLogTable';

const AttendanceLogPage = () => {
  const [showBanner, setShowBanner] = useState(false);

  const updateShowBanner = (shouldShow: boolean) => {
    setShowBanner(shouldShow);
  };

  return (
    <>
      <Typography
        as="span"
        variant="headline6"
        color={Neutral.B18}
        style={{ marginBottom: '8px' }}
      >
        Attendance
      </Typography>
      {showBanner && (
        <StyledBanner status="info" dismissable={false}>
          <Typography as="span" variant="body1" color={Neutral.B18}>
            Please note that attendance logging requirements are specific to
            certain countries. If you do not see this feature, it indicates that
            your location may not require attendance logging at this time.
          </Typography>
        </StyledBanner>
      )}
      <AttendanceLogTable updateShowBanner={updateShowBanner} />
    </>
  );
};

export default AttendanceLogPage;
