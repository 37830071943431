import { Steps as AriesSteps } from 'glints-aries/lib/@next';
import {
  borderRadius4,
  borderRadius8,
  borderRadius16,
} from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
  space24,
  space32,
  space80,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const BankTransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space24};
  height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    overflow: auto;
  }
`;

export const HeaderInvoicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${space4} 0 ${space32};
  align-items: center;
  gap: ${space4};

  @media (max-width: ${BREAKPOINTS.medium}) {
    margin: ${space4} 0 ${space16};
  }
`;

export const BankTransferContent = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;

export const StepContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${space16} ${space80} ${space32};
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    overflow-y: unset;
    padding: 0 2px;
  }
`;

export const BankTransferFooter = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px -2px 10px -1px rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const BankTransferFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
`;

export const ReviewSummaryContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const InvoicePaymentSummary = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${space8} ${space32};
  gap: ${space8};
  border-radius: ${borderRadius16};
  background: ${Neutral.B100};
  margin-bottom: ${space16};

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${space8} ${space16};
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: ${space8} ${space12};
  }
`;

export const InvoiceItemCloseButton = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  opacity: 0;
  cursor: pointer;
  transition: all 400ms ease-in-out;
`;

export const InvoiceItem = styled.div<{ enabledHover?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  transition: all 400ms ease-in-out;
  position: relative;

  ${({ enabledHover }) =>
    enabledHover &&
    `
    &:hover {
      padding: 12px 8px;
      background: ${Blue.S08};
      border-radius: ${borderRadius8};
      box-shadow: 0px 0px 5px 0px rgba(71, 71, 71, 0.1),
        0px 1px 2px 0px rgba(71, 71, 71, 0.2);

      ${InvoiceItemCloseButton} {
        opacity: 1;
      }
    }
  `}
`;

export const InvoicePaymentTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TotalAmount = styled.div`
  display: flex;
  align-items: center;
  gap: ${space4};
`;

export const InvoicePaymentTotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${space16} ${space32};
  background: ${Blue.S08};
  box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15);
  border-radius: ${borderRadius4};
  margin-bottom: ${space4};
`;

export const StepsContainer = styled.div`
  padding: ${space16} ${space16} ${space16} ${space24};
  border-right: 1px solid ${Neutral.B85};

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${space16} 0;
    border-right: none;

    [class*='VerticalLineWrapper'] {
      display: none;
    }
  }
`;

export const Steps = styled(AriesSteps)`
  width: 200px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: flex;
    width: 100%;
    gap: ${space32};
    justify-content: space-around;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    gap: 0;
  }
`;

export const ViewInstructionsContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${space24};
  padding-bottom: 60px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: ${space16};
    padding-bottom: 40px;
  }
`;

export const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;

export const LeftSectionContainer = styled.div`
  flex: 1;
  margin-right: ${space32};

  @media (max-width: ${BREAKPOINTS.medium}) {
    margin-right: 0;
  }
`;

export const RightSectionContainer = styled.div`
  flex: 1;
`;

export const BankTransferDetailsCard = styled.div`
  margin-bottom: ${space24};
`;

export const ViewInstructionsLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space24};

  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: ${space16};
  }
`;
