import { Breakpoints } from 'glints-aries/lib/@next';
import { button } from 'glints-aries/lib/@next/Typography/TypographyStyles';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BANNER_MARGIN_TOP } from '../AttendanceLogPageStyle';

const CUSTOM_BREAKPOINT = '1180px';
const HEADER_HEIGHT = '136px';

export const DataTableContainer = styled.div`
  ::-webkit-scrollbar {
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  box-shadow: 0px 0px 0px 1px rgba(84, 84, 84, 0.2);
  transform: translateX(-24px);
  width: calc(100%);
  position: absolute;
  height: calc(100% - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Neutral.B100};

  .data-table-container {
    background-color: ${Neutral.B100};
    height: 100%;
    box-shadow: none !important;

    table {
      height: auto;

      tbody tr {
        height: 56px;
      }
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    transform: translateX(-16px);
  }
`;

export const TablePaginationContainer = styled.div`
  display: grid;
  padding: ${space8} ${space16};
  background: ${Neutral.B100};
`;

export const TableHeadingCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space4};

  svg {
    height: 20px;
    width: 20px;
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const TableButtonContainer = styled.div`
  ${button}
  margin: 0px ${space8};

  &[data-no-margin-right='true'] {
    margin-right: 0px;
  }
`;

export const ClickableCell = styled.div`
  display: flex;
  cursor: pointer;

  &[data-left-padding='true'] {
    padding-left: ${space8};
  }
`;

export const TotalHourCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space4};

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const AttendanceLogHeader = styled.div`
  margin: ${space16} 0;
  display: flex;
  flex-direction: row;
  gap: ${space16};

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    display: grid;
    grid-template-areas:
      'header-request-filter header-request-filter'
      'header-talent-filter header-talent-filter'
      'header-search header-export';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const HeaderSearchContainer = styled.div`
  width: 200px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    grid-area: header-search;
  }
`;

export const HeaderRequestFilterContainer = styled.div`
  width: 240px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
    grid-area: header-request-filter;
  }
`;

export const HeaderTalentFilterContainer = styled.div`
  width: 250px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
    grid-area: header-talent-filter;
  }
`;

export const HeaderExportButtonContainer = styled.div`
  margin-left: auto;

  svg {
    height: 16px;
    width: 16px;
  }

  @media (max-width: ${CUSTOM_BREAKPOINT}}) {
    display: flex;
    justify-content: flex-end;
    grid-area: header-export;
  }
`;

export const EmptyStateContainer = styled.div`
  max-width: 400px;
  height: calc(100% - ${HEADER_HEIGHT} - ${BANNER_MARGIN_TOP});
  margin: auto;
  display: flex;
  align-items: center;
`;
