import { type ReactNode } from 'react';
import { Flex } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space4 } from 'glints-aries/lib/@next/utilities/spacing';

import { CardData as StyledCardData } from './styled.sc';

export interface CardDataProps {
  label: string | ReactNode;
  value?: string | ReactNode;
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  emptyText?: string;
  suffix?: string | ReactNode;
}

export const CardData = ({
  label,
  value,
  flexWrap,
  emptyText = '-',
  suffix,
}: CardDataProps) => (
  <StyledCardData>
    <Typography as="span" variant="caption" color={Neutral.B40}>
      {label}
    </Typography>
    <Flex
      flexDirection="row"
      alignItems="center"
      gap={space4}
      flexWrap={flexWrap}
    >
      {typeof value === 'string' || value === null || value === undefined ? (
        <Typography
          as="div"
          variant="body1"
          color={value ? Neutral.B18 : Neutral.B68}
        >
          {value ?? emptyText}
        </Typography>
      ) : (
        value
      )}
      {suffix && (
        <>
          {typeof suffix === 'string' ? (
            <Typography as="span" variant="overline" color={Neutral.B40}>
              {suffix}
            </Typography>
          ) : (
            suffix
          )}
        </>
      )}
    </Flex>
  </StyledCardData>
);
