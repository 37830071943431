import { styled } from 'styled-components';

export const SimplePaginationContainer = styled.div<{
  hidePageButtons?: boolean;
}>`
  ${({ hidePageButtons }) =>
    hidePageButtons &&
    `
    nav {
      div:nth-child(2) {
        display: none;
      }
      button:nth-child(3), button:nth-child(4) {
        display: none;
      }
    }
  `}
`;
