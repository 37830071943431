import { Banner } from 'glints-aries/lib/@next';
import { space24, space32 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const BankBanner = styled(Banner)`
  min-height: auto;
  margin-bottom: ${space24};
`;

export const BannerContent = styled.div`
  padding-right: ${space32}; // 16 space + 16 width of icon
`;
