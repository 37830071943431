import {
  Icon,
  OutlineButton,
  Spinner,
  Typography,
} from 'glints-aries/lib/@next';
import {
  Blue,
  Green,
  Neutral,
  Orange,
  Red,
} from 'glints-aries/lib/@next/utilities/colors';
import { useNavigate } from 'react-router-dom';

import {
  ContentContainer,
  FloatingIcon,
  IconsContainer,
  MainContainer,
  StyledPageTitleContainer,
} from './EgiroStyle';
import { ReactComponent as BankUnionSVG } from '@/assets/images/bank-union.svg';
import { ReactComponent as EgiroGraySVG } from '@/assets/images/eGIRO-gray.svg';
import { ReactComponent as GlintsStarLogoSVG } from '@/assets/images/glints-star-logo.svg';
import { getGraphqlClient } from '@/clients/graphql';
import {
  DirectDebitStatus,
  type GetDirectDebitAuthorisationQuery,
  useGetDirectDebitAuthorisationQuery,
} from '@/generated/graphql';

export const EgiroConnect = ({ bankName }: { bankName: string }) => {
  const navigate = useNavigate();
  const graphqlClient = getGraphqlClient();

  const bankStatusContent: {
    [key in DirectDebitStatus]?: {
      headline: string;
      body: string;
      body2?: string;
      backgroundColor: string;
      icon: React.ReactNode;
    };
  } = {
    [DirectDebitStatus.Pending]: {
      headline: 'Pending',
      body: 'Connection with your eGIRO account is currently in progress.',
      body2:
        'If it remains pending for more than 60 minutes, check with your finance team as there may be an approval process involved.',
      icon: <FloatingIcon name="ri-error-warning-fill" fill={Orange.S86} />,
      backgroundColor: Orange.S21,
    },
    [DirectDebitStatus.Active]: {
      headline: 'You’re all set!',
      body: 'Your account has been successfully connected with Glints!',
      icon: <FloatingIcon name="ri-checkbox-circle-fill" fill={Green.B61} />,
      backgroundColor: 'rgba(194, 226, 218, 0.3)',
    },
    [DirectDebitStatus.Failed]: {
      headline: 'Failed to Connect',
      body: `There's been an issue with the setup for connecting to your ${bankName} account. Please retry this process later.`,
      icon: <FloatingIcon name="ri-close-circle-fill" fill={Red.B93} />,
      backgroundColor: Red.B100,
    },
  };

  const onClose = () => {
    navigate('/company-settings/auto-payment');
  };

  const {
    data: directDebitAuthData,
    isLoading: isLoadingDirectDebitAuth,
    isFetching: isFetchingDirectDebitAuth,
  } = useGetDirectDebitAuthorisationQuery<
    GetDirectDebitAuthorisationQuery,
    Error
  >(graphqlClient);

  return (
    <MainContainer>
      <StyledPageTitleContainer>
        <Typography as="div" variant="headline6" color={Neutral.B18}>
          Connect with {bankName} business account
        </Typography>
        <Icon
          name="ri-close-circle-fill"
          height="20px"
          fill={Neutral.B40}
          onClick={onClose}
        />
      </StyledPageTitleContainer>
      <ContentContainer>
        {directDebitAuthData?.company?.directDebit?.status &&
        (!isLoadingDirectDebitAuth || !isFetchingDirectDebitAuth) ? (
          <div className="status-content-container">
            <IconsContainer
              backgroundColor={
                bankStatusContent[
                  directDebitAuthData?.company?.directDebit?.status
                ]?.backgroundColor
              }
            >
              {
                bankStatusContent[
                  directDebitAuthData?.company?.directDebit?.status
                ]?.icon
              }
              <div className="icon-section">
                <EgiroGraySVG />
                <BankUnionSVG />
                <GlintsStarLogoSVG />
              </div>
            </IconsContainer>
            <div className="status-content">
              <Typography as="span" variant="headline6">
                {
                  bankStatusContent[
                    directDebitAuthData?.company?.directDebit?.status
                  ]?.headline
                }
              </Typography>
              <div className="status-content-body">
                <Typography variant="body1" as="span">
                  {
                    bankStatusContent[
                      directDebitAuthData?.company?.directDebit?.status
                    ]?.body
                  }
                </Typography>
                {bankStatusContent[
                  directDebitAuthData?.company?.directDebit?.status
                ]?.body2 && (
                  <Typography variant="body1" as="span">
                    {
                      bankStatusContent[
                        directDebitAuthData?.company?.directDebit?.status
                      ]?.body2
                    }
                  </Typography>
                )}
              </div>
            </div>
            <OutlineButton onClick={onClose}>Got It</OutlineButton>
          </div>
        ) : (
          <Spinner label="Loading..." size="large" fill={Blue.S99} />
        )}
      </ContentContainer>
    </MainContainer>
  );
};
