import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import PageLayout from './components/PageLayout/PageLayout';
import { AuthenticatePage } from './components/particles/AuthenticatePage/AuthenticatePage';
import AttendanceLogPage from './modules/AttendanceLog/AttendanceLogPage';
import DailyLog from './modules/AttendanceLog/DailyLog/DailyLog';
import AutoPaymentTab from './modules/CompanyProfile/AutoPaymentTab/AutoPaymentTab';
import { CompanyDetailsTab } from './modules/CompanyProfile/CompanyDetailsTab/CompanyDetailsTab';
import CompanyProfilePage from './modules/CompanyProfile/CompanyProfilePage';
import { companyProfileTabNames } from './modules/CompanyProfile/constants';
import Egiro from './modules/CompanyProfile/Egiro/Egiro';
import { ReportingLinesTab } from './modules/CompanyProfile/ReportingLinesTab/ReportingLinesTab';
import RolesPermissionsTab from './modules/CompanyProfile/RolesPermissionsTab/RolesPermissionsTab';
import { errorType } from './modules/Error/constants';
import ErrorPage from './modules/Error/Error';
import ExpensePage from './modules/Expenses/ExpensesPage/ExpensesPage';
import HomePage from './modules/Home/HomePage';
import InvoicePaymentsPage from './modules/InvoicePayments/InvoicePaymentsPage';
import { BankTransfer } from './modules/InvoicePayments/Payment/BankTransfer/BankTransfer';
import { PaymentPage } from './modules/InvoicePayments/Payment/PaymentPage';
import ErrorFrame, {
  errorType as error,
} from './modules/Login/components/Frame/ErrorFrame';
import { LoginLayout } from './modules/Login/components/Layout/LoginLayout';
import { GreyBackgroundOverlay } from './modules/Login/components/Layout/LoginLayoutStyle';
import LoginPage from './modules/Login/LoginPage';
import MagicLinkPage from './modules/Login/MagicLinkPage';
import ManagedTalentsPage from './modules/ManagedTalents/ManagedTalentsPage';
import TimeOffPage from './modules/TimeOff/TimeOffPage';

const ErrorBoundaryWrapper = () => (
  <ErrorBoundary fallback={<ErrorPage errorType={errorType.UNKNOWN} />}>
    <Outlet />
  </ErrorBoundary>
);

const AuthenticatedPageLayout = AuthenticatePage(PageLayout);

export const router = createBrowserRouter([
  {
    element: <ErrorBoundaryWrapper />,
    children: [
      {
        path: '/',
        element: (
          <AuthenticatedPageLayout
            component={<HomePage />}
            direct={true}
            pageTitle="Welcome to Glints Managed Talent Platform"
            metaDescription="Welcome to Glints Managed Talent - where we unlock the full potential of your hiring and talent management endeavors through our streamlined capabilities"
          />
        ),
        errorElement: <ErrorPage errorType={errorType.NOT_FOUND} />,
      },
      {
        path: 'my-employees',
        element: (
          <AuthenticatedPageLayout
            component={<ManagedTalentsPage />}
            pageTitle="Your Employees List | Glints Managed Talent"
            metaDescription="Glints Managed Talent offers comprehensive insights into your managed talents, empowering you with valuable information for enhanced supervision and effective talent management"
          />
        ),
      },
      {
        path: 'company-settings',
        element: (
          <AuthenticatedPageLayout
            component={
              <CompanyProfilePage
                activeTabName={companyProfileTabNames.COMPANY_DETAILS}
              />
            }
            pageTitle="Your Company Settings | Glints Managed Talent"
            metaDescription="View your Company Settings on Glints Managed Talent, where you can access and manage essential company information and details"
          />
        ),
        children: [
          {
            path: 'company-information',
            element: <CompanyDetailsTab />,
          },
          {
            path: 'roles-and-permissions',
            element: <RolesPermissionsTab />,
            children: [
              {
                path: ':role',
              },
            ],
          },
          { path: 'reporting-lines', element: <ReportingLinesTab /> },
          {
            path: 'auto-payment',
            element: <AutoPaymentTab />,
          },
        ],
      },
      {
        path: 'egiro',
        element: (
          <AuthenticatedPageLayout
            component={<Egiro />}
            pageTitle="eGIRO | Glints Managed Talent"
          />
        ),
        children: [
          {
            path: 'connect',
          },
          {
            path: 'disconnect',
          },
          {
            path: 'disconnected',
          },
        ],
      },
      {
        path: 'time-off',
        element: (
          <AuthenticatedPageLayout
            component={<TimeOffPage />}
            pageTitle="Time Off | Glints Managed Talent"
            metaDescription="TODO"
          />
        ),
        children: [
          {
            path: 'time-off-balance',
          },
          {
            path: 'time-off-requests',
          },
        ],
      },
      {
        path: 'Expenses',
        element: (
          <AuthenticatedPageLayout
            component={<ExpensePage />}
            pageTitle="Expenses | Glints Managed Talent"
            metaDescription="TODO"
            disabledPadding={true}
          />
        ),
      },
      {
        path: 'attendance',
        element: (
          <AuthenticatedPageLayout
            component={<AttendanceLogPage />}
            pageTitle="Attendance | Glints Managed Talent"
            metaDescription="TODO"
          />
        ),
      },
      {
        path: 'attendance/:hubberId',
        element: <AuthenticatedPageLayout component={<DailyLog />} />,
      },
      {
        path: 'invoice-payments',
        element: (
          <AuthenticatedPageLayout
            component={<InvoicePaymentsPage />}
            pageTitle="Invoice Payments | Glints Managed Talent"
            metaDescription="TODO"
          />
        ),
        children: [
          {
            path: 'awaiting-payment',
          },
          {
            path: 'payment-completed',
          },
        ],
      },
      {
        path: 'invoice-payments/payment',
        element: <AuthenticatedPageLayout component={<PaymentPage />} />,
      },
      {
        path: 'invoice-payments/bank-transfer',
        element: <AuthenticatedPageLayout component={<BankTransfer />} />,
      },
      {
        path: 'login',
        element: (
          <LoginPage
            pageTitle="Log into Glints Managed Talent"
            metaDescription="Log in to your Glints Managed Talent account to access talent management tools and streamline your hiring and talent management processes efficiently"
          />
        ),
      },
      {
        path: 'magic-link',
        element: (
          <MagicLinkPage
            pageTitle="Login with a Magic Link | Glints Managed Talent"
            metaDescription="Experience a seamless login process with a magic link on Glints Managed Talent. Simplify your access and begin managing talents effortlessly"
          />
        ),
      },
      {
        path: 'auth/403',
        element: (
          <PageLayout
            component={<ErrorPage errorType={errorType.FORBIDDEN} />}
            centered={true}
          />
        ),
      },
      {
        path: 'auth/unknown',
        element: (
          <PageLayout
            component={<ErrorPage errorType={errorType.UNKNOWN} />}
            centered={true}
          />
        ),
      },
      {
        path: '401/unauthorized',
        element: (
          <>
            <GreyBackgroundOverlay />
            <LoginLayout>
              <ErrorFrame errorType={error.UNAUTHORIZED} />
            </LoginLayout>
          </>
        ),
      },
      {
        path: '404',
        element: <ErrorPage errorType={errorType.NOT_FOUND} />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/404" replace={true} />,
  },
]);
