import { Badge } from 'glints-aries/lib/@next';
import {
  borderRadius8,
  borderRadius20,
} from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const StyledBanner = styled.div`
  position: relative;
  min-width: 240px;
  background: ${Blue.S08};
  border: 1px solid ${Blue.S99};
  border-radius: ${borderRadius8};

  display: flex;
  flex: 1 1 100%;
  flex-direction: row;

  @media (max-width: ${BREAKPOINTS.large}) {
    gap: ${space16};
    margin: ${space16} 0;
    width: auto;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    display: inline;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
    gap: ${space16};
  }
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: ${BREAKPOINTS.large}) {
    width: 100%;
  }

  &.grid-bottom-section {
    align-items: center;
    position: relative;
    width: 100%;
    overflow-x: auto;

    @media (max-width: ${BREAKPOINTS.small}) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FrameContainer = styled.div`
  width: 100%;
  padding: ${space16};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${space8};

  @media (max-width: ${BREAKPOINTS.medium}) {
    gap: 0;
  }
`;

export const TotalAmountFrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  width: 100%;
  padding: 0 ${space16};

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${space16};
  }
`;

export const StyledBadge = styled(Badge)`
  border: 1px solid ${Neutral.B100};
  border-radius: ${borderRadius20};
  white-space: nowrap;
`;

export const StyledVerticalLine = styled.div`
  width: 2px;
  background-color: ${Neutral.B85};

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 1px;
    width: 100%;
  }
`;

export const MultiCurrencyContainer = styled.div`
  display: flex;
  gap: ${space16};
  width: 100%;
  justify-content: space-evenly;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: ${space4};
`;

export const TotalUnpaidContainer = styled.div`
  display: flex;
  gap: ${space4};
  align-items: center;
`;

export const NoWrap = styled.div`
  text-align: center;
  white-space: nowrap;
`;
