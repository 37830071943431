import { ReactComponent as GlintsIconSVG } from '../../../../assets/images/glints-icon.svg';
import { ReactComponent as GlintsManagedTalentTextSVG } from '../../../../assets/images/glints-managed-talent-text.svg';
import { ReactComponent as GlintsTitleTextSVG } from '../../../../assets/images/glints-title-text.svg';
import { ReactComponent as MainPlanetsSVG } from '../../../../assets/images/main-planets.svg';
import { ReactComponent as SmallPlanetsSVG } from '../../../../assets/images/small-planets.svg';
import {
  FrameContainer,
  GlintsLogoContainer,
  GlintsTextContainer,
  MainContainer,
  MainPlanetsContainer,
  ManagedTalentTextContainer,
  SmallPlanetsContainer,
  StyledBackground,
  TitleContainer,
  TopBackgroundContainer,
} from './LoginLayoutStyle';

interface LoginLayoutProps {
  children?: React.ReactNode;
}

export const LoginLayout = ({ children }: LoginLayoutProps) => (
  <>
    <StyledBackground />
    <MainPlanetsContainer>
      <MainPlanetsSVG />
    </MainPlanetsContainer>
    <SmallPlanetsContainer>
      <SmallPlanetsSVG />
    </SmallPlanetsContainer>
    <TopBackgroundContainer>
      <TitleContainer>
        <GlintsLogoContainer>
          <GlintsIconSVG />
        </GlintsLogoContainer>
        <GlintsTextContainer>
          <GlintsTitleTextSVG />
        </GlintsTextContainer>
        <ManagedTalentTextContainer>
          <GlintsManagedTalentTextSVG />
        </ManagedTalentTextContainer>
      </TitleContainer>
    </TopBackgroundContainer>
    <MainContainer>
      <FrameContainer>{children}</FrameContainer>
    </MainContainer>
  </>
);
