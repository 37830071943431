import { Link, SideSheet, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { FormattedDate } from '../../../../components/FormattedDate/FormattedDate';
import { formatMoney } from '../../../../utils/formatString';
import {
  type TimeZone,
  timeZoneAbbrMapping,
  timeZoneStringMapping,
} from '../../../../utils/timeZone';
import {
  ContentWrapper,
  FlexRowWrapper,
  SingleFrame,
  StyledBanner,
} from './DisputeInvoiceSidesheetStyle';

interface DisputeInvoiceSidesheetProps {
  isOpen: boolean;
  onClose: () => void;
  handleInvoiceClick: (id: string) => void;
  invoiceId: string;
  invoiceReference: string;
  paymentAmount: string;
  paymentCurrency: string;
  disputeReason: string;
  requester: string;
  submissionDate?: string;
}
type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type TimeZoneLong = Entries<Record<TimeZone, string>>;

export const DisputeInvoiceSidesheet = ({
  isOpen,
  onClose,
  handleInvoiceClick,
  ...data
}: DisputeInvoiceSidesheetProps) => {
  const handleClick = () => {
    handleInvoiceClick(data.invoiceId);
  };
  const localTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZone: TimeZone | undefined = (
    Object.entries(timeZoneStringMapping) as TimeZoneLong
  ).find(([, value]) => localTimeZone === value)?.[0];
  const timeZoneAbbr = timeZone ? timeZoneAbbrMapping[timeZone] : '';

  return (
    <SideSheet
      isOpen={isOpen}
      title="Invoice Dispute"
      showBackButton={false}
      onClose={onClose}
    >
      <ContentWrapper>
        <SingleFrame>
          <Typography as="div" variant="caption" color={Neutral.B40}>
            Invoice Reference
          </Typography>
          <Link url="#" onClick={handleClick} removeUnderline={true}>
            <Typography as="span" variant="body1">
              {data.invoiceReference}
            </Typography>
          </Link>
        </SingleFrame>
        <SingleFrame>
          <Typography as="div" variant="caption" color={Neutral.B40}>
            Payment Amount
          </Typography>
          <FlexRowWrapper>
            <Typography as="span" variant="body1" color={Neutral.B18}>
              {`$${formatMoney({ amount: data.paymentAmount })}`}
            </Typography>
            <Typography as="span" variant="overline" color={Neutral.B40}>
              {data.paymentCurrency}
            </Typography>
          </FlexRowWrapper>
        </SingleFrame>
        <SingleFrame>
          <Typography as="div" variant="caption" color={Neutral.B40}>
            Requester
          </Typography>
          <Typography as="div" variant="body1" color={Neutral.B18}>
            {data.requester}
          </Typography>
        </SingleFrame>
        <SingleFrame>
          <Typography as="div" variant="caption" color={Neutral.B40}>
            Submission Date
          </Typography>
          <FlexRowWrapper>
            <FormattedDate date={data.submissionDate} />
            <Typography as="span" variant="overline" color={Neutral.B40}>
              {timeZoneAbbr}
            </Typography>
          </FlexRowWrapper>
        </SingleFrame>
        <SingleFrame data-noborder={true}>
          <Typography as="div" variant="caption" color={Neutral.B40}>
            Reasons for Dispute
          </Typography>
          <Typography as="div" variant="body1" color={Neutral.B18}>
            {data.disputeReason}
          </Typography>
        </SingleFrame>
        <StyledBanner status="warning" dismissable={false}>
          Your request is logged! We&apos;ll reach out soon. If you don&apos;t
          hear from us within 2 business days, please contact your Account
          Manager.
        </StyledBanner>
      </ContentWrapper>
    </SideSheet>
  );
};
