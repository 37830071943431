import { SkeletonImageSquare, Typography } from 'glints-aries/lib/@next';
import { type BadgeStatusVariant } from 'glints-aries/lib/@next/Badge';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { formatMoney } from '../../../../utils/formatString';
import { type Money } from '../../interfaces';
import {
  FrameContainer,
  GridContainer,
  MultiCurrencyContainer,
  NoWrap,
  StyledBadge,
  StyledBanner,
  StyledVerticalLine,
  TotalAmountFrameContainer,
  TotalUnpaidContainer,
} from './InvoiceBannerStyle';

interface InvoiceBannerProps {
  loading: boolean;
  openCount: number;
  outstandingCount: number;
  overdueCount: number;
  totalUnpaidAmount?: Money[];
}

export const InvoiceBanner = ({
  loading,
  openCount,
  outstandingCount,
  overdueCount,
  totalUnpaidAmount,
}: InvoiceBannerProps) => {
  const InvoiceCount = ({
    count,
    status,
    title,
  }: {
    count: number;
    status: BadgeStatusVariant;
    title: string;
  }) => (
    <FrameContainer>
      {loading ? (
        <SkeletonImageSquare height="40px" width="60px" />
      ) : (
        <Typography as="span" variant="headline6" color={Neutral.B18}>
          {count}
        </Typography>
      )}
      <StyledBadge status={status}>{title}</StyledBadge>
    </FrameContainer>
  );

  const awaitingInvoicesCount = (
    <FrameContainer data-right-margin={true}>
      {loading ? (
        <SkeletonImageSquare height="40px" width="60px" />
      ) : (
        <Typography as="span" variant="headline6" color={Neutral.B18}>
          {openCount}
        </Typography>
      )}
      <Typography as="span" variant="caption" color={Neutral.B40}>
        <NoWrap>Unpaid Invoices</NoWrap>
      </Typography>
    </FrameContainer>
  );

  const renderTotalUnpaid = () =>
    totalUnpaidAmount && totalUnpaidAmount?.length > 0 ? (
      <MultiCurrencyContainer>
        {totalUnpaidAmount?.map(({ amount, currency }: Money) => (
          <TotalUnpaidContainer key={`unpaid-${currency}`}>
            <Typography as="span" variant="headline6" color={Neutral.B18}>
              <NoWrap>
                {`${formatMoney({
                  amount,
                })}`}
              </NoWrap>
            </Typography>
            <Typography as="span" variant="caption" color={Neutral.B18}>
              <NoWrap>{`${currency}`}</NoWrap>
            </Typography>
          </TotalUnpaidContainer>
        ))}
      </MultiCurrencyContainer>
    ) : (
      '0'
    );

  const totalAmount = (
    <TotalAmountFrameContainer data-right-margin={true}>
      {loading ? (
        <SkeletonImageSquare height="30px" width="100px" />
      ) : (
        renderTotalUnpaid()
      )}
      <Typography as="span" variant="caption" color={Neutral.B40}>
        <NoWrap>Total Amount</NoWrap>
      </Typography>
    </TotalAmountFrameContainer>
  );

  return (
    <StyledBanner className="invoice-banner">
      <GridContainer>{awaitingInvoicesCount}</GridContainer>
      <StyledVerticalLine />
      <GridContainer>
        <InvoiceCount count={overdueCount} status="attention" title="Overdue" />
        <InvoiceCount
          count={outstandingCount}
          status="warning"
          title="Due soon"
        />
      </GridContainer>
      <StyledVerticalLine />
      <GridContainer className="grid-bottom-section">
        {totalAmount}
      </GridContainer>
    </StyledBanner>
  );
};
