export const ALERT_TYPE = {
  apiError: 'apiError',
} as const;

export const ALERT_STATUS = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error',
} as const;

export const alertMessages = {
  [ALERT_TYPE.apiError]: 'An error occurred. Please retry later.',
};

export const exportFileAlertMessages = {
  [ALERT_STATUS.success]: 'File successfully downloaded',
  [ALERT_STATUS.error]: 'Export failed. Retry later.',
} as const;
