import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import {
  space8,
  space12,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled, { keyframes } from 'styled-components';

import {
  PLACEMENT_LEFT_VALUE,
  PLACEMENT_TRANSFORM_X,
  type PlacementType,
} from './constants';
import { BREAKPOINTS } from '@/constants/breakpoints';

export const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
`;

type PopoverContentProps = {
  visible: boolean;
  placement: PlacementType;
  padding?: string;
  zIndex?: number;
};

export const PopoverContent = styled.div<PopoverContentProps>`
  position: absolute;
  z-index: ${(props) => props.zIndex ?? 100};
  background-color: white;
  padding: ${(props) => props.padding ?? space16};
  width: fit-content;
  top: 100%;
  left: ${(props) => PLACEMENT_LEFT_VALUE[props.placement]};
  transform: ${(props) => PLACEMENT_TRANSFORM_X[props.placement]};
  box-shadow: 0px ${space8} 20px rgba(71, 71, 71, 0.2),
    0px 3px 6px -3px rgba(71, 71, 71, 0.08);
  border-radius: ${borderRadius8};
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  animation: ${(props) =>
      props.visible ? fadeIn(props.placement) : fadeOut(props.placement)}
    0.3s ease forwards;

  @media (max-width: ${BREAKPOINTS.large}) {
    padding: ${(props) => props.padding ?? space12};
  }
`;

const fadeIn = (placement: PlacementType) => keyframes`
  from {
    opacity: 0;
    transform: ${PLACEMENT_TRANSFORM_X[placement]} translateY(-10px);
  }
  to {
    opacity: 1;
    transform: ${PLACEMENT_TRANSFORM_X[placement]} translateY(5px);
  }
`;

const fadeOut = (placement: PlacementType) => keyframes`
  from {
    opacity: 1;
    transform: ${PLACEMENT_TRANSFORM_X[placement]} translateY(0);
  }
  to {
    opacity: 0;
    transform: ${PLACEMENT_TRANSFORM_X[placement]} translateY(-5px);
  }
`;
