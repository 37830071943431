import { Breakpoints } from 'glints-aries/lib/@next';
import { styled } from 'styled-components';

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;

  position: absolute;
  width: 430px;
  left: 50%;
  top: 47%;
  transform: translate(-50%, -50%);

  @media (max-width: ${Breakpoints.large}) {
    position: relative;
    width: 100%;
    left: auto;
    top: auto;
    transform: none;
  }
`;
