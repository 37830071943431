import { useEffect, useState } from 'react';

const LARGE_SCREEN_BREAKPOINT = 768;

export const useScreen = () => {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(true);

  const handleWindowSizeChange = () =>
    setIsLargeScreen(window.innerWidth > LARGE_SCREEN_BREAKPOINT);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isLargeScreen;
};
