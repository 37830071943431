import { type BadgeStatusVariant } from 'glints-aries/lib/@next/Badge';

import { TimeOffRequestStatus } from '@/generated/graphql';

export const requestStatusBadgeMapping: Record<
  TimeOffRequestStatus,
  BadgeStatusVariant
> = {
  [TimeOffRequestStatus.Approved]: 'success',
  [TimeOffRequestStatus.Cancelled]: 'neutral',
  [TimeOffRequestStatus.Pending]: 'warning',
  [TimeOffRequestStatus.Rejected]: 'critical',
};

export const REQUEST_STATUS_TEXT: Record<TimeOffRequestStatus, string> = {
  [TimeOffRequestStatus.Approved]: 'Approved',
  [TimeOffRequestStatus.Cancelled]: 'Cancelled',
  [TimeOffRequestStatus.Pending]: 'Pending',
  [TimeOffRequestStatus.Rejected]: 'Rejected',
} as const;
