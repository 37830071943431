import { Typography, type TypographyProps } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import * as Styled from './styled.sc';
import { formatMoney } from '@/utils/formatString';
import { getLocaleByCurrency } from '@/utils/locale';

export interface FormattedMoneyProps {
  amount: string;
  currency: string;
  amountVariant?: TypographyProps['variant'];
}

const DEFAULT_LOCALE = 'en-US';

export const FormattedMoney = ({
  amount,
  currency,
  amountVariant = 'body1',
}: FormattedMoneyProps) => (
  <Styled.FormattedMoney>
    <Typography
      as="span"
      variant={amountVariant}
      style={{ lineHeight: 'unset' }}
    >
      {formatMoney({
        amount: amount,
        locale: getLocaleByCurrency(currency) || DEFAULT_LOCALE,
      })}
    </Typography>
    <Typography
      as="span"
      variant="overline"
      color={Neutral.B40}
      style={{ lineHeight: 'unset' }}
    >
      {currency}
    </Typography>
  </Styled.FormattedMoney>
);
