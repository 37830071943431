import { createContext, useContext } from 'react';
import { FlagProvider, useFlag, useVariant } from '@unleash/proxy-client-react';

import { useAuthContext } from '../AuthInfoProvider/AuthInfoProvider';
import {
  CONFIG,
  UNLEASH_APP_NAME,
  type UnleashFlagName,
} from '@/constants/constants';

type Props = {
  children: React.ReactNode;
};

type VariantProps = {
  name: string;
  enabled: boolean;
  payload?: {
    type: string;
    value: string;
  };
};

type ContextValue = {
  useFlagEnabled: (flagName: UnleashFlagName) => boolean;
  useFlagVariant: (flagName: UnleashFlagName) => Partial<VariantProps>;
};

const Context = createContext<ContextValue>({
  useFlagEnabled: () => false,
  useFlagVariant: () => ({
    name: '',
    enabled: false,
    payload: undefined,
  }),
});

const FeatureFlagContextProvider = ({ children }: Props) => {
  const useFlagEnabled = (flagName: UnleashFlagName) => useFlag(flagName);

  const useFlagVariant = (flagName: UnleashFlagName): Partial<VariantProps> => {
    const variant = useVariant(flagName);
    return variant;
  };

  return (
    <Context.Provider
      value={{
        useFlagEnabled,
        useFlagVariant,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useFeatureFlagContext = (): ContextValue => useContext(Context);

export const FeatureFlagProvider: React.FunctionComponent<Props> = ({
  children,
}: Props) => {
  const UNLEASH_FRONTEND_API_TOKEN =
    CONFIG.REACT_APP_UNLEASH_FRONTEND_API_TOKEN;
  const UNLEASH_FRONTEND_API_URL = CONFIG.REACT_APP_UNLEASH_FRONTEND_API_URL;
  const { userInfo } = useAuthContext();

  return UNLEASH_FRONTEND_API_TOKEN &&
    UNLEASH_FRONTEND_API_URL &&
    UNLEASH_APP_NAME &&
    userInfo?.contact.id ? (
    <FlagProvider
      config={{
        context: { userId: userInfo?.contact.id },
        appName: UNLEASH_APP_NAME,
        url: UNLEASH_FRONTEND_API_URL,
        clientKey: UNLEASH_FRONTEND_API_TOKEN,
      }}
    >
      <FeatureFlagContextProvider>{children}</FeatureFlagContextProvider>
    </FlagProvider>
  ) : (
    <>{children}</>
  );
};
