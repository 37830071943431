import { ReactComponent as CostCalculatorSVG } from '@/assets/images/employment-cost-calculator.svg';
import { ToolButton } from '@/components/molecules/ToolButton/ToolButton';

export interface CostCalculatorButtonProps {
  onClick?: () => void;
}

export const CostCalculatorButton = ({
  onClick,
}: CostCalculatorButtonProps) => (
  <ToolButton
    icon={<CostCalculatorSVG />}
    title="Employment Cost Calculator"
    description="Gain insights into employment costs across Southeast Asia & Taiwan"
    onClick={onClick}
  />
);
