import {
  type CompanyDirectManagerFragment,
  type ExpensesTableExpenseClaimFragment,
  type GetUserQuery,
} from '@/generated/graphql';

export const buildReportingManagerFiltersOptions = (
  companyDirectManagers: CompanyDirectManagerFragment[],
) => {
  const filtersOptions = companyDirectManagers.map((directManager) => ({
    text: directManager.name,
    value: directManager.id,
  }));

  filtersOptions.push({
    text: 'Unassigned',
    value: null,
  });

  return filtersOptions;
};

export const isReportingLineHubber = (
  userInfo: GetUserQuery | null,
  hubber: ExpensesTableExpenseClaimFragment['hubber'],
) =>
  userInfo &&
  hubber.directManager[0] &&
  hubber.directManager[0].id === userInfo.contact.id;
