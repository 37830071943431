import { InlineError } from 'glints-aries/lib/@next';

const DEFUALT_ERROR_MESSAGE = 'An unknown error has occurred';

export interface TextInputErrorProps {
  errorMessage?: string;
}

export const TextInputError = ({
  errorMessage = DEFUALT_ERROR_MESSAGE,
}: TextInputErrorProps) => <InlineError text={errorMessage} />;
