import { SideSheet as AriesSideSheet } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const CollapseItemContainer = styled.div`
  .collapse-item-content {
    padding: 0;
  }
`;

export const PermissionDetailsContainer = styled.ul`
  display: flex;
  list-style: none;
  flex-direction: column;
  padding: 0;
  margin: 0;

  li {
    padding: ${space8};
    display: flex;
    gap: ${space8};
  }

  li:not(:last-child) {
    border-bottom: 1px solid ${Neutral.B85};
  }
`;

export const SideSheetContentContainer = styled.div`
  padding: 20px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.large}) {
    padding: ${space16};
  }
`;

export const SideSheet = styled(AriesSideSheet)`
  width: 600px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`;
