import { useEffect, useRef, useState } from 'react';
import { getMonth, getYear } from 'date-fns';
import { Box, Flex } from 'glints-aries';
import {
  Badge,
  EmptyState,
  Icon,
  type PaginationProps,
  PlainButton,
  PrimaryButton,
  TextInput,
  Typography,
  useAlert,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import { gql } from 'graphql-request';
import { isBrowser } from 'react-device-detect';

import {
  PERMITTED_TIMEOFF_WRITE_ROLES,
  timeOffRequestAlertContent,
} from '../../../constants';
import {
  REQUEST_STATUS_TEXT,
  requestStatusBadgeMapping,
} from '../../constants';
import { TimeOffRequestSideSheet } from '../TimeOffRequestSideSheet/TimeOffRequestSideSheet';
import {
  requestStatusFiltersOptions,
  TIME_OFF_REQUESTS_TABLE_KEY,
  TIME_OFF_REQUESTS_TABLE_TITLE,
} from './constants';
import {
  buildReportingManagerFiltersOptions,
  isReportingLineHubber,
  renderTimeOffDuration,
} from './helpers';
import * as Styled from './styled.sc';
import { renderTimeOffType } from './TimeOffType';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { GlintsAntdTable } from '@/components/atoms/GlintsAntdTable/GlintsAntdTable';
import {
  type TableColumns,
  type TableFilters,
  type TableOnChange,
} from '@/components/atoms/GlintsAntdTable/types';
import { GlintsAntdTooltip } from '@/components/atoms/GlintsAntdTooltip/GlintsAntdTooltip';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { NonDirectManagerTooltip } from '@/components/molecules/NonDirectManagerTooltip/NonDirectManagerTooltip';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { StatusIndicator } from '@/components/StatusIndicator/StatusIndicator';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { ALERT_STATUS, ALERT_TYPE, alertMessages } from '@/constants/alert';
import {
  type ApproveTimeOffRequestMutation,
  EmploymentStatus,
  type GetCompanyDirectManagersQuery,
  type GetModifiedTimeOffRequestQuery,
  type GetTimeOffSideSheetTimeOffRequestQuery,
  type RejectTimeOffRequestMutation,
  type TimeOffRequestSideSheetTimeOffRequestFragment,
  type TimeOffRequestsTableTimeOffRequestFragment,
  TimeOffRequestStatus,
  useApproveTimeOffRequestMutation,
  useGetCompanyDirectManagersQuery,
  useGetModifiedTimeOffRequestQuery,
  useGetTimeOffSideSheetTimeOffRequestQuery,
  useRejectTimeOffRequestMutation,
} from '@/generated/graphql';
import { useAntdTableScrollbar } from '@/hooks/useAntdTableScrollbar';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { generateHubFiltersOptions } from '@/utils/talentHub';
import { type TimeZone } from '@/utils/timeZone';

interface TimeOffRequestsTableProps {
  timeOffRequests?: TimeOffRequestsTableTimeOffRequestFragment[];
  companyHubs: string[];
  loading: boolean;
  isFiltering: boolean;
  filteredProps: {
    filteredInfo: TableFilters;
    setFilteredInfo: React.Dispatch<React.SetStateAction<TableFilters>>;
    onReset: () => void;
  };
  paginationProps: PaginationProps;
  onTableChange: TableOnChange;
}

export const TimeOffRequestsTable = ({
  loading,
  timeOffRequests,
  companyHubs,
  isFiltering,
  filteredProps,
  paginationProps,
  onTableChange,
}: TimeOffRequestsTableProps) => {
  const graphqlClient = getGraphqlClient();
  const { userInfo } = useAuthContext();
  const { open: openAlert } = useAlert();
  const { isTableDefaultScrollbarXHidden } = useAntdTableScrollbar();

  const [tableTimeOffRequestsData, setTableTimeOffRequestsData] = useState<
    TimeOffRequestsTableTimeOffRequestFragment[]
  >(timeOffRequests || []);
  const [isTimeOffRequestSideSheetOpen, setIsTimeOffRequestSideSheetOpen] =
    useState(false);
  // `triggeredTimeOffRequestId` is the ID used to fetch time-off request data when the sidesheet is opened.
  // Setting this ID triggers the GraphQL query to load the relevant time-off request details.
  const [triggeredTimeOffRequestId, setTriggeredTimeOffRequestId] =
    useState<string>();
  const [sideSheetTimeOffRequestData, setSideSheetTimeOffRequestData] =
    useState<TimeOffRequestSideSheetTimeOffRequestFragment>();
  const [
    timeOffRequestSideSheetSubmitting,
    setTimeOffRequestSideSheetSubmitting,
  ] = useState(false);
  const [modifiedRequestId, setModifiedRequestId] = useState<string>();
  const [searchNameText, setSearchNameText] = useState<string>('');
  const searchNameInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (timeOffRequests) {
      setTableTimeOffRequestsData(timeOffRequests);
    }
  }, [timeOffRequests]);

  const {
    data: getTimeOffSideSheetTimeOffRequestData,
    isLoading: getTimeOffSideSheetTimeOffRequestLoading,
    error: getTimeOffSideSheetTimeOffRequestError,
  } = useGetTimeOffSideSheetTimeOffRequestQuery<
    GetTimeOffSideSheetTimeOffRequestQuery,
    Error
  >(
    graphqlClient,
    {
      id: triggeredTimeOffRequestId,
      year: getYear(new Date()),
      month: getMonth(new Date()) + 1,
    },
    {
      enabled: Boolean(triggeredTimeOffRequestId),
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    data: getCompanyDirectMangersData,
    isLoading: getCompanyDirectManagersLoading,
    error: getCompanyDirectManagersError,
  } = useGetCompanyDirectManagersQuery<GetCompanyDirectManagersQuery, Error>(
    graphqlClient,
  );

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function setSideSheetTimeOffRequestDataWhenSideSheetOpen() {
      if (getTimeOffSideSheetTimeOffRequestData?.timeoffRequest) {
        setSideSheetTimeOffRequestData(
          getTimeOffSideSheetTimeOffRequestData.timeoffRequest,
        );
      }
    },
    [getTimeOffSideSheetTimeOffRequestData],
  );

  const { error: getModifiedTimeOffRequestError } =
    useGetModifiedTimeOffRequestQuery<GetModifiedTimeOffRequestQuery, Error>(
      graphqlClient,
      {
        id: modifiedRequestId,
        year: getYear(new Date()),
        month: getMonth(new Date()) + 1,
      },
      {
        enabled: Boolean(modifiedRequestId),
        staleTime: 0,
        cacheTime: 0,
        onSuccess: (data) =>
          updateTimeOffLocalRequestData(data?.timeoffRequest),
      },
    );

  const {
    mutate: approveTimeOffRequestMutate,
    error: approveTimeOffRequestError,
  } = useApproveTimeOffRequestMutation<Error, ApproveTimeOffRequestMutation>(
    graphqlClient,
    {
      onSuccess: (data) => {
        const response = data.approveTimeOffRequest;
        if (response) {
          updateTimeOffLocalRequestData(response);
          setTimeOffRequestSideSheetSubmitting(false);
          setModifiedRequestId(undefined);
          openAlert({
            content: timeOffRequestAlertContent[ALERT_STATUS.success],
            status: ALERT_STATUS.success,
            zIndex: 1200,
            position: {
              top: '24px',
            },
          });
        }
      },
      onError: (err) => handleTimeOffMutationError(err),
    },
  );

  const {
    mutate: rejectTimeOffRequestMutate,
    error: rejectTimeOffRequestError,
  } = useRejectTimeOffRequestMutation<Error, ApproveTimeOffRequestMutation>(
    graphqlClient,
    {
      onSuccess: (data) => {
        const response = data.rejectTimeOffRequest;
        if (response) {
          updateTimeOffLocalRequestData(response);
          setTimeOffRequestSideSheetSubmitting(false);
          setModifiedRequestId(undefined);
          openAlert({
            content: timeOffRequestAlertContent[ALERT_STATUS.success],
            status: ALERT_STATUS.success,
            zIndex: 1200,
            position: {
              top: '24px',
            },
          });
        }
      },
      onError: (err) => handleTimeOffMutationError(err),
    },
  );

  const updateTimeOffLocalRequestData = (
    updatedData:
      | ApproveTimeOffRequestMutation['approveTimeOffRequest']
      | RejectTimeOffRequestMutation['rejectTimeOffRequest']
      | GetModifiedTimeOffRequestQuery['timeoffRequest'],
  ) => {
    if (!updatedData) return;

    const updatedTimeOffRequestsData = tableTimeOffRequestsData.map(
      (timeOffRequest) =>
        timeOffRequest.id === updatedData.id
          ? { ...timeOffRequest, ...updatedData }
          : timeOffRequest,
    );
    setTableTimeOffRequestsData(updatedTimeOffRequestsData);

    if (sideSheetTimeOffRequestData) {
      setSideSheetTimeOffRequestData({
        ...sideSheetTimeOffRequestData,
        ...updatedData,
      });
    }
  };

  const columns: TableColumns = [
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[
          TIME_OFF_REQUESTS_TABLE_KEY.requestedTime
        ],
      key: TIME_OFF_REQUESTS_TABLE_KEY.requestedTime,
      width: 230,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <FormattedDate
          date={record.requestedAt}
          pattern={{
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
          timeZone={record.hubber.hub as TimeZone}
        />
      ),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[TIME_OFF_REQUESTS_TABLE_KEY.employee],
      key: TIME_OFF_REQUESTS_TABLE_KEY.employee,
      width: 250,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <Styled.HubberNameFlexContainer>
          <Styled.HubberNameWrapper>
            {record.hubber.fullName}
          </Styled.HubberNameWrapper>
          <StatusIndicator
            active={record.hubber.status === EmploymentStatus.Active}
          />
        </Styled.HubberNameFlexContainer>
      ),
      filteredValue:
        filteredProps.filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.employee] ||
        null,
      filterIcon: () =>
        filteredProps.filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.employee] ? (
          <Icon name="ri-search" fill={Blue.S99} height={16} width={16} />
        ) : (
          <Icon name="ri-search" fill={Neutral.B40} height={16} width={16} />
        ),
      filterDropdown: ({ confirm }) => (
        <Box p={16}>
          <Box mb={16}>
            <TextInput
              inputRef={searchNameInput}
              placeholder="Search Name"
              value={searchNameText}
              onChange={(value: string) => setSearchNameText(value)}
            />
          </Box>
          <Flex gap={space8}>
            <PlainButton
              onClick={() => {
                confirm({ closeDropdown: true });
                setSearchNameText('');
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [TIME_OFF_REQUESTS_TABLE_KEY.employee]: null,
                });
              }}
            >
              Clear
            </PlainButton>
            <PrimaryButton
              onClick={() => {
                setSearchNameText(searchNameText);
                filteredProps.setFilteredInfo({
                  ...filteredProps.filteredInfo,
                  [TIME_OFF_REQUESTS_TABLE_KEY.employee]: [searchNameText],
                });
              }}
            >
              Search
            </PrimaryButton>
          </Flex>
        </Box>
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchNameInput.current?.select(), 100);
        }
        if (!visible) {
          if (searchNameText) {
            setSearchNameText(searchNameText);
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [TIME_OFF_REQUESTS_TABLE_KEY.employee]: [searchNameText],
            });
          } else {
            filteredProps.setFilteredInfo({
              ...filteredProps.filteredInfo,
              [TIME_OFF_REQUESTS_TABLE_KEY.employee]: null,
            });
          }
          paginationProps.onPageChanged?.(1);
        }
      },
    },
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[TIME_OFF_REQUESTS_TABLE_KEY.talentHub],
      key: TIME_OFF_REQUESTS_TABLE_KEY.talentHub,
      width: 200,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <HubLocationText hubCode={record.hubber.hub as HubCode} />
      ),
      filteredValue:
        filteredProps.filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.talentHub] ||
        null,
      filters: generateHubFiltersOptions(companyHubs),
    },
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[TIME_OFF_REQUESTS_TABLE_KEY.timeOffType],
      key: TIME_OFF_REQUESTS_TABLE_KEY.timeOffType,
      width: 230,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <>
          {renderTimeOffType({
            name: record.balance.name,
            category: record.balance.category,
          })}
        </>
      ),
    },
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[TIME_OFF_REQUESTS_TABLE_KEY.startDate],
      key: TIME_OFF_REQUESTS_TABLE_KEY.startDate,
      width: 200,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <FormattedDate
          date={record.timeoffStartDate}
          timeZone={record.hubber.hub as TimeZone}
        />
      ),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      title: TIME_OFF_REQUESTS_TABLE_TITLE[TIME_OFF_REQUESTS_TABLE_KEY.endDate],
      key: TIME_OFF_REQUESTS_TABLE_KEY.endDate,
      width: 200,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <FormattedDate
          date={record.timeoffEndDate}
          timeZone={record.hubber.hub as TimeZone}
        />
      ),
      sorter: true,
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[TIME_OFF_REQUESTS_TABLE_KEY.duration],
      key: TIME_OFF_REQUESTS_TABLE_KEY.duration,
      width: 200,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <>
          {renderTimeOffDuration({ timeoffDuration: record.timeoffDuration })}
        </>
      ),
    },
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[
          TIME_OFF_REQUESTS_TABLE_KEY.reportingManager
        ],
      key: TIME_OFF_REQUESTS_TABLE_KEY.reportingManager,
      width: 230,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <>
          {record.hubber.directManager[0] ? (
            record.hubber.directManager[0].name
          ) : (
            <Typography variant="subtitle2" color={Neutral.B68}>
              Unassigned
            </Typography>
          )}
        </>
      ),
      filteredValue:
        filteredProps.filteredInfo[
          TIME_OFF_REQUESTS_TABLE_KEY.reportingManager
        ] || null,
      filters: buildReportingManagerFiltersOptions(
        getCompanyDirectMangersData?.company.directManagers || [],
      ),
    },
    {
      title:
        TIME_OFF_REQUESTS_TABLE_TITLE[
          TIME_OFF_REQUESTS_TABLE_KEY.requestStatus
        ],
      key: TIME_OFF_REQUESTS_TABLE_KEY.requestStatus,
      fixed: isBrowser ? 'right' : undefined,
      width: 120,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => (
        <Badge status={requestStatusBadgeMapping[record.requestStatus]}>
          {REQUEST_STATUS_TEXT[record.requestStatus]}
        </Badge>
      ),
      filteredValue:
        filteredProps.filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.requestStatus] ||
        null,
      filters: requestStatusFiltersOptions,
    },
    {
      title: TIME_OFF_REQUESTS_TABLE_TITLE[TIME_OFF_REQUESTS_TABLE_KEY.actions],
      key: TIME_OFF_REQUESTS_TABLE_KEY.actions,
      fixed: isBrowser ? 'right' : undefined,
      width: 80,
      render: (record: TimeOffRequestsTableTimeOffRequestFragment) => {
        const isRequestCancelled =
          record.requestStatus === TimeOffRequestStatus.Cancelled;
        const isPermitted = PERMITTED_TIMEOFF_WRITE_ROLES.includes(
          userInfo?.contact?.roles[0].id,
        );
        return (
          <>
            {isPermitted ? (
              isReportingLineHubber(userInfo, record.hubber) ? (
                <Styled.OutlineButton
                  onClick={() => handleOpenTimeOffRequestSideSheet(record)}
                  disabled={isRequestCancelled}
                >
                  <Icon name="ri-arrow-m-right-line" />
                </Styled.OutlineButton>
              ) : (
                <NonDirectManagerTooltip placement="topRight">
                  <Styled.OutlineButton disabled={true}>
                    <Icon name="ri-arrow-m-right-line" />
                  </Styled.OutlineButton>
                </NonDirectManagerTooltip>
              )
            ) : (
              <GlintsAntdTooltip
                placement="topRight"
                title="No permission to manage leaves"
              >
                <Styled.OutlineButton disabled={true}>
                  <Icon name="ri-arrow-m-right-line" />
                </Styled.OutlineButton>
              </GlintsAntdTooltip>
            )}
          </>
        );
      },
    },
  ];

  const emptyState = (
    <EmptyState
      title="No Hubbers' Data"
      description="There are currently no hubbers under your company"
      image={<NebulaSVG />}
    />
  );

  const noMatchingResultsState = (
    <Box py={64}>
      <EmptyState
        title="No Requests"
        description="No results were found based on current filtering conditions."
        basicButtonAction={{
          label: 'Reset All',
          onClick: () => {
            setSearchNameText('');
            filteredProps.onReset();
          },
        }}
      />
    </Box>
  );

  const handleOpenTimeOffRequestSideSheet = (
    data: TimeOffRequestsTableTimeOffRequestFragment,
  ) => {
    setTriggeredTimeOffRequestId(data.id);
    setIsTimeOffRequestSideSheetOpen(true);
  };

  const handleTimeOffRequestSideSheetClose = () => {
    setIsTimeOffRequestSideSheetOpen(false);
    setTimeOffRequestSideSheetSubmitting(false);
    setModifiedRequestId(undefined);
  };

  const handleTimeOffApprove = (timeOffId: string) => {
    setTimeOffRequestSideSheetSubmitting(true);
    approveTimeOffRequestMutate({
      timeOffId,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    });
  };

  const handleTimeOffReject = (timeOffId: string, remark?: string) => {
    setTimeOffRequestSideSheetSubmitting(true);
    rejectTimeOffRequestMutate({
      timeOffId,
      remark,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    });
  };

  const handleTimeOffMutationError = (err: Error) => {
    const requestHasModifiedErr = 'timeoff outdated';

    const isRequestModified = err.message.includes(requestHasModifiedErr);
    if (isRequestModified) {
      setTimeOffRequestSideSheetSubmitting(false);
      if (sideSheetTimeOffRequestData) {
        setModifiedRequestId(sideSheetTimeOffRequestData.id);
      }
      return;
    }

    openAlert({
      content: alertMessages[ALERT_TYPE.apiError],
      status: ALERT_STATUS.error,
      zIndex: 1200,
      position: {
        top: '24px',
      },
    });
  };

  const tableSticky = loading
    ? undefined
    : {
        offsetHeader: isBrowser ? -32 : -16,
        offsetScroll: isBrowser ? 32 : 16,
      };

  const isTableLoading = loading || getCompanyDirectManagersLoading;

  useGraphqlError([
    getTimeOffSideSheetTimeOffRequestError,
    getCompanyDirectManagersError,
    getModifiedTimeOffRequestError,
    approveTimeOffRequestError,
    rejectTimeOffRequestError,
  ]);

  return (
    <>
      <Styled.TableContainer>
        <GlintsAntdTable
          columns={columns}
          dataSource={tableTimeOffRequestsData}
          onChange={onTableChange}
          loading={isTableLoading}
          pagination={false}
          emptyState={
            tableTimeOffRequestsData?.length === 0 && isFiltering
              ? noMatchingResultsState
              : emptyState
          }
          scroll={{ x: 'max-content' }}
          hideDefaultScrollbarX={isTableDefaultScrollbarXHidden}
          sticky={tableSticky}
        />
        {!loading && tableTimeOffRequestsData?.length > 0 && (
          <Styled.TablePaginationContainer>
            <TablePagination
              currentPage={paginationProps.currentPage}
              pageSize={paginationProps.pageSize}
              totalItems={paginationProps.totalItems}
              onPageChanged={paginationProps.onPageChanged}
            />
          </Styled.TablePaginationContainer>
        )}
      </Styled.TableContainer>

      <TimeOffRequestSideSheet
        isOpen={isTimeOffRequestSideSheetOpen}
        timeOffRequestData={sideSheetTimeOffRequestData}
        isRequestModified={Boolean(modifiedRequestId)}
        loading={getTimeOffSideSheetTimeOffRequestLoading}
        submitting={timeOffRequestSideSheetSubmitting}
        onApprove={handleTimeOffApprove}
        onReject={handleTimeOffReject}
        onClose={handleTimeOffRequestSideSheetClose}
      />
    </>
  );
};

TimeOffRequestsTable.fragments = {
  timeOffRequest: gql`
    fragment TimeOffRequestsTableTimeOffRequest on TimeOffRequest {
      id
      requestStatus
      requestedAt
      timeoffStartDate
      timeoffEndDate
      timeoffDuration
      hubber {
        id
        fullName
        hub
        status
        directManager {
          id
          name
        }
      }
      balance {
        hubberId
        name
        category
      }
    }
  `,
};
