import { IndexTable, SkeletonImageSquare } from 'glints-aries/lib/@next';

import { multipleSelectionHeadings, resourceName } from './constants';
import * as Styled from './styled.sc';

export const LoadingStateTable = () => {
  const loadingRow = [...Array(10).keys()].map((n) => (
    <Styled.LoadingRow id={`loading-row-${n}`} key={n} position={n}>
      <Styled.Cell>
        <Styled.LeftPaddingWrapper>
          <SkeletonImageSquare height="24px" width="100%" />
        </Styled.LeftPaddingWrapper>
      </Styled.Cell>
      <Styled.Cell>
        <SkeletonImageSquare height="24px" width="100%" />
      </Styled.Cell>
      <Styled.Cell>
        <SkeletonImageSquare height="24px" width="100%" />
      </Styled.Cell>
      <Styled.Cell>
        <Styled.PaymentAmountWrapper>
          <SkeletonImageSquare height="24px" width="100%" />
        </Styled.PaymentAmountWrapper>
      </Styled.Cell>
      <Styled.Cell>
        <SkeletonImageSquare height="24px" width="100%" />
      </Styled.Cell>
    </Styled.LoadingRow>
  ));

  return (
    <IndexTable
      itemCount={10}
      resourceName={resourceName}
      headings={multipleSelectionHeadings}
      emptyState={<></>}
      height="100%"
    >
      {loadingRow}
    </IndexTable>
  );
};
