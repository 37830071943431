/**
 * All constants that has external dependencies should be defined here.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CONFIG: { [key: string]: any } = (<any>window).appConfig;

// GraphQL Endpoint constants
export const GRAPHQL_ENDPOINT =
  <string>CONFIG.REACT_APP_API_HOST ||
  'https://managed-talent-api.staging.glints.com/';

// AWS S3 constants
export const HOME_BACKGROUND_IMAGE_LINK =
  'https://glints-dashboard-dev.s3.ap-southeast-1.amazonaws.com/images/mts/layout/home-background.webp';
export const HOME_BACKGROUND_MOBILE_IMAGE_LINK =
  'https://glints-dashboard-dev.s3.ap-southeast-1.amazonaws.com/images/mts/layout/home-background-mobile.webp';
export const LOGIN_BACKGROUND_IMAGE_LINK =
  'https://glints-dashboard-dev.s3.ap-southeast-1.amazonaws.com/images/mts/layout/login-background.webp';
export const PLACEHOLDER_COMPANY_IMAGE_LINK =
  'https://glints-dashboard-dev.s3.ap-southeast-1.amazonaws.com/images/mts/company/company-logo-placeholder.webp';
export const PLACEHOLDER_PROFILE_IMAGE_LINK =
  'https://glints-dashboard-dev.s3.ap-southeast-1.amazonaws.com/images/mts/company/profile-placeholder.webp';

// GA4
export const GA_DEV = 'G-L9ECHQP0LZ';
export const GA_PROD = 'G-437PBW8J1V';
export const GTM_ID = 'GTM-PTGKVL5L';

// UNLEASH
export const UNLEASH_APP_NAME = 'managed-talent';
export const UNLEASH_FLAG_NAME = {
  mtsShowBankTransfer: 'mtsShowBankTransfer',
  mtseGIRO: 'mtseGIRO',
  mtsShowExpenseClaim: 'mtsShowExpenseClaim',
} as const;
export type UnleashFlagName = ValueOf<typeof UNLEASH_FLAG_NAME>;

// Other constants
export const SUPPORT_EMAIL = 'mts-platform@glints.com';
export const USER_AGREEMENT = 'https://glints.com/sg/about/terms';
