import {
  Banner as AriesBanner,
  SideSheet as AriesSideSheet,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space2,
  space4,
  space8,
  space12,
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const SIDE_SHEET_FOOTER_HEIGHT = '52px';

export const SideSheet = styled(AriesSideSheet)`
  .sidesheet-content {
    background: ${Neutral.B99};
    padding: ${space12};
    height: 100%;
    padding-bottom: calc(${SIDE_SHEET_FOOTER_HEIGHT} + 20px);

    &::-webkit-scrollbar {
      display: none !important;
    }

    *::-webkit-scrollbar {
      width: 0 !important;
    }
  }
`;

export const MultipleCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CardRow = styled.div<{ flexDirection?: 'row' | 'column' }>`
  padding: ${space16} ${space24};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${space32};
`;

export const CardData = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  flex: 1;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Neutral.B85};
`;

export const Banner = styled(AriesBanner)`
  min-height: auto;
`;

export const SideSheetFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-height: ${SIDE_SHEET_FOOTER_HEIGHT};
  background: ${Neutral.B100};
  padding: ${space8} ${space12};
  box-shadow: 0px 1px 0px 0px #eef0f3 inset;
  display: flex;
  justify-content: flex-end;
`;

export const ApprovalActionsLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${space2};
  margin-bottom: ${space8};
`;

export const ApprovalActions = styled.div`
  background: ${Neutral.B100};
  margin-bottom: ${space8};
`;
