import { Badge } from 'glints-aries/lib/@next';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const PeriodLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBadge = styled(Badge)`
  margin-left: ${space8};
`;
