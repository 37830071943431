import {
  FormMode,
  Permission,
  PermissionFeature,
  Role,
  UserRoleCount,
} from './interfaces';

export const ROLE_UNASSIGNED = 'UNASSIGNED';
export type RoleUnassigned = typeof ROLE_UNASSIGNED;

export const roleDetailsMapping: Record<
  Role | RoleUnassigned,
  { role: string; roleDetails: string }
> = {
  [Role.CompanyAdmin]: {
    role: 'Company Admin',
    roleDetails:
      'The Company Admin can manage all the aspects of the company: everything from members, billing and other company-wide settings.',
  },
  [Role.PeopleManager]: {
    role: 'People Manager',
    roleDetails:
      "The People Team Manager can access employees' personal, employment, and contract information, manage time off requests and expense claims.",
  },
  [Role.Payer]: {
    role: 'Payer',
    roleDetails:
      'The payer can add items to payroll, receive notifications related to invoicing, and process payments.',
  },
  [Role.Viewer]: {
    role: 'Viewer',
    roleDetails:
      'Viewers can view personal and professional sensitive data, such as contract details, compensation and benefits.',
  },
  [ROLE_UNASSIGNED]: {
    role: 'Unassigned',
    roleDetails:
      'Please review the user profiles listed here and assign them appropriate roles. Complete any missing data to enable platform usage for them. Exercise caution: avoid editing or deleting profiles if you are unsure of the changes, as these profiles are integrated with our CRMS. For guidance or assistance, please contact your Account Manager.',
  },
};

export const roleCountMapping: Record<Role | RoleUnassigned, UserRoleCount> = {
  [Role.CompanyAdmin]: UserRoleCount.ADMIN,
  [Role.PeopleManager]: UserRoleCount.PEOPLE_MANAGER,
  [Role.Payer]: UserRoleCount.PAYER,
  [Role.Viewer]: UserRoleCount.VIEWER,
  [ROLE_UNASSIGNED]: UserRoleCount.NONE,
};

export const roleRouteMapping: Record<Role | RoleUnassigned, string> = {
  [Role.CompanyAdmin]: 'company-admin',
  [Role.PeopleManager]: 'people-manager',
  [Role.Payer]: 'payer',
  [Role.Viewer]: 'viewer',
  [ROLE_UNASSIGNED]: 'unassigned',
};

export const permissionFeaturesInOrder = [
  PermissionFeature.MY_EMPLOYEES,
  PermissionFeature.ATTENDANCE,
  PermissionFeature.TIME_OFF,
  PermissionFeature.EXPENSES,
  PermissionFeature.INVOICING,
  PermissionFeature.COMPANY_SETTINGS,
] as const;

export const permissionFeatureTitle = {
  [PermissionFeature.MY_EMPLOYEES]: 'Employees',
  [PermissionFeature.ATTENDANCE]: 'Attendance',
  [PermissionFeature.EXPENSES]: 'Expenses',
  [PermissionFeature.TIME_OFF]: 'Time Off',
  [PermissionFeature.INVOICING]: 'Invoice Payments',
  [PermissionFeature.COMPANY_SETTINGS]: 'Company Settings',
} as const;

const permissions = {
  COMPANY_SETTINGS: {
    [Permission.READ]: `COMPANY_SETTINGS.${Permission.READ}`,
    [Permission.WRITE]: `COMPANY_SETTINGS.${Permission.WRITE}`,
  },
  ROLES_AND_PERMISSIONS: {
    [Permission.WRITE]: `ROLES_AND_PERMISSIONS.${Permission.WRITE}`,
  },
  AUTO_PAYMENT: {
    [Permission.WRITE]: `AUTO_PAYMENT.${Permission.WRITE}`,
  },
  MY_EMPLOYEES: {
    [Permission.READ]: `MY_EMPLOYEES.${Permission.READ}`,
  },
  TIME_OFF: {
    [Permission.READ]: `TIME_OFF.${Permission.READ}`,
    [Permission.WRITE]: `TIME_OFF.${Permission.WRITE}`,
  },
  EXPENSES: {
    [Permission.READ]: `EXPENSES.${Permission.READ}`,
    [Permission.WRITE]: `EXPENSES.${Permission.WRITE}`,
  },
  ATTENDANCE: {
    [Permission.READ]: `ATTENDANCE.${Permission.READ}`,
  },
  INVOICING: {
    [Permission.READ]: `INVOICING.${Permission.READ}`,
  },
  INVOICE_QUERIES_REVIEWS: {
    [Permission.WRITE]: `INVOICE_QUERIES_REVIEWS.${Permission.WRITE}`,
  },
  INVOICE_RECEIVE_NOTIFICATIONS: {
    [Permission.READ]: `INVOICE_RECEIVE_NOTIFICATIONS.${Permission.READ}`,
  },
  REPORTING_LINES: {
    [Permission.WRITE]: `REPORTING_LINES.${Permission.WRITE}`,
  },
  REPORTING_LINES_AS_MANAGER: {
    [Permission.WRITE]: `REPORTING_LINES_AS_MANAGER.${Permission.WRITE}`,
  },
};

export const permissionDetails = {
  [PermissionFeature.MY_EMPLOYEES]: {
    [permissions.MY_EMPLOYEES[Permission.READ]]:
      'View all details of employees, including contract type, job start/end date, and contract expiry date among others',
  },
  [PermissionFeature.ATTENDANCE]: {
    [permissions.ATTENDANCE[Permission.READ]]:
      'View detailed attendance records, including presence, absence, and other attendance-related information',
  },
  [PermissionFeature.TIME_OFF]: {
    [permissions.TIME_OFF[Permission.READ]]:
      'View details related to time off tracking, including time off request statuses, leave types, requested dates, and leave balances',
    [permissions.TIME_OFF[Permission.WRITE]]:
      'Approve/reject time off requests and and receive relevant email notifications within reporting lines',
  },
  [PermissionFeature.EXPENSES]: {
    [permissions.EXPENSES[Permission.READ]]:
      'View details related to expense claims, including requested time, expense category, total amount, and claim status',
    [permissions.EXPENSES[Permission.WRITE]]:
      'Approve/reject expense claims and receive relevant email notifications within reporting lines',
  },
  [PermissionFeature.INVOICING]: {
    [permissions.INVOICING[Permission.READ]]:
      'View details of invoices including issued date, due date, payment amount, status',
    [permissions.INVOICE_QUERIES_REVIEWS[Permission.WRITE]]:
      'Raise queries to Glints and request a review and adjustment of the invoice',
    [permissions.INVOICE_RECEIVE_NOTIFICATIONS[Permission.READ]]:
      'Receive notifications regarding invoice payments',
  },
  [PermissionFeature.COMPANY_SETTINGS]: {
    [permissions.COMPANY_SETTINGS[Permission.READ]]:
      'View company information including registered business address, billing entity name and other company-specific information',
    [permissions.ROLES_AND_PERMISSIONS[Permission.WRITE]]:
      'Manage roles and permissions, including viewing, editing, and removing users under your company account',
    [permissions.AUTO_PAYMENT[Permission.WRITE]]:
      'Manage auto-payment settings, including viewing, connecting, and disconnecting bank accounts for direct debit/eGIRO',
    [permissions.REPORTING_LINES[Permission.WRITE]]: 'Manage reporting lines',
    [permissions.REPORTING_LINES_AS_MANAGER[Permission.WRITE]]:
      'Can be designated as a reporting manager to manage employees requests',
  },
};

export type RolePermissionFeatureType = {
  role: string;
  permissions: Record<
    Partial<PermissionFeature>,
    Partial<Record<string, boolean>>
  >;
};

export type RolePermissionsDetailsMappingType = Record<
  Role,
  RolePermissionFeatureType
>;

export const rolePermissionsDetailsMapping: RolePermissionsDetailsMappingType =
  {
    [Role.CompanyAdmin]: {
      role: 'Company Admin',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [permissions.COMPANY_SETTINGS[Permission.READ]]: true,
          [permissions.ROLES_AND_PERMISSIONS[Permission.WRITE]]: true,
          [permissions.AUTO_PAYMENT[Permission.WRITE]]: true,
          [permissions.REPORTING_LINES[Permission.WRITE]]: true,
          [permissions.REPORTING_LINES_AS_MANAGER[Permission.WRITE]]: true,
        },
        [PermissionFeature.MY_EMPLOYEES]: {
          [permissions.MY_EMPLOYEES[Permission.READ]]: true,
        },
        [PermissionFeature.TIME_OFF]: {
          [permissions.TIME_OFF[Permission.READ]]: true,
          [permissions.TIME_OFF[Permission.WRITE]]: true,
        },
        [PermissionFeature.EXPENSES]: {
          [permissions.EXPENSES[Permission.READ]]: true,
          [permissions.EXPENSES[Permission.WRITE]]: true,
        },
        [PermissionFeature.ATTENDANCE]: {
          [permissions.ATTENDANCE[Permission.READ]]: true,
        },
        [PermissionFeature.INVOICING]: {
          [permissions.INVOICING[Permission.READ]]: true,
          [permissions.INVOICE_QUERIES_REVIEWS[Permission.WRITE]]: true,
          [permissions.INVOICE_RECEIVE_NOTIFICATIONS[Permission.READ]]: true,
        },
      },
    },
    [Role.PeopleManager]: {
      role: 'People Manager',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [permissions.COMPANY_SETTINGS[Permission.READ]]: true,
          [permissions.ROLES_AND_PERMISSIONS[Permission.WRITE]]: false,
          [permissions.AUTO_PAYMENT[Permission.WRITE]]: false,
          [permissions.REPORTING_LINES[Permission.WRITE]]: false,
          [permissions.REPORTING_LINES_AS_MANAGER[Permission.WRITE]]: true,
        },
        [PermissionFeature.MY_EMPLOYEES]: {
          [permissions.MY_EMPLOYEES[Permission.READ]]: true,
        },
        [PermissionFeature.TIME_OFF]: {
          [permissions.TIME_OFF[Permission.READ]]: true,
          [permissions.TIME_OFF[Permission.WRITE]]: true,
        },
        [PermissionFeature.EXPENSES]: {
          [permissions.EXPENSES[Permission.READ]]: true,
          [permissions.EXPENSES[Permission.WRITE]]: true,
        },
        [PermissionFeature.ATTENDANCE]: {
          [permissions.ATTENDANCE[Permission.READ]]: true,
        },
        [PermissionFeature.INVOICING]: {
          [permissions.INVOICING[Permission.READ]]: false,
          [permissions.INVOICE_QUERIES_REVIEWS[Permission.WRITE]]: false,
          [permissions.INVOICE_RECEIVE_NOTIFICATIONS[Permission.READ]]: false,
        },
      },
    },
    [Role.Payer]: {
      role: 'Payer',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [permissions.COMPANY_SETTINGS[Permission.READ]]: true,
          [permissions.ROLES_AND_PERMISSIONS[Permission.WRITE]]: false,
          [permissions.AUTO_PAYMENT[Permission.WRITE]]: true,
          [permissions.REPORTING_LINES[Permission.WRITE]]: false,
          [permissions.REPORTING_LINES_AS_MANAGER[Permission.WRITE]]: false,
        },
        [PermissionFeature.MY_EMPLOYEES]: {
          [permissions.MY_EMPLOYEES[Permission.READ]]: true,
        },
        [PermissionFeature.TIME_OFF]: {
          [permissions.TIME_OFF[Permission.READ]]: true,
          [permissions.TIME_OFF[Permission.WRITE]]: false,
        },
        [PermissionFeature.EXPENSES]: {
          [permissions.EXPENSES[Permission.READ]]: true,
          [permissions.EXPENSES[Permission.WRITE]]: false,
        },
        [PermissionFeature.ATTENDANCE]: {
          [permissions.ATTENDANCE[Permission.READ]]: true,
        },
        [PermissionFeature.INVOICING]: {
          [permissions.INVOICING[Permission.READ]]: true,
          [permissions.INVOICE_QUERIES_REVIEWS[Permission.WRITE]]: true,
          [permissions.INVOICE_RECEIVE_NOTIFICATIONS[Permission.READ]]: true,
        },
      },
    },
    [Role.Viewer]: {
      role: 'Viewer',
      permissions: {
        [PermissionFeature.COMPANY_SETTINGS]: {
          [permissions.COMPANY_SETTINGS[Permission.READ]]: true,
          [permissions.ROLES_AND_PERMISSIONS[Permission.WRITE]]: false,
          [permissions.AUTO_PAYMENT[Permission.WRITE]]: false,
          [permissions.REPORTING_LINES[Permission.WRITE]]: false,
          [permissions.REPORTING_LINES_AS_MANAGER[Permission.WRITE]]: false,
        },
        [PermissionFeature.MY_EMPLOYEES]: {
          [permissions.MY_EMPLOYEES[Permission.READ]]: true,
        },
        [PermissionFeature.TIME_OFF]: {
          [permissions.TIME_OFF[Permission.READ]]: true,
          [permissions.TIME_OFF[Permission.WRITE]]: false,
        },
        [PermissionFeature.EXPENSES]: {
          [permissions.EXPENSES[Permission.READ]]: true,
          [permissions.EXPENSES[Permission.WRITE]]: false,
        },
        [PermissionFeature.ATTENDANCE]: {
          [permissions.ATTENDANCE[Permission.READ]]: true,
        },
        [PermissionFeature.INVOICING]: {
          [permissions.INVOICING[Permission.READ]]: true,
          [permissions.INVOICE_QUERIES_REVIEWS[Permission.WRITE]]: false,
          [permissions.INVOICE_RECEIVE_NOTIFICATIONS[Permission.READ]]: false,
        },
      },
    },
  };

export const COUNTRY_CODE_OPTIONS = [
  {
    id: '1',
    label: '+852',
    value: '+852',
  },
  {
    id: '2',
    label: '+62',
    value: '+62',
  },
  {
    id: '3',
    label: '+60',
    value: '+60',
  },
  {
    id: '4',
    label: '+65',
    value: '+65',
  },
  {
    id: '5',
    label: '+886',
    value: '+886',
  },
  {
    id: '6',
    label: '+84',
    value: '+84',
  },
];

export const alertContent: Partial<Record<FormMode, string>> = {
  [FormMode.CREATE]: 'has been added as a',
  [FormMode.EDIT]: 'has been updated',
  [FormMode.DELETE]: 'has been removed from this platform',
};
