import { Banner as AriesBanner } from 'glints-aries/lib/@next';
import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { card as cardDropShadow } from 'glints-aries/lib/@next/utilities/dropShadow';
import {
  space4,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const ReportingLinesContainer = styled.div<{
  makeBannerSpace?: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 240px);
  margin-top: ${space24};

  ${({ makeBannerSpace }) =>
    makeBannerSpace &&
    `
    height: calc(100vh - 240px - 56px);
  `}

  box-shadow: ${cardDropShadow};
  border-radius: ${borderRadius8};
  background-color: ${Neutral.B100};
`;

export const Header = styled.div`
  padding: ${space16};
  display: flex;
  gap: ${space4};
  flex-direction: column;
  border-bottom: 1px solid ${Neutral.B85};
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  border-bottom-left-radius: ${borderRadius8};
  border-bottom-right-radius: ${borderRadius8};

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;

export const Sider = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  overflow: auto;
  box-shadow: ${cardDropShadow};
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
    height: fit-content;
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  background-color: ${Neutral.B99};
`;

export const Banner = styled(AriesBanner)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space16};
  min-height: 56px;
  width: calc(100% + 48px);
  position: relative;
  top: 0;
  transform: translateX(-24px);

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: calc(100% + 32px);
    transform: translateX(-16px);
  }

  /* Override Aries styles */
  div:has(svg) {
    padding-right: 0;
  }
  svg {
    margin-top: 6px;

    @media (max-width: ${BREAKPOINTS.medium}) {
      margin-top: 2px;
    }
  }
`;
