import { useEffect, useState } from 'react';
import {
  TextArea,
  type TextAreaProps,
  Typography,
} from 'glints-aries/lib/@next';
import { Red } from 'glints-aries/lib/@next/utilities/colors';
import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

import * as Styled from '../styled.sc';
import { StyledTextArea } from './styled.sc';
import { TextInputError } from '@/components/atoms/TextInputError/TextInputError';

interface FormTextAreaProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<TextAreaProps, 'value' | 'defaultValue' | 'name' | 'onChange'> {
  label?: string;
  required?: boolean;
  apiError?: string | null;
  height?: string;
  helpText?: string;
}

export const FormTextArea = <T extends FieldValues>({
  control,
  label,
  name,
  required = false,
  apiError,
  helpText,
  ...props
}: FormTextAreaProps<T>) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: props?.rules,
  });

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.message);
    }
    if (apiError) {
      setErrorMessage(apiError);
    }
  }, [error, apiError]);

  return (
    <>
      {label && (
        <Styled.Label variant="subtitle2">
          {label}
          {required && (
            <Typography variant="subtitle2" color={Red.B93}>
              *
            </Typography>
          )}
        </Styled.Label>
      )}
      <StyledTextArea aria-disabled={props.disabled}>
        <TextArea
          {...props}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          name={field.name}
          ref={field.ref}
          error={Boolean(error)}
        />
      </StyledTextArea>
      {error || apiError ? (
        <Styled.ErrorText>
          <TextInputError errorMessage={errorMessage} />
        </Styled.ErrorText>
      ) : (
        <Styled.HelpText variant="subtitle2">{helpText}</Styled.HelpText>
      )}
    </>
  );
};
