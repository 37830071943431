// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

/**
 * Check if an access token is valid or has expired
 * @param token access token
 */
export const checkTokenValid = (token: string) => {
  try {
    const decodedToken: { exp: number } = jwt_decode(token);
    const expiryTime = new Date(decodedToken.exp);
    const currentTime = new Date();
    return expiryTime >= currentTime;
  } catch {
    return false;
  }
};
