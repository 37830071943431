import { Icon } from 'glints-aries/lib/@next';

import { validateRejectReason } from './helpers';
import { type RadioButtonOption } from '@/components/atoms/RadioButtonGroup/types';

export const APPROVAL_STATE = {
  approve: 'approve',
  reject: 'reject',
} as const;

export type ApprovalState = ValueOf<typeof APPROVAL_STATE>;

export const APPROVAL_STATE_LABEL = {
  [APPROVAL_STATE.approve]: 'Approve',
  [APPROVAL_STATE.reject]: 'Reject',
} as const;

export const approvalOptions: RadioButtonOption[] = [
  {
    label: 'Approve',
    value: APPROVAL_STATE.approve,
    disabled: false,
    variant: 'Positive',
    icon: <Icon name="ri-check" />,
  },
  {
    label: 'Reject',
    value: APPROVAL_STATE.reject,
    disabled: false,
    variant: 'Negative',
    icon: <Icon name="ri-close" />,
  },
];

export const REJECTION_FORM_NAMES = {
  rejectReason: 'rejectReason',
} as const;

export const REJECTION_FORM_PLACEHOLDERS = {
  [REJECTION_FORM_NAMES.rejectReason]: 'Please enter the reason for rejection',
} as const;

export const REJECTION_FORM_RULES = {
  [REJECTION_FORM_NAMES.rejectReason]: {
    required: 'Reason must be provided',
    validate: validateRejectReason,
  },
} as const;

export type RejectionFormFields = {
  [REJECTION_FORM_NAMES.rejectReason]: string;
};
