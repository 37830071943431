import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  bankDetailsMapping,
  type BankSwiftCode,
} from '../AutoPaymentTab/constants';
import { EgiroConnect } from './EgiroConnect';
import { EgiroDisconnect } from './EgiroDisconnect';
import { errorType } from '@/modules/Error/constants';
import ErrorPage from '@/modules/Error/Error';

const Egiro = () => {
  const { pathname } = useLocation();
  const eGIROurl = pathname.split('/')[2];
  const [searchParams] = useSearchParams();
  const [bankName] = useState(
    bankDetailsMapping[searchParams.get('bank') as BankSwiftCode].name,
  );
  const [bankSwiftBicCode] = useState(
    bankDetailsMapping[searchParams.get('bank') as BankSwiftCode]
      .bankSwiftBicCode,
  );

  if (eGIROurl === 'connect') {
    return <EgiroConnect bankName={bankName} />;
  }

  if (eGIROurl === 'disconnect' || eGIROurl === 'disconnected') {
    return (
      <EgiroDisconnect
        bankName={bankName}
        eGIROurl={eGIROurl}
        bankSwiftBicCode={bankSwiftBicCode}
      />
    );
  }

  return <ErrorPage errorType={errorType.NOT_FOUND} />;
};

export default Egiro;
