import { useState } from 'react';
import {
  Button,
  Icon,
  Menu,
  Popover,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { statusFilterOptions } from '../constants';
import {
  StatusFilterButton,
  StatusFilterLeftContainer,
  StatusFilterRightContainer,
  StyledBadge,
} from '../ManagedTalentsPageStyle';

export const StatusFilterSelect = ({
  selectedStatus,
  handleSelectStatus,
  updateHideStatusBadge,
  hideBadge,
}: {
  selectedStatus: string[];
  handleSelectStatus: ({ value }: { value: string }) => void;
  updateHideStatusBadge: (hide: boolean) => void;
  hideBadge: boolean;
}) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = () => {
    setPopoverActive(!popoverActive);
    if (
      statusFilterOptions.length === selectedStatus.length ||
      selectedStatus.length === 0
    ) {
      updateHideStatusBadge(true);
      return;
    }
    updateHideStatusBadge(false);
  };

  const activator = (
    <Button onClick={togglePopoverActive}>
      <StatusFilterButton>
        <StatusFilterLeftContainer>
          <Icon name="ri-user5-line" fill={Neutral.B40} />
          <Typography variant="button">Employee</Typography>
        </StatusFilterLeftContainer>
        <StatusFilterRightContainer>
          <StyledBadge status="enticing" data-hidden={hideBadge}>
            {selectedStatus.length}
          </StyledBadge>
          <Icon name="ri-arrow-m-down-fill" fill={Neutral.B40} />
        </StatusFilterRightContainer>
      </StatusFilterButton>
    </Button>
  );

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      fullWidth={true}
    >
      <Popover.Pane>
        <Menu
          options={statusFilterOptions}
          allowMultiple={true}
          onClick={handleSelectStatus}
          selectedValues={selectedStatus}
        />
      </Popover.Pane>
    </Popover>
  );
};
