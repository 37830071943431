import { Breakpoints } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Neutral.B85};

  width: 188px;
  margin: 0 ${space16} ${space8};
  padding: ${space12} 0;

  & > .menu-header-expand-icon {
    visibility: hidden;
  }

  &:hover {
    justify-content: space-between;

    & > .menu-header-logo {
      height: 26px;
      width: 117px;
    }

    & > .menu-header-expand-icon {
      margin-left: auto;
      visibility: visible;
    }
  }
`;

export const HeaderCollapsedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${Neutral.B85};

  min-height: 64px;
  padding: 0 ${space4};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${Neutral.B85};
  border-radius: 4px;

  height: 40px;
  width: 40px;

  &:hover {
    background-color: ${Neutral.B95};
  }

  svg {
    height: 20px;
    width: 20px;
    fill: ${Neutral.B40};
  }
`;

export const MainLogoContainer = styled.div`
  position: fixed;
  height: 36px;
  width: 162px;
  cursor: pointer;
  transition: width 1s;

  &:hover {
    transition: none;
  }

  &[data-faded='true'] {
    opacity: 0.1;
  }

  &[data-nopointer='true'] {
    cursor: default;
  }

  @media (max-width: ${Breakpoints.large}) {
    height: 30px;
    width: 135px;
  }
`;

export const HeaderSmallScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${Neutral.B85};
  padding: 12px 16px;

  &[data-collapsed='true'] {
    border-bottom: none;
    padding: 0;
  }
`;

export const IconSmallScreenContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;

  &[data-collapsed='true'] {
    height: 30px;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${Neutral.B40};
  }
`;
