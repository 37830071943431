import {
  type PaginationProps,
  SimplePagination as AriesSimplePagination,
} from 'glints-aries/lib/@next';

import * as Styled from './styled.sc';

export interface SimplePaginationProps extends PaginationProps {
  hidePageButtons?: boolean;
}

export const SimplePagination = ({
  hidePageButtons,
  ...props
}: SimplePaginationProps) => (
  <Styled.SimplePaginationContainer hidePageButtons={hidePageButtons}>
    <AriesSimplePagination {...props} />
  </Styled.SimplePaginationContainer>
);
