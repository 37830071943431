import { StatusIndicator } from '../../StatusIndicator/StatusIndicator';
import * as Styled from './styled.sc';
import { EmploymentStatus, type Hubber } from '@/generated/graphql';

export interface HubberNameProps {
  hubber: Pick<Hubber, 'fullName' | 'status'>;
}

export const HubberName = ({ hubber }: HubberNameProps) => (
  <Styled.Container>
    <Styled.Name>{hubber.fullName}</Styled.Name>
    <StatusIndicator active={hubber.status === EmploymentStatus.Active} />
  </Styled.Container>
);
