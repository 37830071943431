import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { HUB_CODE_FLAG, HUB_CODE_LABEL, type HubCode } from './constants';
import * as Styled from './styled.sc';
export interface HubLocationTextProps {
  hubCode: HubCode;
}

export const HubLocationText = ({ hubCode }: HubLocationTextProps) => (
  <Styled.HubLocationText>
    <Styled.CountryFlag>{HUB_CODE_FLAG[hubCode]}</Styled.CountryFlag>
    <Styled.CountryText>
      <Typography as="span" variant="subtitle2" color={Neutral.B18}>
        {HUB_CODE_LABEL[hubCode]}
      </Typography>
    </Styled.CountryText>
  </Styled.HubLocationText>
);
