import { OutlineButton as GlintsOutlineButton } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${Neutral.B100};
  border-top: 1px solid ${Neutral.B85};
`;

export const TablePaginationContainer = styled.div`
  width: 100%;
  padding: ${space8} ${space16};
  background: ${Neutral.B100};
  box-shadow: 0px 0px 0px 1px rgba(71, 71, 71, 0.05),
    0px 1px 3px 0px rgba(63, 63, 68, 0.15);
  position: sticky;
  bottom: 0;
  z-index: 10;
`;

export const OutlineButton = styled(GlintsOutlineButton)<{
  disabled?: boolean;
}>`
  min-width: unset;
  padding: revert;

  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: unset !important;
  `}

  &:hover {
    ${({ disabled }) =>
      disabled &&
      `
        cursor: not-allowed;
        pointer-events: unset !important;
    `}
  }
`;

export const TooltipInnerWrapper = styled.div`
  width: fit-content;
`;
