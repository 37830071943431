import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const HubberCardContainer = styled.div`
  border-radius: ${space8};
  background-color: ${Neutral.B100};
  box-shadow: 0px 1px 2px 0px rgba(71, 71, 71, 0.2),
    0px 0px 5px 0px rgba(71, 71, 71, 0.1);
  overflow-x: hidden;

  ::-webkit-scrollbar {
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  td {
    padding: ${space12} ${space16};
  }
`;

export const HubberCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${space24} ${space24} 20px;

  @media (max-width: 576px) {
    display: block;
  }
`;

export const HubberCardHeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HubberCardHeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
  margin-left: auto;

  svg {
    height: 14px;
    width: 18px;
    border: 1px solid ${Neutral.B85};
  }

  .no-border {
    svg {
      transform: translateY(2px);
      height: 20px;
      border: none;
    }
  }
`;

export const HubberCardNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HubberCardHeaderCountryLink = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${space4};
  cursor: pointer;
`;

export const DataTableContainer = styled.div`
  transform: translateX(-1px);
  width: calc(100% + 2px);
`;

export const TableCellLeftMarginContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
  margin-left: ${space8};

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const TableCellRightMarginContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
  margin-right: ${space32};

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const HolidaysTableContainer = styled.div`
  .data-table-container {
    box-shadow: none;
  }
`;
