import { Breakpoints, Select } from 'glints-aries/lib/@next';
import {
  space4,
  space8,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 208px);

  @media (max-width: ${Breakpoints.large}) {
    height: calc(100vh - 231px);
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space8};
`;

export const HeadingNav = styled.div`
  display: flex;
  align-items: center;
  gap: ${space4};
`;

export const HeadingTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
  margin-bottom: ${space24};
`;

export const BackButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${space16};

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  gap: ${space8};
  width: 100%;

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: column;
    margin-bottom: ${space16};
    gap: ${space16};
  }
`;

export const PeriodSelectContainer = styled.div`
  width: 226px;

  @media (max-width: ${Breakpoints.large}) {
    width: 100%;
  }
`;

export const PeriodSelect = styled(Select)`
  @media (max-width: ${Breakpoints.large}) {
    width: 100% !important;
  }
`;

export const AttendanceStatusSelectContainer = styled.div`
  width: 200px;

  @media (max-width: ${Breakpoints.large}) {
    width: 100%;
  }
`;

export const AttendanceStatusSelect = styled(Select)`
  @media (max-width: ${Breakpoints.large}) {
    width: 100%;
  }
`;

export const ExportContainer = styled.div`
  margin-left: auto;

  @media (max-width: ${Breakpoints.large}) {
    margin-left: 0;
    margin-right: auto;
  }
`;
