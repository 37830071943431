import { Typography } from 'glints-aries/lib/@next';

import { getInitials } from './helpers';
import * as Styled from './styled.sc';
import { type AvatarSize } from './types';

interface AvatarProps {
  size?: AvatarSize;
  name?: string;
  color?: string;
  backgroundColor?: string;
}

export const Avatar = ({ name, color, backgroundColor, size }: AvatarProps) => (
  <Styled.Avatar size={size} backgroundColor={backgroundColor}>
    <Typography as="span" variant="body1" color={color}>
      {getInitials(name)}
    </Typography>
  </Styled.Avatar>
);
