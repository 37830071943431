export const timeZone = {
  INDONESIA_BATAM: 'BT',
  INDONESIA_JAKARTA: 'ID',
  SINGAPORE: 'SG',
  VIETNAM: 'VN',
  PHILIPPINES: 'PH',
  TAIWAN: 'TW',
} as const;

type TypeTimeZone = typeof timeZone;
export type TimeZone = TypeTimeZone[keyof TypeTimeZone];

export const timeZoneAbbrMapping: Record<TimeZone, string> = {
  [timeZone.INDONESIA_BATAM]: 'WIB',
  [timeZone.INDONESIA_JAKARTA]: 'WIB',
  [timeZone.SINGAPORE]: 'SGT',
  [timeZone.VIETNAM]: 'ICT',
  [timeZone.PHILIPPINES]: 'PHT',
  [timeZone.TAIWAN]: 'CST',
};

export const timeZoneStringMapping: Record<TimeZone, string> = {
  [timeZone.INDONESIA_BATAM]: 'Asia/Jakarta',
  [timeZone.INDONESIA_JAKARTA]: 'Asia/Jakarta',
  [timeZone.SINGAPORE]: 'Asia/Singapore',
  [timeZone.VIETNAM]: 'Asia/Ho_Chi_Minh',
  [timeZone.PHILIPPINES]: 'Asia/Manila',
  [timeZone.TAIWAN]: 'Asia/Taipei',
};
