import { Modal as AriesModal, PrimaryButton } from 'glints-aries/lib/@next';
import { space8, space24 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import {
  BUTTON_COLOR,
  type ButtonType,
  CONFIRMATION_MODAL_BUTTON_TYPE,
} from './constants';
import { BREAKPOINTS } from '@/constants/breakpoints';

export const Modal = styled(AriesModal)`
  width: 550px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }

  .modal-content {
    width: 100%;
    padding: 20px ${space24};
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  gap: ${space8};
`;

export const ConfirmButton = styled(PrimaryButton)<{
  buttonType?: ButtonType;
}>`
  ${({ buttonType }) => {
    const btType: ButtonType =
      buttonType ?? CONFIRMATION_MODAL_BUTTON_TYPE.primary;
    return `
      background: ${BUTTON_COLOR[btType]};
      &:hover {
        background: ${BUTTON_COLOR[btType]};
    `;
  }}
`;
