import styled from 'styled-components';

import { avatarSize, DEFAULT_SIZE } from './constants';

type AvatarProps = {
  size?: 'medium' | 'large';
  backgroundColor?: string;
};

export const Avatar = styled.div<AvatarProps>`
  width: ${({ size = DEFAULT_SIZE }) => `${avatarSize[size]}px`};
  height: ${({ size = DEFAULT_SIZE }) => `${avatarSize[size]}px`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
`;
