import { Typography } from 'glints-aries/es/@next';
import { Green, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const Label = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${Neutral.B18};
  margin-bottom: ${space4};
  gap: ${space4};
`;

export const HelpText = styled(Typography)`
  color: ${Neutral.B40};
  margin-top: ${space4};

  &[aria-success='true'] {
    color: ${Green.B52};
  }
`;

export const ErrorText = styled.div`
  margin-top: ${space4};
`;
