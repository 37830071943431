import { Badge } from 'glints-aries/lib/@next';

import { BorderedIcon } from './styled.sc';
import { ReactComponent as FlagIdSVG } from '@/assets/images/flag-id.svg';
import { ReactComponent as FlagMYSVG } from '@/assets/images/flag-my.svg';
import { ReactComponent as FlagPHSVG } from '@/assets/images/flag-ph.svg';
import { ReactComponent as FlagSGSVG } from '@/assets/images/flag-sg.svg';
import { ReactComponent as FlagTWSVG } from '@/assets/images/flag-tw.svg';
import { ReactComponent as FlagVTSVG } from '@/assets/images/flag-vn.svg';
import { formatMoney } from '@/utils/formatString';

export const COUNTRY_CODE = {
  indonesia: 'ID',
};

export const SUPPORTED_COUNTRIES = [COUNTRY_CODE.indonesia];

export const CURRENCY = {
  [COUNTRY_CODE.indonesia]: 'IDR',
};

export const TEAM_EXPANSION_URL =
  'https://talenthub.glints.com/employee-cost-calculator';

export const MINIMUM_WAGE = {
  [COUNTRY_CODE.indonesia]: 4685050.0,
};

const ID_ESTIMATED_COST_RESULT_KEY = {
  grossSalary: 'GROSS_SALARY',
  JHT: 'JHT',
  JP: 'JP',
  JKK: 'JKK',
  JKM: 'JKM',
  socialSecurityProgram: 'SOCIAL_SECURITY_PROGRAM',
  BPJSKesehatan: 'BPJS_KESEHATAN',
  THR: 'THR',
  estimatedSeverance: 'ESTIMATED_SEVERANCE',
  statutoryBenefits: 'STATUTORY_BENEFITS',
  managementFee: 'MANAGEMENT_FEE',
  totalCost: 'TOTAL_COST',
};

export const ESTIMATED_COST_RESULT_CONTENT: Record<
  string,
  Record<string, string>
> = {
  [COUNTRY_CODE.indonesia]: {
    [ID_ESTIMATED_COST_RESULT_KEY.grossSalary]: 'Monthly Gross Salary',
    [ID_ESTIMATED_COST_RESULT_KEY.JHT]: 'Old Age Security (JHT)',
    [ID_ESTIMATED_COST_RESULT_KEY.JP]: 'Pension Insurance (JP)',
    [ID_ESTIMATED_COST_RESULT_KEY.JKK]: 'Work Accident Insurance (JKK)',
    [ID_ESTIMATED_COST_RESULT_KEY.JKM]: 'Death Insurance (JKM)',
    [ID_ESTIMATED_COST_RESULT_KEY.socialSecurityProgram]:
      'Social Security Programs',
    [ID_ESTIMATED_COST_RESULT_KEY.BPJSKesehatan]:
      'Health Insurance / BPJS Health',
    [ID_ESTIMATED_COST_RESULT_KEY.THR]: 'Religious Holiday Allowance (THR)',
    [ID_ESTIMATED_COST_RESULT_KEY.estimatedSeverance]: 'Severance Pay',
    [ID_ESTIMATED_COST_RESULT_KEY.statutoryBenefits]: 'Statutory Benefits',
    [ID_ESTIMATED_COST_RESULT_KEY.managementFee]: 'Glints Management Fee',
    [ID_ESTIMATED_COST_RESULT_KEY.totalCost]: 'Estimated Monthly Total Cost',
  },
};

export const countries = [
  { label: 'Indonesia', value: 'ID' },
  { label: 'Malaysia', value: 'MY', disabled: true },
  { label: 'Philippines', value: 'PH', disabled: true },
  { label: 'Singapore', value: 'SG', disabled: true },
  { label: 'Taiwan', value: 'TW', disabled: true },
  { label: 'Vietnam', value: 'VT', disabled: true },
];

export const CONTRACT_TYPE = {
  indefinite: 'INDEFINITE',
  fixedTerm: 'FIXED-TERM',
};

const CONTRACT_TYPE_LABEL = {
  [CONTRACT_TYPE.indefinite]: 'Indefinite',
  [CONTRACT_TYPE.fixedTerm]: 'Fixed-Term',
};

export const contractTypeOptions = [
  {
    label: CONTRACT_TYPE_LABEL[CONTRACT_TYPE.indefinite],
    value: CONTRACT_TYPE.indefinite,
    badge: <Badge status="promotion">Recommended</Badge>,
  },
  {
    label: CONTRACT_TYPE_LABEL[CONTRACT_TYPE.fixedTerm],
    value: CONTRACT_TYPE.fixedTerm,
  },
];

export const COST_CALCULATOR_FORM_NAMES = {
  country: 'country',
  contractType: 'contractType',
  contractPeriod: 'contractPeriod',
  monthlyGrossSalary: 'monthlyGrossSalary',
  paymentCurrency: 'paymentCurrency',
} as const;

export type CostCalculatorFields = {
  [COST_CALCULATOR_FORM_NAMES.country]: string;
  [COST_CALCULATOR_FORM_NAMES.contractType]: string;
  [COST_CALCULATOR_FORM_NAMES.contractPeriod]: number | undefined;
  [COST_CALCULATOR_FORM_NAMES.monthlyGrossSalary]: number;
  [COST_CALCULATOR_FORM_NAMES.paymentCurrency]: string;
};

export const COST_CALCULATOR_FORM_LABEL = {
  [COST_CALCULATOR_FORM_NAMES.country]: 'Country of Employment',
  [COST_CALCULATOR_FORM_NAMES.contractPeriod]: 'Contract Period',
} as const;

export const COST_CALCULATOR_FORM_PLACEHOLDER = {
  [COST_CALCULATOR_FORM_NAMES.contractPeriod]: 'Enter',
  [COST_CALCULATOR_FORM_NAMES.monthlyGrossSalary]: 'Select',
} as const;

export const COST_CALCULATOR_FORM_HELPTEXT = {
  [COST_CALCULATOR_FORM_NAMES.contractPeriod]: 'Min: 12 months; Max: 60 months',
  [COST_CALCULATOR_FORM_NAMES.monthlyGrossSalary]: `Min wage: ${
    CURRENCY.ID
  } ${formatMoney({
    amount: MINIMUM_WAGE.ID.toString(),
  })}`,
} as const;

export const countryOptions = [
  {
    label: 'Indonesia',
    value: 'ID',
    icon: (
      <BorderedIcon>
        <FlagIdSVG />
      </BorderedIcon>
    ),
  },
  {
    label: 'Malaysia',
    value: 'MY',
    icon: (
      <BorderedIcon>
        <FlagMYSVG />
      </BorderedIcon>
    ),
    badge: <Badge>Coming Soon</Badge>,
  },
  {
    label: 'Philippines',
    value: 'PH',
    icon: (
      <BorderedIcon>
        <FlagPHSVG />
      </BorderedIcon>
    ),
    badge: <Badge>Coming Soon</Badge>,
  },
  {
    label: 'Singapore',
    value: 'SG',
    icon: (
      <BorderedIcon>
        <FlagSGSVG />
      </BorderedIcon>
    ),
    badge: <Badge>Coming Soon</Badge>,
  },
  {
    label: 'Taiwan',
    value: 'TW',
    icon: (
      <BorderedIcon>
        <FlagTWSVG />
      </BorderedIcon>
    ),
    badge: <Badge>Coming Soon</Badge>,
  },
  {
    label: 'Vietnam',
    value: 'VT',
    icon: (
      <BorderedIcon>
        <FlagVTSVG />
      </BorderedIcon>
    ),
    badge: <Badge>Coming Soon</Badge>,
  },
];

export const FORM_NUMBER_INPUT_SUFFIX = 'months';
