import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space4 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  .card-container .card-content-container {
    padding-bottom: 0 !important;
  }
`;

export const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space4};
  cursor: pointer;
  background: ${Neutral.B95};
  height: 42px;
  border-radius-bottom-left: ${borderRadius8};
  border-radius-bottom-right: ${borderRadius8};
`;

export const FileName = styled.div`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
