export enum Currency {
  INDONESIA = 'IDR',
  MALAYSIA = 'MYR',
  PHILIPPINES = 'PHP',
  SINGAPORE = 'SGD',
  TAIWAN = 'TWD',
  VIETNAM = 'VND',
  UNITED_STATES = 'USD',
}

export type CurrencyType = ValueOf<typeof Currency>;

export const CURRENCY_TO_LOCALE = {
  [Currency.INDONESIA]: 'in-ID',
  [Currency.MALAYSIA]: 'ms-MY',
  [Currency.PHILIPPINES]: 'fil-PH',
  [Currency.SINGAPORE]: 'en-SG',
  [Currency.TAIWAN]: 'zh-TW',
  [Currency.VIETNAM]: 'vi-VN',
  [Currency.UNITED_STATES]: 'en-US',
};
