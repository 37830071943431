import { gql } from 'graphql-request';

export const hubberFragment = gql`
  fragment Hubber on Hubber {
    id
    fullName
    hub
    jobTitle
    joinDate
    contractEndDate
    resignDate
    employmentType
    contractType
    status
    email
    phoneNumber
  }
`;

export const hubberWithDirectManagerFragment = gql`
  fragment HubberWithDirectManager on Hubber {
    id
    ...Hubber
    directManager {
      id
      name
    }
  }
  ${hubberFragment}
`;
