import { Banner, Link, Typography } from 'glints-aries/lib/@next';

import { BannerContent } from '../AwaitingPaymentTab/AwaitingPaymentTabStyle';
import { EGIRO_NEXT_COLLECTION_DAY } from '../AwaitingPaymentTab/constants';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { Role } from '@/modules/CompanyProfile/RolesPermissionsTab/interfaces';

export const EGIROBanner = () => {
  const { userInfo } = useAuthContext();

  return (
    <Banner
      type="fixed"
      status="success"
      dismissable={false}
      className="egiro-banner"
    >
      <BannerContent>
        <span>
          Your eGIRO is active for monthly payments on the
          {` ${EGIRO_NEXT_COLLECTION_DAY}. `}
        </span>
        {userInfo?.contact.roles[0]?.id !== Role.Viewer && (
          <Link url="/company-settings/auto-payment" removeUnderline={true}>
            <Typography as="span" variant="body2">
              Learn More
            </Typography>
          </Link>
        )}
      </BannerContent>
    </Banner>
  );
};
