import {
  type EmploymentStatus,
  type TimeOffCategory,
  type TimeOffRequestStatus,
} from '../../generated/graphql';

type Hubber = {
  id: string;
  fullName: string;
  hub: string;
  status: EmploymentStatus;
};

type TimeOffBalance = {
  category: TimeOffCategory;
  hubberId: string;
  name: string | null;
  balance?: string;
  used?: string;
  available?: string;
};

type TimeOffRequestApproval = {
  id: string;
  remarks?: string | null;
  contact?: {
    id: string;
    name: string;
  } | null;
  createdAt: string;
  updatedAt: string;
};
export interface TimeOffRequest {
  id: string;
  hubberId: string;
  noteToManager?: string | null;
  requestStatus: TimeOffRequestStatus;
  requestedAt: string;
  timeoffCode: string;
  timeoffDuration: string;
  timeoffEndDate: string;
  timeoffStartDate: string;
  hubber: Hubber;
  approval?: TimeOffRequestApproval | null;
  balance: TimeOffBalance;
}

export const timeOffTrackerTab = {
  TIME_OFF_REQUESTS: 0,
  TIME_OFF_BALANCE: 1,
} as const;

type TypeTimeOffTrackerTab = typeof timeOffTrackerTab;
export type timeOffTrackerTab =
  TypeTimeOffTrackerTab[keyof TypeTimeOffTrackerTab];

export const tabIndexMapping: Record<number, string> = {
  [timeOffTrackerTab.TIME_OFF_BALANCE]: 'time-off-balance',
  [timeOffTrackerTab.TIME_OFF_REQUESTS]: 'time-off-requests',
};

export type ShowAlertType = {
  status?: 'success' | 'error';
  content: string;
  shouldShow?: boolean;
};
