import { Spinner } from 'glints-aries/lib/@next';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

const LoadingStateContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingState = () => (
  <LoadingStateContainer>
    <Spinner fill={Blue.S99} size="large" label="Preparing..." />
  </LoadingStateContainer>
);
