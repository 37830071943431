export const getInitials = (name?: string) => {
  if (!name) return '';

  const splitName = name.split(' ');
  const firstInitial = splitName[0]?.[0] || '';
  const lastInitial =
    splitName.length > 1 ? splitName[splitName.length - 1]?.[0] || '' : '';

  return `${firstInitial}${lastInitial}`.toUpperCase();
};
