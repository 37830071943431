import { useState } from 'react';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { getGraphqlClient } from '../../clients/graphql';
import { PAGE_SIZE } from '../../components/TablePagination/TablePagination';
import {
  type EmploymentStatus,
  useGetAllHubbersQuery,
} from '../../generated/graphql';
import { useDebounce } from '../../hooks/debounce';
import HubbersTable from './components/ManagedTalentsTable';
import { StatusFilterSelect } from './components/StatusFilterSelect';
import { statusFilterOptions } from './constants';
import { type AllHubbersQuery } from './interface';
import {
  Container,
  FilterContainer,
  SearchInput,
  SearchInputContainer,
  StatusFilterContainer,
} from './ManagedTalentsPageStyle';
import { useGraphqlError } from '@/hooks/useGraphqlError';

const ManagedTalentsPage = () => {
  const graphqlClient = getGraphqlClient();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string[]>([
    statusFilterOptions[0].value,
  ]);
  const [hideStatusBadge, setHideStatusBadge] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const variables = {
    page,
    pageSize: PAGE_SIZE,
    name: debouncedSearchValue,
    status: selectedStatus as EmploymentStatus[],
  };

  const { isLoading, data, error, isFetching } = useGetAllHubbersQuery<
    AllHubbersQuery,
    Error
  >(graphqlClient, variables, {});

  const handleResetSearch = () => {
    setSearchValue('');
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSearchChange = (value: string) => {
    setPage(1);
    setSearchValue(value);
  };

  const updateHideStatusBadge = (hide: boolean) => {
    setHideStatusBadge(hide);
  };

  const handleSelectStatus = ({ value }: { value: string }) => {
    let updatedSelectedStatus = [];

    if (selectedStatus.includes(value)) {
      updatedSelectedStatus = selectedStatus.filter(
        (option) => option !== value,
      );
      setSelectedStatus(updatedSelectedStatus);
      setPage(1);
    } else {
      updatedSelectedStatus = [...selectedStatus, value];
      setSelectedStatus(updatedSelectedStatus);
      setPage(1);
    }

    if (updatedSelectedStatus.length < statusFilterOptions.length) {
      setHideStatusBadge(false);
    }
  };

  useGraphqlError([error]);

  return (
    <Container>
      <Typography variant="headline6" as="span" color={Neutral.B18}>
        Employees
      </Typography>
      <div>
        <FilterContainer>
          <SearchInputContainer>
            <SearchInput
              value={searchValue}
              prefix={<Icon name="ri-search" />}
              placeholder="Search for Name"
              onChange={handleSearchChange}
            />
          </SearchInputContainer>
          <StatusFilterContainer>
            <StatusFilterSelect
              handleSelectStatus={handleSelectStatus}
              selectedStatus={selectedStatus}
              updateHideStatusBadge={updateHideStatusBadge}
              hideBadge={hideStatusBadge}
            />
          </StatusFilterContainer>
        </FilterContainer>
        <HubbersTable
          noSearchResults={
            Boolean(debouncedSearchValue) &&
            !isFetching &&
            !data?.hubbers?.data.length
          }
          hubbers={error ? [] : data?.hubbers?.data}
          loading={isLoading || isFetching}
          currentPage={data?.hubbers?.pageNo || 1}
          pageSize={data?.hubbers?.pageSize || 0}
          totalItems={data?.hubbers?.total || 0}
          onPageChanged={handlePageChange}
          onResetSearch={handleResetSearch}
        />
      </div>
    </Container>
  );
};

export default ManagedTalentsPage;
