import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { styled } from 'styled-components';

export const StyledTextArea = styled.div`
  background-color: ${Neutral.B100};

  &[aria-disabled='true'] {
    background-color: unset;
    textarea {
      color: ${Neutral.B85};
      background-color: unset;
    }
  }
`;
