import { useEffect, useRef, useState } from 'react';

import { PLACEMENT, type PlacementType } from './constants';
import * as Styled from './styled.sc';

export interface PopoverProps {
  content: React.ReactNode | (() => React.ReactNode);
  placement?: PlacementType;
  padding?: string;
  zIndex?: number;
  children: React.ReactNode;
}

export const Popover = ({
  content,
  placement = PLACEMENT.bottom,
  padding,
  zIndex,
  children,
}: PopoverProps) => {
  const childrenRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        childrenRef.current &&
        !childrenRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <Styled.PopoverContainer>
      <div onClick={(e) => handleClick(e)} ref={childrenRef}>
        {children}
      </div>
      <Styled.PopoverContent
        ref={popoverRef}
        visible={isOpen}
        placement={placement}
        padding={padding}
        zIndex={zIndex}
        onClick={(e) => e.stopPropagation()}
      >
        {typeof content === 'function' ? content() : content}
      </Styled.PopoverContent>
    </Styled.PopoverContainer>
  );
};
