import { useEffect, useState } from 'react';
import { InlineError, type TextInputProps } from 'glints-aries/lib/@next';
import { Neutral, Red } from 'glints-aries/lib/@next/utilities/colors';
import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

import {
  FormTextInputContainer,
  LabelContainer,
  StyledLabel,
  StyledTextInput,
} from './FormTextInputStyle';

interface FormTextInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<TextInputProps, 'value' | 'defaultValue' | 'name'> {
  label?: string;
  required?: boolean;
  value?: string | null;
  apiError?: string | null;
  height?: string;
}

export const TextInputError = ({
  errorMessage = 'An unknown error has occurred',
}: {
  errorMessage?: string;
}) => <InlineError text={errorMessage} />;

export const FormTextInput = <T extends FieldValues>({
  control,
  label,
  name,
  required = false,
  value,
  apiError,
  height = '86px',
  ...props
}: FormTextInputProps<T>) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.message);
    }

    if (apiError) {
      setErrorMessage(apiError);
    }
  }, [error, apiError]);

  return (
    <FormTextInputContainer height={height}>
      {label && (
        <LabelContainer>
          <StyledLabel variant="subtitle2" color={Neutral.B18}>
            {label}
          </StyledLabel>
          {required && (
            <StyledLabel variant="subtitle2" color={Red.B93}>
              *
            </StyledLabel>
          )}
        </LabelContainer>
      )}
      <StyledTextInput
        {...props}
        onChange={field.onChange} // send value to hook form
        onBlur={field.onBlur} // notify when input is touched/blur
        value={value || field.value} // input value
        name={field.name} // send down the input name
        ref={field.ref} // send input ref, so we can focus on input when error appear
      />
      {(error || apiError) && <TextInputError errorMessage={errorMessage} />}
    </FormTextInputContainer>
  );
};
