import { Badge, Icon, Typography } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import * as Styled from './styled.sc';
import { Avatar } from '@/components/atoms/Avatar/Avatar';
import { type ReportingLinesTabContactFragment } from '@/generated/graphql';
import { useEllipsisTextStyle } from '@/styles/ellipsisText';

interface ContactListItemProps {
  contact: ReportingLinesTabContactFragment;
  reportLength: number;
  active: boolean;
  onClick: (contactId: string) => void;
}

export const ContactListItem = ({
  contact,
  reportLength,
  active,
  onClick,
}: ContactListItemProps) => {
  const ellipsisTextCss = useEllipsisTextStyle();

  return (
    <Styled.ContactListItem active={active} onClick={() => onClick(contact.id)}>
      <Avatar
        name={contact.name}
        color={active ? Neutral.B100 : Neutral.B18}
        backgroundColor={active ? Blue.S99 : Blue.S08}
      />

      <Styled.Content>
        <Typography
          as="div"
          variant="caption"
          color={Neutral.B18}
          style={ellipsisTextCss}
        >
          {contact.name}
        </Typography>
        <Typography as="div" variant="overline" color={Neutral.B40}>
          {contact.roles[0].name}
        </Typography>
      </Styled.Content>
      <Styled.Indicator>
        {active ? (
          <Icon name="ri-arrow-m-right-line" height={20} fill={Neutral.B40} />
        ) : (
          <Badge status={reportLength > 0 ? 'information' : 'neutral'}>
            {reportLength}
          </Badge>
        )}
      </Styled.Indicator>
    </Styled.ContactListItem>
  );
};
