import { Box } from 'glints-aries';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import * as Styled from './styled.sc';

export interface ToolButtonProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
}

export const ToolButton = ({
  icon,
  title,
  description,
  onClick,
}: ToolButtonProps) => (
  <Styled.Button onClick={onClick}>
    <Styled.ButtonSVG>{icon}</Styled.ButtonSVG>
    <Styled.ButtonContent>
      <Box pb={4}>
        <Typography as="div" variant="body2" color={Neutral.B18}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography as="div" variant="subtitle2" color={Neutral.B40}>
          {description}
        </Typography>
      </Box>
    </Styled.ButtonContent>
    <Styled.ButtonArrowIcon>
      <Icon name="ri-arrow-m-right-line" width="36px" fill={Neutral.B40} />
    </Styled.ButtonArrowIcon>
  </Styled.Button>
);
