import { Badge, Breakpoints, TextInput } from 'glints-aries/lib/@next';
import {
  space8,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

const CUSTOM_BREAKPOINT = '800px';

export const Container = styled.div`
  height: calc(100vh - 208px);

  @media (max-width: ${Breakpoints.large}) {
    height: calc(100vh - 231px);
  }
`;

export const SearchInput = styled(TextInput)`
  width: 300px;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: ${space24} ${space16} ${space16} 0;
  flex-wrap: wrap;

  @media (max-width: ${Breakpoints.large}) {
    flex-wrap: wrap;
  }
`;

export const SearchInputContainer = styled.div`
  margin-right: ${space16};

  input {
    width: 200px;
  }

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
    margin-bottom: ${space16};
    margin-right: 0;

    input {
      width: 100%;
    }
  }
`;

export const StatusFilterButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space8};
  width: 100%;
`;

export const StatusFilterLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: ${space8};
  align-items: center;
`;

export const StatusFilterRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: ${space8};
  align-items: center;
`;

export const StatusFilterContainer = styled.div`
  width: 250px;

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    width: 100%;
  }
`;

export const StyledBadge = styled(Badge)`
  &[data-hidden='true'] {
    visibility: hidden;
  }
`;
