import { Badge, Banner, Modal } from 'glints-aries/lib/@next';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import {
  space8,
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const BankCards = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: ${space24};

  .card-heading-container {
    .card-custom-heading {
      display: flex;
      gap: ${space8};
      align-items: center;
    }
  }

  .card-actions-wrapper {
    align-items: flex-start;
  }

  @media (max-width: ${BREAKPOINTS.large}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CardHeadingText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardLi = styled.li`
  list-style: none;
  margin-bottom: ${space24};

  &:not(:nth-child(3n)) {
    margin-right: ${space24};
  }

  @media (max-width: ${BREAKPOINTS.large}) {
    &:not(:nth-child(3n)) {
      margin-right: 0;
    }

    &:nth-child(odd) {
      margin-right: ${space24};
    }
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    &:nth-child(odd) {
      margin-right: 0;
    }
  }
`;

export const SetupWithBankContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${space32};
`;

export const BankContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
  margin-top: 120px;

  p {
    margin: 0;
  }

  @media (max-width: ${BREAKPOINTS.large}) {
    margin-top: 80px;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    margin-top: 60px;
  }
`;

export const BankInstructions = styled.div`
  width: 624px;
  text-align: center;
`;

export const RedirectContainer = styled.div`
  margin-bottom: ${space16};
  animation: shimmering 600ms ease-in-out 0s 8 alternate;

  @keyframes shimmering {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space16};
`;

export const CardSectionContainer = styled.div`
  display: flex;
  gap: ${space16};
`;

export const CardSectionContent = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;
  }
`;

export const StyledBadge = styled(Badge)`
  height: fit-content;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-right: auto;
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    width: 620px;
  }
`;

export const DisconnectModalActions = styled.div`
  display: flex;
  gap: ${space8};
`;

export const EgiroDescription = styled.div`
  a {
    margin: 0;
    color: ${Blue.S99};
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: rgba(0, 86, 140, 0.9);
    }

    &:active {
      color: ${Blue.S100};
    }
  }
`;

export const AutoPaymentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${space24};
`;

export const OtherBanksTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${space24} 0;
  gap: ${space24};
`;

export const SetupEgiroContainer = styled.div`
  margin: ${space24} 0;
`;

export const ConnectedBankBanner = styled(Banner)`
  min-height: auto;
  margin-bottom: ${space24};
`;
