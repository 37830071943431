import { Flex } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { TimeOffCategory } from '@/generated/graphql';
import { timeOffTypeMapping } from '@/modules/TimeOff/constants';

export interface TimeOffTypeTextProps {
  name: string;
  category: TimeOffCategory;
}

export const TimeOffTypeText = ({
  name,
  category,
}: {
  name: string;
  category: TimeOffCategory;
}) => {
  if (category === TimeOffCategory.Others) {
    return (
      <Flex flexDirection="column" alignItems="flex-start">
        <Typography as="span" variant="body1" color={Neutral.B18}>
          {timeOffTypeMapping[category]}:
        </Typography>
        <Typography as="span" variant="body1" color={Neutral.B18}>
          {name}
        </Typography>
      </Flex>
    );
  }

  return (
    <Typography as="span" variant="body1" color={Neutral.B18}>
      {timeOffTypeMapping[category]}
    </Typography>
  );
};
