import { useEffect, useState } from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral, Red } from 'glints-aries/lib/@next/utilities/colors';
import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

import { DEFAULT_DROPDOWN_PLACEHOLDER, DEFAULT_PLACEHOLDER } from './constants';
import { LabelContainer } from './styled.sc';
import { InlineError } from '@/components/atoms/InlineError/InlineError';
import {
  Selector,
  type SelectorProps,
} from '@/components/atoms/Selector/Selector';

interface FormSelectProps<T extends FieldValues>
  extends Omit<SelectorProps, 'onSelect' | 'name' | 'width'>,
    UseControllerProps<T> {
  inputValue?: string;
  label?: string;
  placeholder?: string;
  hasError?: boolean;
  helpText?: string;
  onBlur?: () => void;
  onClose?: () => void;
  onSelect?: (value: string) => void;
  onClear?: () => void;
  onInputChange?: (value: string) => void;
  required?: boolean;
  apiError?: string;
  width?: string;
}

export const FormSelect = <T extends FieldValues>({
  apiError,
  control,
  label,
  name,
  required,
  placeholder = DEFAULT_PLACEHOLDER,
  dropdownPlaceholder,
  helpText,
  onBlur,
  onClose,
  onClear,
  onSelect,
  onInputChange,
  searchable = false,
  options,
  width = '100%',
  ...props
}: FormSelectProps<T>) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState('');

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: props.rules,
  });

  const handleInputChange = (value: string) => {
    if (onInputChange) {
      onInputChange(value);

      return;
    }
    setInputValue(value);
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
      return;
    }

    field.onChange(null);
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.message);
    }

    if (apiError) {
      setErrorMessage(apiError);
    }
  }, [error, apiError]);

  const defaultDropdownPlaceholder = inputValue
    ? DEFAULT_DROPDOWN_PLACEHOLDER.noResult
    : DEFAULT_DROPDOWN_PLACEHOLDER.typeIn;

  return (
    <div>
      {label && (
        <LabelContainer>
          <Typography as="span" variant="subtitle2" color={Neutral.B18}>
            {label}
          </Typography>
          {required && (
            <Typography as="span" variant="subtitle2" color={Red.B93}>
              *
            </Typography>
          )}
        </LabelContainer>
      )}
      <Selector
        {...props}
        selectedValues={[field.value]}
        onSelect={({ value }: { value: string }) => {
          if (value) {
            field.onChange(value);
            onSelect?.(value);
          }
        }}
        onClear={handleClear}
        onClose={onClose}
        onBlur={onBlur || field.onBlur}
        placeholder={placeholder}
        hasError={Boolean(error)}
        searchable={searchable}
        searchableProps={
          searchable
            ? {
                inputValue,
                onInputChange: (value: string) => handleInputChange(value),
              }
            : undefined
        }
        dropdownPlaceholder={dropdownPlaceholder ?? defaultDropdownPlaceholder}
        options={options}
        width={width}
        removable={true}
        clearOnSelect={true}
      />
      {helpText && (
        <Typography as="span" variant="subtitle2" color={Neutral.B40}>
          {helpText}
        </Typography>
      )}
      {(error || apiError) && <InlineError errorMessage={errorMessage} />}
    </div>
  );
};
