import { Box } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { Banner } from '@/components/molecules/SideSheet/styled.sc';

export const AutoApprovedBanner = ({ startDate }: { startDate: string }) => (
  <Box mb={12}>
    <Banner status="success" dismissable={false}>
      <Typography as="span" variant="body1" color={Neutral.B18}>
        This time off request was automatically approved on its start date of{' '}
        {<FormattedDate date={startDate} textVariant="body1" />}.
      </Typography>
    </Banner>
  </Box>
);
