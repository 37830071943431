import { space2 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  gap: ${space2};
`;

export const Label = styled.span`
  cursor: pointer;
  text-align: left;
`;
