import { type SortOrder as AntdSortOrder } from 'antd/es/table/interface';

import { SortOrder } from '@/generated/graphql';

export const ANTD_SORT_ORDER: Record<SortOrder, AntdSortOrder> = {
  [SortOrder.Asc]: 'ascend',
  [SortOrder.Desc]: 'descend',
} as const;

export const ANTD_SORT_ORDER_MAP: Record<
  NonNullable<AntdSortOrder>,
  SortOrder
> = {
  ascend: SortOrder.Asc,
  descend: SortOrder.Desc,
} as const;
