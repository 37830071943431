import { Flex } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space4, space16 } from 'glints-aries/lib/@next/utilities/spacing';

import { ActionPopoverButton } from './ActionPopoverButton';
import * as Styled from './styled.sc';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { type HubberFragment } from '@/generated/graphql';
import { useEllipsisTextStyle } from '@/styles/ellipsisText';

export interface ReportingHubberCardProps {
  hubber: HubberFragment;
  isAllowForActions: boolean;
  onClick: () => void;
  onRemove: () => void;
}

export const ReportingHubberCard = ({
  hubber,
  isAllowForActions,
  onClick,
  onRemove,
}: ReportingHubberCardProps) => {
  const ellipsisTextCss = useEllipsisTextStyle();

  return (
    <Styled.Card onClick={onClick}>
      <Flex flexDirection="column" gap={space16}>
        <Flex flexDirection="column" gap={space4}>
          <Typography as="span" variant="caption" color={Neutral.B18}>
            {hubber.fullName}
          </Typography>
          <Typography
            as="span"
            variant="subtitle2"
            color={Neutral.B40}
            style={ellipsisTextCss}
          >
            {hubber.jobTitle}
          </Typography>
        </Flex>
        <HubLocationText hubCode={hubber.hub as HubCode} />
      </Flex>
      {isAllowForActions && (
        <Styled.CardActionButton>
          <ActionPopoverButton onRemove={onRemove} />
        </Styled.CardActionButton>
      )}
    </Styled.Card>
  );
};
