import { type Role } from '../RolesPermissionsTab/interfaces';
import { rolesAllowedForActions } from './constants';
import {
  type Contact,
  type ReportingLinesTabContactFragment,
} from '@/generated/graphql';

// sorted by the their names in alphabetically order,
// and if the user’s name appears on the list, their name should be placed on top of the list
export const sortManagerContacts = (
  contacts: ReportingLinesTabContactFragment[],
  currentUser?: Pick<Contact, 'id'>,
) => {
  let currentUserContact: ReportingLinesTabContactFragment | null = null;
  const remainingContacts = contacts.filter((contact) => {
    if (contact.id === currentUser?.id) {
      currentUserContact = contact;
      return false;
    }
    return true;
  });

  const sortedContacts = remainingContacts.sort((a, b) => {
    const firstCharA = a.name.charAt(0).toLowerCase();
    const firstCharB = b.name.charAt(0).toLowerCase();

    if (firstCharA < firstCharB) {
      return -1;
    }
    if (firstCharA > firstCharB) {
      return 1;
    }
    return 0;
  });

  if (currentUserContact) {
    sortedContacts.unshift(currentUserContact);
  }

  return sortedContacts;
};

export const isRoleAllowForActions = (role: Role) =>
  rolesAllowedForActions.includes(role);
