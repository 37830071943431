import { type ColumnFilterItem } from 'antd/es/table/interface';
import { flatten, invertBy } from 'lodash-es';

import {
  HUB_CODE_LABEL,
  type HubCode,
} from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';

export const generateHubFiltersOptions = (
  companyHubs: string[],
): ColumnFilterItem[] => {
  if (companyHubs.length === 0) return [];

  const options: ColumnFilterItem[] = [];
  const addedLabels: string[] = [];

  // ignore the same label twice. e.g. ID and BT use the same label, we don't want to show it twice
  companyHubs.forEach((hubCode) => {
    const label = HUB_CODE_LABEL[hubCode as HubCode];

    if (!addedLabels.includes(label)) {
      options.push({
        text: <HubLocationText hubCode={hubCode as HubCode} />,
        value: label,
      });
      addedLabels.push(label);
    }
  });

  return options;
};

// Invert the HUB_CODE_LABEL object to group hub codes by their labels.
// For example, it transforms the object to have 'ID' as a key with an array of hub codes that map to 'ID', e.g., { ID: ['BT', 'ID'] }.
const invertedHubCodeLabel = invertBy(HUB_CODE_LABEL);
export const generateFilteredHubs = (hubs: string[]): string[] => {
  const filteredHubs = hubs.map((hub) => invertedHubCodeLabel[hub]);
  return flatten(filteredHubs);
};
