import type { SelectorOptGroup, SelectorOption } from './types';

const isOptGroup = (
  props: (SelectorOption | SelectorOptGroup)[],
): props is SelectorOptGroup[] => props.length > 0 && 'options' in props[0];
const isOption = (
  props: (SelectorOption | SelectorOptGroup)[],
): props is SelectorOption[] =>
  props.length > 0 && 'label' in props[0] && 'value' in props[0];

function getSelectedItemsFromOptions(
  _options: (SelectorOption | SelectorOptGroup)[],
  selectedValues: string[],
  defaultSelectedValues?: string[],
): SelectorOption[] {
  let optionItems: SelectorOption[] = [];
  if (isOptGroup(_options)) {
    for (const opt of _options) {
      optionItems = optionItems.concat(opt.options);
    }
  } else if (isOption(_options)) {
    optionItems = [..._options];
  }

  const selected =
    selectedValues === undefined ? defaultSelectedValues : selectedValues;
  return optionItems.filter((item) => selected?.includes(item.value));
}

function buildDisplayValue(
  options: (SelectorOption | SelectorOptGroup)[],
  selectedValues: string[],
) {
  return getSelectedItemsFromOptions(options, selectedValues)
    .map((o) => o.label)
    .join(', ');
}

const filterOptions = (
  text: string,
  options: (SelectorOption | SelectorOptGroup)[],
): (SelectorOption | SelectorOptGroup)[] => {
  if (text === '') {
    return options;
  }
  const filteredOptions: (SelectorOption | SelectorOptGroup)[] = [];

  if (isOptGroup(options)) {
    for (const item of options) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const filteredSubOptions = filterOptions(
        text,
        item.options,
      ) as SelectorOption[];
      if (filteredSubOptions.length > 0) {
        filteredOptions.push({
          ...item,
          options: filteredSubOptions,
        });
      }
    }
  } else if (isOption(options)) {
    for (const option of options) {
      if (
        option.label.toLowerCase().includes(text.toLowerCase()) ||
        option.value.toString().toLowerCase().includes(text.toLowerCase())
      ) {
        filteredOptions.push(option);
      }
    }
  }

  return filteredOptions;
};

export {
  buildDisplayValue,
  filterOptions,
  getSelectedItemsFromOptions,
  isOptGroup,
  isOption,
};
