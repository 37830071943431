import { type BadgeStatusVariant } from 'glints-aries/lib/@next/Badge';
import { type Option } from 'glints-aries/lib/@next/Menu';

import { EmploymentStatus, ExpenseClaimsStatus } from '@/generated/graphql';
import { Role } from '@/modules/CompanyProfile/RolesPermissionsTab/interfaces';

export const TALENT_OPTIONS: Option[] = [
  {
    id: '1',
    label: 'Active',
    value: EmploymentStatus.Active,
  },
  {
    id: '2',
    label: 'End of Contract',
    value: EmploymentStatus.ContractEnded,
  },
  {
    id: '3',
    label: 'Departed',
    value: EmploymentStatus.ResignedOrTerminated,
  },
];

export const pendingStatus = 'PENDING';
type PENDING_STATUS = typeof pendingStatus;

export const claimApprovalStatusBadgeMapping: Record<
  ExpenseClaimsStatus | PENDING_STATUS,
  BadgeStatusVariant
> = {
  PENDING: 'warning',
  [ExpenseClaimsStatus.Approved]: 'success',
  [ExpenseClaimsStatus.Rejected]: 'critical',
};

export const CLAIM_APPROVAL_STATUS_TEXT: Record<
  ExpenseClaimsStatus | PENDING_STATUS,
  string
> = {
  PENDING: 'Pending',
  [ExpenseClaimsStatus.Approved]: 'Approved',
  [ExpenseClaimsStatus.Rejected]: 'Rejected',
};

export const PERMITTED_CLAIM_WRITE_ROLES = [
  Role.CompanyAdmin,
  Role.PeopleManager,
];

export const PAGE_SIZE = 20;

export const DEFAULT_BILLING_CURRENCY = 'SGD';
