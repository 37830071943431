export const BANK_TRANSFER_STEP = {
  reviewSummary: 1,
  viewInstructions: 2,
} as const;

export type BankTransferStepType =
  (typeof BANK_TRANSFER_STEP)[keyof typeof BANK_TRANSFER_STEP];

export const URL_INVOICE_PARAMETER_NAME = 'invoices';

export const PAYMENT_DUE_DATE_FORMAT = 'MMM dd, yyyy';
