import { SkeletonText } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  CardSectionDataContainer,
  StyledLabel,
  StyledValue,
} from './CardSectionDataStyle';

interface CardSectionDataProps {
  label?: React.ReactNode;
  children?: React.ReactNode | undefined;
  isLoading?: boolean;
}

export const CardSectionData = ({
  label,
  children,
  isLoading = false,
}: CardSectionDataProps) => {
  if (isLoading) return <SkeletonText rows={[{}, {}]} />;

  return (
    <CardSectionDataContainer>
      <StyledLabel variant="caption" color={Neutral.B40}>
        {label}
      </StyledLabel>
      <StyledValue variant="body1" color={children ? Neutral.B18 : Neutral.B85}>
        {!children || children === '' ? 'Unknown' : children}
      </StyledValue>
    </CardSectionDataContainer>
  );
};
