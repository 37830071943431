import { Box } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { COUNTRY_CODE, ESTIMATED_COST_RESULT_CONTENT } from '../constants';
import * as Styled from './styled.sc';
import { CURRENCY_TO_LOCALE, type CurrencyType } from '@/constants/currency';
import { type CostStructure } from '@/generated/graphql';
import { formatMoney } from '@/utils/formatString';

const computeAnnual = (monthlyAmount: string) => {
  const annualAmount = parseFloat(monthlyAmount) * 12;
  return annualAmount.toString();
};

export const ComputationResult = ({
  estimatedCost,
  paymentCurrency,
  isMonthly,
}: {
  estimatedCost: CostStructure;
  paymentCurrency: CurrencyType;
  isMonthly: boolean;
}) => {
  const renderDataRows = (
    estimatedCostChildren: CostStructure[],
    depth = 0,
  ): React.ReactNode =>
    estimatedCostChildren.map((e: CostStructure) => (
      <div key={e?.labelKey}>
        <Styled.DataRow borderBold={depth === 0}>
          <Styled.IndentLabel depth={depth}>
            <Typography as="span" variant="subtitle2" color={Neutral.B18}>
              {
                ESTIMATED_COST_RESULT_CONTENT[COUNTRY_CODE.indonesia][
                  e?.labelKey
                ]
              }
            </Typography>
          </Styled.IndentLabel>

          <Typography
            as="span"
            variant={depth === 0 ? 'caption' : 'subtitle2'}
            color={Neutral.B18}
          >
            {isMonthly
              ? formatMoney({
                  amount:
                    e?.total?.inCurrencies?.find(
                      (money) => money.currency === paymentCurrency,
                    )?.amount || '0.00',
                  locale: CURRENCY_TO_LOCALE[paymentCurrency],
                })
              : formatMoney({
                  amount:
                    computeAnnual(
                      e?.total?.inCurrencies?.find(
                        (money) => money.currency === paymentCurrency,
                      )?.amount,
                    ) || '0.00',
                  locale: CURRENCY_TO_LOCALE[paymentCurrency],
                })}
          </Typography>
        </Styled.DataRow>

        {e?.children?.length > 0 && (
          <div>{renderDataRows(e?.children, depth + 1)}</div>
        )}
      </div>
    ));

  return (
    <Box pt={8} pb={16} px={24}>
      {renderDataRows(estimatedCost.children)}

      <Styled.DataRow noBorder={true}>
        <Typography as="span" variant="body2" color={Neutral.B18}>
          {
            ESTIMATED_COST_RESULT_CONTENT[COUNTRY_CODE.indonesia][
              estimatedCost.labelKey
            ]
          }
        </Typography>
        <Styled.TotalLabel>
          <Typography as="span" variant="overline" color={Neutral.B40}>
            {paymentCurrency}
          </Typography>
          <Typography as="span" variant="body2" color={Neutral.B18}>
            {isMonthly
              ? formatMoney({
                  amount:
                    estimatedCost.total.inCurrencies.find(
                      (money) => money.currency === paymentCurrency,
                    )?.amount || '0.00',
                })
              : formatMoney({
                  amount:
                    computeAnnual(
                      estimatedCost.total.inCurrencies.find(
                        (money) => money.currency === paymentCurrency,
                      )?.amount,
                    ) || '0.00',
                })}
          </Typography>
        </Styled.TotalLabel>
      </Styled.DataRow>
    </Box>
  );
};
