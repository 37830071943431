import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { space4, space8 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const DateTableHeaderContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    margin-right: ${space4};

    svg {
      margin: 0;
    }
  }
`;

export const SortButton = styled.button`
  display: flex;
  align-items: center;
`;

export const TableContainer = styled.div`
  .data-table-container {
    border-radius: ${borderRadius8};
    box-shadow: 0px 12px 30px rgba(71, 71, 71, 0.1),
      0px -3px 15px -3px rgba(71, 71, 71, 0.05);

    table {
      border: none;

      tr:last-child {
        box-shadow: none;
      }

      .empty-state {
        pointer-events: none;
      }
    }
  }
`;

export const LoggedWorkHoursContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HoursContainer = styled.div`
  margin-right: ${space8};
`;

export const MinutesContainer = styled.div``;
