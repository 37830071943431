import { CLAIM_APPROVAL_STATUS_TEXT } from '../../../constants';
import { ALERT_STATUS } from '@/constants/alert';

export const EXPENSES_TABLE_KEY = {
  createdAt: 'createdAt',
  employee: 'employee',
  talentHub: 'talentHub',
  expenseCategory: 'expenseCategory',
  amount: 'amount',
  baseAmount: 'baseAmount',
  reportingManager: 'reportingManager',
  status: 'status',
  actions: 'actions',
} as const;

export const EXPENSES_TABLE_TITLE = {
  [EXPENSES_TABLE_KEY.createdAt]: 'Requested Time',
  [EXPENSES_TABLE_KEY.employee]: 'Employee',
  [EXPENSES_TABLE_KEY.talentHub]: 'TalentHub',
  [EXPENSES_TABLE_KEY.expenseCategory]: 'Expense Category',
  [EXPENSES_TABLE_KEY.amount]: 'Total Amount (Local)',
  [EXPENSES_TABLE_KEY.baseAmount]: 'Est. Total Amount (Base)',
  [EXPENSES_TABLE_KEY.reportingManager]: 'Reporting Manager',
  [EXPENSES_TABLE_KEY.status]: 'Status',
  [EXPENSES_TABLE_KEY.actions]: '',
} as const;

export const statusFiltersOptions = Object.entries(
  CLAIM_APPROVAL_STATUS_TEXT,
).map(([key, value]) => ({
  text: value,
  value: key,
}));

export const expenseClaimAlertContent = {
  [ALERT_STATUS.success]: 'Claim status updated.',
} as const;
