import { useEffect, useLayoutEffect, useState } from 'react';
import { Breakpoints, Tabs, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useLocation, useNavigate } from 'react-router-dom';

import { tabs } from './constants';
import { tabIndexMapping, timeOffTrackerTab } from './interfaces';
import * as Styled from './styled.sc';
import { TimeOffBalance } from './TimeOffBalanceTab/TimeOffBalanceTab';
import TimeOffRequestsTab from './TimeOffRequestsTab/TimeOffRequestsTab';

const TimeOffPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(
    pathname === '/time-off/time-off-requests'
      ? timeOffTrackerTab.TIME_OFF_REQUESTS
      : timeOffTrackerTab.TIME_OFF_BALANCE,
  );

  const handleTabSelect = (index: number) => {
    setActiveTab(index);
    navigate(`/time-off/${tabIndexMapping[index]}`);
  };

  const tabContents = [
    <TimeOffRequestsTab key="time-off-requests-tab" />,
    <TimeOffBalance key="time-off-balance-tab" />,
  ];

  useLayoutEffect(() => {
    const BREAKPOINT = parseInt(Breakpoints.large.slice(0, 3));
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= BREAKPOINT);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setActiveTab(
      pathname === '/time-off/time-off-requests'
        ? timeOffTrackerTab.TIME_OFF_REQUESTS
        : timeOffTrackerTab.TIME_OFF_BALANCE,
    );
  }, [pathname]);

  return (
    <Styled.Container>
      <Typography as="span" variant="headline6" color={Neutral.B18}>
        Time Off
      </Typography>
      <Tabs
        tabs={tabs}
        selected={activeTab}
        onSelected={handleTabSelect}
        fitted={isSmallScreen}
      >
        {tabContents[activeTab]}
      </Tabs>
    </Styled.Container>
  );
};

export default TimeOffPage;
