import { type ReactNode } from 'react';
import { Spinner, Tooltip, Typography } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import * as Styled from './styled.sc';

const DEFAULT_TOOLTIP_CONTENT =
  'The file is still processing. Please check back in a few minutes.';

export interface NotReadyInvoiceTextProps {
  referenceNumber: string;
  tooltipContent?: ReactNode;
}

export const NotReadyInvoiceText = ({
  referenceNumber,
  tooltipContent = DEFAULT_TOOLTIP_CONTENT,
}: NotReadyInvoiceTextProps) => (
  <Styled.NotReadyInvoice>
    <Tooltip content={tooltipContent} preferredPosition="top-right">
      <Spinner fill={Blue.S99} size="small" />
    </Tooltip>
    <Typography as="span" variant="subtitle2" color={Neutral.B40}>
      {referenceNumber}
    </Typography>
  </Styled.NotReadyInvoice>
);
