import { useState } from 'react';
import { useAlert } from 'glints-aries/lib/@next';

import { PAGE_SIZE, TALENT_OPTIONS } from '../constants';
import { TIME_OFF_REQUESTS_TABLE_KEY } from './components/TimeOffRequestsTable/constants';
import { TimeOffRequestsTable } from './components/TimeOffRequestsTable/TimeOffRequestsTable';
import { buildArraySortedInfo } from './helpers';
import { getGraphqlClient } from '@/clients/graphql';
import { ExportPopoverButton } from '@/components/atoms/ExportPopoverButton/ExportPopoverButton';
import {
  type TableFilters,
  type TableOnChange,
  type TableSorts,
} from '@/components/atoms/GlintsAntdTable/types';
import { ALERT_STATUS, exportFileAlertMessages } from '@/constants/alert';
import { EXPORT_SCOPE, type ExportScope } from '@/constants/exportFile';
import { ANTD_SORT_ORDER } from '@/constants/sortOrder';
import {
  type EmploymentStatus,
  type ExportTimeoffRequestsMutation,
  type ExportTimeoffRequestsMutationVariables,
  type GetCompanyHubberHubsQuery,
  type GetTimeOffRequestsQuery,
  SortOrder,
  type TimeOffRequestStatus,
  useExportTimeoffRequestsMutation,
  useGetCompanyHubberHubsQuery,
  useGetTimeOffRequestsQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { StatusFilterSelect } from '@/modules/ManagedTalents/components/StatusFilterSelect';
import { statusFilterOptions } from '@/modules/ManagedTalents/constants';
import * as PageLayoutStyled from '@/styles/pageLayout.sc';
import { isFilteredInfoEmpty } from '@/utils/glintsAntdTable';
import { generateFilteredHubs } from '@/utils/talentHub';

const TimeOffRequstsTab = () => {
  const graphqlClient = getGraphqlClient();
  const { open: openAlert } = useAlert();

  const [page, setPage] = useState(1);
  const [selectedTalent, setSelectedTalent] = useState<string[]>([
    TALENT_OPTIONS[0].value,
  ]);
  const [hideStatusBadge, setHideStatusBadge] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState<TableFilters>({
    [TIME_OFF_REQUESTS_TABLE_KEY.employee]: null,
    [TIME_OFF_REQUESTS_TABLE_KEY.talentHub]: null,
    [TIME_OFF_REQUESTS_TABLE_KEY.reportingManager]: null,
    [TIME_OFF_REQUESTS_TABLE_KEY.requestStatus]: null,
  });
  const [sortedInfo, setSortedInfo] = useState<TableSorts[]>([
    {
      columnKey: TIME_OFF_REQUESTS_TABLE_KEY.requestedTime,
      order: ANTD_SORT_ORDER[SortOrder.Desc],
    },
    {
      columnKey: TIME_OFF_REQUESTS_TABLE_KEY.startDate,
      order: null,
    },
    {
      columnKey: TIME_OFF_REQUESTS_TABLE_KEY.endDate,
      order: null,
    },
  ]);

  const isFiltering = !isFilteredInfoEmpty(filteredInfo);

  const getTimeOffRequestsVariables = {
    page,
    pageSize: PAGE_SIZE,
    hubberName:
      filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.employee]?.toString() || '',
    sorts: buildArraySortedInfo(sortedInfo),
    hubberStatus: selectedTalent as EmploymentStatus[],
    requestStatus: filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.requestStatus] as
      | TimeOffRequestStatus[]
      | undefined,
    hub: filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.talentHub]?.length
      ? generateFilteredHubs(
          filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.talentHub] as string[],
        )
      : undefined,
    directManagerIds: filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.reportingManager]
      ?.length
      ? filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.reportingManager]
      : undefined,
  };

  const {
    isLoading: isLoadingTimeOffRequests,
    data: timeOffRequestsData,
    isFetching: isFetchingTimeOffRequests,
    error: getTimeoffRequestsError,
  } = useGetTimeOffRequestsQuery<GetTimeOffRequestsQuery, Error>(
    graphqlClient,
    getTimeOffRequestsVariables,
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const { data: companyData } = useGetCompanyHubberHubsQuery<
    GetCompanyHubberHubsQuery,
    Error
  >(graphqlClient);

  const { mutate: exportMutate, error: exportTimeoffRequestsError } =
    useExportTimeoffRequestsMutation<Error, ExportTimeoffRequestsMutation>(
      graphqlClient,
    );

  const handleTalentSelect = ({ value }: { value: string }) => {
    let updatedSelectedStatus = [];
    if (selectedTalent) {
      if (selectedTalent.includes(value)) {
        updatedSelectedStatus = selectedTalent.filter(
          (option) => option !== value,
        );
        setSelectedTalent(updatedSelectedStatus);
        setPage(1);
      } else {
        updatedSelectedStatus = [...selectedTalent, value];
        setSelectedTalent(updatedSelectedStatus);
        setPage(1);
      }
      if (updatedSelectedStatus?.length < statusFilterOptions?.length) {
        setHideStatusBadge(false);
      }
    }
  };

  const updateHideStatusBadge = (hide: boolean) => {
    setHideStatusBadge(hide);
  };

  const handleTableChange: TableOnChange = (_, filters, sorter) => {
    setFilteredInfo(filters as TableFilters);
    setSortedInfo(sorter as TableSorts[]);
    setPage(1);
  };

  const handleExport = ({ scope }: { scope: ExportScope }) => {
    let exportVariables: ExportTimeoffRequestsMutationVariables = {};

    if (scope === EXPORT_SCOPE.filtered) {
      exportVariables = {
        hubberName:
          filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.employee]?.toString() || '',
        hubberStatus: selectedTalent as EmploymentStatus[],
        requestStatus: filteredInfo[
          TIME_OFF_REQUESTS_TABLE_KEY.requestStatus
        ] as TimeOffRequestStatus[] | undefined,
        hub: filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.talentHub]?.length
          ? generateFilteredHubs(
              filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.talentHub] as string[],
            )
          : undefined,
        directManagerIds: filteredInfo[
          TIME_OFF_REQUESTS_TABLE_KEY.reportingManager
        ]?.length
          ? filteredInfo[TIME_OFF_REQUESTS_TABLE_KEY.reportingManager]
          : undefined,
      };
    }

    exportMutate(exportVariables, {
      onSuccess: (data) => {
        openAlert({
          content: exportFileAlertMessages[ALERT_STATUS.success],
          status: ALERT_STATUS.success,
          zIndex: 1200,
          position: {
            top: '24px',
          },
        });

        const linkElement = document.createElement('a');
        linkElement.href = data?.exportTimeoffRequests?.fileUrl;
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
      },
      onError: () => {
        openAlert({
          content: exportFileAlertMessages[ALERT_STATUS.error],
          status: ALERT_STATUS.error,
          zIndex: 1200,
          position: {
            top: '24px',
          },
        });
      },
    });
  };

  useGraphqlError([getTimeoffRequestsError, exportTimeoffRequestsError]);

  return (
    <PageLayoutStyled.Container>
      <PageLayoutStyled.Header dense={true}>
        <PageLayoutStyled.Toolbar>
          <StatusFilterSelect
            selectedStatus={selectedTalent}
            handleSelectStatus={handleTalentSelect}
            updateHideStatusBadge={updateHideStatusBadge}
            hideBadge={hideStatusBadge}
          />
          <ExportPopoverButton
            disableExportFiltered={!isFiltering}
            onExportAll={() => handleExport({ scope: EXPORT_SCOPE.all })}
            onExportFilteredData={() =>
              handleExport({ scope: EXPORT_SCOPE.filtered })
            }
          />
        </PageLayoutStyled.Toolbar>
      </PageLayoutStyled.Header>

      <PageLayoutStyled.Body>
        <TimeOffRequestsTable
          timeOffRequests={timeOffRequestsData?.timeoffRequests?.data}
          companyHubs={companyData?.company.hubberHubs || []}
          loading={isLoadingTimeOffRequests || isFetchingTimeOffRequests}
          isFiltering={isFiltering}
          filteredProps={{
            filteredInfo,
            setFilteredInfo,
            onReset: () => setFilteredInfo({}),
          }}
          paginationProps={{
            currentPage: timeOffRequestsData?.timeoffRequests.pageNo || 1,
            pageSize: timeOffRequestsData?.timeoffRequests.pageSize || 0,
            totalItems: timeOffRequestsData?.timeoffRequests.total || 0,
            onPageChanged: (page: number) => setPage(page),
          }}
          onTableChange={handleTableChange}
        />
      </PageLayoutStyled.Body>
    </PageLayoutStyled.Container>
  );
};

export default TimeOffRequstsTab;
