import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: 10px ${space8};
  cursor: pointer;

  &:hover {
    background-color: ${Neutral.B95};
  }

  svg {
    height: 20px;
    width: 20px;
    fill: ${Neutral.B40};
    transform: translateY(1px);
  }

  & > span {
    margin-left: ${space16};
  }

  &[data-collapsed='true'] {
    width: 20px;
    padding: 10px;

    & > span {
      display: none;
    }
  }
`;
