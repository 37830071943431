import { useLocation, useNavigate } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useAuthToken } from '@/hooks/useAuthToken';
import { errorIdentifiedWords, errorType } from '@/modules/Error/constants';

export const useGraphqlError = (errors: (Error | null)[]): void => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearAuthToken } = useAuthToken();

  useDeepCompareEffect(() => {
    for (const error of errors) {
      if (!error || !error.message) continue;

      if (
        error.message.includes(errorIdentifiedWords[errorType.UNAUTHORIZED])
      ) {
        clearAuthToken();
        navigate('/401/unauthorized');
        return;
      } else if (
        error.message.includes(errorIdentifiedWords[errorType.FORBIDDEN])
      ) {
        navigate('/auth/403');
        return;
      } else if (
        error.message.includes(
          errorIdentifiedWords[errorType.INTERNAL_SERVER_ERROR],
        )
      ) {
        navigate('/auth/unknown', {
          state: { from: location.pathname },
        });
        return;
      }
    }
  }, [errors]);
};
