import { useEffect, useState } from 'react';
import {
  ButtonGroup,
  Icon,
  Spinner,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { TextInputError } from '../../../../components/FormTextInput/FormTextInput';
import { SUPPORT_EMAIL } from '../../../../constants/constants';
import {
  ContactUsTextContainer,
  FrameContentContainer,
  StyledButtonGroupContainer,
  StyledOutlineButton,
  StyledSendEmailErrorContainer,
} from './FrameStyle';

const COUNTDOWN_DURATION = 60; // in seconds

interface ConfirmationFrameProps {
  email: string;
  onBack: () => void;
  onSend: (email: string) => void;
  isLoading: boolean;
  error: string;
}

const ConfirmationFrame = ({
  email,
  onBack,
  onSend,
  isLoading,
  error,
}: ConfirmationFrameProps) => {
  const [timerCount, setTimerCount] = useState<number>(COUNTDOWN_DURATION);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (timerCount > 0) {
      const timer = setTimeout(() => {
        setTimerCount(timerCount - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timerCount]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'glintsMTS') {
        const accessToken = event.newValue;
        if (accessToken) setIsLoggedIn(true);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleSendAgain = () => {
    onSend(email);
    setTimerCount(COUNTDOWN_DURATION);
  };

  return (
    <>
      <FrameContentContainer>
        <Typography
          as="div"
          variant="subtitle1"
          style={{
            overflowWrap: 'anywhere',
          }}
        >
          We&apos;ve sent a magic link to
          <br />
          <span style={{ color: Neutral.B40 }}>{email}</span>
        </Typography>
        <Typography as="div" variant="subtitle2">
          Please check your mailbox including the spam folder.
        </Typography>
        <StyledButtonGroupContainer>
          <ButtonGroup>
            <StyledOutlineButton onClick={onBack}>
              Back to Landing Page
            </StyledOutlineButton>
            {!isLoggedIn && (
              <StyledOutlineButton
                icon={isLoading ? <Spinner /> : null}
                disabled={timerCount > 0 || isLoading}
                onClick={handleSendAgain}
              >
                {!isLoading &&
                  `Send Again ${timerCount > 0 ? `(${timerCount})` : ''}`}
              </StyledOutlineButton>
            )}
          </ButtonGroup>
          {error !== '' && (
            <StyledSendEmailErrorContainer>
              <TextInputError
                errorMessage={
                  error?.toString().substring(7, 16) === 'too often'
                    ? 'Please wait for a moment before sending another magic link.'
                    : 'Something went wrong, please try again later.'
                }
              />
            </StyledSendEmailErrorContainer>
          )}
        </StyledButtonGroupContainer>
      </FrameContentContainer>
      <ContactUsTextContainer>
        <Typography as="span" variant="subtitle2">
          Still no email? Contact us at&nbsp;
        </Typography>
        <Typography as="span" variant="subtitle2" color={Blue.S99}>
          {SUPPORT_EMAIL}
        </Typography>
        <Tooltip
          preferredPosition="right-middle"
          content="Copied"
          clickable={true}
          timeout={1000}
          onClick={() => navigator.clipboard.writeText(SUPPORT_EMAIL)}
        >
          <Icon name="ri-file-copy2-line" />
        </Tooltip>
      </ContactUsTextContainer>
    </>
  );
};

export default ConfirmationFrame;
