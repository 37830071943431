import { Fragment, type ReactNode } from 'react';
import { Box } from 'glints-aries';
import {
  Banner,
  Card,
  Link,
  Typography,
  useAlert,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styled.sc';
import { getGraphqlClient } from '@/clients/graphql';
import { CopyText } from '@/components/atoms/CopyText/CopyText';
import { NotReadyInvoiceText } from '@/components/atoms/NotReadyInvoiceText/NotReadyInvoiceText';
import { ALERT_STATUS, ALERT_TYPE, alertMessages } from '@/constants/alert';
import {
  type GetInvoicePdfMutation,
  type PlatformPaymentBankTransfer,
  useGetInvoicePdfMutation,
} from '@/generated/graphql';
import {
  ERROR_MESSAGES,
  ERROR_TYPES,
} from '@/modules/InvoicePayments/constants';
import { formatMoney } from '@/utils/formatString';

interface PaymentSideSheetProps {
  isOpen: boolean;
  onClose(): void;
  platformPayment: PlatformPaymentBankTransfer;
}

const CardSectionData = ({
  label,
  value,
  leftMargin = false,
}: {
  label: string;
  value?: ReactNode;
  leftMargin?: boolean;
}) => (
  <Styled.CardData data-left-margin={leftMargin}>
    <Typography as="div" variant="caption" color={Neutral.B40}>
      {label}
    </Typography>
    {typeof value === 'string' ? (
      <Typography
        as="div"
        variant="body1"
        color={value ? Neutral.B18 : Neutral.B85}
      >
        {value ? value : 'Unknown'}
      </Typography>
    ) : (
      <Box>{value}</Box>
    )}
  </Styled.CardData>
);

export const PaymentSideSheet = ({
  isOpen,
  onClose,
  platformPayment,
}: PaymentSideSheetProps) => {
  const graphqlClient = getGraphqlClient();
  const { open: openAlert } = useAlert();
  const navigate = useNavigate();

  const { mutate: pdfMutate } = useGetInvoicePdfMutation<
    Error,
    GetInvoicePdfMutation
  >(graphqlClient);

  const handleClickInvoicePdf = (id: string) => {
    const pdfVariables = {
      id,
    };
    pdfMutate(pdfVariables, {
      onSuccess: (data) => {
        window.open(data?.invoicePdf?.url, '_blank');
      },
      onError: (error) => {
        if (error.message.includes(ERROR_TYPES.invoicePdfNotReady)) {
          openAlert({
            content: ERROR_MESSAGES[ERROR_TYPES.invoicePdfNotReady],
            status: ALERT_STATUS.warning,
          });
        } else {
          openAlert({
            content: alertMessages[ALERT_TYPE.apiError],
            status: ALERT_STATUS.error,
          });
        }
      },
    });
  };

  const handleOnAccountManagerClick = () => {
    navigate('/company-settings/company-information');
  };

  return (
    <Styled.SideSheet
      isOpen={isOpen}
      title="Pending Payment"
      onClose={onClose}
      showBackButton={false}
    >
      <Styled.SideSheetContainer>
        <Banner dismissable={false} status="warning">
          <Typography variant="body1" color={Neutral.B18}>
            Your payment hasn&apos;t been received due to one of the following
            reasons:
          </Typography>
          <ol>
            <li>
              <Typography variant="body2" color={Neutral.B18} as="span">
                Incomplete Bank Transfer:&nbsp;
              </Typography>
              <Typography variant="body1" color={Neutral.B18} as="span">
                The transfer wasn&apos;t completed.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color={Neutral.B18} as="span">
                Payment Reconciliation:&nbsp;
              </Typography>
              <Typography variant="body1" color={Neutral.B18} as="span">
                The payment is being processed, which can take up to 2 business
                days. If it has been longer, contact your{' '}
                <Link onClick={handleOnAccountManagerClick}>
                  Glints Account Manager
                </Link>{' '}
                for help.
              </Typography>
            </li>
          </ol>
        </Banner>
        <Card heading="Payment Details">
          <Styled.CardRow>
            <CardSectionData
              label="Amount"
              value={
                <CopyText
                  label={formatMoney({
                    amount: platformPayment.total.amount,
                  })}
                  copyValue={platformPayment.total.amount}
                />
              }
            />
            <CardSectionData
              label="Billing Currency"
              value={platformPayment.total.currency}
              leftMargin={true}
            />
          </Styled.CardRow>
          <Styled.CardRow>
            <CardSectionData
              label="Payables"
              value={platformPayment?.invoices.map((invoice, index) => (
                <Fragment key={`invoice-ref-${invoice.id}`}>
                  {invoice.isPdfReady ? (
                    <Link
                      target="_blank"
                      onClick={() => handleClickInvoicePdf(invoice.id)}
                    >
                      {invoice.referenceNumber}
                    </Link>
                  ) : (
                    <NotReadyInvoiceText
                      referenceNumber={invoice.referenceNumber}
                    />
                  )}
                  {index !== platformPayment?.invoices.length - 1 && (
                    <span>{', '}</span>
                  )}
                </Fragment>
              ))}
            />
          </Styled.CardRow>
        </Card>
        <Card heading="Bank Transfer Details">
          <Styled.CardRow>
            <CardSectionData
              label="Beneficiary Name"
              value={
                <CopyText
                  label={platformPayment.beneficiaryName}
                  copyValue={platformPayment.beneficiaryName}
                />
              }
            />
            <CardSectionData
              label="Bank"
              value={
                <CopyText
                  label={platformPayment.bankName}
                  copyValue={platformPayment.bankName}
                />
              }
              leftMargin={true}
            />
          </Styled.CardRow>
          <Styled.CardRow>
            <CardSectionData
              label="Bank Code"
              value={
                <CopyText
                  label={platformPayment.bankCode}
                  copyValue={platformPayment.bankCode}
                />
              }
            />
            <CardSectionData
              label="Virtual Account Number"
              value={
                <CopyText
                  label={platformPayment.virtualAccount}
                  copyValue={platformPayment.virtualAccount}
                />
              }
              leftMargin={true}
            />
          </Styled.CardRow>
        </Card>
      </Styled.SideSheetContainer>
    </Styled.SideSheet>
  );
};
