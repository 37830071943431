import { Fragment } from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { MenuItem } from './MenuItem';
import * as Styled from './styled.sc';
import { type MenuItem as MenuItemType, menuType } from './types';

export interface MenuProps {
  items: MenuItemType[];
  selectedKey?: string;
  isCollapsed?: boolean;
  isMobile?: boolean;
  onClick?: (key: string) => void;
}

export const Menu = ({
  items,
  selectedKey,
  isCollapsed = false,
  isMobile = false,
  onClick,
}: MenuProps) => (
  <Styled.MenuItemsContainer>
    {items.map((item, index) => (
      <Fragment key={index}>
        {item.type === menuType.GROUP && (
          <Styled.GroupContainer isCollapsed={isCollapsed}>
            {!isCollapsed && (
              <Styled.Label isMobile={isMobile}>
                <Typography as="span" variant="caption" color={Neutral.B40}>
                  {item.label}
                </Typography>
              </Styled.Label>
            )}
            <Styled.MenuItemsContainer>
              {item.children && item.children.length > 0 && (
                <>
                  {item.children.map((child) => (
                    <MenuItem
                      key={child.key}
                      itemKey={child.key}
                      label={child.label}
                      selectedKey={selectedKey}
                      iconName={child.iconName}
                      selectedIconName={child.selectedIconName}
                      isCollapsed={isCollapsed}
                      isMobile={isMobile}
                      onClick={onClick}
                    />
                  ))}
                </>
              )}
            </Styled.MenuItemsContainer>
          </Styled.GroupContainer>
        )}
        {item.type === menuType.ITEM && (
          <MenuItem
            key={item.key}
            itemKey={item.key}
            label={item.label}
            selectedKey={selectedKey}
            iconName={item.iconName}
            selectedIconName={item.selectedIconName}
            isCollapsed={isCollapsed}
            isMobile={isMobile}
            onClick={onClick}
          />
        )}
      </Fragment>
    ))}
  </Styled.MenuItemsContainer>
);
