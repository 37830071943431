import { forwardRef } from 'react';
import { Card } from 'glints-aries/lib/@next';

import {
  CardContainer,
  CardContentContainer,
  ColumnFlexContainer,
  LeftSectionContainer,
  RightSectionContainer,
  RowFlexContainer,
} from '../../../components/CardStyle';
import SingleData from '../../../components/SingleData';
import { type User } from '../../interfaces';

interface UserCardProps {
  user?: User;
  onEdit({ id }: { id?: string }): void;
  onDelete({ id }: { id?: string }): void;
}

export const UserCard = forwardRef<HTMLDivElement, UserCardProps>(
  ({ user, onEdit, onDelete }: UserCardProps, ref) => {
    const handleEdit = () => {
      onEdit({ id: user?.id });
    };

    const handleDelete = () => {
      onDelete({ id: user?.id });
    };

    const headerPrimaryAction = {
      label: 'Edit',
      action: handleEdit,
    };

    const headerSecondaryAction = {
      label: 'Delete',
      action: handleDelete,
    };

    return (
      <CardContainer>
        <Card
          // in case of empty user, use "Unknown" as the heading
          // leaving heading as blank will cause the header to collapse, thus hiding the primary and secondary actions
          heading={user?.name || 'Unknown'}
          headerPrimaryAction={headerPrimaryAction}
          headerSecondaryAction={headerSecondaryAction}
          ref={ref}
        >
          <CardContentContainer>
            <ColumnFlexContainer>
              <RowFlexContainer>
                <LeftSectionContainer>
                  <SingleData label="Name">{user?.name}</SingleData>
                </LeftSectionContainer>
                <RightSectionContainer>
                  <SingleData label="Department">{user?.department}</SingleData>
                </RightSectionContainer>
              </RowFlexContainer>
              <RowFlexContainer>
                <LeftSectionContainer>
                  <SingleData label="Email">{user?.email}</SingleData>
                </LeftSectionContainer>
                <RightSectionContainer>
                  <SingleData label="Job Title">{user?.jobTitle}</SingleData>
                </RightSectionContainer>
              </RowFlexContainer>
              <RowFlexContainer>
                <LeftSectionContainer>
                  <SingleData label="Phone" isPhone={true}>
                    {user?.phone}
                  </SingleData>
                </LeftSectionContainer>
                <RightSectionContainer />
              </RowFlexContainer>
            </ColumnFlexContainer>
          </CardContentContainer>
        </Card>
      </CardContainer>
    );
  },
);

UserCard.displayName = 'UserCard';
