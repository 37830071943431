import { useState } from 'react';
import { add as addDate, intervalToDuration } from 'date-fns';
import {
  ActionList,
  type ActionListItem,
  Badge,
  Icon,
  IndexTable,
  Link,
  OutlineButton,
  type PaginationProps,
  PrimaryButton,
  Tooltip,
  Typography,
  useAlert,
  useIndexResourceState,
} from 'glints-aries/lib/@next';
import { type IndexTableProps } from 'glints-aries/lib/@next/IndexTable/IndexTable';
import { Neutral, Orange } from 'glints-aries/lib/@next/utilities/colors';
import { noop } from 'lodash-es';

import {
  DISABLED_INVOICE_NAMES,
  DISABLED_INVOICE_REASON,
  InvoiceStatus,
} from '../../constants';
import { DisputeInvoiceModal } from '../DisputeInvoiceModal';
import { DisputeInvoiceSidesheet } from '../DisputeInvoiceSidesheet';
import { PaymentSideSheet } from '../PaymentSideSheet/PaymentSideSheet';
import {
  baseHeadings,
  multipleSelectionHeadings,
  resourceName,
} from './constants';
import { LoadingStateTable } from './LoadingState';
import * as Styled from './styled.sc';
import { getGraphqlClient } from '@/clients/graphql';
import { NotReadyInvoiceText } from '@/components/atoms/NotReadyInvoiceText/NotReadyInvoiceText';
import { PLACEMENT } from '@/components/atoms/Popover/constants';
import { Popover } from '@/components/atoms/Popover/Popover';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { useFeatureFlagContext } from '@/components/particles/FeatureFlagProvider/FeatureFlagProvider';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { ALERT_STATUS, ALERT_TYPE, alertMessages } from '@/constants/alert';
import { UNLEASH_FLAG_NAME } from '@/constants/constants';
import {
  type GetInvoicePdfMutation,
  type PlatformPaymentBankTransfer,
  PlatformPaymentMethod,
  useGetInvoicePdfMutation,
} from '@/generated/graphql';
import {
  ALLOWED_PAYMENT_COUNTRIES,
  ALLOWED_PAYMENT_CURRENCIES,
  ERROR_MESSAGES,
  ERROR_TYPES,
} from '@/modules/InvoicePayments/constants';
import { type Invoice } from '@/modules/InvoicePayments/interfaces';
import { formatMoney } from '@/utils/formatString';

interface AwaitingPaymentTableProps extends PaginationProps {
  invoicesData?: Invoice[];
  loading: boolean;
  handleRefetch: () => void;
  handlePayClick: (index: string[]) => void;
  iseGIROActive?: boolean;
}

export const AwaitingPaymentTable = ({
  totalItems,
  currentPage,
  pageSize,
  onPageChanged,
  invoicesData,
  loading,
  handleRefetch,
  handlePayClick,
  iseGIROActive,
}: AwaitingPaymentTableProps) => {
  const featureFlagContext = useFeatureFlagContext();
  const { useFlagEnabled } = featureFlagContext;
  const { open: openAlert } = useAlert();
  const { userInfo } = useAuthContext();
  const isBankTransferShown = useFlagEnabled(
    UNLEASH_FLAG_NAME.mtsShowBankTransfer,
  );

  const [modalIndexSelected, setModalIndexSelected] = useState<number | null>(
    null,
  );
  const [sidesheetIndexSelected, setSidesheetIndexSelected] = useState<
    number | null
  >(null);
  const [selectedPendingPayment, setSelectedPendingPayment] = useState<
    PlatformPaymentBankTransfer | undefined
  >();

  const [showPaymentSidesheet, setShowPaymentSidesheet] = useState(false);

  const disabledInvoiceConditions = [
    {
      condition: (invoice: Invoice) =>
        !ALLOWED_PAYMENT_CURRENCIES.includes(invoice.amount.currency),
      name: DISABLED_INVOICE_NAMES.unavailablePaymentCurrency,
    },
    {
      condition: () => iseGIROActive,
      name: DISABLED_INVOICE_NAMES.eGIROEnabled,
    },
  ];

  const checkDisabledInvoiceCondition = (invoice: Invoice) => {
    const disabledCondition = disabledInvoiceConditions.find(
      (invoiceCondition) => invoiceCondition.condition(invoice),
    );

    if (disabledCondition) {
      return { disabled: true, disabledConditionName: disabledCondition.name };
    }

    return { disabled: false };
  };

  const invoicesDataWithDisabled = invoicesData?.map((invoice) => {
    const disabledReason = checkDisabledInvoiceCondition(invoice);

    return {
      ...invoice,
      ...disabledReason,
    };
  });
  const selectableInvoicesData = invoicesDataWithDisabled?.filter(
    (invoice) => invoice.disabled === false,
  );
  const selectableInvoicesCount = selectableInvoicesData?.length;
  const tableInvoicesData = selectableInvoicesCount
    ? selectableInvoicesData
    : invoicesDataWithDisabled;
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(tableInvoicesData || []);

  const isMultipleSelected = selectedResources.length > 0;

  const handlePendingClick = (index: number) => {
    if (invoicesData && invoicesData[index]) {
      setSelectedPendingPayment(invoicesData[index].platformPayment);
      setShowPaymentSidesheet(true);
    }
  };

  const getPopoverOptions = (dispute: boolean, index: number) => {
    const undisputedPopoverActions: ActionListItem[] = [
      {
        content: 'Dispute Invoice',
        icon: <Icon name="ri-error-warning-line" />,
        action: () => setModalIndexSelected(index),
      },
      {
        content: 'Download Invoice',
        icon: <Icon name="ri-download-line" />,
        action: () => {
          if (invoicesData) handleClickInvoicePdf(invoicesData[index].id);
        },
      },
    ];
    const disputedPopoverActions: ActionListItem[] = [
      {
        content: 'Dispute Invoice',
        icon: <Icon name="ri-error-warning-line" />,
        disabled: true,
      },
      {
        content: 'View Dispute',
        icon: <Icon name="ri-file-list-line" />,
        action: () => setSidesheetIndexSelected(index),
      },
      {
        content: 'Download Invoice',
        icon: <Icon name="ri-download-line" />,
        action: () => {
          if (invoicesData) handleClickInvoicePdf(invoicesData[index].id);
        },
      },
    ];
    return dispute ? disputedPopoverActions : undisputedPopoverActions;
  };

  const graphqlClient = getGraphqlClient();

  const { mutate: pdfMutate } = useGetInvoicePdfMutation<
    Error,
    GetInvoicePdfMutation
  >(graphqlClient);

  const handleClickInvoicePdf = (id: string) => {
    const pdfVariables = {
      id,
    };
    pdfMutate(pdfVariables, {
      onSuccess: (data) => {
        window.open(data?.invoicePdf?.url, '_blank');
      },
      onError: (error) => {
        if (error.message.includes(ERROR_TYPES.invoicePdfNotReady)) {
          openAlert({
            content: ERROR_MESSAGES[ERROR_TYPES.invoicePdfNotReady],
            status: ALERT_STATUS.warning,
          });
        } else {
          openAlert({
            content: alertMessages[ALERT_TYPE.apiError],
            status: ALERT_STATUS.error,
          });
        }
      },
    });
  };

  const getInvoiceStatusBadge = (dueDate?: string | null) => {
    if (!dueDate) {
      return <Badge status="information">{InvoiceStatus.OUTSTANDING}</Badge>;
    }

    const todayDate = new Date();
    const dueDatePlusOneDay = addDate(new Date(dueDate), { days: 1 });
    if (dueDatePlusOneDay < todayDate) {
      return <Badge status="attention">{InvoiceStatus.OVERDUE}</Badge>;
    }

    const duration = intervalToDuration({
      start: todayDate,
      end: new Date(dueDate),
    });

    const totalDays =
      1 +
      (duration ? duration.days ?? 0 : 0) +
      (duration ? duration.months ?? 0 : 0) * 30 +
      (duration ? duration.years ?? 0 : 0) * 365;
    let badgeContent: string = InvoiceStatus.OUTSTANDING;

    if (totalDays === 0) {
      badgeContent = InvoiceStatus.DUE;
    }

    if (totalDays <= 5) {
      badgeContent = `${InvoiceStatus.DUESOON} ${totalDays} ${
        totalDays === 1 ? 'day' : 'days'
      }`;
    }

    return <Badge status="warning">{badgeContent}</Badge>;
  };

  const paymentTooltip = ({
    referenceNumber,
    date,
  }: {
    referenceNumber: string;
    date: string;
  }) => (
    <Styled.Tooltip
      content={
        <Typography as="span" variant="subtitle2">
          There is a remaining balance for a short payment you made for{' '}
          {referenceNumber} on <FormattedDate date={date} />
        </Typography>
      }
      preferredPosition="bottom-center"
    >
      <Icon name="ri-error-warning-fill" fill={Orange.S86} />
    </Styled.Tooltip>
  );

  const tableHeadings: IndexTableProps['headings'] = isMultipleSelected
    ? baseHeadings
    : multipleSelectionHeadings;

  const rowMarkup = invoicesDataWithDisabled?.map(
    (
      {
        id,
        dueDate,
        referenceNumber,
        isPdfReady,
        paidAmount: { amount: paidAmount },
        amount: { currency: amountCurrency, amount },
        paymentLines,
        dispute,
        platformPayment,
        disabled,
        disabledConditionName,
      },
      index,
    ) => {
      const isShort = parseFloat(paidAmount) < parseFloat(amount);
      const hadPaid = (paymentLines?.length ?? 0) > 0;

      const actionButton = (disabled: boolean) => {
        if (
          userInfo?.company?.billingAddress?.country &&
          !ALLOWED_PAYMENT_COUNTRIES.includes(
            userInfo.company.billingAddress.country,
          )
        )
          return null;

        if (
          platformPayment &&
          platformPayment.method === PlatformPaymentMethod.BankTransfer
        ) {
          return (
            <OutlineButton
              size="default"
              onClick={() => handlePendingClick(index)}
            >
              Pending
            </OutlineButton>
          );
        }

        return disabled && disabledConditionName ? (
          <Tooltip
            preferredPosition="top-left"
            content={DISABLED_INVOICE_REASON[disabledConditionName]}
          >
            <PrimaryButton size="default" disabled={true}>
              Pay
            </PrimaryButton>
          </Tooltip>
        ) : (
          <PrimaryButton size="default" onClick={() => handlePayClick([id])}>
            Pay
          </PrimaryButton>
        );
      };

      return (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          disabled={disabled}
          position={index}
          onClick={noop}
        >
          <Styled.Cell>
            <Styled.LeftPaddingWrapper>
              {getInvoiceStatusBadge(dueDate)}
            </Styled.LeftPaddingWrapper>
          </Styled.Cell>
          <Styled.Cell>
            <FormattedDate date={dueDate} />
          </Styled.Cell>
          <Styled.Cell>
            <Styled.InvoiceReferenceWrapper>
              {isPdfReady ? (
                <Link
                  url="#"
                  onClick={() => handleClickInvoicePdf(id)}
                  removeUnderline={true}
                >
                  <Typography as="span" variant="subtitle2">
                    {referenceNumber}
                  </Typography>
                </Link>
              ) : (
                <NotReadyInvoiceText referenceNumber={referenceNumber} />
              )}
              {dispute !== null && (
                <Styled.DisputedBadgeContainer
                  onClick={() => setSidesheetIndexSelected(index)}
                >
                  <Icon name="ri-error-warning-line" />
                  <Typography as="span" variant="subtitle2">
                    Disputed
                  </Typography>
                </Styled.DisputedBadgeContainer>
              )}
            </Styled.InvoiceReferenceWrapper>
          </Styled.Cell>
          <Styled.Cell>
            <Styled.PaymentAmountWrapper>
              {isShort &&
                hadPaid &&
                paymentLines &&
                paymentTooltip({
                  referenceNumber,
                  date: paymentLines[0].date,
                })}
              <Typography as="span" variant="subtitle2" color={Neutral.B18}>
                {formatMoney({ amount })}
              </Typography>
              <Styled.Currency as="span" variant="overline">
                {amountCurrency}
              </Styled.Currency>
            </Styled.PaymentAmountWrapper>
          </Styled.Cell>
          {!isMultipleSelected && (
            <Styled.Cell>
              <Styled.TableActionsContainer>
                {isBankTransferShown && actionButton(disabled)}
                <Popover
                  content={
                    <ActionList
                      items={getPopoverOptions(dispute !== null, index)}
                    />
                  }
                  padding="4px"
                  placement={PLACEMENT.bottomLeft}
                >
                  <Styled.PopoverActivatorContainer>
                    <Icon name="ri-more" height={24} fill={Neutral.B40} />
                  </Styled.PopoverActivatorContainer>
                </Popover>
              </Styled.TableActionsContainer>
            </Styled.Cell>
          )}
        </IndexTable.Row>
      );
    },
  );

  const handleClose = () => {
    handleRefetch();
    setModalIndexSelected(null);
  };

  const selectedDisputeInvoiceModalData =
    invoicesData?.[modalIndexSelected ?? 0] || undefined;
  const selectedDisputeInvoiceSidesheetData =
    invoicesData?.[sidesheetIndexSelected ?? 0] || undefined;

  return (
    <>
      {selectedPendingPayment &&
        selectedPendingPayment.method ===
          PlatformPaymentMethod.BankTransfer && (
          <PaymentSideSheet
            isOpen={showPaymentSidesheet}
            onClose={() => setShowPaymentSidesheet(false)}
            platformPayment={
              selectedPendingPayment as PlatformPaymentBankTransfer
            }
          />
        )}
      <DisputeInvoiceModal
        isOpen={modalIndexSelected !== null}
        onClose={handleClose}
        invoiceId={selectedDisputeInvoiceModalData?.id ?? ''}
        invoiceReference={
          selectedDisputeInvoiceModalData?.referenceNumber ?? ''
        }
        paymentAmount={selectedDisputeInvoiceModalData?.amount.amount ?? ''}
        paymentCurrency={selectedDisputeInvoiceModalData?.amount.currency ?? ''}
      />
      <DisputeInvoiceSidesheet
        isOpen={sidesheetIndexSelected !== null}
        onClose={() => setSidesheetIndexSelected(null)}
        handleInvoiceClick={handleClickInvoicePdf}
        invoiceId={selectedDisputeInvoiceSidesheetData?.id ?? ''}
        invoiceReference={
          selectedDisputeInvoiceSidesheetData?.referenceNumber ?? ''
        }
        paymentAmount={selectedDisputeInvoiceSidesheetData?.amount.amount ?? ''}
        paymentCurrency={
          selectedDisputeInvoiceSidesheetData?.amount.currency ?? ''
        }
        disputeReason={
          selectedDisputeInvoiceSidesheetData?.dispute?.reason ?? ''
        }
        requester={
          selectedDisputeInvoiceSidesheetData?.dispute?.createdBy?.name ?? ''
        }
        submissionDate={selectedDisputeInvoiceSidesheetData?.dispute?.createdAt}
      />
      <Styled.IndexTableContainer>
        {loading ? (
          <LoadingStateTable />
        ) : (
          <IndexTable
            itemCount={tableInvoicesData?.length || 0}
            resourceName={resourceName}
            onSelectionChange={handleSelectionChange}
            selectable={
              isBankTransferShown && userInfo?.company?.billingAddress?.country
                ? ALLOWED_PAYMENT_COUNTRIES.includes(
                    userInfo?.company?.billingAddress?.country,
                  )
                : false
            }
            selectedItemsCount={
              allResourcesSelected ? 'All' : selectedResources.length
            }
            selectableItemsCount={selectableInvoicesCount}
            selectedLabel="selected invoices"
            headings={tableHeadings}
            emptyState={<></>}
            height="100%"
          >
            {rowMarkup}
          </IndexTable>
        )}

        {selectedResources.length > 0 && (
          <Styled.IndexTableBulkActions>
            <Styled.IndexTableBulkActionsText>
              Selected: {selectedResources.length}
            </Styled.IndexTableBulkActionsText>
            <PrimaryButton
              size="default"
              onClick={() => handlePayClick(selectedResources)}
            >
              Pay
            </PrimaryButton>
          </Styled.IndexTableBulkActions>
        )}

        {!loading && (invoicesData?.length ?? 0) > 0 && (
          <Styled.TablePaginationContainer>
            <TablePagination
              currentPage={currentPage}
              pageSize={pageSize}
              totalItems={totalItems}
              onPageChanged={onPageChanged}
            />
          </Styled.TablePaginationContainer>
        )}
      </Styled.IndexTableContainer>
    </>
  );
};
