import { createContext, type ReactNode, useContext, useState } from 'react';
import { noop } from 'lodash-es';

import {
  HubberDetailsSideSheet,
  type HubberDetailsSideSheetProps,
} from '@/components/organisms/SideSheets/HubberDetailsSideSheet/HubberDetailsSideSheet';

type Details = Omit<HubberDetailsSideSheetProps, 'isOpen'>;

type ContextValue = {
  showHubberSideSheet: (details: Details) => void;
  hideHubberSideSheet: () => void;
};

const Context = createContext<ContextValue>({
  showHubberSideSheet: () => noop,
  hideHubberSideSheet: () => noop,
});

export const useHubberDetailsSideSheet = (): ContextValue =>
  useContext(Context);

export const HubberDetailsSideSheetProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [hubberDetailsData, setSideSheetProps] =
    useState<HubberDetailsSideSheetProps | null>(null);

  const showHubberSideSheet = (details: Details) => {
    setSideSheetProps({ ...details, isOpen: true });
  };

  const hideHubberSideSheet = () => {
    setSideSheetProps(null);
  };

  return (
    <Context.Provider value={{ showHubberSideSheet, hideHubberSideSheet }}>
      {children}
      {hubberDetailsData && (
        <HubberDetailsSideSheet
          {...hubberDetailsData}
          onClose={hideHubberSideSheet}
        />
      )}
    </Context.Provider>
  );
};
