import { useState } from 'react';
import { Widget } from '@typeform/embed-react';
import { Flex } from 'glints-aries';
import { Button, Card, Icon, Typography } from 'glints-aries/lib/@next';
import {
  Blue,
  Neutral,
  Orange,
  Red,
} from 'glints-aries/lib/@next/utilities/colors';
import { space16 } from 'glints-aries/lib/@next/utilities/spacing';
import { useNavigate } from 'react-router-dom';

import { PERMITTED_MANAGE_USERS_ROLE } from '../CompanyProfile/constants';
import * as Styled from './styled.sc';
import AstronautStarsSVG from '@/assets/images/astronaut-stars.svg';
import { ReactComponent as AstronautSupportSVG } from '@/assets/images/astronaut-support.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { IllustrationPlanet } from '@/components/atoms/Illustrations/Planet/Planet';
import { CostCalculatorModal } from '@/components/organisms/Modals/CostCalculatorModal/CostCalculatorModal';
import { BudgetToolButton } from '@/components/organisms/Resources/BudgetToolButton/BudgetToolButton';
import { CostCalculatorButton } from '@/components/organisms/Resources/CostCalculatorButton/CostCalculatorButton';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import {
  type GetCompanyUnassignedDirectManagerRelationCountQuery,
  type GetCompanyUnassignedUsersCountQuery,
  useGetCompanyUnassignedDirectManagerRelationCountQuery,
  useGetCompanyUnassignedUsersCountQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';

const HomePage = () => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const { userInfo } = useAuthContext();
  const currentUserRole = userInfo?.contact?.roles[0].id;
  const [isCostCalculatorModalOpen, setIsCostCalculatorModalOpen] =
    useState(false);

  const {
    data: companyUnassignedUsersCountQuery,
    error: getCompanyUnassignedUsersCountError,
  } = useGetCompanyUnassignedUsersCountQuery<
    GetCompanyUnassignedUsersCountQuery,
    Error
  >(
    graphqlClient,
    {},
    {
      staleTime: 0,
    },
  );

  const {
    data: companyUnassignedDirectManagerRelationCountQuery,
    error: getCompanyUnassignedDirectManagerRelationCountError,
  } = useGetCompanyUnassignedDirectManagerRelationCountQuery<
    GetCompanyUnassignedDirectManagerRelationCountQuery,
    Error
  >(
    graphqlClient,
    {},
    {
      staleTime: 0,
    },
  );

  useGraphqlError([
    getCompanyUnassignedUsersCountError,
    getCompanyUnassignedDirectManagerRelationCountError,
  ]);

  return (
    <>
      <Styled.GreetingHeading>
        <div className="greeting-heading-text">
          <Typography as="span" variant="headline3" color={Blue.S100}>
            Hi, {userInfo?.contact.name}
          </Typography>
          <Typography as="span" color={Blue.S100} variant="body1">
            Begin your journey by selecting an option from the menu on the left
          </Typography>
        </div>
        <IllustrationPlanet />
      </Styled.GreetingHeading>

      {PERMITTED_MANAGE_USERS_ROLE.includes(currentUserRole) && (
        <Styled.UrgentCard>
          <Card
            heading={
              <Typography as="span" variant="body2">
                Urgent Actions Needed
              </Typography>
            }
          >
            <Card.Section>
              <Styled.UrgentCardContent>
                <Styled.UrgentCardContentArt>
                  <AstronautSupportSVG />
                </Styled.UrgentCardContentArt>

                <Styled.UrgentCardContentBody>
                  <Typography as="span" variant="body1">
                    Issues detected in your company settings. Please keep your
                    information current to prevent potential disruptions:
                  </Typography>

                  <Styled.UrgentCardsContainer>
                    {companyUnassignedUsersCountQuery &&
                      companyUnassignedUsersCountQuery?.company?.no_role_count >
                        0 && (
                        <Styled.UrgentCardAlert background={Red.B100}>
                          <Flex alignItems="center" gap={space16}>
                            <Typography
                              as="span"
                              variant="headline5"
                              color={Red.B93}
                            >
                              {
                                companyUnassignedUsersCountQuery?.company
                                  .no_role_count
                              }
                            </Typography>
                            <Typography
                              as="span"
                              variant="caption"
                              color={Neutral.B18}
                            >
                              Invalid Users Awaiting Review
                            </Typography>
                          </Flex>
                          <Button
                            onClick={() =>
                              navigate(
                                '/company-settings/roles-and-permissions',
                              )
                            }
                          >
                            <Typography
                              as="span"
                              variant="button"
                              color={Neutral.B18}
                            >
                              Fix Now
                            </Typography>
                            <Icon name="ri-arrow-m-right-line" height={20} />
                          </Button>
                        </Styled.UrgentCardAlert>
                      )}
                    {companyUnassignedDirectManagerRelationCountQuery &&
                      companyUnassignedDirectManagerRelationCountQuery.company
                        .unassignedDirectManagerRelationCount > 0 && (
                        <Styled.UrgentCardAlert background={Orange.S21}>
                          <Flex alignItems="center" gap={space16}>
                            <Typography
                              as="span"
                              variant="headline5"
                              color={Orange.S87}
                            >
                              {
                                companyUnassignedDirectManagerRelationCountQuery
                                  .company.unassignedDirectManagerRelationCount
                              }
                            </Typography>
                            <Typography
                              as="span"
                              variant="caption"
                              color={Neutral.B18}
                            >
                              Employees Missing Assigned Managers
                            </Typography>
                          </Flex>
                          <Button
                            onClick={() =>
                              navigate('/company-settings/reporting-lines')
                            }
                          >
                            <Typography
                              as="span"
                              variant="button"
                              color={Neutral.B18}
                            >
                              Fix Now
                            </Typography>
                            <Icon name="ri-arrow-m-right-line" height={20} />
                          </Button>
                        </Styled.UrgentCardAlert>
                      )}
                  </Styled.UrgentCardsContainer>
                </Styled.UrgentCardContentBody>
              </Styled.UrgentCardContent>
            </Card.Section>
          </Card>
        </Styled.UrgentCard>
      )}

      <Styled.FlexRow>
        <Styled.ResourcesCard>
          <Card
            heading={
              <Typography as="span" variant="body2">
                Explore Your Resources
              </Typography>
            }
          >
            <Styled.ResourcesCardContent>
              <Card.Section>
                <Styled.ResourcesSectionContent>
                  <CostCalculatorButton
                    onClick={() => setIsCostCalculatorModalOpen(true)}
                  />
                  <BudgetToolButton />
                </Styled.ResourcesSectionContent>
              </Card.Section>
              <Styled.ResourcesCardArt src={AstronautStarsSVG} />
            </Styled.ResourcesCardContent>
          </Card>
        </Styled.ResourcesCard>

        <Styled.TypeFormCard>
          <Card
            heading={
              <Typography as="span" variant="body2">
                Offer Your Thoughts
              </Typography>
            }
          >
            <Widget
              id="PJJhH9Kr"
              className="offer-thoughts-typeform-widget"
              hidden={{
                /* eslint-disable camelcase */
                user_id: userInfo?.contact?.id || '',
                name: userInfo?.contact?.name || '',
                email: userInfo?.contact?.email || '',
                company: userInfo?.company?.name || '',
              }}
            />
          </Card>
        </Styled.TypeFormCard>
      </Styled.FlexRow>

      <CostCalculatorModal
        isOpen={isCostCalculatorModalOpen}
        onClose={() => setIsCostCalculatorModalOpen(false)}
      />
    </>
  );
};

export default HomePage;
