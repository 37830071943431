import { useState } from 'react';

import { getGraphqlClient } from '../../../clients/graphql';
import {
  type GetLastMonthTotalInvoiceQuery,
  useGetLastMonthTotalInvoiceQuery,
} from '../../../generated/graphql';
import { SetupEgiro } from './components/SetupEgiro';
import { SetupWithBank } from './components/SetupWithBank';
import { bankDetailsMapping, type BankSwiftCode } from './constants';

const AutoPaymentTab = () => {
  const graphqlClient = getGraphqlClient();
  const [showSetupWithBank, setShowSetupWithBank] = useState(false);
  const [selectedBank, setSelectedBank] = useState<BankSwiftCode | undefined>();

  const { data: LastMonthTotalInvoice } = useGetLastMonthTotalInvoiceQuery<
    GetLastMonthTotalInvoiceQuery,
    Error
  >(graphqlClient);

  const updateShowSetupWithBank = (newValue: boolean) => {
    setShowSetupWithBank(newValue);
  };

  const updateSelectedBank = (newValue: BankSwiftCode) => {
    setSelectedBank(newValue);
  };

  if (showSetupWithBank && selectedBank) {
    return (
      <SetupWithBank
        bankSwiftBicCode={bankDetailsMapping[selectedBank].bankSwiftBicCode}
        lastMonthTotalInvoice={
          LastMonthTotalInvoice?.invoiceSummary?.lastMonthTotal
        }
        bankName={bankDetailsMapping[selectedBank].name}
        icon={bankDetailsMapping[selectedBank].icon}
      />
    );
  }

  return (
    <SetupEgiro
      updateShowSetupWithBank={updateShowSetupWithBank}
      updateSelectedBank={updateSelectedBank}
      lastMonthTotalInvoice={
        LastMonthTotalInvoice?.invoiceSummary.lastMonthTotal
      }
    />
  );
};

export default AutoPaymentTab;
