import { TIME_OFF_REQUESTS_TABLE_KEY } from './components/TimeOffRequestsTable/constants';
import { type TableSorts } from '@/components/atoms/GlintsAntdTable/types';
import { ANTD_SORT_ORDER_MAP } from '@/constants/sortOrder';
import {
  TimeOffRequestSortField,
  type TimeOffRequestSortInput,
} from '@/generated/graphql';

export const convertTableKeyToTimeOffRequestSortField = (
  tableKey: string,
): TimeOffRequestSortField => {
  switch (tableKey) {
    case TIME_OFF_REQUESTS_TABLE_KEY.requestedTime:
      return TimeOffRequestSortField.RequestedAt;
    case TIME_OFF_REQUESTS_TABLE_KEY.startDate:
      return TimeOffRequestSortField.TimeoffStartDate;
    case TIME_OFF_REQUESTS_TABLE_KEY.endDate:
      return TimeOffRequestSortField.TimeoffEndDate;
    default:
      return TimeOffRequestSortField.RequestedAt;
  }
};

export const buildArraySortedInfo = (sortedInfo: TableSorts | TableSorts[]) => {
  const arraySortedInfo = Array.isArray(sortedInfo)
    ? sortedInfo
    : [sortedInfo as TableSorts];
  return arraySortedInfo
    .map((sort) => {
      if (sort.order) {
        return {
          field: convertTableKeyToTimeOffRequestSortField(
            sort.columnKey as TimeOffRequestSortField,
          ),
          order: ANTD_SORT_ORDER_MAP[sort.order],
        };
      }
      return null;
    })
    .filter(Boolean) as TimeOffRequestSortInput[];
};
