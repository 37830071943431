import { Button as AriesButton } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import styled, { css, keyframes } from 'styled-components';

import DrivingImage from '@/assets/images/driving.webp';

const drivingKeyframe = keyframes`
  from {
    background-position: 0 0%;
  }
  to {
    background-position: 0 100%;
  }
`;

const shimmeringKeyframe = keyframes`
  0% {
    transform: rotate(2deg);
    background-color: #f48620;
    color: #fff;
  }
  50% {
    transform: rotate(0deg) scale(1.1);
    background-color: #f48620;
    color: #fff;
  }
  75% {
    transform: rotate(-2deg) scale(1);
    background-color: #f48620;
    color: #fff;
  }
  100% {
    transform: rotate(0deg) scale(1.1);
    background-color: #f48620;
    color: #fff;
  }
`;

export const fadeInKeyframe = keyframes`
  from {
    opacity: 0.5;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space8};
  text-align: center;
`;

export const Text = styled.div<{ fadeIn: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${(props) =>
    props.fadeIn &&
    css`
      animation: ${fadeInKeyframe} 0.5s ease-in-out forwards;
    `}
`;

export const UnderDevelopmentAnimation = styled.div<{
  animationDuration: number;
}>`
  transform: scale(0.8);
  width: 400px;
  height: 300px;
  margin: 0 auto;
  background: url(${DrivingImage}) no-repeat 0 0%;
  animation: ${drivingKeyframe} ${(props) => props.animationDuration}ms steps(8)
    infinite;
`;

export const Button = styled(AriesButton)<{ clicked: boolean }>`
  ${(props) =>
    props.clicked &&
    css`
      animation: ${shimmeringKeyframe} 200ms 3
        cubic-bezier(0.47, 0, 0.745, 0.715);
    `}

  &:hover {
    box-shadow: 2px 2px 0px ${Neutral.B18};
    transition: all;
  }
`;
