import {
  Card,
  RadioButton,
  type RadioButtonProps,
} from 'glints-aries/lib/@next';
import { useController } from 'react-hook-form';

import { roleDetailsMapping } from '../../constants';
import { Role, type RoleCardOptionListProps } from '../../interfaces';
import {
  RoleCardOptionLi,
  RoleCardOptionUl,
} from '../../RolesPermissionsTabStyle';

const RoleCardOption = (props: RadioButtonProps) => (
  <Card>
    <Card.Section>
      <RadioButton {...props} />
    </Card.Section>
  </Card>
);

export const RoleCardOptionList = ({
  selectedRole,
  onSelectRole,
  control,
}: RoleCardOptionListProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'roleId',
    control,
    rules: { required: true },
  });

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectRole(e.target.value as Role);
    field.onChange(e.target.value);
  };

  return (
    <RoleCardOptionUl>
      {Object.values(Role).map((role) => {
        const key = role.toLowerCase().replace(/_/g, '-');
        return (
          <RoleCardOptionLi key={key}>
            <RoleCardOption
              // ref={field.ref}
              key={key}
              label={roleDetailsMapping[role as Role].role}
              helperText={roleDetailsMapping[role as Role].roleDetails}
              value={`${role}`}
              checked={selectedRole === `${role}`}
              onChange={handleSelect}
            />
          </RoleCardOptionLi>
        );
      })}
    </RoleCardOptionUl>
  );
};
