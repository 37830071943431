import { Icon, PlainButton, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { z } from 'zod';

import { FormTextInput } from '../../../../../components/FormTextInput/FormTextInput';
import { roleDetailsMapping } from '../../constants';
import { FormMode, type UserFieldsProps } from '../../interfaces';
import { RoleDetailsContainer } from '../../RolesPermissionsTabStyle';
import { PhoneTextInput } from './PhoneInput';

export const validationSchema = z.object({
  /* eslint-disable camelcase */
  roleId: z.string().nullish(),
  name: z
    .string({
      invalid_type_error: 'Name is required',
      required_error: 'Name is required',
    })
    .min(1, 'Name is required'),
  department: z.string().nullish(),
  email: z
    .string({
      invalid_type_error: 'Email is required',
      required_error: 'Email is required',
    })
    .email({ message: 'Invalid format for email address' }),
  jobTitle: z
    .string({
      invalid_type_error: 'Job title is required',
      required_error: 'Job title is required',
    })
    .regex(/^.+$/, 'Job title is required'),
  phoneNumber: z.object({
    countryCode: z.string().regex(/\+\d+/).nullish(),
    number: z
      .string({
        invalid_type_error: 'Numbers only, no characters or whitespace',
      })
      .regex(/^(\d{5,})?$/, 'Numbers only, no characters or whitespace')
      .max(15, 'Invalid phone number')
      .nullish(),
    extension: z
      .string()
      .regex(/^(?:\d+)?$/, 'Numbers only, no characters or whitespace')
      .max(7, 'Invalid phone number')
      .nullish(),
    /* eslint-enable camelcase */
  }),
});

export const UserFields = ({
  apiError,
  control,
  role,
  formMode = FormMode.CREATE,
  handleChangeRole,
}: UserFieldsProps) => (
  <>
    <RoleDetailsContainer>
      <Typography as="span" variant="body2" color={Neutral.B18}>
        {role && roleDetailsMapping[role].role}
      </Typography>
      <Typography as="span" variant="body1" color={Neutral.B18}>
        {role && roleDetailsMapping[role].roleDetails}
      </Typography>
      {formMode === FormMode.EDIT && (
        <PlainButton
          icon={<Icon name="ri-group-line" height="20px" />}
          onClick={handleChangeRole}
        >
          Change Role
        </PlainButton>
      )}
    </RoleDetailsContainer>
    <>
      <FormTextInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={control}
        label="Name"
        name="name"
        required={true}
        placeholder="Enter full name"
      />
      <FormTextInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={control}
        label="Email"
        name="email"
        required={true}
        placeholder="Enter email address"
        apiError={
          apiError
            ? 'Duplicate email. This contact person already exists.'
            : null
        }
      />
      <FormTextInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={control}
        label="Job Title"
        name="jobTitle"
        placeholder="Enter job title"
        required={true}
      />
      <FormTextInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={control}
        label="Department"
        name="department"
        placeholder="Enter department"
      />
      <PhoneTextInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={control}
        label="Phone"
      />
    </>
  </>
);
