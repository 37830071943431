import { type HubberWithDirectManagerFragment } from '@/generated/graphql';

export const hubberNameSorter = (
  a?: HubberWithDirectManagerFragment,
  b?: HubberWithDirectManagerFragment,
) => {
  if (!a || !b) return 0;
  if (!a.fullName || !b.fullName) return 0;

  const firstCharA = a.fullName.charAt(0).toLowerCase();
  const firstCharB = b.fullName.charAt(0).toLowerCase();

  if (firstCharA < firstCharB) {
    return -1;
  }
  if (firstCharA > firstCharB) {
    return 1;
  }
  return 0;
};
