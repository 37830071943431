export const useEllipsisTextStyle = ({
  maxWidth = '100%',
  lineClamp = 1,
} = {}) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  maxWidth,
  ...(lineClamp > 1
    ? {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical' as const,
        WebkitLineClamp: lineClamp,
      }
    : {
        whiteSpace: 'nowrap',
      }),
});
