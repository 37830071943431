import { Modal as AriesModal } from 'glints-aries/lib/@next';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const Modal = styled(AriesModal)`
  width: 860px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }

  .modal-content {
    padding: 0;
    width: 100%;
  }
`;

export const ModalContent = styled.div`
  height: calc(70vh - 130px);
  overflow: auto;

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: 100%;
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
