import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import { space12, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const ReportingHubbersPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${space12} ${space16};
  background: ${Blue.S08};
  height: 56px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    justify-content: center;
    height: fit-content;
  }
`;

export const Body = styled.div`
  flex: 1;
  overflow: auto;
`;
