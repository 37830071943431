import { Blue, Red } from 'glints-aries/lib/@next/utilities/colors';

export const CONFIRMATION_MODAL_BUTTON_TYPE = {
  primary: 'primary',
  danger: 'danger',
} as const;

export type ButtonType = ValueOf<typeof CONFIRMATION_MODAL_BUTTON_TYPE>;

export const BUTTON_COLOR = {
  [CONFIRMATION_MODAL_BUTTON_TYPE.primary]: Blue.S99,
  [CONFIRMATION_MODAL_BUTTON_TYPE.danger]: Red.B93,
} as const;
