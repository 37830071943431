import {
  Badge,
  EmptyState,
  IndexTable,
  SkeletonImageSquare,
  Typography,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { resourceName, statusMapping } from '../constants';
import { type Hubber, type MangedTalentsTableProps } from '../interface';
import {
  ClickableWrapper,
  EmptyStateContainer,
  LeftPaddingWrapper,
  LoadingRow,
  ManagedTalentsTableContainer,
  RightPaddingWrapper,
  StyledCell,
  TablePaginationContainer,
} from './ManagedTalentsTableStyle';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { useHubberDetailsSideSheet } from '@/components/organisms/SideSheets/HubberDetailsSideSheet/context';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { EmploymentStatus, EmploymentType } from '@/generated/graphql';
import { computeTenure } from '@/utils/date';

const ManagedTalentsTable = ({
  currentPage,
  hubbers,
  loading,
  noSearchResults,
  onPageChanged,
  onResetSearch,
  pageSize,
  totalItems,
  ...props
}: MangedTalentsTableProps) => {
  const { showHubberSideSheet } = useHubberDetailsSideSheet();

  const renderStatusBadge = ({ status }: Pick<Hubber, 'status'>) => {
    const badgeStatus =
      status === EmploymentStatus.Active ? 'success' : 'neutral';

    return <Badge status={badgeStatus}>{statusMapping[status]}</Badge>;
  };

  const renderContractTypeBadge = ({
    employmentType,
    contractType: contract,
  }: Pick<Hubber, 'employmentType' | 'contractType'>) => {
    if (employmentType === EmploymentType.Permanent) {
      return <Badge status="information">Permanent</Badge>;
    } else if (employmentType === EmploymentType.Contract) {
      return <Badge status="neutral">{contract}</Badge>;
    }
  };

  const rowMarkup = hubbers?.map(
    (
      {
        id,
        fullName,
        hub,
        jobTitle,
        employmentType,
        contractType,
        joinDate,
        contractEndDate,
        resignDate: jobEndDate,
        status,
      },
      index,
    ) => {
      const { tenureYears, tenureMonths } = computeTenure({
        startDate: joinDate,
        endDate: jobEndDate,
      });
      return (
        <IndexTable.Row id={id} key={id} position={index}>
          <StyledCell>
            <LeftPaddingWrapper>
              <Typography as="span" variant="subtitle2">
                {renderStatusBadge({ status })}
              </Typography>
            </LeftPaddingWrapper>
          </StyledCell>
          <StyledCell>
            <ClickableWrapper
              onClick={() => {
                showHubberSideSheet({
                  hubberData: hubbers[index],
                });
              }}
            >
              <Typography as="span" variant="subtitle2" color={Blue.S99}>
                {fullName}
              </Typography>
            </ClickableWrapper>
          </StyledCell>
          <StyledCell>
            <HubLocationText hubCode={hub as HubCode} />
          </StyledCell>
          <StyledCell>
            <Typography as="span" variant="subtitle2">
              {jobTitle}
            </Typography>
          </StyledCell>
          <StyledCell>
            {renderContractTypeBadge({ employmentType, contractType })}
          </StyledCell>
          <StyledCell>
            <FormattedDate date={joinDate} />
          </StyledCell>
          <StyledCell>
            <FormattedDate date={jobEndDate} noDataText="Still present" />
          </StyledCell>
          <StyledCell>
            <Typography as="span" variant="subtitle2">
              {tenureYears}
            </Typography>
            <Typography as="span" variant="overline" color={Neutral.B40}>
              {' '}
              yr{' '}
            </Typography>
            <Typography as="span" variant="subtitle2">
              {tenureMonths}
            </Typography>
            <Typography as="span" variant="overline" color={Neutral.B40}>
              {' '}
              mo{' '}
            </Typography>
          </StyledCell>
          <StyledCell>
            <FormattedDate date={contractEndDate} noDataText="N/A" />
          </StyledCell>
        </IndexTable.Row>
      );
    },
  );

  const loadingRow = [...Array(10).keys()].map((n) => (
    <LoadingRow id={`loading-row-${n}`} key={n} position={n}>
      {[...Array(10).keys()].map((n) => (
        <StyledCell key={`loading-row-cell-${n}`}>
          <SkeletonImageSquare height="24px" width="100%" />
        </StyledCell>
      ))}
    </LoadingRow>
  ));

  return (
    <ManagedTalentsTableContainer>
      <IndexTable
        {...props}
        itemCount={hubbers?.length || 0}
        resourceName={resourceName}
        headings={[
          {
            title: <LeftPaddingWrapper>Status</LeftPaddingWrapper>,
            id: 'title',
          },
          { title: 'Name' },
          { title: 'TalentHub' },
          { title: 'Job Title' },
          { title: 'Contract Type' },
          { title: 'Job Start Date' },
          { title: 'Job End Date' },
          { title: 'Tenure' },
          {
            title: (
              <RightPaddingWrapper>Contract Expiry Date</RightPaddingWrapper>
            ),
            id: 'contractEndDate',
          },
        ]}
        selectable={false}
        emptyState={
          <>
            {loading ? null : (
              <EmptyStateContainer colSpan={9}>
                {noSearchResults ? (
                  <EmptyState
                    basicButtonAction={{
                      label: 'Reset All',
                      onClick: onResetSearch,
                    }}
                    title="No Matching Results"
                    description="No results were found based on your search keywords or filtering conditions."
                  />
                ) : (
                  <EmptyState
                    title="No Employees"
                    description={
                      <Typography variant="subtitle2">
                        Currently, there are no employees in the system.
                        <br />
                        Please consult your system admin for further information
                      </Typography>
                    }
                    imageName="empty-mailbox"
                  />
                )}
              </EmptyStateContainer>
            )}
          </>
        }
      >
        {loading ? loadingRow : rowMarkup}
      </IndexTable>
      {(hubbers?.length ?? 0) > 0 && (
        <TablePaginationContainer>
          <TablePagination
            currentPage={currentPage}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChanged={onPageChanged}
          />
        </TablePaginationContainer>
      )}
    </ManagedTalentsTableContainer>
  );
};

export default ManagedTalentsTable;
