import { useState } from 'react';

import { ManageUsers } from './components/ManageUsers/ManageUsers';
import { RolesPermissionsOverview } from './components/RolesPermissionsOverview';
import { type Role } from './interfaces';

const RolesPermissionsTab = () => {
  const [isManageUsers, setIsManageUsers] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);

  const updateIsManageUsers = (newValue: boolean) => {
    setIsManageUsers(newValue);
  };

  const updateSelectedRole = (newValue: Role) => {
    setSelectedRole(newValue);
  };

  if (isManageUsers) {
    return (
      <ManageUsers
        role={selectedRole}
        updateIsManageUsers={updateIsManageUsers}
      />
    );
  }

  return (
    <RolesPermissionsOverview
      selectedRole={selectedRole}
      updateIsManageUsers={updateIsManageUsers}
      updateSelectedRole={updateSelectedRole}
    />
  );
};

export default RolesPermissionsTab;
