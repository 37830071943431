import { useEffect, useMemo, useState } from 'react';
import { Flex } from 'glints-aries';
import { PlainButton, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space12 } from 'glints-aries/lib/@next/utilities/spacing';
import { gql } from 'graphql-request';
import { keyBy } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { ContactListItem } from './components/ContactListItem/ContactListItem';
import { ReportingHubbersPanel } from './components/ReportingHubbersPanel/ReportingHubbersPanel';
import { UnassignedEmployeesModal } from './components/UnassignedEmployeesModal/UnassignedEmployeesModal';
import { sortManagerContacts } from './helpers';
import * as Styled from './styled.sc';
import { getGraphqlClient } from '@/clients/graphql';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import {
  type GetCompanyContactsReportingLinesQuery,
  type GetCompanyUnassignedDirectManagerRelationCountQuery,
  useGetCompanyContactsReportingLinesQuery,
  useGetCompanyUnassignedDirectManagerRelationCountQuery,
} from '@/generated/graphql';
import { hubberFragment } from '@/graphql/fragments/hubber';
import { useGraphqlError } from '@/hooks/useGraphqlError';

export const ReportingLinesTab = () => {
  const graphqlClient = getGraphqlClient();
  const { userInfo } = useAuthContext();

  const {
    data: getCompanyContactsReportingLinesData,
    refetch: refetchCompanyContactsReportingLines,
    error: getCompanyContactsReportingLinesError,
  } = useGetCompanyContactsReportingLinesQuery<
    GetCompanyContactsReportingLinesQuery,
    Error
  >(
    graphqlClient,
    {},
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    data: companyUnassignedDirectManagerRelationCountQuery,
    refetch: refetchCompanyUnassignedDirectManagerRelationCount,
    error: getCompanyUnassignedDirectManagerRelationCountError,
  } = useGetCompanyUnassignedDirectManagerRelationCountQuery<
    GetCompanyUnassignedDirectManagerRelationCountQuery,
    Error
  >(
    graphqlClient,
    {},
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const contactsData = useMemo(
    () => getCompanyContactsReportingLinesData?.company.contacts ?? [],
    [getCompanyContactsReportingLinesData],
  );
  const sortedManagerContacts = useMemo(
    () => sortManagerContacts(contactsData, userInfo?.contact),
    [contactsData, userInfo?.contact],
  );
  const contactsMap = useMemo(
    () => keyBy(sortedManagerContacts, 'id'),
    [sortedManagerContacts],
  );

  const [selectedContactId, setSelectedContactId] = useState<string>();
  // prevent re-intializing selectedContactId when refetching company contacts
  const [isSelectedContactIdInitialized, setIsSelectedContactIdInitialized] =
    useState(false);

  const reportingHubbers = useMemo(
    () =>
      selectedContactId ? contactsMap[selectedContactId].reportingLines : [],
    [selectedContactId, contactsMap],
  );

  const [isUnassignedEmployeesModalOpen, setIsUnassignedEmployeesModalOpen] =
    useState(false);

  useEffect(() => {
    if (isSelectedContactIdInitialized || sortedManagerContacts.length === 0)
      return;
    setSelectedContactId(sortedManagerContacts[0].id);
    setIsSelectedContactIdInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedManagerContacts]);

  useGraphqlError([
    getCompanyContactsReportingLinesError,
    getCompanyUnassignedDirectManagerRelationCountError,
  ]);

  return (
    <>
      {companyUnassignedDirectManagerRelationCountQuery &&
        companyUnassignedDirectManagerRelationCountQuery.company
          .unassignedDirectManagerRelationCount > 0 && (
          <Styled.Banner dismissable={false} status="warning" type="fixed">
            <Flex flexDirection="row" alignItems="center" gap={space12}>
              <FormattedMessage
                id="unassigned.direct.manager.relation.count"
                defaultMessage="{count, plural, one {# employee} other {# employees}} missing assigned managers."
                values={{
                  count:
                    companyUnassignedDirectManagerRelationCountQuery.company
                      .unassignedDirectManagerRelationCount,
                }}
              />
              <PlainButton
                onClick={() => setIsUnassignedEmployeesModalOpen(true)}
                size="slim"
              >
                <Typography variant="button">View</Typography>
              </PlainButton>
            </Flex>
          </Styled.Banner>
        )}

      <Styled.ReportingLinesContainer
        makeBannerSpace={
          companyUnassignedDirectManagerRelationCountQuery &&
          companyUnassignedDirectManagerRelationCountQuery.company
            .unassignedDirectManagerRelationCount > 0
        }
      >
        <Styled.Header>
          <Typography as="span" variant="body2" color={Neutral.B18}>
            Reporting Lines
          </Typography>
          <Typography as="span" variant="subtitle2" color={Neutral.B18}>
            To get instant notifications for new time-off requests and expense
            claims and manage them by approving or rejecting.
          </Typography>
        </Styled.Header>
        <Styled.Body>
          <Styled.Sider>
            {sortedManagerContacts.map((contact) => (
              <ContactListItem
                key={contact.id}
                contact={contact}
                reportLength={contact.reportingLineCount}
                active={selectedContactId === contact.id}
                onClick={(contactId) => setSelectedContactId(contactId)}
              />
            ))}
          </Styled.Sider>
          <Styled.Content>
            <ReportingHubbersPanel
              selectedContact={
                selectedContactId ? contactsMap[selectedContactId] : null
              }
              reportingHubbers={reportingHubbers}
              refetchCompanyContactsReportingLines={
                refetchCompanyContactsReportingLines
              }
              refetchCompanyUnassignedDirectManagerRelationCount={
                refetchCompanyUnassignedDirectManagerRelationCount
              }
            />
          </Styled.Content>
        </Styled.Body>
      </Styled.ReportingLinesContainer>

      {companyUnassignedDirectManagerRelationCountQuery &&
        companyUnassignedDirectManagerRelationCountQuery.company
          .unassignedDirectManagerRelationCount > 0 && (
          <UnassignedEmployeesModal
            isOpen={isUnassignedEmployeesModalOpen}
            unassignedCount={
              companyUnassignedDirectManagerRelationCountQuery.company
                .unassignedDirectManagerRelationCount
            }
            onClose={() => setIsUnassignedEmployeesModalOpen(false)}
          />
        )}
    </>
  );
};

ReportingLinesTab.fragments = {
  contact: gql`
    fragment ReportingLinesTabContact on Contact {
      id
      name
      roles {
        id
        name
      }
      reportingLines {
        ...Hubber
      }
      reportingLineCount
    }
    ${hubberFragment}
  `,
};
