import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { card as cardDropShadow } from 'glints-aries/lib/@next/utilities/dropShadow';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const ReportingHubberCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${space16};
  padding: ${space16};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33.333% - ${space16});
  max-width: calc(33.333% - ${space16});
  border-radius: ${borderRadius8};
  background-color: ${Neutral.B100};
  box-shadow: ${cardDropShadow};
  padding: ${space8} ${space16};
  margin-bottom: 16px;
  position: relative;
  min-width: 240px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
`;

export const CardActionButton = styled.div`
  position: absolute;
  right: ${space8};
  top: ${space8};
`;
