import { useState } from 'react';
import {
  ActionList,
  type ActionListItem,
  Icon,
  Popover,
  Typography,
} from 'glints-aries/lib/@next';

import * as Styled from './styled.sc';

export interface ExportPopoverButtonProps {
  disableExportFiltered: boolean;
  onExportAll?: () => void;
  onExportFilteredData?: () => void;
}

export const ExportPopoverButton = ({
  disableExportFiltered = true,
  onExportAll,
  onExportFilteredData,
}: ExportPopoverButtonProps) => {
  const [popoverActivated, setPopoverActivated] = useState(false);

  const activator = (
    <Styled.Button onClick={() => setPopoverActivated(true)} loading={false}>
      <Icon name="ri-download-line" className="export-download-icon" />
      <Typography variant="button">Export</Typography>
      <Icon name="ri-arrow-m-down-fill" className="export-down-icon" />
    </Styled.Button>
  );

  const actions: ActionListItem[] = [
    { content: 'Export all', action: onExportAll },
    {
      content: 'Export filtered data',
      action: onExportFilteredData,
      disabled: disableExportFiltered,
    },
  ];

  return (
    <Styled.Container>
      <Popover
        active={popoverActivated}
        activator={activator}
        onClose={() => setPopoverActivated(false)}
      >
        <ActionList items={actions} />
      </Popover>
    </Styled.Container>
  );
};
