import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { timeOffTypeMapping } from '../../../constants';
import { TimeOffTypeContainer } from './styled.sc';
import { TimeOffCategory } from '@/generated/graphql';

export const renderTimeOffType = ({
  name,
  category,
}: {
  name: string | null;
  category: TimeOffCategory;
}) => {
  if (!name) {
    return (
      <Typography as="span" variant="caption" color={Neutral.B68}>
        Undefined
      </Typography>
    );
  }

  if (category === TimeOffCategory.Others) {
    return (
      <TimeOffTypeContainer>
        <Typography as="span" variant="caption">
          {timeOffTypeMapping[category]}
        </Typography>
        <Typography as="span" variant="subtitle2" color={Neutral.B40}>
          {name}
        </Typography>
      </TimeOffTypeContainer>
    );
  }

  return (
    <Typography as="span" variant="caption">
      {timeOffTypeMapping[category]}
    </Typography>
  );
};
