import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const DataRow = styled.div<{
  borderBold?: boolean;
  noBorder?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${space16} 0 ${space8} 0;
  border-bottom: ${(props) =>
    props.noBorder ? 'none' : `1px solid ${Neutral.B85}`};
  border-width: ${(props) => (props.borderBold ? '2px' : '1px')};
  border-color: ${(props) => (props.borderBold ? Neutral.B68 : Neutral.B85)};
`;

export const TotalLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
`;

export const IndentLabel = styled.div<{ depth: number }>`
  padding-left: ${(props) => props.depth * 30}px;
`;
