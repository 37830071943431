import { Button as AriesButton, Icon } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
`;

export const Button = styled(AriesButton)`
  margin-left: auto;
  border: none !important;
  box-shadow: none !important;
  background: ${Neutral.B95};
  padding-left: 0;

  &:hover {
    background: ${Neutral.B95};
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space8};
`;

export const ButtonContentIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  fill: ${Blue.S99} !important;
`;
