import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';
import '@fontsource/poppins/700.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import {
  AlertProvider,
  AlertWithProvider,
  ModalWithProvider,
} from 'glints-aries/lib/@next';
import { BaseContainer } from 'glints-aries/lib/Layout/GlintsContainer/GlintsContainer';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import GlobalStyle from './globalStyle';
import { router } from './Routes';
import { defaultGlintsAntdTableTheme } from '@/components/atoms/GlintsAntdTable/constants';
import { HubberDetailsSideSheetProvider } from '@/components/organisms/SideSheets/HubberDetailsSideSheet/context';
import { AuthInfoProvider } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { FeatureFlagProvider } from '@/components/particles/FeatureFlagProvider/FeatureFlagProvider';
import { LocaleProvider } from '@/components/particles/LocaleProvider';
import { ParticlesComposer } from '@/components/particles/ParticlesComposer';

const particles = [FeatureFlagProvider, HubberDetailsSideSheetProvider];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

const App = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <AuthInfoProvider>
        <ParticlesComposer particles={particles}>
          <LocaleProvider locale="en">
            <AntdConfigProvider theme={defaultGlintsAntdTableTheme}>
              <GlobalStyle />
              <ModalWithProvider />
              <AlertProvider>
                <BaseContainer>
                  <RouterProvider router={router} />
                  <div id="glints-portal-container" />
                </BaseContainer>
                <ReactQueryDevtools />
                <AlertWithProvider />
              </AlertProvider>
            </AntdConfigProvider>
          </LocaleProvider>
        </ParticlesComposer>
      </AuthInfoProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

export default App;
