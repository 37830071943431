import { EmptyState as AriesEmptyState } from 'glints-aries/lib/@next';

import * as Styled from './styled.sc';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';

type EmptyStateProps = {
  isCurrentUser: boolean;
  name: string;
};

export const EmptyState = ({ isCurrentUser, name }: EmptyStateProps) => {
  const displayText = isCurrentUser ? 'you' : name;

  return (
    <Styled.EmptyStateContainer>
      <AriesEmptyState
        title={`No one reports to ${displayText}`}
        image={<NebulaSVG />}
      />
    </Styled.EmptyStateContainer>
  );
};
