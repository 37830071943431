import { useState } from 'react';
import {
  Icon,
  Menu,
  Popover,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

import * as Styled from './styled.sc';
import { Currency } from '@/constants/currency';

export const currencyOptionList = [
  { label: Currency.SINGAPORE, value: Currency.SINGAPORE },
  { label: Currency.INDONESIA, value: Currency.INDONESIA },
  { label: Currency.UNITED_STATES, value: Currency.UNITED_STATES },
];

interface FormPaymentCurrencySelectProps<T extends FieldValues>
  extends UseControllerProps<T> {
  onCurrencyChange: (value: string) => void;
}

export const FormPaymentCurrencySelect = <T extends FieldValues>({
  control,
  name,
  onCurrencyChange,
}: FormPaymentCurrencySelectProps<T>) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = () => {
    setPopoverActive((prevState) => !prevState);
  };

  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const handleCurrencyChange = (value: string) => {
    togglePopoverActive();
    field.onChange(value);
    onCurrencyChange(value);
  };

  const activator = (
    <Styled.Container>
      <Tooltip
        content="Glints shall apply a floating foreign exchange rate to convert amounts denominated in a different currency based on the date and time of the invoice and shall be determined by Glints (and/or its Affiliates) with reference by referring to third-party FX providers."
        preferredPosition="left-middle"
      >
        <Styled.ButtonContentIcon name="ri-information-fill" fill={Blue.S99} />
      </Tooltip>
      <Styled.Button onClick={togglePopoverActive} type="button">
        <Styled.ButtonContent>
          <Typography variant="body1">{field.value}</Typography>
          <Icon name="ri-arrow-m-down-line" fill={Neutral.B40} />
        </Styled.ButtonContent>
      </Styled.Button>
    </Styled.Container>
  );

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      fullWidth={true}
    >
      <Popover.Pane>
        <Menu
          options={currencyOptionList}
          allowMultiple={false}
          onClick={({ value }) => handleCurrencyChange(value)}
          selectedValues={[field.value]}
        />
      </Popover.Pane>
    </Popover>
  );
};
