import { Tooltip, type TooltipProps } from 'antd';
import { borderRadius2 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space12 } from 'glints-aries/lib/@next/utilities/spacing';

export interface GlintsAntdTooltipProps {
  children?: React.ReactNode;
  placement?: TooltipProps['placement'];
  title?: TooltipProps['title'];
  trigger?: TooltipProps['trigger'];
  zIndex?: TooltipProps['zIndex'];
  width?: string;
}

export const GlintsAntdTooltip = ({
  children,
  placement = 'bottom',
  title,
  trigger,
  zIndex,
  width,
}: GlintsAntdTooltipProps) => (
  <Tooltip
    placement={placement}
    title={title}
    arrow={{
      pointAtCenter: true,
    }}
    trigger={trigger ?? 'hover'}
    zIndex={zIndex}
    overlayInnerStyle={{
      backgroundColor: Neutral.B18,
      borderRadius: borderRadius2,
      fontWeight: 400,
      padding: `${space8} ${space12}`,
      boxShadow: 'none',
      width,
    }}
  >
    {children}
  </Tooltip>
);
