import { space8, space12 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

const CUSTOM_BREAKPOINT = '820px';

export const TablePaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${space8};

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    margin: ${space12} 0 ${space8};
    flex-direction: column;
    gap: 10px;
  }
`;
