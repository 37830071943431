import {
  Pagination,
  type PaginationProps,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { TablePaginationContainer } from './TablePaginationStyle';

export const PAGE_SIZE = 10;

export const TablePagination = ({
  currentPage = 1,
  pageSize = PAGE_SIZE,
  totalItems,
  onPageChanged,
}: PaginationProps) => {
  const computePageCount = () => {
    let from = 1;
    let to = 1;

    if (totalItems === 1 && pageSize === 1) {
      return `${from} - ${to}`;
    }

    if (totalItems < pageSize) {
      return `${from} - ${totalItems}`;
    }

    const pageMultipleCount = pageSize * currentPage;
    const subtractFromTotal = totalItems - pageMultipleCount;
    const remainder = totalItems % pageSize;

    from = pageMultipleCount - pageSize + 1;
    to =
      subtractFromTotal < 0
        ? pageSize * (currentPage - 1) + remainder
        : pageMultipleCount;

    return `${from} - ${to}`;
  };

  return (
    <TablePaginationContainer className="table-pagination">
      <Typography as="div" variant="body1" color={Neutral.B40}>
        {computePageCount()} of {totalItems}
      </Typography>
      <Pagination
        totalItems={totalItems}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChanged={onPageChanged}
      />
    </TablePaginationContainer>
  );
};
