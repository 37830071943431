import { Flex } from 'glints-aries';
import { Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styled.sc';
import {
  GlintsAntdTooltip,
  type GlintsAntdTooltipProps,
} from '@/components/atoms/GlintsAntdTooltip/GlintsAntdTooltip';

const REPORTING_LINES_PAGE_URL = '/company-settings/reporting-lines';

export interface NonDirectManagerTooltipProps {
  children: React.ReactNode;
  placement?: GlintsAntdTooltipProps['placement'];
}

export const NonDirectManagerTooltip = ({
  children,
  placement,
}: NonDirectManagerTooltipProps) => {
  const navigate = useNavigate();

  return (
    <GlintsAntdTooltip
      placement={placement}
      title={
        <Flex gap="10px" flexDirection="column">
          <Typography variant="subtitle2" color={Neutral.B100}>
            This employee doesn&apos;t report to you.
          </Typography>
          <Styled.ViewReportingLinesButton
            onClick={() => navigate(REPORTING_LINES_PAGE_URL)}
          >
            <Typography variant="button" color={Neutral.B100}>
              View Reporting Lines
            </Typography>
            <Icon name="ri-arrow-m-right-line" />
          </Styled.ViewReportingLinesButton>
        </Flex>
      }
      width="275px"
    >
      {children}
    </GlintsAntdTooltip>
  );
};
