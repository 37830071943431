import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ErrorFrame, {
  errorType as ErrorOptions,
} from './components/Frame/ErrorFrame';
import { LoadingLayout } from './components/Layout/LoadingLayout';
import { LoginLayout } from './components/Layout/LoginLayout';
import { GreyBackgroundOverlay } from './components/Layout/LoginLayoutStyle';
import { getGraphqlClient } from '@/clients/graphql';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import {
  type ResolveMagicLinkMutation,
  useResolveMagicLinkMutation,
} from '@/generated/graphql';
import { useAuthToken } from '@/hooks/useAuthToken';

const ANIMATION_DURATION = 3000;

interface MagicLinkPageProps {
  pageTitle: string;
  metaDescription: string;
}

const MagicLinkPage = ({ pageTitle, metaDescription }: MagicLinkPageProps) => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const { setAuthToken } = useAuthToken();
  const { userInfo, refetch: refetchUserData } = useAuthContext();

  const [searchParams] = useSearchParams();
  const magicLinkCode = searchParams.get('key');

  const [loading, setLoading] = useState(true);

  const { errorType } = (magicLinkCode && { errorType: null }) as {
    errorType: 'TOKEN_EXPIRED' | 'UNAUTHORIZED' | null;
  };

  const { isError, mutate } = useResolveMagicLinkMutation<
    ResolveMagicLinkMutation,
    Error
  >(graphqlClient, {
    onSuccess: (data) => {
      if (data.resolveMagicLink.accessToken) {
        setAuthToken(data.resolveMagicLink.accessToken);
        refetchUserData();
      }
    },
  });

  useEffect(() => {
    if (isError) {
      setLoading(false);
    }
  }, [isError]);

  useEffect(() => {
    if (magicLinkCode && !userInfo) {
      mutate({
        code: magicLinkCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magicLinkCode]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;

    if (userInfo) {
      timer = setTimeout(() => {
        setLoading(false);
        navigate('/');
      }, ANIMATION_DURATION);
    }

    if (timer) {
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      {loading && <LoadingLayout />}
      {!loading && (isError || errorType !== null) && (
        <>
          <GreyBackgroundOverlay />
          <LoginLayout>
            <ErrorFrame
              errorType={
                errorType === ErrorOptions.TOKEN_EXPIRED
                  ? ErrorOptions.LINK_EXPIRED
                  : errorType || ErrorOptions.LINK_INVALID
              }
            />
          </LoginLayout>
        </>
      )}
    </>
  );
};

export default MagicLinkPage;
