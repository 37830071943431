import { IntlProvider } from 'react-intl';
import { DEFAULT_INTL_CONFIG } from 'react-intl/src/utils';

type Locale = 'en' | 'zh';

export type LocaleProviderProps = { children: React.ReactNode; locale: Locale };

export const LocaleProvider: React.FunctionComponent<LocaleProviderProps> = ({
  children,
  locale,
}: LocaleProviderProps) => (
  <IntlProvider
    locale={locale}
    onError={(error) => {
      // to slience the missing translation errors which we are not focusing on right now
      if (error.code.toString() !== 'MISSING_TRANSLATION') {
        DEFAULT_INTL_CONFIG.onError(error);
      }
    }}
  >
    {children}
  </IntlProvider>
);
