import { ErrorTab } from '../components/ErrorTab';
import { AccountManager } from './AccountManager';
import BasicInfo from './BasicInfo';
import BillingInfo from './BillingInfo';
import { getGraphqlClient } from '@/clients/graphql';
import {
  type GetCompanyProfileQuery,
  useGetCompanyProfileQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';

export const CompanyDetailsTab = () => {
  const graphqlClient = getGraphqlClient();

  const { isLoading, data, error, refetch, isFetching } =
    useGetCompanyProfileQuery<GetCompanyProfileQuery, Error>(graphqlClient);

  useGraphqlError([error]);

  return Boolean(error) && !(isLoading || isFetching) ? (
    <ErrorTab refetch={refetch} />
  ) : (
    <>
      <AccountManager
        name={data?.company?.accountManager?.name}
        email={data?.company?.accountManager?.email}
        isLoading={isLoading || isFetching}
      />
      <BasicInfo
        name={data?.company.name || ''}
        registeredName={data?.company.registeredName}
        registeredAddress={data?.company.registeredAddress.street}
        operatingAddress={data?.company.operatingAddress.street}
        isLoading={isLoading || isFetching}
      />
      <BillingInfo
        billingName={data?.company.billingName}
        billingEmail={data?.company.billingEmail}
        billingAddress={data?.company.billingAddress.street}
        isLoading={isLoading || isFetching}
      />
    </>
  );
};
