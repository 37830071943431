import { Breakpoints } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space12,
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled, { createGlobalStyle } from 'styled-components';

import {
  HOME_BACKGROUND_IMAGE_LINK,
  HOME_BACKGROUND_MOBILE_IMAGE_LINK,
} from '../../constants/constants';

export const StyledHomeBackground = createGlobalStyle`
  body {
    background-image: url(${HOME_BACKGROUND_IMAGE_LINK});
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: -1;

    @media (max-width: ${Breakpoints.large}) {
      background-image: url(${HOME_BACKGROUND_MOBILE_IMAGE_LINK});
    }
  }
`;

export const PageLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${space24};

  @media (max-width: ${Breakpoints.large}) {
    flex-direction: column;
    margin: ${space12};
  }
`;

export const MainPageContainer = styled.div<{ disabledPadding?: boolean }>`
  flex-grow: 1;
  margin-left: 240px;
  padding: ${(props) =>
    props.disabledPadding ? 'unset' : `${space32} ${space24}`};
  height: calc(100vh - 55px);
  overflow: auto;

  // invisible scrollbar
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &[data-hasframe='true'] {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 6px 0px ${Neutral.B85};
    backdrop-filter: blur(3px);
    overflow: auto;
  }

  &[data-collapsed='true'] {
    margin-left: 82px;
  }

  &[data-centered='true'] {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${Breakpoints.large}) {
    margin-left: 0;
    width: calc(100vw - 24px);
    height: calc(100vh - 86px);
    padding: ${(props) => (props.disabledPadding ? 'unset' : `${space16}`)};

    &[data-collapsed='true'] {
      margin-left: 0;
      width: calc(100vw - 24px);
    }

    &[data-hasframe='true'] {
      margin-top: 66px;
      border-radius: 8px;
    }
  }
`;
