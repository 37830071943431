import { useEffect, useState } from 'react';
import { Box } from 'glints-aries';
import { Icon, PrimaryButton } from 'glints-aries/lib/@next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ROLE_UNASSIGNED,
  roleCountMapping,
  roleDetailsMapping,
  roleRouteMapping,
  type RoleUnassigned,
} from '../constants';
import {
  type GetUsersCountPerRoleQuery,
  Role,
  type RolesPermissionsOverviewProps,
} from '../interfaces';
import {
  CardLi,
  Container,
  RolesPermissionsCards,
} from '../RolesPermissionsTabStyle';
import { AddEditUserModal } from './AddEditUserModal/AddEditUserModal';
import { PermissionSideSheet } from './PermissionSideSheet/PermissionSideSheet';
import { RolesPermissionsCard } from './RolesPermissionsCard';
import { getGraphqlClient } from '@/clients/graphql';
import { useGetUsersCountPerRoleQuery } from '@/generated/graphql';

export const RolesPermissionsOverview = ({
  selectedRole,
  updateIsManageUsers,
  updateSelectedRole,
}: RolesPermissionsOverviewProps) => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === '/company-settings/roles-and-permissions/company-admin') {
      updateSelectedRole(Role.CompanyAdmin);
      updateIsManageUsers(true);
    } else if (
      pathname === '/company-settings/roles-and-permissions/people-manager'
    ) {
      updateSelectedRole(Role.PeopleManager);
      updateIsManageUsers(true);
    } else if (pathname === '/company-settings/roles-and-permissions/payer') {
      updateSelectedRole(Role.Payer);
      updateIsManageUsers(true);
    } else if (pathname === '/company-settings/roles-and-permissions/viewer') {
      updateSelectedRole(Role.Viewer);
      updateIsManageUsers(true);
    } else if (
      pathname === '/company-settings/roles-and-permissions/unassigned'
    ) {
      updateSelectedRole(ROLE_UNASSIGNED as RoleUnassigned);
      updateIsManageUsers(true);
    } else {
      updateIsManageUsers(false);
    }
  }, [pathname, updateSelectedRole, updateIsManageUsers]);

  const isUnassignedRole = selectedRole === ROLE_UNASSIGNED;
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [showSideSheet, setShowSideSheet] = useState(false);

  const {
    isLoading,
    data,
    refetch: refetchUsersCountPerRole,
  } = useGetUsersCountPerRoleQuery<GetUsersCountPerRoleQuery, Error>(
    graphqlClient,
    {},
    { staleTime: 0 },
  );

  const handleAddUser = () => {
    setOpenAddUserModal(true);
  };

  const handleCloseUserModal = () => {
    setOpenAddUserModal(false);
  };

  const updateShowSideSheet = (newValue: boolean) => {
    setShowSideSheet(newValue);
  };

  return (
    <Container>
      <AddEditUserModal
        isOpen={openAddUserModal}
        onClose={handleCloseUserModal}
        refetchUsersCountPerRole={refetchUsersCountPerRole}
      />
      {!isUnassignedRole && (
        <PermissionSideSheet
          showSideSheet={showSideSheet}
          updateShowSideSheet={updateShowSideSheet}
          role={selectedRole}
        />
      )}

      <Box pb={12}>
        <PrimaryButton icon={<Icon name="ri-add" />} onClick={handleAddUser}>
          Add User
        </PrimaryButton>
      </Box>

      <RolesPermissionsCards>
        {[...Object.values(Role), ROLE_UNASSIGNED as RoleUnassigned].map(
          (role) => (
            <CardLi key={role}>
              <RolesPermissionsCard
                role={roleDetailsMapping[role as Role].role}
                roleDetails={roleDetailsMapping[role as Role].roleDetails}
                userCount={
                  data?.company && data?.company[roleCountMapping[role]]
                }
                loadingUserCount={isLoading}
                onManageUsersClick={() => {
                  updateSelectedRole(role);
                  updateIsManageUsers(true);
                  navigate(roleRouteMapping[role]);
                }}
                onViewPermissions={() => {
                  updateSelectedRole(role);
                  updateShowSideSheet(true);
                }}
              />
            </CardLi>
          ),
        )}
      </RolesPermissionsCards>
    </Container>
  );
};
