import { type IndexTableProps } from 'glints-aries/lib/@next/IndexTable/IndexTable';

import { LeftPaddingWrapper, PaymentAmountWrapper } from './styled.sc';

export const resourceName = {
  singular: 'invoice',
  plural: 'invoices',
};

export const baseHeadings: IndexTableProps['headings'] = [
  {
    title: <LeftPaddingWrapper>Invoice Status</LeftPaddingWrapper>,
    id: 'invoice-status',
  },
  { title: 'Due Date' },
  { title: 'Invoice Reference' },
  {
    title: <PaymentAmountWrapper>Payment Amount</PaymentAmountWrapper>,
    id: 'payment-amount',
  },
];

export const multipleSelectionHeadings: IndexTableProps['headings'] = [
  ...baseHeadings,
  { title: 'Actions' },
];
