import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space12, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const Button = styled.button`
  display: flex;
  background: ${Neutral.B100};
  border-radius: 100px;
  padding: ${space12};
  gap: ${space16};
  border-width: 0;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(71, 71, 71, 0.1),
    0px 1px 2px 0px rgba(71, 71, 71, 0.2);
  z-index: 2;
`;

export const ButtonSVG = styled.div`
  svg {
    width: 60px;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    svg {
      width: 40px;
    }
  }
`;

export const ButtonContent = styled.div`
  text-align: left;
  flex-grow: 1;
`;

export const ButtonArrowIcon = styled.div`
  width: 36px;

  @media (max-width: ${BREAKPOINTS.small}) {
    svg {
      width: 20px;
    }
  }
`;
