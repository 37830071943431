import { Button, Card, Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { CardHeadingText } from '../AutoPaymentTabStyle';
import { type BankSwiftCode } from '../constants';
import { type BankDetails } from '../interfaces';

export interface BankCardProps extends Omit<BankDetails, 'url'> {
  bank: BankSwiftCode;
  updateShowSetupWithBank(newValue: boolean): void;
  updateSelectedBank(newValue: BankSwiftCode): void;
  disabled?: boolean;
}

export const BankCard = ({
  bank,
  bankName,
  icon,
  disabled = false,
  updateShowSetupWithBank,
  updateSelectedBank,
}: BankCardProps) => (
  <Card
    key={bankName}
    heading={
      <div className="card-custom-heading">
        {icon}
        <CardHeadingText>
          <Typography variant="subtitle1" as="span">
            {bankName}
          </Typography>
          <Typography variant="body1" as="span" color={Neutral.B40}>
            Business accounts only for connection
          </Typography>
        </CardHeadingText>
      </div>
    }
    customActions={
      <Button
        onClick={() => {
          updateShowSetupWithBank(true);
          updateSelectedBank(bank);
        }}
        icon={<Icon name="ri-arrow-m-right-line" />}
        iconPosition="right"
        disabled={disabled}
      >
        Connect
      </Button>
    }
  />
);
