import { useEffect, useState } from 'react';
import { Checkbox, type CheckboxProps } from 'glints-aries/lib/@next';
import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

import * as Styled from './styled.sc';
import { TextInputError } from '@/components/FormTextInput/FormTextInput';

interface FormCheckboxInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<CheckboxProps, 'value' | 'defaultValue' | 'name'> {
  label?: string | React.ReactNode;
  apiError?: string | null;
}

export const FormCheckboxInput = <T extends FieldValues>({
  control,
  name,
  label,
  apiError,
  ...props
}: FormCheckboxInputProps<T>) => {
  const [checkedValue, setCheckedValue] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: props.rules,
  });

  useEffect(() => {
    setCheckedValue(field.value);
  }, [field.value]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.message);
    }
    if (apiError) {
      setErrorMessage(apiError);
    }
  }, [error, apiError]);

  return (
    <>
      <Styled.CheckboxContainer>
        <Checkbox
          {...props}
          onChange={field.onChange}
          onBlur={field.onBlur}
          checked={checkedValue}
          name={field.name}
          ref={field.ref}
        />
        <Styled.Label
          onClick={() => {
            field.onChange(!field.value);
            setCheckedValue(!field.value);
          }}
        >
          {label}
        </Styled.Label>
      </Styled.CheckboxContainer>
      {(error || apiError) && <TextInputError errorMessage={errorMessage} />}
    </>
  );
};
