import { roundDownNearestHalf } from '@/utils/math';

export const timeOffDurationText = ({
  timeoffDuration,
}: {
  timeoffDuration: string;
}) => {
  const parseStringtoFloat = parseFloat(timeoffDuration);
  const stringDay = parseStringtoFloat <= 1 ? 'day' : 'days';
  const duration = roundDownNearestHalf(parseStringtoFloat).toFixed(1);
  return `${duration} ${stringDay}`;
};

export const validateRejectReason = (value: string) => {
  if (value.trim() === '') {
    return 'Reason cannot be blank';
  }
  if (value.length < 20) {
    return 'Reason is too short. Please add more details';
  }
  return undefined;
};
