import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Badge,
  Icon,
  Modal,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { type User, type UserFields } from './interfaces';
import * as Styled from './styled.sc';
import { getGraphqlClient } from '@/clients/graphql';
import { FormTextInput } from '@/components/FormTextInput/FormTextInput';
import {
  type UpdateUserProfileMutation,
  useUpdateUserProfileMutation,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { AlertType } from '@/modules/AttendanceLog/constants';
import { PhoneTextInput } from '@/modules/CompanyProfile/RolesPermissionsTab/components/AddEditUserModal/PhoneInput';
import {
  COUNTRY_CODE_OPTIONS,
  roleDetailsMapping,
} from '@/modules/CompanyProfile/RolesPermissionsTab/constants';
import { getInitials } from '@/utils/formatString';

export interface MyProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  user?: User;
  updateUser(data: User): void;
  updateShowAlert(showAlertValue: {
    shouldShow: boolean;
    type: AlertType;
  }): void;
}

const validationSchema = z.object({
  /* eslint-disable camelcase */
  department: z.string().nullish(),
  phoneNumber: z.object({
    countryCode: z.string().regex(/\+\d+/).nullish(),
    number: z
      .string()
      .regex(/^(\d{5,})?$/, 'Numbers only, no characters or whitespace')
      .max(15, 'Invalid phone number')
      .nullish(),
    extension: z
      .string()
      .regex(/^(?:\d+)?$/, 'Numbers only, no characters or whitespace')
      .max(7, 'Invalid phone number')
      .nullish(),
    /* eslint-enable camelcase */
  }),
});

export const MyProfileModal = ({
  isOpen,
  onClose,
  user,
  updateUser,
  updateShowAlert,
}: MyProfileModalProps) => {
  const graphqlClient = getGraphqlClient();

  const [isEdit, setIsEdit] = useState(false);

  const defaultValues = {
    department: user?.department ?? '',
    phoneNumber: {
      countryCode:
        user?.phoneNumber?.countryCode ?? COUNTRY_CODE_OPTIONS[3].value,
      number: user?.phoneNumber?.number ?? '',
      extension: user?.phoneNumber?.extension ?? '',
    },
  };

  const {
    control,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm<UserFields>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const {
    isLoading: isEditLoading,
    mutate: editMutate,
    error: updateUserProfileError,
  } = useUpdateUserProfileMutation<Error, UpdateUserProfileMutation>(
    graphqlClient,
  );

  const onEdit = async (userFields: UserFields) => {
    editMutate(
      {
        userProfileInput: {
          ...userFields,
          email: user?.email ?? '',
          jobTitle: user?.jobTitle ?? '',
          name: user?.name ?? '',
        },
      },
      {
        onSuccess: (data) => {
          updateUser(data.updateProfile);
          updateShowAlert({ shouldShow: true, type: AlertType.SUCCESS });
          setIsEdit(false);
        },
        onError: () => {
          updateShowAlert({ shouldShow: true, type: AlertType.ERROR });
        },
      },
    );
  };

  useEffect(() => {
    if (user) {
      resetForm(user, { keepDefaultValues: true });
    }
  }, [resetForm, user]);

  const customActions = (
    <PrimaryButton
      onClick={() => setIsEdit(true)}
      icon={<Icon name="ri-pencil-line" />}
      iconPosition="left"
    >
      Edit
    </PrimaryButton>
  );

  const editPrimaryAction = {
    label: 'Save',
    action: handleSubmit(onEdit),
    loading: isEditLoading,
    disabled: !formState.isValid,
  };
  const editSecondaryAction = {
    label: 'Cancel',
    action: () => setIsEdit(false),
    disabled: isEditLoading,
  };

  const modalBanner = (
    <Styled.Banner>
      <Styled.TopBannerSection>
        <Styled.Avatar
          initials={getInitials(user?.name || '')}
          size="large"
          variant="warning"
        />
        <Styled.UserInfoSection>
          <Styled.UserInfoRow>
            <Typography as="div" variant="body2" color={Neutral.B18}>
              {user?.name}
            </Typography>
            <Badge
              status="neutral"
              style={{
                marginLeft: 'auto',
              }}
            >
              {user?.roles[0] && roleDetailsMapping[user?.roles[0].id]?.role}
            </Badge>
          </Styled.UserInfoRow>
          <Styled.UserInfoRow>
            <Icon name="ri-user-line" height={16} fill={Neutral.B40} />
            <Typography
              as="div"
              variant="body1"
              color={user?.jobTitle ? Neutral.B18 : Neutral.B85}
            >
              {user?.jobTitle ?? 'Unknown'}
            </Typography>
          </Styled.UserInfoRow>
          <Styled.UserInfoRow>
            <Icon name="ri-mail-line" height={16} fill={Neutral.B40} />
            <Typography as="div" variant="body1" color={Neutral.B18}>
              {user?.email}
            </Typography>
          </Styled.UserInfoRow>
        </Styled.UserInfoSection>
      </Styled.TopBannerSection>
      <Styled.BottomBannerSection>
        <Icon name="ri-error-warning-fill" height={16} fill={Neutral.B40} />
        <Typography as="div" variant="overline" color={Neutral.B40}>
          Need an update? Contact your company admins
        </Typography>
      </Styled.BottomBannerSection>
    </Styled.Banner>
  );

  const modalContent = isEdit ? (
    <>
      <FormTextInput
        control={control}
        label="Department"
        name="department"
        placeholder="Enter department"
      />
      <PhoneTextInput control={control} label="Phone" />
    </>
  ) : (
    <>
      <Styled.SingleInfoSection>
        <Typography as="div" variant="caption" color={Neutral.B18}>
          Department
        </Typography>
        <Typography
          as="div"
          variant="body1"
          color={user?.department ? Neutral.B18 : Neutral.B85}
        >
          {user?.department ?? 'Unknown'}
        </Typography>
      </Styled.SingleInfoSection>

      <Styled.HorizontalLine />

      <Styled.SingleInfoSection>
        <Typography as="div" variant="caption" color={Neutral.B18}>
          Phone Number
        </Typography>
        <Typography
          as="div"
          variant="body1"
          color={user?.phoneNumber?.number ? Neutral.B18 : Neutral.B85}
        >
          {user?.phoneNumber?.number
            ? `${user?.phoneNumber?.countryCode} ${user?.phoneNumber?.number} ${
                user?.phoneNumber?.extension ?? ''
              }`
            : 'Unknown'}
        </Typography>
      </Styled.SingleInfoSection>
    </>
  );

  useGraphqlError([updateUserProfileError]);

  if (isEdit) {
    return (
      <>
        <Modal
          isOpen={isOpen}
          header="My Profile"
          showCloseButton={true}
          onClose={onClose}
          primaryAction={editPrimaryAction}
          secondaryAction={editSecondaryAction}
        >
          <Styled.ModalContentContainer>
            {modalBanner}
            {modalContent}
          </Styled.ModalContentContainer>
        </Modal>
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      header="My Profile"
      showCloseButton={true}
      onClose={onClose}
      customActions={customActions}
    >
      <Styled.ModalContentContainer>
        {modalBanner}
        {modalContent}
      </Styled.ModalContentContainer>
    </Modal>
  );
};
