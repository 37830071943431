import { useMemo, useState } from 'react';
import { Flex } from 'glints-aries';
import {
  Icon,
  PlainButton,
  Typography,
  useAlert,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import { keyBy } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';

import { PAGE_SIZE } from '../../constants';
import { isRoleAllowForActions } from '../../helpers';
import { AssignEmployeesModal } from '../AssignEmployeesModal/AssignEmployeesModal';
import { EMPLOYEES_TABLE_KEY } from '../AssignEmployeesModal/constants';
import { EmptyState } from './components/EmptyState/EmptyState';
import { ReportingHubberCard } from './components/ReportingHubberCard/ReportingHubberCard';
import { ReportingHubberCardsContainer } from './components/ReportingHubberCard/styled.sc';
import { buildDirectManagerRelations } from './helpers';
import * as Styled from './styled.sc';
import { getGraphqlClient } from '@/clients/graphql';
import {
  type TableFilters,
  type TableOnChange,
} from '@/components/atoms/GlintsAntdTable/types';
import { GlintsAntdTooltip } from '@/components/atoms/GlintsAntdTooltip/GlintsAntdTooltip';
import { ConfirmationModal } from '@/components/organisms/Modals/ConfirmationModal/ConfirmationModal';
import { CONFIRMATION_MODAL_BUTTON_TYPE } from '@/components/organisms/Modals/ConfirmationModal/constants';
import { useHubberDetailsSideSheet } from '@/components/organisms/SideSheets/HubberDetailsSideSheet/context';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { ALERT_STATUS } from '@/constants/alert';
import {
  type CreateDirectManagerRelationsMutation,
  type DeleteDirectManagerRelationByManagerIdHubberIdMutation,
  EmploymentStatus,
  type GetCompanyHubberHubsQuery,
  type GetHubbersWithDirectManagerQuery,
  type HubberFragment,
  type ReportingLinesTabContactFragment,
  useCreateDirectManagerRelationsMutation,
  useDeleteDirectManagerRelationByManagerIdHubberIdMutation,
  useGetCompanyHubberHubsQuery,
  useGetHubbersWithDirectManagerQuery,
} from '@/generated/graphql';
import { useScreen } from '@/hooks/screen';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { isFilteredInfoEmpty } from '@/utils/glintsAntdTable';
import { generateFilteredHubs } from '@/utils/talentHub';

interface ReportingHubbersPanelProps {
  selectedContact: ReportingLinesTabContactFragment | null;
  reportingHubbers: HubberFragment[];
  refetchCompanyContactsReportingLines: () => void;
  refetchCompanyUnassignedDirectManagerRelationCount: () => void;
}

export const ReportingHubbersPanel = ({
  selectedContact,
  reportingHubbers,
  refetchCompanyContactsReportingLines,
  refetchCompanyUnassignedDirectManagerRelationCount,
}: ReportingHubbersPanelProps) => {
  const intl = useIntl();
  const { userInfo } = useAuthContext();
  const graphqlClient = getGraphqlClient();
  const { open: openAlert } = useAlert();
  const { showHubberSideSheet } = useHubberDetailsSideSheet();

  const isLargeScreen = useScreen();
  const isAllowForActions = isRoleAllowForActions(
    userInfo?.contact.roles[0].id,
  );

  const { data: companyData, error: getCompanyHubberHubsError } =
    useGetCompanyHubberHubsQuery<GetCompanyHubberHubsQuery, Error>(
      graphqlClient,
    );

  const [filteredInfo, setFilteredInfo] = useState<TableFilters>({
    [EMPLOYEES_TABLE_KEY.employee]: null,
    [EMPLOYEES_TABLE_KEY.jobTitle]: null,
    [EMPLOYEES_TABLE_KEY.talentHub]: null,
  });
  const [page, setPage] = useState(1);

  const {
    data: getHubbersWithDirectManagerData,
    isLoading: getHubbersWithDirectManagerLoading,
    isFetching: getHubbersWithDirectManagerFetching,
    refetch: refetchHubbersWithDirectManager,
    error: getHubbersWithDirectManagerError,
  } = useGetHubbersWithDirectManagerQuery<
    GetHubbersWithDirectManagerQuery,
    Error
  >(
    graphqlClient,
    {
      page: page,
      pageSize: PAGE_SIZE,
      status: [EmploymentStatus.Active],
      name: filteredInfo[EMPLOYEES_TABLE_KEY.employee]?.toString() || '',
      hub: filteredInfo[EMPLOYEES_TABLE_KEY.talentHub]?.length
        ? generateFilteredHubs(
            filteredInfo[EMPLOYEES_TABLE_KEY.talentHub] as string[],
          )
        : undefined,
      jobTitle: filteredInfo[EMPLOYEES_TABLE_KEY.jobTitle]?.toString() || '',
    },
    {
      staleTime: 0,
    },
  );

  const hubbersWithDirectManager = useMemo(
    () => getHubbersWithDirectManagerData?.hubbers.data ?? [],
    [getHubbersWithDirectManagerData],
  );

  const [isAssignEmployeesModalOpen, setIsAssignEmployeesModalOpen] =
    useState(false);
  const [isRemoveEmployeeModalOpen, setIsRemoveEmployeeModalOpen] =
    useState(false);
  const [preparedRemoveHubberId, setPreparedRemoveHubberId] =
    useState<string>();

  const reportingHubbersMap = keyBy(reportingHubbers, 'id');

  const isFiltering = !isFilteredInfoEmpty(filteredInfo);

  const {
    mutate: createDirectManagerRelationsMutate,
    isLoading: createDirectManagerRelationsLoading,
    error: createDirectManagerRelationsError,
  } = useCreateDirectManagerRelationsMutation<
    Error,
    CreateDirectManagerRelationsMutation
  >(graphqlClient, {
    onSuccess: (data) => {
      openAlert({
        content: intl.formatMessage(
          {
            id: 'assign.employees.success',
            defaultMessage:
              '{count, plural, one {# employee} other {# employees}} assigned to {name}',
          },
          {
            count: data.createDirectManagerRelations.length,
            name: selectedContact?.name,
          },
        ),
        status: ALERT_STATUS.success,
      });
      setIsAssignEmployeesModalOpen(false);
      refetchHubbersWithDirectManager();
      refetchCompanyContactsReportingLines();
      refetchCompanyUnassignedDirectManagerRelationCount();
    },
  });

  const {
    mutate: deleteDirectManagerRelationMutate,
    isLoading: deleteDirectManagerRelationLoading,
    error: deleteDirectManagerRelationError,
  } = useDeleteDirectManagerRelationByManagerIdHubberIdMutation<
    Error,
    DeleteDirectManagerRelationByManagerIdHubberIdMutation
  >(graphqlClient, {
    onSuccess: () => {
      if (!preparedRemoveHubberId) return;

      openAlert({
        content: `${reportingHubbersMap[preparedRemoveHubberId].fullName} has been removed`,
        status: ALERT_STATUS.success,
      });
      handleRemoveEmployeeModalClose();
      refetchHubbersWithDirectManager();
      refetchCompanyContactsReportingLines();
      refetchCompanyUnassignedDirectManagerRelationCount();
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleTableChange: TableOnChange = (_, filters, __) => {
    setFilteredInfo(filters as TableFilters);
  };

  const handleRemoveEmployeeModalOpen = (id: string) => {
    setPreparedRemoveHubberId(id);
    setIsRemoveEmployeeModalOpen(true);
  };

  const handleRemoveEmployeeModalClose = () => {
    setPreparedRemoveHubberId(undefined);
    setIsRemoveEmployeeModalOpen(false);
  };

  const handleDeleteDirectManagerRelation = () => {
    if (!selectedContact || !preparedRemoveHubberId) return;

    deleteDirectManagerRelationMutate({
      directManagerId: selectedContact.id,
      hubberId: preparedRemoveHubberId,
    });
  };

  useGraphqlError([
    getCompanyHubberHubsError,
    getHubbersWithDirectManagerError,
    createDirectManagerRelationsError,
    deleteDirectManagerRelationError,
  ]);

  if (!selectedContact) return null;

  const headerText = isLargeScreen ? (
    <FormattedMessage
      id="managing.employees"
      defaultMessage="Managing {count, plural, =0 {# employee} one {# employee} other {# employees}}"
      values={{
        count: reportingHubbers.length,
      }}
    />
  ) : (
    <FormattedMessage
      id="managing.employees"
      defaultMessage="{name} is managing {count, plural, =0 {# employee} one {# employee} other {# employees}}"
      values={{
        name: selectedContact.name,
        count: reportingHubbers.length,
      }}
    />
  );

  return (
    <>
      <Styled.ReportingHubbersPanelContainer>
        <Styled.Header>
          <Flex gap={space8}>
            <Typography as="span" variant="subtitle2" color={Neutral.B18}>
              {headerText}
            </Typography>
            <GlintsAntdTooltip
              title="Reporting lines enable platform users to manage time-off requests and expense claims. Currently, the platform supports only a single reporting manager."
              placement="bottomLeft"
              width="340px"
            >
              <Icon name="ri-information-fill" fill={Neutral.B40} height={20} />
            </GlintsAntdTooltip>
          </Flex>
          {isAllowForActions && (
            <PlainButton onClick={() => setIsAssignEmployeesModalOpen(true)}>
              <Icon name="ri-add" />
              <Typography variant="button">Assign Employee</Typography>
            </PlainButton>
          )}
        </Styled.Header>

        <Styled.Body>
          {reportingHubbers.length > 0 ? (
            <ReportingHubberCardsContainer>
              {reportingHubbers.map((hubber) => (
                <ReportingHubberCard
                  key={hubber.id}
                  hubber={hubber}
                  isAllowForActions={isAllowForActions}
                  onClick={() => {
                    showHubberSideSheet({
                      hubberData: hubber,
                    });
                  }}
                  onRemove={() => handleRemoveEmployeeModalOpen(hubber.id)}
                />
              ))}
            </ReportingHubberCardsContainer>
          ) : (
            <EmptyState
              isCurrentUser={userInfo?.contact.id === selectedContact.id}
              name={selectedContact.name}
            />
          )}
        </Styled.Body>
      </Styled.ReportingHubbersPanelContainer>

      {selectedContact && (
        <AssignEmployeesModal
          isOpen={isAssignEmployeesModalOpen}
          selectedContact={selectedContact}
          hubbersWithDirectManager={hubbersWithDirectManager}
          companyHubs={companyData?.company.hubberHubs || []}
          loading={
            getHubbersWithDirectManagerLoading ||
            getHubbersWithDirectManagerFetching
          }
          submitting={createDirectManagerRelationsLoading}
          isFiltering={isFiltering}
          filteredProps={{
            filteredInfo,
            setFilteredInfo,
            onReset: () => setFilteredInfo({}),
          }}
          paginationProps={{
            currentPage: getHubbersWithDirectManagerData?.hubbers.pageNo || 1,
            pageSize: getHubbersWithDirectManagerData?.hubbers.pageSize || 0,
            totalItems: getHubbersWithDirectManagerData?.hubbers.total || 0,
            onPageChanged: (page: number) => setPage(page),
          }}
          onTableChange={handleTableChange}
          onSubmit={(selectedHubberIds) => {
            const directManagerRelations = buildDirectManagerRelations(
              selectedContact.id,
              selectedHubberIds,
            );
            createDirectManagerRelationsMutate({
              directManagerRelations,
            });
          }}
          onClose={() => {
            setIsAssignEmployeesModalOpen(false);
            setPage(1);
          }}
        />
      )}

      {preparedRemoveHubberId && selectedContact && (
        <ConfirmationModal
          isOpen={isRemoveEmployeeModalOpen}
          header={`Remove ${reportingHubbersMap[preparedRemoveHubberId].fullName}`}
          content={
            <Flex flexDirection="column" gap={space16}>
              <Typography as="span" variant="body1" color={Neutral.B18}>
                Are you sure you want to remove{' '}
                {reportingHubbersMap[preparedRemoveHubberId].fullName} from{' '}
                <b>{selectedContact.name}</b>&apos;s reporting lines?
              </Typography>
              <Typography as="span" variant="body1" color={Neutral.B18}>
                If confirmed, please assign a new reporting manager to ensure
                that {reportingHubbersMap[preparedRemoveHubberId].fullName}
                &apos;s future requests will be received and managed by a
                platform user.
              </Typography>
            </Flex>
          }
          confirmButtonText="Remove"
          confirmButtonType={CONFIRMATION_MODAL_BUTTON_TYPE.danger}
          submitting={deleteDirectManagerRelationLoading}
          onConfirm={handleDeleteDirectManagerRelation}
          onClose={handleRemoveEmployeeModalClose}
        />
      )}
    </>
  );
};
