import { Breakpoints } from 'glints-aries/lib/@next';
import styled, { createGlobalStyle } from 'styled-components';

import { LOGIN_BACKGROUND_IMAGE_LINK } from '../../../../constants/constants';

export const StyledBackground = createGlobalStyle`
  body {
    background-image: url(${LOGIN_BACKGROUND_IMAGE_LINK});
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: -1;
  }
`;

export const MainPlanetsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  display: grid;

  svg {
    height: 512px;
    width: 415px;
  }

  &.animate {
    svg {
      animation: fading 800ms ease-in-out 200ms 1 forwards;
    }
  }

  @keyframes fading {
    from {
    }
    to {
      opacity: 0.2;
      transform: scale(0.9);
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    width: 100%;
    display: block;

    svg {
      width: 40%;
      height: 50%;
      transform: translateY(4px);
    }
  }
`;

export const SmallPlanetsContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;

  svg {
    height: 271px;
    width: 254px;
    transform: translateY(4px);
  }

  &.animate {
    svg {
      animation: fading 600ms ease-in-out 200ms 1 forwards;
    }
  }

  @keyframes fading {
    from {
    }
    to {
      opacity: 0.2;
      transform: scale(0.9);
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    top: 0;
    right: 0;
    bottom: auto;
    width: 30%;

    svg {
      width: 100%;
      height: 32%;
      transform: rotateX(180deg);
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 32px);
  justify-content: center;
  align-items: center;
  margin: 16px;
`;

export const TopBackgroundContainer = styled.div`
  position: fixed;
  height: calc(20% - 32px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  position: relative;
  height: 80px;
  width: 360px;
  top: 10%;
  aspect-ratio: 4/1;

  @media (max-width: ${Breakpoints.large}) {
    height: 50%;
    min-height: 32px;
    width: auto;
  }
`;

export const GlintsLogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 25%;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const GlintsTextContainer = styled.div`
  position: absolute;
  top: -4px;
  left: 27%;
  height: 45%;
  padding-top: 1%;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const ManagedTalentTextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 27%;
  height: 55%;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const FrameContainer = styled.div`
  height: 400px;
  width: 680px;

  border-radius: 20px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 50px 0px rgba(175, 175, 175, 0.2);
  backdrop-filter: blur(10px);
  z-index: 0;

  @media (max-width: ${Breakpoints.large}) {
    max-width: 400px;
    height: auto;
    margin: 16px;
    padding: 60px 24px;
  }
`;

export const GreyBackgroundOverlay = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  mix-blend-mode: hue;
  z-index: 0;
`;
