import { type TabModel } from 'glints-aries/lib/@next';
import { type Option } from 'glints-aries/lib/@next/Menu';

import { EmploymentStatus, TimeOffCategory } from '../../generated/graphql';
import { ALERT_STATUS } from '@/constants/alert';
import { Role } from '@/modules/CompanyProfile/RolesPermissionsTab/interfaces';

export const tabs: TabModel[] = [
  { id: 'time-off-requests', content: 'Time Off Requests' },
  { id: 'time-off-balance', content: 'Time Off Balance' },
];

export const PAGE_SIZE = 20;

export const timeOffTypeMapping: Record<TimeOffCategory, string> = {
  [TimeOffCategory.AnnualLeave]: 'Annual Leave',
  [TimeOffCategory.SickLeave]: 'Sick Leave',
  [TimeOffCategory.Others]: 'Others',
};

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const alertContent = {
  [AlertType.SUCCESS]: 'File successfully downloaded',
  [AlertType.ERROR]: 'Export failed. Retry later.',
};

export const TALENT_OPTIONS: Option[] = [
  {
    id: '1',
    label: 'Active',
    value: EmploymentStatus.Active,
  },
  {
    id: '2',
    label: 'End of Contract',
    value: EmploymentStatus.ContractEnded,
  },
  {
    id: '3',
    label: 'Departed',
    value: EmploymentStatus.ResignedOrTerminated,
  },
];

export const timeOffRequestAlertContent = {
  [ALERT_STATUS.success]: 'Request status updated.',
};

export const PERMITTED_TIMEOFF_WRITE_ROLES = [
  Role.CompanyAdmin,
  Role.PeopleManager,
];

export const JS_MONTH_OFFSET = 1;
