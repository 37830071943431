import { space16 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
  display: flex;
  margin: ${space16};
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledCell = styled.div`
  td {
    width: 92px;
  }
`;
