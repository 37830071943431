import { useEffect, useState } from 'react';
import { Typography } from 'glints-aries/lib/@next';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';

import { ReactComponent as GlintsIconSVG } from '../../../../assets/images/glints-icon.svg';
import { ReactComponent as GlintsManagedTalentTextSVG } from '../../../../assets/images/glints-managed-talent-text.svg';
import { ReactComponent as GlintsTitleTextSVG } from '../../../../assets/images/glints-title-text.svg';
import { ReactComponent as MainPlanetsSVG } from '../../../../assets/images/main-planets.svg';
import { ReactComponent as RocketSVG } from '../../../../assets/images/rocket.svg';
import { ReactComponent as SmallPlanetsSVG } from '../../../../assets/images/small-planets.svg';
import {
  CardWrapper,
  LoadingTitleContainer,
  RocketContainer,
  StyledTextArea,
} from './LoadingLayoutStyle';
import {
  GlintsLogoContainer,
  GlintsTextContainer,
  MainContainer,
  MainPlanetsContainer,
  ManagedTalentTextContainer,
  SmallPlanetsContainer,
  StyledBackground,
} from './LoginLayoutStyle';

export const LoadingLayout = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <StyledBackground />
      <LoadingTitleContainer>
        <GlintsLogoContainer>
          <GlintsIconSVG />
        </GlintsLogoContainer>
        <GlintsTextContainer>
          <GlintsTitleTextSVG />
        </GlintsTextContainer>
        <ManagedTalentTextContainer>
          <GlintsManagedTalentTextSVG />
        </ManagedTalentTextContainer>
      </LoadingTitleContainer>
      <MainPlanetsContainer className="animate">
        <MainPlanetsSVG />
      </MainPlanetsContainer>
      <SmallPlanetsContainer className="animate">
        <SmallPlanetsSVG />
      </SmallPlanetsContainer>
      <MainContainer>
        <CardWrapper className={loading ? 'inactive' : ''}>
          <RocketContainer>
            <RocketSVG />
          </RocketContainer>
          <StyledTextArea>
            <Typography as="div" variant="headline3" color={Blue.S100}>
              <b>Hold Tight</b>
            </Typography>
            <Typography as="div" variant="body1" color={Blue.S100}>
              We&apos;re shipping you to the MTS
            </Typography>
          </StyledTextArea>
        </CardWrapper>
      </MainContainer>
    </>
  );
};
