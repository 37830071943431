import { Box } from 'glints-aries';
import { SkeletonImageSquare, SkeletonText } from 'glints-aries/lib/@next';

import * as Styled from './styled.sc';

export const InvoicesLoadingState = () => (
  <>
    {[...Array(3)].map((_, index) => (
      <Box key={index} mr={4}>
        <SkeletonImageSquare height="32px" width="135px" />
      </Box>
    ))}
  </>
);

export const ReviewSummaryLoadingState = () => (
  <>
    <Box mb={4}>
      <SkeletonImageSquare height="240px" width="100%" />
    </Box>
    <SkeletonText
      rows={[
        { width: '100%', variant: 'headline1' },
        { width: '100%', variant: 'body1' },
      ]}
    />
  </>
);

export const ViewInstructionsLoadingState = () => (
  <Styled.ViewInstructionsLoadingContainer>
    <SkeletonImageSquare height="100px" width="100%" />
    <SkeletonImageSquare height="250px" width="100%" />
    <SkeletonImageSquare height="250px" width="100%" />
  </Styled.ViewInstructionsLoadingContainer>
);
