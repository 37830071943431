import { useEffect, useState } from 'react';
import {
  DestructiveButton,
  Divider,
  OutlineButton,
  Typography,
} from 'glints-aries/lib/@next';
import { useNavigate } from 'react-router-dom';

import {
  AutoPaymentDetailsContainer,
  BankCards,
  CardLi,
  DisconnectModalActions,
  EgiroDescription,
  OtherBanksTitle,
  SetupEgiroContainer,
  StyledModal,
} from '../AutoPaymentTabStyle';
import { BankCard } from '../components/BankCard';
import {
  Bank,
  bankDetailsMapping,
  type BankSwiftCode,
  computeLastMonthInvoiceSGD,
  DEFAULT_LAST_MONTH_INVOICE_AMOUNT,
  DEFAULT_LAST_MONTH_INVOICE_CURRENCY,
} from '../constants';
import { BankStatusBanner } from './BankStatusBanner/BankStatusBanner';
import { ConnectedBankCard } from './ConnectedBankCard/ConnectedBankCard';
import { getGraphqlClient } from '@/clients/graphql';
import {
  DirectDebitStatus,
  type DisconnectDirectDebitMutation,
  type GetDirectDebitAuthorisationQuery,
  type Money,
  useDisconnectDirectDebitMutation,
  useGetDirectDebitAuthorisationQuery,
} from '@/generated/graphql';

export const SetupEgiro = ({
  lastMonthTotalInvoice,
  updateShowSetupWithBank,
  updateSelectedBank,
}: {
  lastMonthTotalInvoice?: Omit<Money, 'inCurrencies'>[];
  updateShowSetupWithBank(newValue: boolean): void;
  updateSelectedBank(newValue: BankSwiftCode): void;
}) => {
  const navigate = useNavigate();
  const graphqlClient = getGraphqlClient();
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [lastMonthTotalInvoiceSGD, setLastMonthTotalInvoiceSGD] = useState<
    Omit<Money, 'inCurrencies'> | undefined
  >({
    amount: DEFAULT_LAST_MONTH_INVOICE_AMOUNT,
    currency: DEFAULT_LAST_MONTH_INVOICE_CURRENCY,
  });
  const [showBankStatusBanner, setShowBankStatusBanner] = useState(false);

  const onDirectDebitAuthSuccess = (data: GetDirectDebitAuthorisationQuery) => {
    if (
      data?.company?.directDebit?.status === DirectDebitStatus.Pending ||
      data?.company?.directDebit?.status === DirectDebitStatus.Failed
    ) {
      setShowBankStatusBanner(true);
      return;
    }
  };

  const { data: directDebitAuthData } = useGetDirectDebitAuthorisationQuery<
    GetDirectDebitAuthorisationQuery,
    Error
  >(graphqlClient, {}, { staleTime: 0, onSuccess: onDirectDebitAuthSuccess });

  const { mutate: disconnectDirectDebitMutate } =
    useDisconnectDirectDebitMutation<Error, DisconnectDirectDebitMutation>(
      graphqlClient,
    );

  const updateShowDisconnectModal = (newValue: boolean) => {
    setShowDisconnectModal(newValue);
  };

  const updateShowBankStatusBanner = (newValue: boolean) => {
    setShowBankStatusBanner(newValue);
  };

  const handleDisconnectClick = () => {
    // redirect to bank if bank status is ACTIVE
    if (
      directDebitAuthData?.company.directDebit?.status ===
      DirectDebitStatus.Active
    ) {
      navigate(
        `/egiro/disconnect?bank=${directDebitAuthData?.company.directDebit?.bankSwiftBicCode}`,
      );

      return;
    }

    // if not, directly disconnect from platform
    const disconnectDirectDebitParams = {
      param: {
        redirectUrl: `${window.location.origin}/egiro/disconnected?bank=${directDebitAuthData?.company.directDebit?.bankSwiftBicCode}`,
      },
    };

    disconnectDirectDebitMutate(disconnectDirectDebitParams, {
      onSuccess: () => {
        navigate(
          `/egiro/disconnected?bank=${directDebitAuthData?.company.directDebit?.bankSwiftBicCode}`,
        );
      },
    });
  };

  useEffect(() => {
    if (lastMonthTotalInvoice) {
      const lastMonthInvoiceSGD = computeLastMonthInvoiceSGD(
        lastMonthTotalInvoice,
      );

      setLastMonthTotalInvoiceSGD({
        amount:
          lastMonthInvoiceSGD?.amount ?? DEFAULT_LAST_MONTH_INVOICE_AMOUNT,
        currency:
          lastMonthInvoiceSGD?.currency ?? DEFAULT_LAST_MONTH_INVOICE_CURRENCY,
      });
    }
  }, [lastMonthTotalInvoice]);

  return (
    <SetupEgiroContainer>
      <AutoPaymentDetailsContainer>
        <Typography variant="subtitle1">
          Automate your payments using eGIRO
        </Typography>
        <EgiroDescription>
          <Typography as="span" variant="body1">
            eGIRO is an electronic payment service designed for effortless and
            automated transactions, simplifying your financial management
            through the automation of fund transfers. Enjoy the convenience of
            quick, secure payments directly from your bank account.{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.abs.org.sg/consumer-banking/eGIRO"
            >
              Learn More
            </a>
          </Typography>
        </EgiroDescription>
      </AutoPaymentDetailsContainer>
      <StyledModal
        isOpen={showDisconnectModal}
        header="Disconnect eGIRO account"
        showCloseButton={false}
        customActions={
          <DisconnectModalActions>
            <OutlineButton onClick={() => setShowDisconnectModal(false)}>
              Cancel
            </OutlineButton>
            <DestructiveButton onClick={handleDisconnectClick}>
              Disconnect
            </DestructiveButton>
          </DisconnectModalActions>
        }
      >
        If you disconnect your eGIRO account, your auto-payment will be
        deactivated. Are you sure you want to disconnect it?
      </StyledModal>
      {showBankStatusBanner && directDebitAuthData?.company.directDebit && (
        <BankStatusBanner
          directDebitAuthData={directDebitAuthData?.company.directDebit}
          lastMonthTotalInvoiceSGD={lastMonthTotalInvoiceSGD}
          updateShowBanner={updateShowBankStatusBanner}
        />
      )}
      {(directDebitAuthData?.company.directDebit?.status ===
        DirectDebitStatus.Pending ||
        directDebitAuthData?.company.directDebit?.status ===
          DirectDebitStatus.Active) && (
        <ConnectedBankCard
          updateShowDisconnectModal={updateShowDisconnectModal}
          directDebitAuthData={directDebitAuthData?.company.directDebit}
        />
      )}
      {(directDebitAuthData?.company.directDebit?.status ===
        DirectDebitStatus.Pending ||
        directDebitAuthData?.company.directDebit?.status ===
          DirectDebitStatus.Active) && (
        <OtherBanksTitle>
          <Divider />
          <Typography as="span" variant="subtitle1">
            Other participating banks
          </Typography>
        </OtherBanksTitle>
      )}
      <BankCards>
        {Object.values(Bank).map((bank) => {
          const { name, icon, bankSwiftBicCode } = bankDetailsMapping[bank];
          const isActive =
            directDebitAuthData?.company?.directDebit?.bankSwiftBicCode ===
              bankSwiftBicCode &&
            directDebitAuthData?.company?.directDebit?.status ===
              DirectDebitStatus.Active;

          if (isActive) {
            return;
          }
          return (
            <CardLi key={name}>
              <BankCard
                bank={bank}
                bankName={name}
                bankSwiftBicCode={bankSwiftBicCode}
                icon={icon}
                updateShowSetupWithBank={updateShowSetupWithBank}
                updateSelectedBank={updateSelectedBank}
                disabled={
                  directDebitAuthData?.company?.directDebit?.status ===
                    DirectDebitStatus.Pending ||
                  directDebitAuthData?.company?.directDebit?.status ===
                    DirectDebitStatus.Active
                }
              />
            </CardLi>
          );
        })}
      </BankCards>
    </SetupEgiroContainer>
  );
};
