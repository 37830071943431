import {
  type CompanyDirectManagerFragment,
  type GetUserQuery,
  type TimeOffRequestsTableTimeOffRequestFragment,
} from '@/generated/graphql';
import { roundDownNearestHalf } from '@/utils/math';

export const renderTimeOffDuration = ({
  timeoffDuration,
}: {
  timeoffDuration: string;
}) => {
  const parseStringtoFloat = parseFloat(timeoffDuration);
  const stringDay = parseStringtoFloat <= 1 ? 'day' : 'days';
  const duration = roundDownNearestHalf(parseStringtoFloat).toFixed(1);
  return `${duration} ${stringDay}`;
};

export const buildReportingManagerFiltersOptions = (
  companyDirectManagers: CompanyDirectManagerFragment[],
) => {
  const filtersOptions = companyDirectManagers.map((directManager) => ({
    text: directManager.name,
    value: directManager.id,
  }));

  filtersOptions.push({
    text: 'Unassigned',
    value: null,
  });

  return filtersOptions;
};

export const isReportingLineHubber = (
  userInfo: GetUserQuery | null,
  hubber: TimeOffRequestsTableTimeOffRequestFragment['hubber'],
) =>
  userInfo &&
  hubber.directManager[0] &&
  hubber.directManager[0].id === userInfo.contact.id;
