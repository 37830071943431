import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Breakpoints,
  Icon,
  type TabModel,
  Tabs,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useLocation, useNavigate } from 'react-router-dom';

import { AwaitingPaymentTab } from './AwaitingPaymentTab/AwaitingPaymentTab';
import { invoicePaymentsTab, tabIndexMapping } from './interfaces';
import {
  Container,
  TabFlexContainer,
  TabsContainer,
} from './InvoicePaymentsPageStyle';
import { PaymentCompletedTab } from './PaymentCompletedTab/PaymentCompletedTab';
import { getGraphqlClient } from '@/clients/graphql';
import {
  type GetDirectDebitAuthorisationQuery,
  useGetDirectDebitAuthorisationQuery,
} from '@/generated/graphql';

const InvoicePaymentsPage = () => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(
    pathname === '/invoice-payments/awaiting-payment'
      ? invoicePaymentsTab.AWAITING_PAYMENT
      : invoicePaymentsTab.PAYMENT_COMPLETED,
  );

  const { isLoading: isLoadingDirectDebitAuth, data: directDebitAuthData } =
    useGetDirectDebitAuthorisationQuery<
      GetDirectDebitAuthorisationQuery,
      Error
    >(graphqlClient, {}, { staleTime: 0 });

  const handleTabSelect = (index: number) => {
    setActiveTab(index);
    navigate(`/invoice-payments/${tabIndexMapping[index]}`);
  };

  useEffect(() => {
    setActiveTab(
      location.pathname === '/invoice-payments/awaiting-payment'
        ? invoicePaymentsTab.AWAITING_PAYMENT
        : invoicePaymentsTab.PAYMENT_COMPLETED,
    );
  }, [location]);

  useLayoutEffect(() => {
    const BREAKPOINT = parseInt(Breakpoints.large.slice(0, 3));
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= BREAKPOINT);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tabs: TabModel[] = [
    {
      id: 'awaiting-payment',
      content: (
        <TabFlexContainer
          data-active={activeTab === invoicePaymentsTab.AWAITING_PAYMENT}
        >
          <Icon name="ri-timer3-line" />
          <Typography as="span" variant="body1">
            Awaiting Payment
          </Typography>
        </TabFlexContainer>
      ),
    },
    {
      id: 'payment-completed',
      content: (
        <TabFlexContainer
          data-active={activeTab === invoicePaymentsTab.PAYMENT_COMPLETED}
        >
          <Icon name="ri-check-double-line" />
          <Typography as="span" variant="body1">
            Payment Completed
          </Typography>
        </TabFlexContainer>
      ),
    },
  ];

  const tabContents = [
    <AwaitingPaymentTab
      key="awaiting-payment-tab"
      directDebitAuthData={directDebitAuthData}
      isLoadingDirectDebitAuth={isLoadingDirectDebitAuth}
    />,
    <PaymentCompletedTab
      key="completed-tab"
      directDebitAuthData={directDebitAuthData}
    />,
  ];

  return (
    <Container>
      <Typography as="span" variant="headline6" color={Neutral.B18}>
        Invoice Payments
      </Typography>
      <TabsContainer>
        <Tabs
          tabs={tabs}
          selected={activeTab}
          onSelected={handleTabSelect}
          fitted={isSmallScreen}
        >
          {tabContents[activeTab]}
        </Tabs>
      </TabsContainer>
    </Container>
  );
};

export default InvoicePaymentsPage;
