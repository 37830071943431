import { Box } from 'glints-aries';
import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { Banner } from '@/components/molecules/SideSheet/styled.sc';

export const ModifiedBanner = () => (
  <Box mb={12}>
    <Banner status="warning" dismissable={false}>
      <Typography as="span" variant="body1" color={Neutral.B18}>
        This request has been revised by the employee. Please review again.
      </Typography>
    </Banner>
  </Box>
);
