import { borderRadiusHalf } from 'glints-aries/lib/@next/utilities/borderRadius';
import { space4 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const StatusIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  padding-left: ${space4};

  .status-badge {
    margin-left: ${space4};
    display: none;
  }

  &:hover {
    .status-badge {
      animation: slide-in-from-left 0.3s;
      transform: translateX(-10px);
      display: initial;
      z-index: 2;
      position: fixed;
      margin-left: 8px;
    }
    .status-indicator {
      display: none;
    }
  }

  @keyframes slide-in-from-left {
    0% {
      transform: translateX(-30px);
      opacity: 0;
    }
    100% {
      transform: translateX(-10px);
      opacity: 1;
    }
  }
`;

export const StatusDot = styled.span<{ color: string }>`
  width: 6px;
  height: 6px;
  background-color: ${(props) => props.color};
  border-radius: ${borderRadiusHalf};
`;
