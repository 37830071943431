import { EmptyState, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as AstronautSOS } from '../../assets/images/astronaut-sos.svg';
import { Box } from '../../components/Box/Box';
import { LandingPageLayout } from '../../components/layout/LandingPage/LandingPageLayout';
import { useAuthToken } from '../../hooks/useAuthToken';
import { ContactUs } from './components/ContactUs';
import {
  errorContent,
  type ErrorType,
  errorType as typeError,
} from './constants';
import {
  ContactUsContainer,
  ContentContainer,
  ContentDescription,
  ErrorPageContainer,
  MainContent,
  StyledButton,
} from './ErrorStyle';

interface ErrorNotFoundProps {
  errorType: ErrorType;
}

type ActionButtonType = {
  label: string;
  onClick(): void;
};

const Error = ({ errorType = typeError.UNKNOWN }: ErrorNotFoundProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuthToken();
  const showContactUs = (
    [
      typeError.INTERNAL_SERVER_ERROR,
      typeError.SERVICE_UNAVAILABLE,
      typeError.UNKNOWN,
    ] as Partial<ErrorType>[]
  ).includes(errorType);

  const redirectHomepage = () => {
    navigate('/', { replace: true });
  };

  const basicButtonAction: Partial<
    Record<Partial<ErrorType>, ActionButtonType>
  > = {
    INTERNAL_SERVER_ERROR: {
      label: 'Reload',
      onClick: () => navigate(0),
    },
    FORBIDDEN: {
      label: 'Back to Homepage',
      onClick: redirectHomepage,
    },
    NOT_FOUND: {
      label: 'Back to Homepage',
      onClick: redirectHomepage,
    },
    SERVICE_UNAVAILABLE: {
      label: 'Reload',
      onClick: () => navigate(0),
    },
    UNKNOWN: {
      label: 'Back to Homepage',
      onClick: redirectHomepage,
    },
  };

  const primaryButtonAction: Partial<
    Record<Partial<ErrorType>, ActionButtonType>
  > = {
    INTERNAL_SERVER_ERROR: undefined,
    FORBIDDEN: undefined,
    NOT_FOUND: undefined,
    SERVICE_UNAVAILABLE: undefined,
    UNKNOWN: {
      label: 'Reload',
      onClick: () => {
        const from = location.state?.from || '/';
        navigate(from, { replace: true });
      },
    },
  };

  if (isAuthenticated()) {
    return (
      <ErrorPageContainer>
        <MainContent data-margin-top-auto={showContactUs}>
          <ContentContainer>
            <EmptyState
              image={<AstronautSOS />}
              title={errorContent[errorType].title}
              description={
                <ContentDescription>
                  <Typography variant="body1" as="span" color={Neutral.B40}>
                    {errorContent[errorType].description}
                  </Typography>
                </ContentDescription>
              }
              basicButtonAction={basicButtonAction[errorType]}
              primaryButtonAction={primaryButtonAction[errorType]}
            />
          </ContentContainer>
        </MainContent>
        {showContactUs && (
          <ContactUsContainer>
            <ContactUs />
          </ContactUsContainer>
        )}
      </ErrorPageContainer>
    );
  }

  return (
    <LandingPageLayout
      overlayGrey={true}
      hasFooter={errorType === typeError.UNKNOWN}
    >
      <ErrorPageContainer>
        <Box>
          <Typography variant="subtitle1" as="span" color={Neutral.B18}>
            {errorContent[errorType].title}
          </Typography>
          <ContentDescription>
            <Typography variant="body1" as="span" color={Neutral.B40}>
              {errorContent[errorType].description}
            </Typography>
          </ContentDescription>
          <StyledButton
            data-footer={errorType === typeError.UNKNOWN}
            onClick={basicButtonAction[errorType]?.onClick}
          >
            {basicButtonAction[errorType]?.label}
          </StyledButton>
        </Box>
        {showContactUs && (
          <ContactUsContainer data-margin-bottom={!isAuthenticated()}>
            <ContactUs />
          </ContactUsContainer>
        )}
      </ErrorPageContainer>
    </LandingPageLayout>
  );
};

export default Error;
