import clm from 'country-locale-map';
import countryToCurrency, { type Countries } from 'country-to-currency';

import { currencyToCountry } from '@/constants/locale';
import { type Address } from '@/generated/graphql';

export const getCurrencyByCountry = (country: Countries) =>
  countryToCurrency[country];

export const isCountryValid = (country: string): country is Countries =>
  country in countryToCurrency;

export const getCountriesCodeByCurrency = (currency: string): string[] | null =>
  currencyToCountry[currency];

export const getLocaleByCountryCode = (countryCode: string): string | null =>
  clm.getLocaleByAlpha2(countryCode) || null;

export const getLocaleByCurrency = (currency: string): string | null => {
  const countryCodes = getCountriesCodeByCurrency(currency);
  return countryCodes && countryCodes.length > 0
    ? getLocaleByCountryCode(countryCodes[0])?.replace(/_/g, '-') || null
    : null;
};

export const getCompanyBillingCurrency = (
  billingAddress: Pick<Address, 'country'> | null | undefined,
): string | null => {
  const country = billingAddress?.country;
  if (country && isCountryValid(country)) {
    return getCurrencyByCountry(country);
  }
  return null;
};
