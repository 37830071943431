import { Icon, Tooltip } from 'glints-aries/lib/@next';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';

import * as Styled from './styled.sc';

export interface CopyTextProps {
  label: string;
  copyValue: string;
}

export const CopyText = ({ label, copyValue }: CopyTextProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(copyValue);
  };

  return (
    <Styled.CopyText>
      {label}
      <Tooltip
        preferredPosition="right-middle"
        content="Copied"
        clickable={true}
        timeout={1000}
        onClick={handleCopy}
        zIndex={999}
      >
        <Icon name="ri-file-copy-line" fill={Blue.S99} height="20px" />
      </Tooltip>
    </Styled.CopyText>
  );
};
