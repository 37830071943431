import { Icon } from 'glints-aries/lib/@next';
import { cloneDeep } from 'lodash-es';

import { validateRejectReason } from './helpers';
import {
  HUB_CODE,
  type HubCode,
} from '@/components/atoms/HubLocationText/constants';
import { type RadioButtonOption } from '@/components/atoms/RadioButtonGroup/types';

export const AVAILABLE_REQUEST_HUBS = [
  HUB_CODE.BT,
  HUB_CODE.ID,
  HUB_CODE.VN,
] as HubCode[];
export const NOT_AVAILABLE_REQUEST_HUBS = [
  HUB_CODE.MY,
  HUB_CODE.SG,
  HUB_CODE.PH,
  HUB_CODE.TW,
] as HubCode[];

export const APPROVAL_STATE = {
  approve: 'approve',
  reject: 'reject',
} as const;

export type ApprovalState = ValueOf<typeof APPROVAL_STATE>;

export const APPROVAL_STATE_LABEL = {
  [APPROVAL_STATE.approve]: 'Approve',
  [APPROVAL_STATE.reject]: 'Reject',
} as const;

const defaultApprovalOptions: RadioButtonOption[] = [
  {
    label: 'Approve',
    value: APPROVAL_STATE.approve,
    disabled: false,
    variant: 'Positive',
    icon: <Icon name="ri-check" />,
  },
  {
    label: 'Reject',
    value: APPROVAL_STATE.reject,
    disabled: false,
    variant: 'Negative',
    icon: <Icon name="ri-close" />,
  },
];

export const getApprovalOptions = (hubCode: HubCode) => {
  const options = cloneDeep(defaultApprovalOptions);

  if (NOT_AVAILABLE_REQUEST_HUBS.includes(hubCode)) {
    options.forEach((option) => {
      option.disabled = true;
    });
  }

  return options;
};

export const REJECTION_FORM_NAMES = {
  rejectReason: 'rejectReason',
} as const;

export const REJECTION_FORM_PLACEHOLDERS = {
  [REJECTION_FORM_NAMES.rejectReason]: 'Please enter the reason for rejection',
} as const;

export const REJECTION_FORM_RULES = {
  [REJECTION_FORM_NAMES.rejectReason]: {
    required: 'Reason must be provided',
    validate: validateRejectReason,
  },
} as const;

export type RejectionFormFields = {
  [REJECTION_FORM_NAMES.rejectReason]: string;
};
