import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { NotoSans } from 'glints-aries/lib/@next/utilities/fonts';

export const defaultGlintsAntdTableTheme = {
  token: {
    fontFamily: `${NotoSans}, sans-serif`,
  },
  components: {
    Table: {
      headerBorderRadius: 0,
      headerBg: Neutral.B99,
      borderColor: Neutral.B85,
      rowHoverBg: Blue.S08,
    },
  },
};

export const defaultPaginationSettings = {
  pageSize: 10,
  showSizeChanger: true,
};
