import { createContext, useContext } from 'react';
import { noop } from 'lodash-es';

import { getGraphqlClient } from '@/clients/graphql';
import { type GetUserQuery, useGetUserQuery } from '@/generated/graphql';
import { useAuthToken } from '@/hooks/useAuthToken';

type ContextValue = {
  loading: boolean;
  userInfo: GetUserQuery | null;
  refetch: () => void;
};

const Context = createContext<ContextValue>({
  loading: false,
  userInfo: null,
  refetch: noop,
});

export const useAuthContext = (): ContextValue => useContext(Context);

export const AuthInfoProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const graphqlClient = getGraphqlClient();
  const { getCurrentUserId } = useAuthToken();
  const currentUserId = getCurrentUserId();

  const {
    data: userData,
    refetch: refetchUserData,
    isLoading,
    isFetching,
  } = useGetUserQuery<GetUserQuery, Error>(
    graphqlClient,
    { userId: currentUserId },
    { enabled: Boolean(currentUserId) },
  );

  return (
    <Context.Provider
      value={{
        loading: isLoading || isFetching,
        userInfo: userData ?? null,
        refetch: refetchUserData,
      }}
    >
      {children}
    </Context.Provider>
  );
};
