import React from 'react';
import { Button } from 'glints-aries/lib/@next';

import { type ButtonType } from './constants';
import * as Styled from './styled.sc';

export interface ConfirmationModalProps {
  isOpen: boolean;
  header: string;
  content: React.ReactNode | string;
  confirmButtonText?: string;
  confirmButtonType?: ButtonType;
  submitting?: boolean;
  onClose(): void;
  onConfirm?(): void;
}

export const ConfirmationModal = ({
  isOpen,
  header,
  content,
  confirmButtonText,
  confirmButtonType,
  submitting,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  const customActions = (
    <Styled.Actions>
      <Button onClick={onClose} disabled={submitting}>
        Cancel
      </Button>
      <Styled.ConfirmButton
        onClick={onConfirm}
        buttonType={confirmButtonType}
        loading={submitting}
      >
        {confirmButtonText ?? 'Confirm'}
      </Styled.ConfirmButton>
    </Styled.Actions>
  );

  return (
    <Styled.Modal
      isOpen={isOpen}
      header={header}
      showCloseButton={false}
      onClose={onClose}
      customActions={customActions}
    >
      {content}
    </Styled.Modal>
  );
};
