import { Tooltip } from 'glints-aries/lib/@next';
import { Icon } from 'glints-aries/lib/@next/Icon/Icon';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

export const HalfDayLeaveTooltip = () => (
  <Tooltip
    preferredPosition="top-right"
    content="Half-day leave requests or public holidays may result in a shorter duration."
    zIndex={999}
  >
    <Icon name="ri-question-fill" fill={Neutral.B40} height="16px" />
  </Tooltip>
);
