import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

type GlintsAntdTableContainerProps = {
  height?: string;
  hideDefaultScrollbarX?: boolean;
  hideTableBorderBottom?: boolean;
};

export const GlintsAntdTableContainer = styled.div<GlintsAntdTableContainerProps>`
  height: ${(props) => props.height};

  .ant-table-container {
    background-color: ${Neutral.B100};
  }

  .ant-table-thead > tr > th {
    background-color: ${Neutral.B99};
    border-bottom: 1px solid ${Neutral.B85};
    color: ${Neutral.B18};
  }

  .ant-table-tbody > tr > td {
    border-bottom: ${(props) =>
      props.hideTableBorderBottom ? 'none' : `1px solid ${Neutral.B85}`};
    color: ${Neutral.B18};
  }

  ${(props) =>
    props.hideDefaultScrollbarX &&
    `
    .ant-table-body {
      scrollbar-width: none;
    }
  `}

  /* Override Aries styles */
  .glints-checkbox-styled-row {
    gap: unset !important;
  }
`;
