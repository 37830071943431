import { useEffect, useState } from 'react';

export const useAntdTableScrollbar = () => {
  const [isTableDefaultScrollbarXHidden, setIsTableDefaultScrollbarXHidden] =
    useState(false);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const elements = document.getElementsByClassName(
        'ant-table-sticky-scroll',
      );
      if (elements.length > 0) {
        setIsTableDefaultScrollbarXHidden(true);
      } else {
        setIsTableDefaultScrollbarXHidden(false);
      }
    });

    const targetNode = document.querySelector('.ant-table');
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  return {
    isTableDefaultScrollbarXHidden,
  };
};
