import * as Styled from './styled.sc';
import { ReactComponent as PlanetSVG } from '@/assets/images/planet.svg';
import { ReactComponent as SatelitesSVG } from '@/assets/images/satelites.svg';

export const IllustrationPlanet = () => (
  <Styled.PlanetContainer>
    <Styled.PlanetSVG>
      <PlanetSVG />
    </Styled.PlanetSVG>
    <Styled.SatelitesSVG>
      <SatelitesSVG />
    </Styled.SatelitesSVG>
  </Styled.PlanetContainer>
);
