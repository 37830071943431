import { Badge, Breakpoints } from 'glints-aries/lib/@next';
import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const CUSTOM_BREAKPOINT = '900px';

export const Container = styled.div`
  height: calc(100vh - 208px);

  @media (max-width: ${Breakpoints.large}) {
    height: calc(100vh - 231px);
  }
`;

export const RolesPermissionsCards = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const CardLi = styled.li`
  list-style: none;
  flex: 1;
  min-width: calc(50% - 48px);
  margin-bottom: ${space24};

  &:nth-child(odd) {
    margin-right: ${space24};
  }

  &:last-child {
    .card-container {
      width: 50%;
    }
  }

  .card-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-content-container {
      height: 100%;
    }

    .custom-actions-container {
      display: flex;
      flex-wrap: wrap;
      gap: ${space8};

      button {
        width: 215px;
      }
    }
  }

  @media (max-width: ${CUSTOM_BREAKPOINT}) {
    flex: none;
    width: 100%;

    &:last-child {
      .card-container {
        width: 100%;
      }
    }
  }
`;

export const UnassignedContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: ${space4};
`;

export const RoleCardOptionUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${space12};
  width: 600px;
  margin: 0;
  padding: 0;
`;

export const RoleCardOptionLi = styled.li`
  list-style: none;
`;

export const ModalContentContainer = styled.div`
  width: 600px;

  @media (max-width: ${Breakpoints.large}) {
    width: auto;
  }
`;

export const RoleDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  background-color: ${Neutral.B99};
  padding: ${space16};
  box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15),
    0px 0px 0px 1px rgba(71, 71, 71, 0.05);
  margin-bottom: ${space16};
  border-radius: ${borderRadius8};

  button {
    align-self: end;
    margin-top: ${space16};
  }
`;

export const StyledUserCountBadge = styled(Badge)`
  &[data-hidden='true'] {
    visibility: hidden;
  }
`;

export const RoleBannerContainer = styled.div`
  margin-bottom: ${space24};

  #prefix-icon {
    fill: ${Neutral.B40};
  }
`;

export const RoleBannerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BackOverviewButton = styled.button`
  color: ${Neutral.B40};
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  margin-bottom: ${space16};
  display: flex;
  gap: ${space4};
  cursor: pointer;

  svg {
    fill: ${Neutral.B40};
  }
`;

export const ViewPermissionsButton = styled.button`
  color: ${Neutral.B18};
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
`;
