import { useEffect, useState } from 'react';
import {
  type CurrencyInputProps,
  InlineError,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral, Red } from 'glints-aries/lib/@next/utilities/colors';
import {
  type FieldValues,
  useController,
  type UseControllerProps,
} from 'react-hook-form';

import { LabelContainer, StyledCurrencyInput, StyledLabel } from './styled.sc';

interface FormCurrencyInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<CurrencyInputProps, 'value' | 'defaultValue' | 'name'> {
  label?: string;
  required?: boolean;
  value?: number | null;
  apiError?: string | null;
  height?: string;
  helpText?: string;
}

export const CurrencyInputError = ({
  errorMessage = 'An unknown error has occurred',
}: {
  errorMessage?: string;
}) => <InlineError text={errorMessage} />;

export const FormCurrencyInput = <T extends FieldValues>({
  control,
  label,
  name,
  required = false,
  value,
  apiError,
  helpText,
  ...props
}: FormCurrencyInputProps<T>) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.message);
    }

    if (apiError) {
      setErrorMessage(apiError);
    }
  }, [error, apiError]);

  return (
    <div>
      {label && (
        <LabelContainer>
          <StyledLabel variant="subtitle2" color={Neutral.B18}>
            {label}
          </StyledLabel>
          {required && (
            <StyledLabel variant="subtitle2" color={Red.B93}>
              *
            </StyledLabel>
          )}
        </LabelContainer>
      )}
      <StyledCurrencyInput
        {...props}
        onChange={field.onChange || props.onChange}
        onBlur={field.onBlur || props.onBlur}
        value={value || field.value}
        name={field.name || name}
        ref={field.ref}
      />
      {!(error || apiError) && helpText && (
        <Typography as="span" variant="subtitle2" color={Neutral.B40}>
          {helpText}
        </Typography>
      )}
      {(error || apiError) && (
        <CurrencyInputError errorMessage={errorMessage} />
      )}
    </div>
  );
};
