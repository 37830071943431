import { useEffect, useState } from 'react';
import { format, getMonth, getYear, parse } from 'date-fns';
import {
  Alert,
  Button,
  ButtonGroup,
  EmptyState,
  Icon,
  PrimaryButton,
  Select,
  SkeletonImageSquare,
  TextInput,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { DataTable, type TableHeading } from 'glints-aries/lib/@next/DataTable';
import {
  Blue,
  Neutral,
  Orange,
  Red,
} from 'glints-aries/lib/@next/utilities/colors';
import { type ComponentAction } from 'glints-aries/lib/types/componentAction';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { periodFilterOptions } from '../components/PeriodFilter';
import {
  alertContent,
  AlertType,
  HOUR_TOLERANCE,
  JS_MONTH_OFFSET,
  PAGE_SIZE,
  TALENT_OPTIONS,
} from '../constants';
import { type AttendanceLogQuery } from '../interfaces';
import {
  AttendanceLogHeader,
  ClickableCell,
  DataTableContainer,
  EmptyStateContainer,
  HeaderExportButtonContainer,
  HeaderRequestFilterContainer,
  HeaderSearchContainer,
  HeaderTalentFilterContainer,
  TableButtonContainer,
  TableHeadingCellContainer,
  TablePaginationContainer,
  TotalHourCell,
} from './AttendanceLogTableStyle';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { useHubberDetailsSideSheet } from '@/components/organisms/SideSheets/HubberDetailsSideSheet/context';
import { StatusIndicator } from '@/components/StatusIndicator/StatusIndicator';
import { TablePagination } from '@/components/TablePagination/TablePagination';
import { MONTH_YEAR_DATE_FORMAT } from '@/constants/date';
import {
  EmploymentStatus,
  type ExportAttendanceSummaryMutation,
  HubberSortField,
  SortOrder,
  useExportAttendanceSummaryMutation,
  useGetAttendanceLogQuery,
} from '@/generated/graphql';
import { useDebounce } from '@/hooks/debounce';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { StatusFilterSelect } from '@/modules/ManagedTalents/components/StatusFilterSelect';
import { statusFilterOptions } from '@/modules/ManagedTalents/constants';

export const AttendanceLogTable = ({
  updateShowBanner,
}: {
  updateShowBanner(shouldShow: boolean): void;
}) => {
  const today = new Date();
  const [searchParams, setSearchParams] = useSearchParams();

  const { showHubberSideSheet } = useHubberDetailsSideSheet();

  const yearParam = searchParams.get('year');
  const monthParam = searchParams.get('month');
  const dateParamFormat = 'yyyy-M';

  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1,
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get('name') ?? '',
  );
  const [selectedPeriod, setSelectedPeriod] = useState(() => {
    if (yearParam && monthParam) {
      const dateString = `${yearParam}-${monthParam}`;
      const parsedDate = parse(dateString, dateParamFormat, new Date());
      return format(parsedDate, MONTH_YEAR_DATE_FORMAT);
    } else {
      return format(today, MONTH_YEAR_DATE_FORMAT);
    }
  });
  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState(
    searchParams.getAll('status') ?? [TALENT_OPTIONS[0].value],
  );

  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Asc);

  const [showAlert, setShowAlert] = useState<{
    status: undefined | 'success' | 'error';
    content: string;
    shouldShow: boolean;
  }>({
    status: undefined,
    content: '',
    shouldShow: false,
  });
  const [hideStatusBadge, setHideStatusBadge] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();

  const parsedSelectedPeriodValue = parse(
    selectedPeriod,
    MONTH_YEAR_DATE_FORMAT,
    new Date(),
  );

  const variables = {
    page: currentPage,
    pageSize: PAGE_SIZE,
    sorts: [{ field: HubberSortField.FullName, order: sortOrder }],
    name: debouncedSearchValue,
    status: selectedEmploymentStatus as EmploymentStatus[],
    period: {
      year: getYear(parsedSelectedPeriodValue),
      month: getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET,
    },
  };
  const {
    data,
    isLoading,
    error: getAttendanceLogError,
  } = useGetAttendanceLogQuery<AttendanceLogQuery, Error>(
    graphqlClient,
    variables,
    {},
  );

  const hubsWithAttendance = ['BT', 'ID', 'PH'];

  const hasOnlyAttendanceData = data?.company.hubberHubs.every((hub: string) =>
    hubsWithAttendance.includes(hub),
  );
  const hasSomeAttendanceData = data?.company.hubberHubs.some((hub: string) =>
    hubsWithAttendance.includes(hub),
  );

  const {
    mutate: exportMutate,
    isLoading: isLoadingExport,
    error: exportAttendanceSummaryError,
  } = useExportAttendanceSummaryMutation<
    Error,
    ExportAttendanceSummaryMutation
  >(graphqlClient);

  // differentiate between no hubbers and no search results // TODO - is there a better way? this is quite confusing
  const [isNoHubbers, setIsNoHubbers] = useState<boolean>(false);
  useEffect(() => {
    if (!isLoading && data?.hubbers?.data?.length === 0) {
      setIsNoHubbers(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value: string) => {
    setCurrentPage(1);
    setSearchValue(value);
  };

  const handleSelectPeriod = ({ value }: { value: string }) => {
    setCurrentPage(1);
    setSelectedPeriod(value);
    setSearchParams({
      year: `${new Date(value).getFullYear()}`,
      month: `${new Date(value).getMonth() + JS_MONTH_OFFSET}`,
    });
  };

  const updateHideStatusBadge = (hide: boolean) => {
    setHideStatusBadge(hide);
  };

  const handleTalentSelect = ({ value }: { value: string }) => {
    let updatedSelectedStatus = [];

    if (selectedEmploymentStatus) {
      if (selectedEmploymentStatus.includes(value)) {
        updatedSelectedStatus = selectedEmploymentStatus.filter(
          (option) => option !== value,
        );
        setSelectedEmploymentStatus(updatedSelectedStatus);
        setCurrentPage(1);
      } else {
        updatedSelectedStatus = [...selectedEmploymentStatus, value];
        setSelectedEmploymentStatus(updatedSelectedStatus);
        setCurrentPage(1);
      }

      if (updatedSelectedStatus?.length < statusFilterOptions?.length) {
        setHideStatusBadge(false);
      }
    }
  };

  const handleExport = () => {
    const exportVariables = {
      year: getYear(parsedSelectedPeriodValue),
      month: getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET,
    };
    exportMutate(exportVariables, {
      onSuccess: (data) => {
        setShowAlert({
          status: AlertType.SUCCESS,
          content: alertContent[AlertType.SUCCESS],
          shouldShow: true,
        });

        const linkElement = document.createElement('a');
        linkElement.href = data?.exportAttendanceSummary?.fileUrl;
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
      },
      onError: () => {
        setShowAlert({
          status: AlertType.ERROR,
          content: alertContent[AlertType.ERROR],
          shouldShow: true,
        });
      },
    });
  };

  const handleSortClick = () => {
    setCurrentPage(1);
    setSortOrder(sortOrder === SortOrder.Desc ? SortOrder.Asc : SortOrder.Desc);
  };

  useEffect(() => {
    setSearchParams({
      page: `${currentPage}`,
      pageSize: `${PAGE_SIZE}`,
      sortField: HubberSortField.FullName,
      sortOrder,
      name: debouncedSearchValue,
      status: selectedEmploymentStatus as EmploymentStatus[],
      year: String(getYear(parsedSelectedPeriodValue)),
      month: String(getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    debouncedSearchValue,
    selectedEmploymentStatus,
    setSearchParams,
    sortOrder,
  ]);

  useEffect(() => {
    if (hasOnlyAttendanceData) {
      updateShowBanner(false);
      return;
    }

    if (hasSomeAttendanceData || !hasOnlyAttendanceData) {
      updateShowBanner(true);
      return;
    }
  }, [hasOnlyAttendanceData, hasSomeAttendanceData, updateShowBanner]);

  const headings: TableHeading[] = [
    {
      title: 'Employee',
      defaultSortDirection: 'DESCENDING',
    },
    {
      title: 'TalentHub',
    },
    {
      title: (
        <TableHeadingCellContainer>
          Expected Total Hours
          <Tooltip
            content="This is the expected total number of hours an individual is obligated to work over a specific period, accounting for deductions for leaves and holidays."
            preferredPosition="bottom-center"
          >
            <Icon
              name="ri-question-fill"
              height="24px"
              width="24px"
              fill={Neutral.B40}
            />
          </Tooltip>
        </TableHeadingCellContainer>
      ),
      align: 'right',
    },
    {
      title: (
        <TableHeadingCellContainer>
          Actual Total Hours
          <Tooltip
            content="This represents the total working hours when check-in and check-out times are logged within a day."
            preferredPosition="bottom-center"
          >
            <Icon
              name="ri-question-fill"
              height="24px"
              width="24px"
              fill={Neutral.B40}
            />
          </Tooltip>
        </TableHeadingCellContainer>
      ),
      align: 'right',
    },
    {
      title: 'Actions',
    },
  ];

  const emptyState = (
    <EmptyState
      title="No Logging Data"
      description="None of your talent has ever been required to log attendance"
      image={<NebulaSVG />}
    />
  );
  const noMatchAction: ComponentAction = {
    label: 'Clear All Filters',
    onClick: () => {
      setCurrentPage(1);
      setSearchValue('');
      setSelectedEmploymentStatus([]);
      setSelectedPeriod(format(today, MONTH_YEAR_DATE_FORMAT));
    },
  };
  const noMatchingResultsState = (
    <EmptyState
      title="No Logging Data"
      description="No results were found based on current filtering conditions."
      basicButtonAction={noMatchAction}
    />
  );

  const loadingRow = [...Array(10).keys()].map((n) => (
    <DataTable.Row id={`loading-row-${n}`} key={n} position={n}>
      {[...Array(5).keys()].map((n) => (
        <DataTable.Cell key={`loading-row-cell-${n}`}>
          <SkeletonImageSquare height="24px" width="100%" />
        </DataTable.Cell>
      ))}
    </DataTable.Row>
  ));

  const rowMarkup = data?.hubbers.data.map((hubber, index) => {
    const expectedHours = parseFloat(hubber?.attendance.scheduledHours).toFixed(
      1,
    );
    const actualHours = parseFloat(hubber?.attendance.actualHours).toFixed(1);
    const hasAttendance = hubsWithAttendance.includes(hubber.hub);

    return (
      <DataTable.Row key={index}>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <ClickableCell
            onClick={() => {
              showHubberSideSheet({
                hubberData: hubber,
              });
            }}
            data-left-padding={true}
          >
            <Typography
              as="span"
              color={Blue.S99}
              className="hubber-name-span"
              style={{
                zIndex: 10,
              }}
            >
              {hubber.fullName}
            </Typography>
            <StatusIndicator
              active={hubber.status === EmploymentStatus.Active}
            />
          </ClickableCell>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center">
          <HubLocationText hubCode={hubber.hub as HubCode} />
        </DataTable.Cell>
        <DataTable.Cell align="right" verticalAlign="center">
          <Typography
            as="span"
            variant="subtitle2"
            color={hasAttendance ? Neutral.B18 : Neutral.B85}
          >
            {hasAttendance ? expectedHours : `Inapplicable`}
          </Typography>
        </DataTable.Cell>
        <DataTable.Cell align="right" verticalAlign="center">
          <TotalHourCell>
            {parseFloat(actualHours) <
              parseFloat(expectedHours) - HOUR_TOLERANCE && (
              <Icon
                name="ri-arrow-down-line"
                height="16px"
                width="16px"
                fill={Orange.S86}
              />
            )}
            {parseFloat(actualHours) >
              parseFloat(expectedHours) + HOUR_TOLERANCE && (
              <Icon
                name="ri-arrow-up-line"
                height="16px"
                width="16px"
                fill={Red.B93}
              />
            )}
            <Typography
              as="span"
              variant="subtitle2"
              color={hasAttendance ? Neutral.B18 : Neutral.B85}
            >
              {hasAttendance ? actualHours : `Inapplicable`}
            </Typography>
          </TotalHourCell>
        </DataTable.Cell>
        <DataTable.Cell verticalAlign="center" noWrap={true}>
          <ButtonGroup>
            <Button
              size="slim"
              icon={<Icon name="ri-arrow-m-right-line" />}
              iconPosition="right"
              onClick={() => {
                navigate({
                  pathname: `${hubber?.id}`,
                  search: createSearchParams({
                    year: String(getYear(parsedSelectedPeriodValue)),
                    month: String(
                      getMonth(parsedSelectedPeriodValue) + JS_MONTH_OFFSET,
                    ),
                    prevPage: `${currentPage}`,
                    prevSearch: `${debouncedSearchValue}`,
                  }).toString(),
                });
              }}
              disabled={!hasAttendance}
            >
              <TableButtonContainer data-no-margin-right={true}>
                Daily Log
              </TableButtonContainer>
            </Button>
          </ButtonGroup>
        </DataTable.Cell>
      </DataTable.Row>
    );
  });

  const paginationTable = (
    <TablePaginationContainer className="table-pagination">
      <TablePagination
        currentPage={currentPage}
        pageSize={data?.hubbers.pageSize || 0}
        totalItems={data?.hubbers.total || 0}
        onPageChanged={(page: number) => setCurrentPage(page)}
      />
    </TablePaginationContainer>
  );

  const pageHeaderActions = (
    <AttendanceLogHeader>
      <HeaderSearchContainer>
        <TextInput
          value={searchValue}
          prefix={<Icon name="ri-search" />}
          placeholder="Search for Name"
          onChange={handleSearchChange}
        />
      </HeaderSearchContainer>
      <HeaderRequestFilterContainer>
        <Select
          prefix={
            <Icon
              name="ri-calendar-event-line"
              height="20px"
              fill={Neutral.B40}
            />
          }
          options={periodFilterOptions()}
          onSelect={handleSelectPeriod}
          selectedValues={[selectedPeriod]}
          width="100%"
          listHeight={248}
        />
      </HeaderRequestFilterContainer>
      <HeaderTalentFilterContainer>
        <StatusFilterSelect
          selectedStatus={selectedEmploymentStatus}
          handleSelectStatus={handleTalentSelect}
          updateHideStatusBadge={updateHideStatusBadge}
          hideBadge={hideStatusBadge}
        />
      </HeaderTalentFilterContainer>
      <HeaderExportButtonContainer>
        <PrimaryButton
          icon={<Icon name="ri-download-line" />}
          type="button"
          onClick={handleExport}
          loading={isLoadingExport}
        >
          Export
        </PrimaryButton>
      </HeaderExportButtonContainer>
    </AttendanceLogHeader>
  );

  const renderTableContent = () => {
    if (!hasSomeAttendanceData) {
      return <EmptyStateContainer>{emptyState}</EmptyStateContainer>;
    }

    return (
      <>
        {!isNoHubbers && pageHeaderActions}
        <DataTableContainer>
          <DataTable
            headings={headings}
            emptyState={isNoHubbers ? emptyState : noMatchingResultsState}
            onSortChanged={handleSortClick}
            style={{ border: 'none' }}
          >
            {isLoading ? loadingRow : rowMarkup}
          </DataTable>
          {!isLoading &&
            (data?.hubbers?.data?.length ?? 0) > 0 &&
            paginationTable}
        </DataTableContainer>
        <Alert
          show={showAlert.shouldShow}
          onDismissed={() =>
            setShowAlert({ status: undefined, content: '', shouldShow: false })
          }
          content={showAlert.content}
          status={showAlert.status}
        />
      </>
    );
  };

  useGraphqlError([getAttendanceLogError, exportAttendanceSummaryError]);

  return <>{renderTableContent()}</>;
};
