import { Breakpoints, Icon } from 'glints-aries/lib/@next';
import { borderRadius4 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral, Red } from 'glints-aries/lib/@next/utilities/colors';
import { NotoSans } from 'glints-aries/lib/@next/utilities/fonts';
import {
  space4,
  space8,
  space12,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const Selector = styled.div`
  position: relative;
  font-family: ${NotoSans}, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

const InputContainer = styled.span`
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;

  &[data-error='true'] > input {
    border: 1px solid ${Red.B93};
  }

  &[data-error='true'] > input:focus {
    box-shadow: none;
  }

  &[aria-disabled='true'] > input {
    pointer-events: none;
    color: ${Neutral.B85};
    border-color: ${Neutral.B85};
    background: ${Neutral.B95};

    &::placeholder {
      color: ${Neutral.B68};
    }
  }

  &[aria-disabled='true'] svg {
    fill: ${Neutral.B85};
  }

  @media (max-width: ${Breakpoints.large}) {
    font-size: 14px;
  }
`;

const Input = styled.input`
  background: ${Neutral.B100};
  box-sizing: border-box;
  border: 1px solid ${Neutral.B68};
  border-radius: ${borderRadius4};
  padding: 0 12px;

  font-family: ${NotoSans}, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${Neutral.B18};

  height: 36px;
  width: 100%;

  &::placeholder {
    color: ${Neutral.B68};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px ${Neutral.B100}, 0px 0px 0px 3px #6ac9ec;
  }

  &[data-prefix='true'] {
    padding-left: 37px;
  }

  &[data-icon='true'] {
    padding-left: 65px;
  }

  &[data-prefix='false'][data-icon='true'] {
    padding-left: 45px;
  }

  &[data-readonly='true'] {
    cursor: pointer;
  }

  @media (max-width: ${Breakpoints.large}) {
    font-size: 14px;
  }
`;

const InputPrefix = styled.div`
  position: absolute;
  left: 0;
  color: ${Neutral.B40};
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0px ${space8} 0 ${space12};

  svg {
    height: 17px;
    width: 17px;
    fill: ${Neutral.B40};
  }
`;

const InputDropdownSuffix = styled.div`
  position: absolute;
  right: 0;
  color: ${Neutral.B40};
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0px ${space12} 0 ${space4};
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
    fill: ${Neutral.B40};
  }
`;

const InputIcon = styled.div`
  position: absolute;
  left: 4px;
  color: ${Neutral.B40};
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0px ${space8} 0 ${space12};

  &[data-prefix='true'] {
    left: 25px;
  }

  svg {
    height: 16px;
    width: 100%;
    fill: ${Neutral.B40};
  }
`;

const InputBadge = styled.div`
  position: absolute;
  right: 35px;
  display: flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
`;

const InputSuffix = styled.div`
  position: absolute;
  right: 0;
  color: ${Neutral.B40};
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0px ${space12} 0 ${space4};
  cursor: pointer;

  svg {
    height: 17px;
    width: 17px;
    fill: ${Neutral.B40};
  }
`;

type DropdownProps = {
  visible: boolean;
  openDirection: 'up' | 'down';
};

const Dropdown = styled.div<DropdownProps>`
  position: absolute;
  left: 0;
  bottom: ${({ openDirection }) => openDirection === 'up' && '44px'};
  top: ${({ openDirection }) => openDirection === 'down' && '44px'};
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px ${space8} 20px rgba(71, 71, 71, 0.2),
    0px 3px 6px -3px rgba(71, 71, 71, 0.08);
  padding: 8px 0;
  width: 100%;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 10;

  @media (max-width: ${Breakpoints.large}) {
    font-size: 14px;
  }
`;

const DropdownList = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: ${space8} 0;
  list-style: none;

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c0c4ca;
    border-radius: 6px;
    border: 3px solid #ffffff;
  }

  &--no-scroll {
    margin-right: 0;
  }
`;

const DropdownListItem = styled.li`
  align-items: center;
  display: flex;
  font-weight: 400;
  padding: 0 ${space8};
  margin-bottom: ${space4};
  min-height: 44px;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  outline: none;
  position: relative;

  &:hover {
    background: ${Neutral.B99};
    border-radius: ${borderRadius4};
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.001);
    border: 2px solid ${Blue.S54};
    border-radius: ${space4};
  }

  &.item--disabled {
    cursor: not-allowed;
    color: ${Neutral.B85};
    background-color: ${Neutral.B100};
  }

  &.item--selected {
    background: ${Blue.S08};
    border-radius: ${borderRadius4};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -${space8};
      height: 44px;
      width: 3px;
      background-color: ${Blue.S99};
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

const DropdownListItemCheckboxContainer = styled.div`
  position: relative;
  margin-right: 8px;
  display: flex;
  justify-content: center;
`;

const DropdownListItemCheckbox = styled.input`
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-color: ${Neutral.B68};
  cursor: pointer;
  border-radius: ${borderRadius4};

  &:checked {
    background-color: ${Blue.S99};
    border-color: ${Blue.S99};
    transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2), opacity 0.3s;

    & + svg {
      fill: ${Neutral.B100};
      opacity: 1;
    }
  }

  &:disabled {
    border-color: ${Neutral.B85};
  }
`;

const DropdownListItemCheckboxIcon = styled(Icon)`
  position: absolute;
  height: 18px;
  width: 18px;
  top: 0;
  left: 0;
  opacity: 0;
`;

const DropdownListGroupLabel = styled.div`
  color: ${Neutral.B40};
  padding: 8px 12px;
  font-weight: 400;
`;

const DropdownListPlaceholder = styled.div`
  padding: 16px;
  text-align: center;
`;

const DropdownListItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const DropdownListItemBadge = styled.div`
  position: absolute;
  right: 0;
`;

export {
  Dropdown,
  DropdownList,
  DropdownListGroupLabel,
  DropdownListItem,
  DropdownListItemCheckbox,
  DropdownListItemCheckboxContainer,
  DropdownListItemCheckboxIcon,
  DropdownListPlaceholder,
  DropdownListItemIcon,
  DropdownListItemBadge,
  InputDropdownSuffix,
  Input,
  InputContainer,
  InputPrefix,
  InputIcon,
  InputSuffix,
  InputBadge,
  Selector,
};

export type { DropdownProps };
