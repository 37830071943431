import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../AuthInfoProvider/AuthInfoProvider';
import { useAuthToken } from '@/hooks/useAuthToken';

export const AuthenticatePage = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const AuthenticationRequiredPage: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const { isAuthenticated, clearAuthToken } = useAuthToken();
    const authContext = useAuthContext();

    const shouldRedirectToLogin = !isAuthenticated();

    useEffect(() => {
      if (shouldRedirectToLogin) {
        clearAuthToken();
        navigate('/401/unauthorized', { replace: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRedirectToLogin]);

    if (authContext.loading) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return AuthenticationRequiredPage;
};
