interface Props {
  children: React.ReactNode;
}

export const ParticlesComposer = ({
  children,
  particles,
}: {
  children: React.ReactNode;
  particles: React.ComponentType<Props>[];
}) => (
  <>
    {particles.reduceRight(
      (child, ParticleProvider) => (
        <ParticleProvider>{child}</ParticleProvider>
      ),
      children,
    )}
  </>
);
