import { ReactComponent as BankOfChinaSVG } from '../../../assets/images/bank-bank-of-china.svg';
import { ReactComponent as DbsSVG } from '../../../assets/images/bank-dbs.svg';
import { ReactComponent as MaybankSVG } from '../../../assets/images/bank-maybank.svg';
import { ReactComponent as OcbcSVG } from '../../../assets/images/bank-ocbc.svg';
import { ReactComponent as UobSVG } from '../../../assets/images/bank-uob.svg';
import { Currency } from '@/constants/currency';
import { DirectDebitStatus, type Money } from '@/generated/graphql';
import { roundUpToNearest500 } from '@/utils/math';

export const Bank = {
  DBS: 'DBSSSGSGXXX',
  OCBC: 'OCBCSGSGXXX',
  UOB: 'UOVBSGSGXXX',
  Maybank: 'MBBESGS2XXX',
  BankOfChina: 'BKCHSGSGXXX',
} as const;

export type BankSwiftCode = ValueOf<typeof Bank>;

export const bankDetailsMapping: {
  [key in BankSwiftCode]: {
    name: string;
    icon: React.ReactNode;
    url: string;
    bankSwiftBicCode: BankSwiftCode;
  };
} = {
  [Bank.DBS]: {
    name: 'DBS',
    icon: <DbsSVG key="dbs-icon" />,
    url: 'https://ideal.dbs.com/',
    bankSwiftBicCode: Bank.DBS,
  },
  [Bank.OCBC]: {
    name: 'OCBC',
    icon: <OcbcSVG key="ocbc-icon" />,
    url: 'https://velocity.ocbc.com/',
    bankSwiftBicCode: Bank.OCBC,
  },
  [Bank.UOB]: {
    name: 'UOB',
    icon: <UobSVG key="uob-icon" />,
    url: ' https://uobsme.uobgroup.com/',
    bankSwiftBicCode: Bank.UOB,
  },
  [Bank.Maybank]: {
    name: 'Maybank',
    icon: <MaybankSVG key="maybank-icon" />,
    url: 'https://www.maybank2u.com.my/',
    bankSwiftBicCode: Bank.Maybank,
  },
  [Bank.BankOfChina]: {
    name: 'Bank of China',
    icon: <BankOfChinaSVG key="bank-of-china-icon" />,
    url: ' https://www.bochk.com/en/home.html',
    bankSwiftBicCode: Bank.BankOfChina,
  },
};

export const bankStatusBadge: {
  [key in DirectDebitStatus]?: { badge?: string };
} = {
  [DirectDebitStatus.Pending]: {
    badge: 'Pending',
  },
  [DirectDebitStatus.Active]: {
    badge: 'Connected',
  },
  [DirectDebitStatus.Failed]: {
    badge: 'Failed to Connect',
  },
};

export enum BankError {
  PAYER_AUTHORIZATION_FAILED = 'PAYER_AUTHORIZATION_FAILED',
  PAYER_SPECIFIED_LOWER_MAX_AMOUNT = 'PAYER_SPECIFIED_LOWER_MAX_AMOUNT',
  PAYER_SPECIFIED_EARLIER_END_DATE = 'PAYER_SPECIFIED_EARLIER_END_DATE',
  DEFAULT = 'DEFAULT',
}

export const DEFAULT_LAST_MONTH_INVOICE_AMOUNT = 2000;
export const DEFAULT_LAST_MONTH_INVOICE_CURRENCY = Currency.SINGAPORE;
export const LAST_MONTH_INVOICE_MULTIPLIER = 1.5;

export const computeLastMonthInvoiceSGD = (
  lastMonthTotalInvoice: Omit<Money, 'inCurrencies'>[],
): { amount: number; currency: string | Currency } | undefined => {
  const getLastMonthTotal = lastMonthTotalInvoice.find(
    (money) => money.currency === Currency.SINGAPORE,
  );
  const amountToFloat = parseFloat(getLastMonthTotal?.amount || '0.00');
  const multipledAmount = amountToFloat * LAST_MONTH_INVOICE_MULTIPLIER;
  const roundedUpAmount = roundUpToNearest500(multipledAmount);

  if (roundedUpAmount < DEFAULT_LAST_MONTH_INVOICE_AMOUNT) {
    return;
  }

  const lastMonthTotalInvoiceSGD = {
    amount: roundedUpAmount,
    currency: getLastMonthTotal?.currency || Currency.SINGAPORE,
  };

  return lastMonthTotalInvoiceSGD;
};
