import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space4, space8 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const HubLocationText = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: ${space4};
  align-items: center;
`;

export const CountryFlag = styled.div`
  margin-right: ${space8};

  svg {
    height: 18px;
    border: 1px solid ${Neutral.B85};
    vertical-align: middle;
  }
`;

export const CountryText = styled.div`
  display: flex;
  align-items: center;
  gap: ${space4};
`;
