import {
  space16,
  space24,
  space32,
} from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div<{ dense?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${space24};
  padding: ${space32} ${space24} ${space16} ${space24};

  /* activate "dense" if the page is wrapped by the tab content */
  ${({ dense }) =>
    dense &&
    `
    padding: ${space16} 0;
  `}

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${space32} ${space16} ${space16} ${space16};
  }
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  gap: ${space16};

  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: space-between;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
  }
`;

export const Body = styled.div`
  flex-grow: 1;
`;
