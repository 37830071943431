import { useMemo, useState } from 'react';
import { EmptyState, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { PAGE_SIZE } from '../../constants';
import {
  UNASSIGNED_EMPLOYEES_TABLE_KEY,
  UNASSIGNED_EMPLOYEES_TABLE_TITLE,
} from './constants';
import { hubberNameSorter } from './helpers';
import * as Styled from './styled.sc';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { GlintsAntdTable } from '@/components/atoms/GlintsAntdTable/GlintsAntdTable';
import { type TableColumns } from '@/components/atoms/GlintsAntdTable/types';
import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { SimplePagination } from '@/components/atoms/SimplePagination/SimplePagination';
import {
  EmploymentStatus,
  type GetHubbersWithDirectManagerQuery,
  type HubberWithDirectManagerFragment,
  useGetHubbersWithDirectManagerQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';

export interface UnassignedEmployeesModalProps {
  isOpen: boolean;
  unassignedCount: number;
  onClose: () => void;
}

export const UnassignedEmployeesModal = ({
  isOpen,
  unassignedCount,
  onClose,
}: UnassignedEmployeesModalProps) => {
  const graphqlClient = getGraphqlClient();

  const [page, setPage] = useState(1);

  const handleClose = () => {
    onClose();
    setPage(1);
  };

  const {
    data: getHubbersWithDirectManagerData,
    isLoading: getHubbersWithDirectManagerLoading,
    error: getHubbersWithDirectManagerError,
  } = useGetHubbersWithDirectManagerQuery<
    GetHubbersWithDirectManagerQuery,
    Error
  >(
    graphqlClient,
    {
      page: page,
      pageSize: PAGE_SIZE,
      status: [EmploymentStatus.Active],
    },
    {
      enabled: isOpen && Boolean(page),
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const unassignedHubbers = useMemo(
    () =>
      getHubbersWithDirectManagerData?.hubbers.data.filter(
        (hubber) => hubber.directManager.length === 0,
      ) || [],
    [getHubbersWithDirectManagerData],
  );

  const columns: TableColumns<HubberWithDirectManagerFragment> = [
    {
      key: UNASSIGNED_EMPLOYEES_TABLE_KEY.employee,
      title:
        UNASSIGNED_EMPLOYEES_TABLE_TITLE[
          UNASSIGNED_EMPLOYEES_TABLE_KEY.employee
        ],
      dataIndex: 'fullName',
      width: 250,
      sorter: hubberNameSorter,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'ascend',
    },
    {
      key: UNASSIGNED_EMPLOYEES_TABLE_KEY.jobTitle,
      title:
        UNASSIGNED_EMPLOYEES_TABLE_TITLE[
          UNASSIGNED_EMPLOYEES_TABLE_KEY.jobTitle
        ],
      dataIndex: 'jobTitle',
      width: 250,
    },
    {
      key: UNASSIGNED_EMPLOYEES_TABLE_KEY.talentHub,
      title:
        UNASSIGNED_EMPLOYEES_TABLE_TITLE[
          UNASSIGNED_EMPLOYEES_TABLE_KEY.talentHub
        ],
      dataIndex: 'hub',
      width: 130,
      render: (record: HubberWithDirectManagerFragment['hub']) => (
        <HubLocationText hubCode={record as HubCode} />
      ),
    },
    {
      key: UNASSIGNED_EMPLOYEES_TABLE_KEY.reportingManager,
      title:
        UNASSIGNED_EMPLOYEES_TABLE_TITLE[
          UNASSIGNED_EMPLOYEES_TABLE_KEY.reportingManager
        ],
      render: (record: HubberWithDirectManagerFragment) => (
        <>
          {record.directManager[0] ? (
            record.directManager[0].name
          ) : (
            <Typography as="span" variant="subtitle2" color={Neutral.B68}>
              Unassigned
            </Typography>
          )}
        </>
      ),
    },
  ];

  const emptyState = (
    <EmptyState
      title="No active employees"
      description="There are no employees working for your company right now."
      image={<NebulaSVG />}
    />
  );

  const customActions = (
    <Styled.Actions>
      <SimplePagination
        currentPage={page || 1}
        pageSize={getHubbersWithDirectManagerData?.hubbers.pageSize || 0}
        totalItems={getHubbersWithDirectManagerData?.hubbers.total || 0}
        onPageChanged={(page: number) => setPage(page)}
        hidePageButtons={true}
      />
    </Styled.Actions>
  );

  useGraphqlError([getHubbersWithDirectManagerError]);

  return (
    <Styled.Modal
      isOpen={isOpen}
      header={`${unassignedCount} Employees Missing Assigned Managers`}
      onClose={handleClose}
      customActions={customActions}
    >
      <Styled.ModalContent>
        <GlintsAntdTable
          columns={columns}
          dataSource={unassignedHubbers}
          loading={getHubbersWithDirectManagerLoading}
          hideTableBorderBottom={true}
          pagination={false}
          emptyState={emptyState}
          sticky={{
            offsetHeader: 0,
          }}
          scroll={{ x: 'max-content' }}
          height="100%"
        />
      </Styled.ModalContent>
    </Styled.Modal>
  );
};
