import { useEffect } from 'react';
import { Icon, OutlineButton, Typography } from 'glints-aries/lib/@next';
import { Green, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { useNavigate } from 'react-router-dom';

import {
  bankDetailsMapping,
  type BankSwiftCode,
} from '../AutoPaymentTab/constants';
import {
  BankIconContainer,
  ContentContainer,
  IconsContainer,
  MainContainer,
  StyledPageTitleContainer,
} from './EgiroStyle';
import { ReactComponent as DisconnectBankSVG } from '@/assets/images/disconnect-bank.svg';
import { ReactComponent as EgiroGraySVG } from '@/assets/images/eGIRO-gray.svg';
import { ReactComponent as GlintsStarLogoSVG } from '@/assets/images/glints-star-logo.svg';
import { getGraphqlClient } from '@/clients/graphql';
import {
  type DisconnectDirectDebitMutation,
  useDisconnectDirectDebitMutation,
} from '@/generated/graphql';

export const EgiroDisconnect = ({
  bankName,
  eGIROurl,
  bankSwiftBicCode,
}: {
  bankName: string;
  eGIROurl: string;
  bankSwiftBicCode: BankSwiftCode;
}) => {
  const navigate = useNavigate();
  const graphqlClient = getGraphqlClient();

  const { mutate: disconnectDirectDebitMutate } =
    useDisconnectDirectDebitMutation<Error, DisconnectDirectDebitMutation>(
      graphqlClient,
    );

  const onClose = () => {
    navigate('/company-settings/auto-payment');
  };

  useEffect(() => {
    if (eGIROurl === 'disconnect') {
      const delay = 2000;

      const disconnectDirectDebit = () => {
        const disconnectDirectDebitParams = {
          param: {
            redirectUrl: `${window.location.origin}/egiro/disconnected?bank=${bankSwiftBicCode}`,
          },
        };

        disconnectDirectDebitMutate(disconnectDirectDebitParams, {
          onSuccess: (data) => {
            window.open(
              data?.disconnectDirectDebit?.cancelationUrl ??
                `${window.location.origin}/egiro/disconnected?bank=${bankSwiftBicCode}`,
              '_blank',
            );
          },
        });
      };

      setTimeout(disconnectDirectDebit, delay);
    }
  }, [bankSwiftBicCode, disconnectDirectDebitMutate, eGIROurl]);

  return (
    <MainContainer>
      {eGIROurl === 'disconnect' ? (
        <>
          <StyledPageTitleContainer>
            <Typography as="div" variant="headline6" color={Neutral.B18}>
              Connect with {bankName} business account
            </Typography>
            <Icon
              name="ri-close-circle-fill"
              height="20px"
              fill={Neutral.B40}
              onClick={onClose}
            />
          </StyledPageTitleContainer>
          <ContentContainer>
            <BankIconContainer>
              {bankDetailsMapping[bankSwiftBicCode].icon}
              <Typography variant="headline3" as="span" color={Neutral.B18}>
                {bankDetailsMapping[bankSwiftBicCode].name}
              </Typography>
            </BankIconContainer>
            <Typography as="span" variant="subtitle1" color={Neutral.B18}>
              We’re redirecting you to{' '}
              {bankDetailsMapping[bankSwiftBicCode].name}...
            </Typography>
          </ContentContainer>
        </>
      ) : (
        <>
          <StyledPageTitleContainer>
            <Typography as="div" variant="headline6" color={Neutral.B18}>
              Disconnect with {bankName} business account
            </Typography>
            <Icon
              name="ri-close-circle-fill"
              height="20px"
              fill={Neutral.B40}
              onClick={onClose}
            />
          </StyledPageTitleContainer>
          <ContentContainer>
            <div className="status-content-container">
              <IconsContainer backgroundColor="rgba(194, 226, 218, 0.3)">
                <Icon
                  name="ri-checkbox-circle-fill"
                  height="48px"
                  fill={Green.B61}
                />
                <div className="icon-section">
                  <EgiroGraySVG />
                  <DisconnectBankSVG />
                  <GlintsStarLogoSVG />
                </div>
              </IconsContainer>
              <div className="status-content">
                <Typography as="span" variant="headline6">
                  Account Disconnected
                </Typography>
                <div className="status-content-body">
                  <Typography variant="body1" as="span">
                    Your {bankName} account has been successfully disconnected.
                  </Typography>
                </div>
              </div>
              <OutlineButton onClick={onClose}>Got It</OutlineButton>
            </div>
          </ContentContainer>
        </>
      )}
    </MainContainer>
  );
};
