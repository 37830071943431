import { Icon, Typography } from 'glints-aries/lib/@next';

import { MenuItemStyled } from './styled.sc';
import { type Item } from './types';
import { GlintsAntdTooltip } from '@/components/atoms/GlintsAntdTooltip/GlintsAntdTooltip';

export const MenuItem = ({
  itemKey,
  label,
  selectedKey,
  iconName,
  selectedIconName,
  isCollapsed,
  isMobile,
  onClick,
}: Item & { itemKey: string; isCollapsed: boolean; isMobile: boolean }) => {
  const isSelected = selectedKey === itemKey;
  const displayIconName =
    isSelected && selectedIconName ? selectedIconName : iconName;

  const TooltipWrapper = ({
    children,
    label,
  }: {
    children: React.ReactNode;
    label: React.ReactNode;
  }) => {
    if (!isCollapsed) return <>{children}</>;
    return (
      <GlintsAntdTooltip title={label} placement="right">
        {children}
      </GlintsAntdTooltip>
    );
  };

  return (
    <TooltipWrapper label={label}>
      <MenuItemStyled
        data-selected={isSelected}
        data-collapsed={isCollapsed}
        data-mobile={isMobile}
        onClick={() => onClick?.(itemKey)}
      >
        {displayIconName && <Icon name={displayIconName} />}
        <Typography as="span" variant="button">
          {label}
        </Typography>
      </MenuItemStyled>
    </TooltipWrapper>
  );
};
