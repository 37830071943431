import { useState } from 'react';
import { EmptyState } from 'glints-aries/lib/@next';
import { type SortDirection } from 'glints-aries/lib/@next/DataTable';

import { TopSection } from '../AwaitingPaymentTab/AwaitingPaymentTabStyle';
import { EGIROBanner } from '../components/EGIROBanner';
import {
  ALLOWED_PAYMENT_COUNTRIES,
  PAYMENT_COMPLETED_TABLE_PAGE_SIZE,
  PERMITTED_EGIRO_ROLES,
} from '../constants';
import { PaymentCompletedTable } from './components/PaymentCompletedTable';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { useFeatureFlagContext } from '@/components/particles/FeatureFlagProvider/FeatureFlagProvider';
import { UNLEASH_FLAG_NAME } from '@/constants/constants';
import {
  DirectDebitStatus,
  type GetDirectDebitAuthorisationQuery,
  type GetPaymentCompletedQuery,
  PaymentLineSortField,
  SortOrder,
  useGetPaymentCompletedQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';

export const PaymentCompletedTab = ({
  directDebitAuthData,
}: {
  directDebitAuthData?: GetDirectDebitAuthorisationQuery;
}) => {
  const featureFlagContext = useFeatureFlagContext();
  const { useFlagEnabled } = featureFlagContext;
  const { userInfo } = useAuthContext();
  const iseGIROShown =
    useFlagEnabled(UNLEASH_FLAG_NAME.mtseGIRO) &&
    userInfo?.company?.billingAddress?.country &&
    directDebitAuthData?.company.directDebit?.status ===
      DirectDebitStatus.Active &&
    ALLOWED_PAYMENT_COUNTRIES.includes(
      userInfo.company.billingAddress.country,
    ) &&
    PERMITTED_EGIRO_ROLES.includes(userInfo?.contact?.roles[0].id);

  const graphqlClient = getGraphqlClient();
  const [page, setPage] = useState(1);
  const [sorts, setSorts] = useState([
    { field: PaymentLineSortField.Date, order: SortOrder.Desc },
  ]);

  const variables = {
    page,
    pageSize: PAYMENT_COMPLETED_TABLE_PAGE_SIZE,
    sorts,
  };

  const { isLoading, data, error, isFetching } = useGetPaymentCompletedQuery<
    GetPaymentCompletedQuery,
    Error
  >(graphqlClient, variables, {});

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleSortPaidDate = (
    context: string,
    sortDirection: SortDirection,
  ) => {
    if (context !== 'paid-date') {
      return;
    }

    const order =
      sortDirection === 'ASCENDING' ? SortOrder.Asc : SortOrder.Desc;

    setSorts([
      {
        field: PaymentLineSortField.Date,
        order,
      },
    ]);
  };

  const paymentCompledData = data?.paymentLines?.data || [];
  const isNoData = paymentCompledData.length === 0;

  useGraphqlError([error]);

  return (
    <>
      {iseGIROShown && (
        <TopSection data-direct-debit-active={true}>
          <EGIROBanner />
        </TopSection>
      )}
      {(!isNoData || isLoading || isFetching) && (
        <PaymentCompletedTable
          totalItems={data?.paymentLines?.total || 0}
          currentPage={page}
          pageSize={PAYMENT_COMPLETED_TABLE_PAGE_SIZE}
          onPageChanged={handlePageChange}
          data={paymentCompledData}
          loading={isLoading || isFetching}
          handleSortChange={handleSortPaidDate}
        />
      )}
      {isNoData && !isLoading && !isFetching && (
        <EmptyState
          image={<NebulaSVG />}
          title="No Invoices Have Been Paid"
          description="We haven't received any payments from your company yet."
        />
      )}
    </>
  );
};
