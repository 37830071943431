import { Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  RadioButtonContainer,
  RadioButtonContent,
  RadioButtonIcon,
} from './styled.sc';
import { type RadioButtonProps } from './types';

export const RadioButton = ({
  label,
  value,
  icon,
  variant = 'Neutral',
  size = 'default',
  disabled,
  active,
  onChange,
}: RadioButtonProps) => (
  <RadioButtonContainer
    onClick={() => onChange?.(value)}
    vairant={variant}
    size={size}
    active={active}
    aria-disabled={disabled}
  >
    <RadioButtonContent>
      {icon && (
        <RadioButtonIcon aria-disabled={disabled}>{icon}</RadioButtonIcon>
      )}
      <Typography
        as="span"
        variant="button"
        color={disabled ? Neutral.B85 : Neutral.B18}
      >
        {label}
      </Typography>
    </RadioButtonContent>
  </RadioButtonContainer>
);
