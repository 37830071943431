import {
  type QueryObserverResult,
  type RefetchOptions,
  type RefetchQueryFilters,
} from '@tanstack/react-query';
import { EmptyState } from 'glints-aries/lib/@next';

import { type GetCompanyProfileQuery } from '@/generated/graphql';

export const ErrorTab = ({
  refetch,
}: {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<GetCompanyProfileQuery, Error>>;
}) => (
  <EmptyState
    basicButtonAction={{
      label: 'Reload',
      onClick: () => refetch(),
    }}
    description="An error has occured. Please reload the page later."
    imageName="safety-cone"
    title="Something went wrong"
  />
);
