import { Card, PrimaryButton, Typography } from 'glints-aries/lib/@next';

import {
  ActionsContainer,
  CardSectionContainer,
  CardSectionContent,
  StyledBadge,
} from '../../AutoPaymentTabStyle';
import {
  bankDetailsMapping,
  bankStatusBadge,
  type BankSwiftCode,
} from '../../constants';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import {
  type DirectDebitAuthorization,
  DirectDebitStatus,
} from '@/generated/graphql';
import { EGIRO_NEXT_COLLECTION_DAY } from '@/modules/InvoicePayments/AwaitingPaymentTab/constants';

interface ConnectedBankCardProps {
  directDebitAuthData: Omit<DirectDebitAuthorization, 'id'>;
  updateShowDisconnectModal(newValue: boolean): void;
}

export const ConnectedBankCard = ({
  directDebitAuthData,
}: ConnectedBankCardProps) => {
  const content: {
    [key in DirectDebitStatus]?: {
      badgeStatus: 'success' | 'warning' | 'critical';
      content: React.ReactNode;
      customActions?: React.ReactNode;
    };
  } = {
    [DirectDebitStatus.Pending]: {
      badgeStatus: 'warning',
      content: (
        <>
          <Typography variant="body1" as="span">
            {`Once this platform is linked with your ${
              bankDetailsMapping[
                directDebitAuthData?.bankSwiftBicCode as BankSwiftCode
              ].name
            } bank account, we will attempt to collect funds via eGIRO before the due date of each invoice.`}
          </Typography>
        </>
      ),
      customActions: (
        <ActionsContainer>
          <PrimaryButton
            onClick={() =>
              window.open(directDebitAuthData.authorizationUrl, '_blank')
            }
          >
            Go to Bank
          </PrimaryButton>
        </ActionsContainer>
      ),
    },
    [DirectDebitStatus.Active]: {
      badgeStatus: 'success',
      content: (
        <>
          <Typography variant="body1" as="span">{`Your ${
            bankDetailsMapping[
              directDebitAuthData?.bankSwiftBicCode as BankSwiftCode
            ].name
          } bank account is linked for eGIRO payments.
        Please ensure sufficient funds by the payment date, typically the ${EGIRO_NEXT_COLLECTION_DAY}. If the ${EGIRO_NEXT_COLLECTION_DAY} falls on a weekend,
        we collect on the nearest preceding workday. In the event of a failed payment, our Finance team will promptly retry. To disconnect your eGIRO account, please visit your bank app.`}</Typography>
          <ul>
            <li>
              <Typography variant="body1" as="span">
                • Connected date:{' '}
                <FormattedDate
                  date={directDebitAuthData?.activatedAt}
                  textVariant="body1"
                />
              </Typography>
            </li>
            <li>
              {' '}
              <Typography variant="body1" as="span">
                • Payment date: {EGIRO_NEXT_COLLECTION_DAY} of each month
              </Typography>
            </li>
          </ul>
        </>
      ),
    },
  };

  return (
    <Card
      customActions={
        content[directDebitAuthData.status]?.customActions ?? (
          <ActionsContainer>
            <PrimaryButton
              onClick={() =>
                window.open(
                  bankDetailsMapping[
                    directDebitAuthData?.bankSwiftBicCode as BankSwiftCode
                  ].url,
                  '_blank',
                )
              }
            >
              Go to Bank
            </PrimaryButton>
          </ActionsContainer>
        )
      }
    >
      <Card.Section>
        <CardSectionContainer>
          <div className="active-bank-icon" style={{ width: '48px' }}>
            {
              bankDetailsMapping[
                directDebitAuthData?.bankSwiftBicCode as BankSwiftCode
              ].icon
            }
          </div>
          <CardSectionContent>
            <Typography variant="subtitle1" as="span">
              {
                bankDetailsMapping[
                  directDebitAuthData?.bankSwiftBicCode as BankSwiftCode
                ].name
              }
            </Typography>
            {content[directDebitAuthData.status]?.content}
          </CardSectionContent>
          {bankStatusBadge[directDebitAuthData.status]?.badge && (
            <>
              <StyledBadge
                status={content[directDebitAuthData.status]?.badgeStatus}
              >
                {bankStatusBadge[directDebitAuthData.status]?.badge}
              </StyledBadge>
            </>
          )}
        </CardSectionContainer>
      </Card.Section>
    </Card>
  );
};
