import { Icon } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  GlintsAntdTooltip,
  type GlintsAntdTooltipProps,
} from '@/components/atoms/GlintsAntdTooltip/GlintsAntdTooltip';

export const BaseAmountTooltip = ({
  placement = 'bottom',
}: {
  placement?: GlintsAntdTooltipProps['placement'];
}) => (
  <GlintsAntdTooltip
    placement={placement}
    title="The base currency is selected based on the billing currency and the company's billing address. The displayed amount is an estimate and may not reflect the final amount billed to you."
  >
    <Icon
      name="ri-question-fill"
      height="16px"
      width="16px"
      fill={Neutral.B40}
    />
  </GlintsAntdTooltip>
);
