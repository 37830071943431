import { Typography } from 'glints-aries/lib/@next';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const CardSectionDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled(Typography)`
  margin-bottom: ${space4};
  margin-top: ${space8};
`;

export const StyledValue = styled(Typography)`
  margin-top: 0;
  margin-bottom: ${space16};
`;
