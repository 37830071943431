import { useState } from 'react';
import { Typography, useAlert } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import {
  DEFAULT_BILLING_CURRENCY,
  PAGE_SIZE,
  TALENT_OPTIONS,
} from '../constants';
import { EXPENSES_TABLE_KEY } from './components/ExpensesTable/constants';
import { ExpensesTable } from './components/ExpensesTable/ExpensesTable';
import { getGraphqlClient } from '@/clients/graphql';
import { ExportPopoverButton } from '@/components/atoms/ExportPopoverButton/ExportPopoverButton';
import {
  type TableFilters,
  type TableOnChange,
  type TableSorts,
} from '@/components/atoms/GlintsAntdTable/types';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { ALERT_STATUS, exportFileAlertMessages } from '@/constants/alert';
import { EXPORT_SCOPE, type ExportScope } from '@/constants/exportFile';
import { ANTD_SORT_ORDER, ANTD_SORT_ORDER_MAP } from '@/constants/sortOrder';
import {
  type EmploymentStatus,
  ExpenseClaimSortField,
  type ExpenseClaimsStatusFilter,
  type ExportExpenseClaimMutation,
  type ExportExpenseClaimMutationVariables,
  type GetCompanyHubberHubsQuery,
  type GetExpenseClaimsQuery,
  SortOrder,
  useExportExpenseClaimMutation,
  useGetCompanyHubberHubsQuery,
  useGetExpenseClaimsQuery,
} from '@/generated/graphql';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { StatusFilterSelect } from '@/modules/ManagedTalents/components/StatusFilterSelect';
import { statusFilterOptions } from '@/modules/ManagedTalents/constants';
import * as PageLayoutStyled from '@/styles/pageLayout.sc';
import { isFilteredInfoEmpty } from '@/utils/glintsAntdTable';
import { getCompanyBillingCurrency } from '@/utils/locale';
import { generateFilteredHubs } from '@/utils/talentHub';

const ExpensesPage = () => {
  const graphqlClient = getGraphqlClient();
  const { userInfo } = useAuthContext();
  const { open: openAlert } = useAlert();

  const [page, setPage] = useState(1);
  const [selectedTalent, setSelectedTalent] = useState<string[]>([
    TALENT_OPTIONS[0].value,
  ]);
  const [hideStatusBadge, setHideStatusBadge] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState<TableFilters>({
    [EXPENSES_TABLE_KEY.employee]: null,
    [EXPENSES_TABLE_KEY.talentHub]: null,
    [EXPENSES_TABLE_KEY.reportingManager]: null,
    [EXPENSES_TABLE_KEY.status]: null,
  });
  const [sortedInfo, setSortedInfo] = useState<TableSorts>({
    columnKey: EXPENSES_TABLE_KEY.createdAt,
    order: ANTD_SORT_ORDER[SortOrder.Desc],
  });

  const isFiltering = !isFilteredInfoEmpty(filteredInfo);

  const companyBillingCurrency =
    getCompanyBillingCurrency(userInfo?.company?.billingAddress) ||
    DEFAULT_BILLING_CURRENCY;

  const getExpenseClaimsVariables = {
    page: page,
    pageSize: PAGE_SIZE,
    sorts: [
      {
        field: ExpenseClaimSortField.RequestedAt,
        order: sortedInfo.order
          ? ANTD_SORT_ORDER_MAP[sortedInfo.order]
          : SortOrder.Desc,
      },
    ],
    hubberName: filteredInfo[EXPENSES_TABLE_KEY.employee]?.toString() || '',
    hubberStatus: selectedTalent as EmploymentStatus[],
    expenseClaimStatus: filteredInfo[EXPENSES_TABLE_KEY.status] as
      | ExpenseClaimsStatusFilter[]
      | undefined,
    hub: filteredInfo[EXPENSES_TABLE_KEY.talentHub]?.length
      ? generateFilteredHubs(
          filteredInfo[EXPENSES_TABLE_KEY.talentHub] as string[],
        )
      : undefined,
    directManagerIds: filteredInfo[EXPENSES_TABLE_KEY.reportingManager]?.length
      ? (filteredInfo[EXPENSES_TABLE_KEY.reportingManager] as string[])
      : undefined,
    baseCurrency: companyBillingCurrency,
  };

  const {
    isLoading: isLoadingExpenseClaims,
    data: expenseClaimsData,
    error: getExpenseClaimsError,
  } = useGetExpenseClaimsQuery<GetExpenseClaimsQuery, Error>(
    graphqlClient,
    getExpenseClaimsVariables,
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const { data: companyData, error: getCompanyHubberHubsError } =
    useGetCompanyHubberHubsQuery<GetCompanyHubberHubsQuery, Error>(
      graphqlClient,
    );

  const { mutate: exportMutate, error: exportExpenseClaimError } =
    useExportExpenseClaimMutation<Error, ExportExpenseClaimMutation>(
      graphqlClient,
    );

  const handleTalentSelect = ({ value }: { value: string }) => {
    if (!selectedTalent) return;

    const isSelected = selectedTalent.includes(value);
    const updatedSelectedStatus = isSelected
      ? selectedTalent.filter((option) => option !== value)
      : [...selectedTalent, value];

    setSelectedTalent(updatedSelectedStatus);
    setPage(1);

    if (updatedSelectedStatus.length < statusFilterOptions.length) {
      setHideStatusBadge(false);
    }
  };

  const updateHideStatusBadge = (hide: boolean) => {
    setHideStatusBadge(hide);
  };

  const handleTableChange: TableOnChange = (_, filters, sorter) => {
    setFilteredInfo(filters as TableFilters);
    setSortedInfo(sorter as TableSorts);
    setPage(1);
  };

  const handleExport = ({ scope }: { scope: ExportScope }) => {
    let exportVariables: ExportExpenseClaimMutationVariables = {
      currencyInput: companyBillingCurrency,
    };

    if (scope === EXPORT_SCOPE.filtered) {
      exportVariables = {
        hubberName: filteredInfo[EXPENSES_TABLE_KEY.employee]?.toString() || '',
        hub: filteredInfo[EXPENSES_TABLE_KEY.talentHub]?.length
          ? generateFilteredHubs(
              filteredInfo[EXPENSES_TABLE_KEY.talentHub] as string[],
            )
          : undefined,
        directManagerIds: filteredInfo[EXPENSES_TABLE_KEY.reportingManager]
          ?.length
          ? (filteredInfo[EXPENSES_TABLE_KEY.reportingManager] as string[])
          : undefined,
        claimStatus: filteredInfo[EXPENSES_TABLE_KEY.status] as
          | ExpenseClaimsStatusFilter[]
          | undefined,
        hubberStatus: selectedTalent as EmploymentStatus[],
        ...exportVariables,
      };
    }

    exportMutate(exportVariables, {
      onSuccess: (data) => {
        openAlert({
          content: exportFileAlertMessages[ALERT_STATUS.success],
          status: ALERT_STATUS.success,
          zIndex: 1200,
          position: {
            top: '24px',
          },
        });

        const linkElement = document.createElement('a');
        linkElement.href = data?.exportExpenseClaim?.fileUrl;
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
      },
      onError: () => {
        openAlert({
          content: exportFileAlertMessages[ALERT_STATUS.error],
          status: ALERT_STATUS.error,
          zIndex: 1200,
          position: {
            top: '24px',
          },
        });
      },
    });
  };

  useGraphqlError([
    getExpenseClaimsError,
    getCompanyHubberHubsError,
    exportExpenseClaimError,
  ]);

  return (
    <PageLayoutStyled.Container>
      <PageLayoutStyled.Header>
        <Typography variant="headline6" color={Neutral.B18}>
          Expenses
        </Typography>
        <PageLayoutStyled.Toolbar>
          <StatusFilterSelect
            selectedStatus={selectedTalent}
            handleSelectStatus={handleTalentSelect}
            updateHideStatusBadge={updateHideStatusBadge}
            hideBadge={hideStatusBadge}
          />
          <ExportPopoverButton
            disableExportFiltered={!isFiltering}
            onExportAll={() => handleExport({ scope: EXPORT_SCOPE.all })}
            onExportFilteredData={() =>
              handleExport({ scope: EXPORT_SCOPE.filtered })
            }
          />
        </PageLayoutStyled.Toolbar>
      </PageLayoutStyled.Header>
      <PageLayoutStyled.Body>
        <ExpensesTable
          expenseClaimsData={expenseClaimsData?.expenseClaims.data || []}
          companyHubs={companyData?.company.hubberHubs || []}
          loading={isLoadingExpenseClaims}
          isFiltering={isFiltering}
          filteredProps={{
            filteredInfo,
            setFilteredInfo,
            onReset: () => setFilteredInfo({}),
          }}
          paginationProps={{
            currentPage: expenseClaimsData?.expenseClaims.pageNo || 1,
            pageSize: expenseClaimsData?.expenseClaims.pageSize || 0,
            totalItems: expenseClaimsData?.expenseClaims.total || 0,
            onPageChanged: (page: number) => setPage(page),
          }}
          onTableChange={handleTableChange}
        />
      </PageLayoutStyled.Body>
    </PageLayoutStyled.Container>
  );
};

export default ExpensesPage;
