export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const alertContent = {
  [AlertType.SUCCESS]: 'User profile has been updated',
  [AlertType.ERROR]: 'User profile update failed',
};

export const menuNames = {
  EMPLOYEES: 'EMPLOYEES',
  ATTENDANCE: 'ATTENDANCE',
  TIME_OFF: 'TIME_OFF',
  EXPENSES: 'EXPENSES',
  INVOICE_PAYMENTS: 'INVOICE_PAYMENTS',
  COMPANY_SETTINGS: 'COMPANY_SETTINGS',
} as const;
export type MenuName = ValueOf<typeof menuNames>;

export const menuLabels = {
  [menuNames.EMPLOYEES]: 'Employees',
  [menuNames.ATTENDANCE]: 'Attendance',
  [menuNames.TIME_OFF]: 'Time Off',
  [menuNames.EXPENSES]: 'Expenses',
  [menuNames.INVOICE_PAYMENTS]: 'Invoice Payments',
  [menuNames.COMPANY_SETTINGS]: 'Company Settings',
} as const;

export const menuIcons = {
  [menuNames.EMPLOYEES]: {
    icon: 'ri-user5-line',
    selectedIcon: 'ri-user5-fill',
  },
  [menuNames.ATTENDANCE]: {
    icon: 'ri-calendar-check-line',
    selectedIcon: 'ri-calendar-check-fill',
  },
  [menuNames.TIME_OFF]: {
    icon: 'ri-suitcase3-line',
    selectedIcon: 'ri-suitcase3-fill',
  },
  [menuNames.EXPENSES]: {
    icon: 'ri-custom-expense-line',
    selectedIcon: 'ri-custom-expense-fill',
  },
  [menuNames.INVOICE_PAYMENTS]: {
    icon: 'ri-bank-card-line',
    selectedIcon: 'ri-bank-card-fill',
  },
  [menuNames.COMPANY_SETTINGS]: {
    icon: 'ri-building-line',
    selectedIcon: 'ri-building-fill',
  },
} as const;

/**
 * Maps menu names to their corresponding URL segments.
 * Used to determine which menu a given URL belongs to.
 *
 * Example:
 * If the current URL is "/time-off/time-off-requests",
 * this map helps identify that it belongs to the TIME_OFF menu.
 */
export const menuUrlSegments = {
  [menuNames.EMPLOYEES]: 'my-employees',
  [menuNames.ATTENDANCE]: 'attendance',
  [menuNames.TIME_OFF]: 'time-off',
  [menuNames.EXPENSES]: 'expenses',
  [menuNames.INVOICE_PAYMENTS]: 'invoice-payments',
  [menuNames.COMPANY_SETTINGS]: 'company-settings',
} as const;

export const menuUrls = {
  [menuNames.EMPLOYEES]: '/my-employees',
  [menuNames.ATTENDANCE]: '/attendance',
  [menuNames.TIME_OFF]: '/time-off/time-off-requests',
  [menuNames.EXPENSES]: '/expenses',
  [menuNames.INVOICE_PAYMENTS]: '/invoice-payments/awaiting-payment',
  [menuNames.COMPANY_SETTINGS]: '/company-settings/company-information',
} as const;
