import { format } from 'date-fns';

import {
  bankDetailsMapping,
  BankError,
  type BankSwiftCode,
} from '../../constants';
import * as Styled from './styled.sc';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@/constants/date';
import {
  type DirectDebitAuthorization,
  DirectDebitStatus,
  type Money,
} from '@/generated/graphql';
import { formatMoney } from '@/utils/formatString';

interface BankStatusBannerProps {
  directDebitAuthData?: Omit<DirectDebitAuthorization, 'id'>;
  lastMonthTotalInvoiceSGD?: Omit<Money, 'inCurrencies'>;
  updateShowBanner(newValue: boolean): void;
}
export const BankStatusBanner = ({
  directDebitAuthData,
  lastMonthTotalInvoiceSGD,
  updateShowBanner,
}: BankStatusBannerProps) => {
  const yearFromNow = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  );
  const formattedYearFromNow = format(yearFromNow, MONTH_DAY_YEAR_DATE_FORMAT);
  const bankName =
    bankDetailsMapping[directDebitAuthData?.bankSwiftBicCode as BankSwiftCode]
      .name;
  const lastMonthTotal = lastMonthTotalInvoiceSGD
    ? `  ${lastMonthTotalInvoiceSGD?.currency} ${formatMoney({
        amount: lastMonthTotalInvoiceSGD?.amount,
      })} `
    : 'your expected payment amount ';

  const bannerFailMessage = {
    [BankError.PAYER_AUTHORIZATION_FAILED]: `Connection to your ${bankName} account failed due to an error occurring on your bank's page. Please try again later or report this issue to us if it continues to recur.`,
    [BankError.PAYER_SPECIFIED_LOWER_MAX_AMOUNT]: `Connection to your ${bankName} account failed because the maximum amount is set too low. Please try again and ensure it is set above ${lastMonthTotal} to avoid any disruptions in your future payments.`,
    [BankError.PAYER_SPECIFIED_EARLIER_END_DATE]: `Connection to your ${bankName} account failed because the end date is set too early. Please try again and ensure it is later than ${formattedYearFromNow}, at least 1 year from today.`,
    [BankError.DEFAULT]: `Connection to your ${bankName} account failed due to unknown errors. Please try again or report this issue to us if it continues to recur.`,
  };

  const bannerContent: {
    [key in DirectDebitStatus]?: {
      status: 'warning' | 'critical';
      content: React.ReactNode;
    };
  } = {
    [DirectDebitStatus.Pending]: {
      status: 'warning',
      content:
        'Connection with your eGIRO account is currently in progress. If it remains pending for more than 60 minutes, please consult your finance team as there may be an additional approval process involved. To cancel your connection request, please visit your bank app.',
    },
    [DirectDebitStatus.Failed]: {
      status: 'critical',
      content:
        directDebitAuthData?.failureReason &&
        Object.keys(bannerFailMessage).includes(
          directDebitAuthData?.failureReason,
        )
          ? bannerFailMessage[directDebitAuthData?.failureReason as BankError]
          : bannerFailMessage[BankError.DEFAULT],
    },
  };

  const handleBannerDismiss = () => {
    updateShowBanner(false);
  };

  if (!directDebitAuthData) {
    return null;
  }

  return (
    <Styled.BankBanner
      iconName="ri-error-warning-fill"
      status={bannerContent[directDebitAuthData.status]?.status}
      dismissable={directDebitAuthData.status === DirectDebitStatus.Failed}
      onDismiss={handleBannerDismiss}
    >
      <Styled.BannerContent>
        {bannerContent[directDebitAuthData.status]?.content}
      </Styled.BannerContent>
    </Styled.BankBanner>
  );
};
