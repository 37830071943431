import { space8 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const CopyText = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};

  svg {
    cursor: pointer;
  }
`;
