import { Box } from 'glints-aries';
import { noop } from 'lodash-es';

import * as Styled from './styled.sc';
import type { SelectorOption } from './types';

interface Props {
  selectedValues?: string[];
  optItem: SelectorOption;
  onChange: (value: SelectorOption) => void;
  multiple?: boolean;
}

export const DropdownListItem = ({
  selectedValues,
  optItem,
  onChange,
  multiple,
}: Props) => {
  const isDisabled = selectedValues?.includes(optItem.value)
    ? false
    : optItem.disabled;

  const className = selectedValues?.includes(optItem.value)
    ? 'item--selected'
    : isDisabled
    ? 'item--disabled'
    : '';

  return (
    <Box px={8}>
      <Styled.DropdownListItem
        onClick={() => {
          if (!isDisabled) {
            onChange(optItem);
          }
        }}
        className={className}
      >
        {multiple && (
          <Styled.DropdownListItemCheckboxContainer>
            <Styled.DropdownListItemCheckbox
              type="checkbox"
              checked={selectedValues?.includes(optItem.value)}
              disabled={isDisabled}
              onChange={noop}
            />
            <Styled.DropdownListItemCheckboxIcon name="ri-check" />
          </Styled.DropdownListItemCheckboxContainer>
        )}
        {optItem.icon && (
          <Styled.DropdownListItemIcon>
            {optItem.icon}
          </Styled.DropdownListItemIcon>
        )}
        {optItem.label}
        {optItem.badge && (
          <Styled.DropdownListItemBadge>
            {optItem.badge}
          </Styled.DropdownListItemBadge>
        )}
      </Styled.DropdownListItem>
    </Box>
  );
};
