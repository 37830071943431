import { differenceInDays, intervalToDuration, parse } from 'date-fns';

export const computeTenure = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate?: string | null;
}) => {
  const currentDate = new Date();
  const end = endDate ?? currentDate;

  const interval = intervalToDuration({
    start: new Date(startDate),
    end: new Date(end),
  });

  // include start day in days
  const daysCorrection = interval.days && interval.days + 1;
  const months =
    daysCorrection === 30
      ? interval.months && interval.months + 1
      : interval.months;

  return {
    tenureYears: interval.years,
    tenureMonths: months,
  };
};

export const computeTimeOffDuration = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): number => {
  const formatString = 'yyyy-MM-dd';
  const start = parse(startDate, formatString, new Date());
  const end = parse(endDate, formatString, new Date());

  const duration = differenceInDays(end, start) + 1;

  return duration;
};
