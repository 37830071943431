import {
  Banner as AriesBanner,
  Modal as AriesModal,
} from 'glints-aries/lib/@next';
import {
  borderRadius2,
  borderRadiusHalf,
} from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

const SIDER_WIDTH = '320px';

export const Modal = styled(AriesModal)`
  @media (max-width: ${BREAKPOINTS.medium}) {
    max-height: 100vh;
  }

  .modal-content {
    position: relative;
    padding: 0;
    width: 90vw;
    height: 90vh;
    background: conic-gradient(
      from 134.64deg at 14.69% 65.38%,
      #f8fafc -31.16deg,
      rgba(234, 244, 254, 0.92) 12.57deg,
      #fbebff 44.23deg,
      #faf8eb 76.12deg,
      #f8fafc 187.5deg,
      #f8fafc 328.84deg,
      rgba(234, 244, 254, 0.92) 372.57deg
    );
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.medium}) {
      width: 100vw;
      height: 100vh;
    }
  }
`;

export const ModalArt = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;

  @media (max-width: ${BREAKPOINTS.small}) {
    display: none;
  }
`;

export const Banner = styled(AriesBanner)`
  min-height: initial;
  position: relative;
  padding: ${space12};
`;

export const FormContainer = styled.div<{ showBanner?: boolean }>`
  height: ${(props) => (props.showBanner ? 'calc(100% - 54px)' : '100%')};
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.large}) {
    height: ${(props) =>
      props.showBanner ? 'calc(100% - 54px - 16px)' : '100%'};
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: ${(props) =>
      props.showBanner ? 'calc(100% - 54px - 16px)' : '100%'};
  }
`;

export const Form = styled.form`
  padding: ${space24};

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${space16};
  }
`;

export const Container = styled.div`
  display: flex;
  gap: ${space24};

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;

export const Sider = styled.div`
  width: ${SIDER_WIDTH};
  min-width: ${SIDER_WIDTH};
  z-index: 3;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
    min-width: 100%;
  }
`;

export const SiderCardSection = styled.div`
  flex: 1;
`;

export const SiderCardContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
  }
`;

export const SiderCardActions = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: flex-end;
  }
`;

export const StepLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${space16};
  margin-bottom: ${space16};
`;

export const StepNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: ${borderRadiusHalf};
  background-color: ${Blue.S08};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space16};
`;

export const FormLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
  margin-bottom: ${space4};
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const MainCardContainer = styled.div`
  height: 100%;

  .card-container,
  .card-content-container {
    height: 100%;
  }
`;

export const MainCardHeader = styled.div`
  padding: ${space12} ${space24};
`;

export const MainCardHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
    justify-content: center;
    gap: ${space8};
  }
`;

export const MainCardHeaderContentTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
`;

export const CurrencySelectContainer = styled.div`
  width: 100%;
  background: ${Neutral.B95};
  display: flex;
  justify-content: flex-end;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

export const UnderDevelopmentStateContainer = styled(EmptyStateContainer)`
  padding: ${space24};
`;

export const LoadingStateContainer = styled.div`
  padding: ${space24};

  li {
    margin-bottom: ${space16} !important;
  }
`;

export const BorderedIcon = styled.div`
  display: inline-block;
  line-height: 0;
  border: 1px solid ${Neutral.B85};
  border-radius: ${borderRadius2};
`;
