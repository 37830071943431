export const roundDownNearestHalf = (number: number) =>
  Math.round(number * 2) / 2;

export const roundUpToNearest500 = (number: number) => {
  if (number % 500 !== 0) {
    const roundedUpNumber = Math.ceil(number / 500) * 500;
    return roundedUpNumber;
  }

  return number;
};
