import {
  Breakpoints,
  IndexTable,
  Tooltip as AriesTooltip,
  Typography,
} from 'glints-aries/lib/@next';
import {
  borderRadius4,
  borderRadius8,
} from 'glints-aries/lib/@next/utilities/borderRadius';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { NotoSans } from 'glints-aries/lib/@next/utilities/fonts';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

const HEADER_HEIGHT = '295px';

export const IndexTableContainer = styled.div`
  box-shadow: 0px 0px 0px 1px rgba(84, 84, 84, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Neutral.B100};
  height: calc(100% - ${HEADER_HEIGHT});

  transform: translateX(-24px);
  width: 100%;
  position: absolute;

  @media (max-width: ${Breakpoints.large}) {
    transform: translateX(-16px);
  }

  table thead tr {
    height: 52px;

    th {
      z-index: 1;
    }
  }
`;

export const IndexTableBulkActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${space8};
  width: 200px;
  padding: ${space16};
  border-radius: ${borderRadius8};
  background: ${Neutral.B18};
  box-shadow: 0px 8px 20px 0px rgba(71, 71, 71, 0.2),
    0px 3px 6px -3px rgba(71, 71, 71, 0.08);
  position: sticky;
  margin-left: 60px;
  bottom: 60px;

  @media (max-width: ${Breakpoints.large}) {
    bottom: 80px;
  }
`;

export const IndexTableBulkActionsText = styled.div`
  color: ${Neutral.B100};
  font-size: 14px;
  font-weight: 400;
  font-family: ${NotoSans}, sans-serif;
`;

export const Cell = styled(IndexTable.Cell)`
  padding: ${space16};
`;

export const LeftPaddingWrapper = styled.div`
  padding-left: ${space8};
`;

export const PaymentAmountWrapper = styled.div`
  padding-right: ${space8};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${space8};

  svg {
    height: 20px;
    width: 20px;
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const LoadingRow = styled(IndexTable.Row)`
  pointer-events: none;
`;

export const TablePaginationContainer = styled.div`
  padding: ${space8} ${space16};
  background: ${Neutral.B100};
  box-shadow: 0px -2px 10px -1px rgba(0, 0, 0, 0.15);
  position: sticky;
  bottom: -32px;
`;

export const Currency = styled(Typography)`
  line-height: normal;
  color: ${Neutral.B40};
`;

export const InvoiceReferenceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${space8};
`;

export const DisputedBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${space4};
  padding: ${space4} ${space8};

  background-color: ${Blue.S08};
  color: ${Blue.S99};
  border-radius: ${borderRadius4};
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
    fill: ${Blue.S99};
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

export const TableActionsContainer = styled.div`
  display: flex;
  fled-direction: row;
  gap: ${space16};
  align-items: center;
`;

export const PopoverActivatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius4};
  height: 32px;
  width: 32px;
  cursor: pointer;

  &:hover {
    background-color: ${Neutral.B99};
  }

  svg {
    height: 20px;
    width: 20px;
    transform: rotate(90deg);
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const Tooltip = styled(AriesTooltip)`
  position: static;
`;
