import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledCollapsibleFooter = styled.div`
  margin-top: auto;
  border-top: 1px solid ${Neutral.B85};
`;

export const CollapsedUserSection = styled.div`
  display: flex;
  height: 40px;
  padding: ${space4} ${space4} ${space4} ${space8};
  align-items: center;
  justify-content: center;
  gap: ${space8};
  border-radius: 50px;

  &[data-nav-collapsed='true'] {
    width: 68px;
  }
`;

export const UserAccountSection = styled.div`
  cursor: pointer;
  display: flex;
  padding: ${space12} ${space8};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${space8};

  span {
    margin-bottom: ${space4};
  }
`;

export const IconContainer = styled.div<{ isCollapsed: boolean }>`
  margin-left: auto;

  svg {
    transform: rotate(${(props) => (props.isCollapsed ? '0deg' : '-180deg')});
    transition: transform 200ms ease-in-out;
  }
`;

export const Content = styled.div<{ isCollapsed: boolean }>`
  margin: ${(props) => (props.isCollapsed ? 0 : `${space8} ${space16}`)};
  overflow: hidden;
  max-height: ${(props) => (props.isCollapsed ? 0 : 'auto')};
  transition: max-height 200ms ease-in-out;
`;
