import { Breakpoints } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 8px;
  border-radius: 20px;
  transition: background-color 1s, width 1s, height 1s;

  height: 280px;
  width: 300px;
  box-shadow: 0px 0px 0px 0px rgba(111, 111, 111, 0.2);
  background-color: rgba(255, 255, 255, 0);

  &.inactive {
    height: 400px;
    width: 100%;
    max-width: 680px;
    box-shadow: 0px 1px 2px 2px rgba(111, 111, 111, 0.2);
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(10px);
  }

  &.inactive * {
    visibility: hidden;
  }

  @media (max-width: ${Breakpoints.large}) {
    width: 50%;
    min-width: 280px;
  }
`;

export const RocketContainer = styled.div`
  height: 120px;
  overflow: hidden;
  align-content: center;

  svg {
    height: 75px;
    justify-content: center;
    animation: launching 3s linear 0s infinite;
  }

  @keyframes launching {
    0% {
      transform: translateY(10px);
    }
    3% {
      transform: translateY(10px) rotate(8deg);
    }
    6% {
      transform: translateY(10px) rotate(-8deg);
    }
    9% {
      transform: translateY(10px) rotate(8deg);
    }
    12% {
      transform: translateY(10px) rotate(-8deg);
    }
    15% {
      transform: translateY(15px) rotate(0deg);
    }
    50% {
      transform: translateY(-30px);
    }
    85% {
      opacity: 1;
      transform: translateY(-60px);
    }
    100% {
      opacity: 0;
      transform: translateY(-70px);
    }
  }
`;

export const StyledTextArea = styled.div`
  height: 100px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  animation: flickering 600ms ease-in-out infinite alternate;

  @keyframes flickering {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.3;
    }
  }
`;

export const LoadingTitleContainer = styled.div`
  position: absolute;
  width: 240px;
  height: 50px;
  top: 60px;
  left: 60px;
`;
